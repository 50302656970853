import React from "react";
import "../css/components/Filter.scss";
import {Row, Col, Input, Select} from "antd";

import {SearchOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const TYPE_SELECTS = [
    {name: 'All', label:'all', value: ''},
    {name: 'BUY', label: 'fmk_trans_type_buy', value: 'BUY'},
    {name: 'SELL', label: 'fmk_trans_type_sell', value: 'SELL'},
    {name: 'TRANSFER_BUY', label: 'fmk_trans_type_transfer_buy', value: 'TRANSFER_BUY'},
    {name: 'TRANSFER_SELL', label:'fmk_trans_type_transfer_sell', value: 'TRANSFER_SELL'},
];


const Filter = (props) => {
    const {
        params = {}, setParams = () => {}
    } = props;

    const { t } = useTranslation();
    const texts = t;

    return (
        <Row className="filter" gutter={[8, 8]}>
            <Col span={3} lg={6} sm={12} xs={24}>
                <Input
                    prefix={<SearchOutlined/>}
                    value={params.keyword}
                    placeholder={texts('search')}
                    onChange={(e) => setParams({...params, keyword: e.target.value})}
                />
            </Col>

            <Col span={3} lg={6} sm={12} xs={24}>
                <Select
                    placeholder={"Loại giao dịch"}
                    value={params.type}
                    onChange={(v) => setParams({...params, type: v})}
                    showSearch={true}
                    optionFilterProp="children"
                    className="w-full"
                    filterOption={(input, option) =>
                        `${option.children}`.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {TYPE_SELECTS.map((type) => (
                        <Select.Option value={type.value} key={type.name}>
                            {texts(type.label)}
                        </Select.Option>
                    ))}
                </Select>
            </Col>
        </Row>
    );
};

export default Filter;
