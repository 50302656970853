import React, {
    useState,
    useEffect,
    useMemo
} from "react";

import {
    Form,
    Input,
    Row,
    Col,
    Button,
    message,
    Spin,
    Select
} from 'antd';

import {AdminDetailHeader} from "../../../components";
import {PASSWORD_RULES, PHONE_RULES, USERNAME_RULES} from "../../../config/rules";

import PermissionListData from "../permissions/components/ListData";
import roleServices from "../../../services/admin/role";
import userServices from "../../../services/admin/user";
import {EMAIL_PATTERN, PHONE_PATTERN} from "../../../config/patterns";

const CreateUser = (props) => {
    const {router} = props;
    const [form] = Form.useForm();
    const [role, setRole] = useState({});
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [callingAPI, setCallingAPI] = useState(false);

    const roleIds = Form.useWatch('roleIds', form) || [];

    useEffect(() => {
        fetchRoles();
    }, [])

    const fetchRoles = async (keyword = null) => {
        setLoading(true);
        await roleServices
            .search_roles({
                keyword: keyword,
                page: 0,
                pageSize: 10,
            })
            .then((response) => {
                setRoles(response?.data || []);
            })
            .catch(() => {
                setRoles([]);
            });
        setLoading(false);
    }

    const handleCreateUser = async () => {
        form.validateFields().then(async (values) => {
            setCallingAPI(true);

            let data = {
                adminRequest: {
                    active: 1,
                    email: values?.email || '',
                    name: values?.name || '',
                    password: values?.password || '',
                    phone: values?.phone || '',
                    username: values?.username || '',
                },
                roleIds: values.roleIds,
                permissionIds: (role?.roleCategories || []).map((c) => c.permissions).flat().filter(
                    (p) => p.isChoose && !selectedRolePermissions.includes(p.name)).map(
                    (p) => p.permissionId).filter(Boolean)
            };

            await userServices.create_user({}, data).then((response) => {
                if (response.status?.code === 'CORE-404') {
                    message.error(response?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
                    setCallingAPI(false)
                }
                else {
                    message.success('Thêm mới tài khoản thành công')
                    setCallingAPI(false)
                    window.navigatePage('CMS_USERS')
                }
            })
            .catch((error) => {
                setCallingAPI(false)
                message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
            });
        })
    }

    const selectedRolePermissions = useMemo(() => {
        const selectedRoles = roles.filter((r) => roleIds.includes(r.roleId))
        return selectedRoles.map((r) => r.permission).flat();
    }, [roleIds, roles])

    return (
        <div
            className="role-create"
        >
            <AdminDetailHeader title={router.label}/>
            <Spin spinning={loading}>
                <Form
                    form={form}
                    layout='vertical'
                >
                    <Row gutter={[16, 0]}>
                        <Col lg={8} md={8} sm={24}>
                            <Form.Item
                                label="Tên đăng nhập"
                                name={'username'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng nhập tên đăng nhập.'
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Nhập tên đăng nhập"
                                    disabled={callingAPI}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} md={8} sm={24}>
                            <Form.Item
                                label="Mật khẩu"
                                name={'password'}
                                rules={PASSWORD_RULES}
                            >
                                <Input
                                    placeholder="Nhập mật khẩu"
                                    disabled={callingAPI}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} md={8} sm={24}>
                            <Form.Item
                                label="Nhập lại mật khẩu"
                                name={'re_password'}
                                dependencies={['password']}
                                rules={[
                                    ...PASSWORD_RULES,
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Nhập lại mật khẩu không khớp'));
                                        },
                                    })
                                ]}
                            >
                                <Input
                                    placeholder="Nhập lại mật khẩu"
                                    disabled={callingAPI}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} md={8} sm={24}>
                            <Form.Item
                                label="Họ tên"
                                name={'name'}
                            >
                                <Input
                                    placeholder="Nhập họ tên"
                                    disabled={callingAPI}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} md={8} sm={24}>
                            <Form.Item
                                label="Số điện thoại"
                                name={'phone'}
                                rules={[
                                    {
                                        pattern: PHONE_PATTERN,
                                        message: 'Số điện thoại không hợp lệ',
                                    }
                                ]}
                            >
                                <Input
                                    placeholder="Nhập số điện thoại"
                                    disabled={callingAPI}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} md={8} sm={24}>
                            <Form.Item
                                label="Email"
                                name={'email'}
                                rules={[
                                    {
                                        pattern: EMAIL_PATTERN,
                                        message: 'Email không hợp lệ',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Nhập Email"
                                    disabled={callingAPI}
                                />
                            </Form.Item>
                        </Col>


                        <Col lg={24} sm={24}>
                            <Form.Item
                                label="Vị trí"
                                name={'roleIds'}
                                rules={[]}
                            >
                                <Select
                                    className="filter-filed"
                                    placeholder="Chọn quyền hạn cho tài khoản"
                                    mode="multiple"
                                    showSearch={true}
                                    onSearch={(v) => fetchRoles(v)}
                                    optionFilterProp="children"
                                    disabled={callingAPI}
                                    filterOption={(input, option) =>
                                        `${option.children}`.toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {roles.map((r) => (
                                        <Select.Option value={r.roleId} key={r.roleId}>
                                            {r.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={24} sm={24}>
                            <Form.Item
                                label="Quyền truy cập đặc biệt"
                                name={'permissionIds'}
                                rules={[]}
                            >
                                {
                                    !loading && <PermissionListData
                                        role={role}
                                        setRole={setRole}
                                        rolePermissions={selectedRolePermissions}
                                    />
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row gutter={[8, 8]} className="mt-0">
                    <Col lg={12} align="right">
                        <Button
                            size="large"
                            className="w-30"
                            disabled={callingAPI}
                            onClick={() => window.navigatePage('BACK')}
                        >
                            Huỷ
                        </Button>
                    </Col>
                    <Col lg={12}>
                        <Button
                            size="large"
                            type="primary"
                            loading={callingAPI}
                            className="w-30"
                            onClick={() => handleCreateUser()}
                        >
                            Lưu
                        </Button>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};

export default CreateUser;
