import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function search_banks(params = {}) {
  return request({
    url: ENDPOINT.DICT_ITEMS,
    method: "get",
    params,
  });
}

function search_bank_branchs(id, params = {}) {
  return request({
    url: ENDPOINT.DICT_ITEM_BRANCHS.replace(':id', id),
    method: "get",
    params,
  });
}



export default {
  search_banks,
  search_bank_branchs
};
