import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Input,
    Modal,
    Form,
    Select,
    message,
    Card, Tag
} from 'antd';

import {
} from "@ant-design/icons";
import moment from 'moment';
import CONSTANTS from "../../../../../config/constants";
import { PROJECT_RULES } from "../../../../../config/rules";
// import productServices from '../../../../../services/finblue/product';
import customerService from "../../../../../services/invest/customer";
import { useLocation } from 'react-router-dom';
import {formatMoneyCurrency, getFmkTransactionTypeColorByStatus} from "../../../../../utils/common";
import {useTranslation} from "react-i18next";

const ModalFmarketOrderDetail = (props) => {
    const [customer, setCustomer] = useState({});
    const location = useLocation();
    const [form] = Form.useForm();
    const {
        transaction = null,
        visible = false,
        onClose = () => {},
        reload = () => {}
    } = props;

    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {
        // fetchData();
        // if (product) {
        //     form.setFieldsValue({
        //         name: product.name,
        //         active: product.active
        //     })
        // } else {
        //     form.setFieldsValue({
        //         name: null,
        //         active: true
        //     })
        // }
    }, [transaction]);

    console.log('location', window.location);
    const accountHref = `${window.location.origin}/fmarket-accounts/${transaction?.sysUserId}`;
    const [callingAPI, setCallingAPI] = useState(false);

    const fetchData = async () => {
        // await customerService.customer_detail({
        //         account_id: transaction?.userId
        //     })
        //     .then((response) => {
        //         setCustomer(response?.data || {});
        //     })
        //     .catch(() => {
        //         setCustomer({});
        //     });
    };

    return (
        <Modal
            title={"Chi tiết giao dịch"}
            width={500}
            open={visible}
            footer={null}
            // onOk={() => handleSubmit()}
            onCancel={() => onClose()}
            // okButtonProps={{
            //     loading: callingAPI
            // }}
            // cancelButtonProps={{
            //     disabled: callingAPI
            // }}
            // okText='Lưu'
            // okButtonProps={{ disabled: true, className: "modal-footer-hiden-button" }}
            // cancelText='Đóng'
        >
            <table className="table-transaction-detail">
                <tr>
                    <td className="transaction-detail-col-1">{texts('customer_name')}</td>
                    <td className="transaction-detail-col-2">
                        <a href={accountHref}>{transaction?.accName}</a>
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('fmarket_id')}</td>
                    <td className="transaction-detail-col-2">
                        <a href={accountHref}>{transaction?.fmarketId}</a>
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('phone_number')}</td>
                    <td className="transaction-detail-col-2">{transaction?.accPhone}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('contract_code')}</td>
                    <td className="transaction-detail-col-2">{transaction?.code || transaction?.orderCode}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('product')}</td>
                    <td className="transaction-detail-col-2">{transaction?.productCode}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('transaction_type')}</td>
                    <td className="transaction-detail-col-2">
                        <Tag color={getFmkTransactionTypeColorByStatus(transaction?.type)}>
                            {transaction?.type}
                        </Tag>
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('status')}</td>
                    <td className="transaction-detail-col-2">
                        <Tag color={getFmkTransactionTypeColorByStatus(transaction?.status)}>
                            {transaction?.status}
                        </Tag>
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('net_asset_value')}</td>
                    <td className="transaction-detail-col-2">{formatMoneyCurrency(transaction?.price)}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('amount')}</td>
                    <td className="transaction-detail-col-2">{formatMoneyCurrency(transaction?.amount)}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('transaction_date')}</td>
                    <td className="transaction-detail-col-2">{moment(transaction?.createDate).format('DD/MM/YYYY')}</td>
                </tr>


            </table>


        </Modal>

    );
}

export default ModalFmarketOrderDetail;