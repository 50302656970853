import React, { useEffect, useState, useMemo } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Form,
  Select,
  message,
} from 'antd';

import {
} from "@ant-design/icons";

import {
  BANK_RULES,
  BANK_BRANCH_RULES,
  BANK_ACCOUNT_NAME_RULES,
  BANK_ACCOUNT_NUMBER_RULES
} from "../../../../../config/rules";

import bankServices from '../../../../../services/finblue/bank';
import financeServices from '../../../../../services/finblue/finance';

const SystemBankAccountFormModal = (props) => {
  const [form] = Form.useForm();
  const [callingAPI, setCallingAPI] = useState(false);

  const bankNameId = Form.useWatch('bankNameId', form) || '';
  const bankBranchId = Form.useWatch('bankBranchId', form) || '';

  const [banks, setBanks] = useState([]);
  const [bankBranchs, setBankBranchs] = useState([]);

  const {
    bankAccount = null,
    visible = false,
    onClose = () => {},
    reload = () => {}
  } = props

  useEffect(() => {
    handleSearchBanks();
  }, [bankAccount])

  useEffect(() => {
    if (bankNameId) {
      handleSearchBankBranchs();
    } else {
      setBankBranchs([])
    }
  }, [bankNameId])

  const selectedBank = useMemo(() => {
    return banks.find((b) => b.id == bankNameId)
  }, [banks, bankNameId])

  const selectedBankBranch = useMemo(() => {
    return bankBranchs.find((b) => b.id == bankBranchId)
  }, [bankBranchs, bankBranchId])

  useEffect(() => {
    if (bankAccount) {
      form.setFieldsValue({
        bankNameId: bankAccount.bankNameId,
        bankBranchId: bankAccount.bankBranchId,
        bankAccountName: bankAccount.bankAccountName,
        bankAccountNumber: bankAccount.bankAccountNumber,
      })
    } else {
      form.setFieldsValue({
        bankNameId: null,
        bankBranchId: null,
        bankAccountName: null,
        bankAccountNumber: null,
      })
    }
  }, [bankAccount])

  const handleSubmit = () => {
    form.validateFields().then(async (valid) => {
      if (valid) {
        setCallingAPI(true);
        const values = {
          ...form.getFieldsValue(),
          bankCode: selectedBank.itemCode,
          bankName: selectedBank.itemName,
          bankBranchName: selectedBankBranch.itemName
        };
        if (bankAccount) {
          await handleUpdate(values)
        } else {
          await handleCreate(values)
        }
        setCallingAPI(false);
      }
    })
  }

  const handleCreate = async (data) => {
    await financeServices.create_finblue(data).then(() => {
      message.success('Thêm tài khoản ngân hàng mới thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const handleUpdate = async (data) => {
    await financeServices.update(data, { id: bankAccount?.id }).then(() => {
      message.success('Cập thông tin tài khoản thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const handleSearchBanks = () => {
    bankServices.search_banks().then((response) => {
      setBanks(response.data)
    }).catch(() => {
      setBanks([])
    })
  }

  const handleSearchBankBranchs = () => {
    bankServices.search_bank_branchs(bankNameId).then((response) => {
      setBankBranchs(response.data);
    }).catch(() => {
      setBankBranchs([])
    })
  }


  return (
    <Modal
      title={bankAccount ? "Chỉnh sửa tài khoản ngân hàng" : "Thêm mới tài khoản ngân hàng"}
      width={500}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => onClose()}
      okButtonProps={{
        loading: callingAPI
      }}
      cancelButtonProps={{
        disabled: callingAPI
      }}
      okText='Lưu'
      cancelText='Hủy'
    >
      <Form
        form={form}
        layout='vertical'
      >
        <Row
          justify={'left'}
          gutter={[16, 8]}
        >
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Ngân hàng'}
              name={'bankNameId'}
              rules={BANK_RULES}
            >
              <Select
                placeholder={"Vui lòng chọn ngân hàng"}
                disabled={callingAPI}
                showSearch={true}
                onChange={() => {
                  form.setFieldValue('bankBranchId', null)
                }}
                optionFilterProp="children"
                className="w-full"
                filterOption={(input, option) =>
                  `${option.children}`
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {banks.map((it) => (
                  <Select.Option
                    value={it.id}
                    key={it.id}
                  >
                    {it.itemName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Chi nhánh'}
              name={'bankBranchId'}
              rules={BANK_BRANCH_RULES}
            >
              <Select
                placeholder={"Vui lòng chọn chi nhánh"}
                disabled={callingAPI}
                showSearch={true}
                optionFilterProp="children"
                className="w-full"
                filterOption={(input, option) =>
                  `${option.children}`
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {bankBranchs.map((it) => (
                  <Select.Option
                    value={it.id}
                    key={it.id}
                  >
                    {it.itemName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              name={'bankAccountName'}
              label={'Tên tài khoản'}
              rules={BANK_ACCOUNT_NAME_RULES}
            >
              <Input
                placeholder="Vui lòng nhập tên tài khoản"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Số tài khoản'}
              name={'bankAccountNumber'}
              rules={BANK_ACCOUNT_NUMBER_RULES}
            >
              <Input
                placeholder="Vui lòng nhập số tài khoản"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
    
  );
}

export default SystemBankAccountFormModal;