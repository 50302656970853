import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Input,
    Modal,
    Form,
    Select,
    message,
    Card
} from 'antd';

import {
} from "@ant-design/icons";
import moment from 'moment';
// import CONSTANTS from "../../../../../config/constants";
// import { PROJECT_RULES } from "../../../../../config/rules";
// import productServices from '../../../../../services/finblue/product';
// import customerService from "../../../../../services/invest/customer";
// import {displayInvestProfitCapitalDeal, formatMoneyCurrency} from "../../../../../utils/common";
import SalesmanList from '../sales/SalesmanList';

const ModalAddSalesmanToCustomer = (props) => {
    const [customer, setCustomer] = useState({});
    const [form] = Form.useForm();
    const {
        transaction = null,
        visible = false,
        onClose = () => {},
        onSelect = () => {},
        salesAccountId = 0
    } = props;

    const [callingAPI, setCallingAPI] = useState(false);

    // const onSelect = (salesman) => {
    //
    // };

    // const handleSubmit = () => {
    //     form.validateFields().then(async (valid) => {
    //         if (valid) {
    //             setCallingAPI(true);
    //             const values = form.getFieldsValue();
    //             if (transaction) {
    //                 await handleUpdate({
    //                     id: transaction.id,
    //                     ...values,
    //                 })
    //             } else {
    //                 await handleCreate(values)
    //             }
    //             setCallingAPI(false);
    //         }
    //     })
    // }

    return (
        <Modal
            title={"Assign customers to sales"}
            width={900}
            open={visible}
            footer={null}
            // onOk={() => handleSubmit()}
            onCancel={() => onClose()}
            // okButtonProps={{
            //     loading: callingAPI
            // }}
            // cancelButtonProps={{
            //     disabled: callingAPI
            // }}
            // okText='Lưu'
            // okButtonProps={{ disabled: true, className: "modal-footer-hiden-button" }}
            // cancelText='Đóng'
            style={{top: 20}}
            bodyStyle={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: 'calc(100vh - 150px)' }}
        >
            <SalesmanList
                source={'CUSTOMER'}
                onSelect={onSelect}
            />
        </Modal>

    );
}

export default ModalAddSalesmanToCustomer;