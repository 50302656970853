import systemServices from '../../services/system';
import FINBLUE_PERMISSIONS from './finblue';
import INVEST_PERMISSIONS from './invest';

const getPermissions = () => {
  const cmsInfo = systemServices.cms_info();
  let permissions = {
    // Tài khoản CMS
    VIEW_LIST_USER_ROLE: 'VIEW_LIST_USER_ROLE',
    VIEW_USER_ROLE_PERMISSION: 'VIEW_USER_ROLE_PERMISSION',
    CREATE_USER_ROLE: 'CREATE_USER_ROLE',
    UPDATE_USER_ROLE: 'UPDATE_USER_ROLE',
    DELETE_USER_ROLE: 'DELETE_USER_ROLE',

    // Vị trí
    VIEW_LIST_ROLE: 'VIEW_LIST_ROLE',
    VIEW_ROLE: 'VIEW_ROLE',
    CREATE_ROLE: 'CREATE_ROLE',
    UPDATE_ROLE: 'UPDATE_ROLE',
    DELETE_ROLE: 'DELETE_ROLE',
    
    // Chức vụ
    VIEW_LIST_POSITION: 'VIEW_LIST_POSITION',
    CREATE_POSITION: 'CREATE_POSITION',
    UPDATE_POSITION: 'UPDATE_POSITION',
    DELETE_POSITION: 'DELETE_POSITION',

    // Danh mục quyền
    LIST_ROLE_CATEGORY: 'LIST_ROLE_CATEGORY',
    ROLE_CATEGORY: 'ROLE_CATEGORY',
    CREATE_ROLE_CATEGORY: 'CREATE_ROLE_CATEGORY',
    UPDATE_ROLE_CATEGORY: 'UPDATE_ROLE_CATEGORY',
    DELETE_ROLE_CATEGORY: 'DELETE_ROLE_CATEGORY',

    // Quyền truy cập
    VIEW_LIST_PERMISSION: 'VIEW_LIST_PERMISSION',
    VIEW_LIST_ALL_PERMISSION: 'VIEW_LIST_ALL_PERMISSION',
    VIEW_PERMISSION: 'VIEW_PERMISSION',
    CREATE_PERMISSION: 'CREATE_PERMISSION',
    UPDATE_PERMISSION: 'UPDATE_PERMISSION',
    DELETE_PERMISSION: 'DELETE_PERMISSION',

    // Nhân viên
    LIST_USER_POSITION: 'LIST_USER_POSITION',
    VIEW_USER_POSITION: 'VIEW_USER_POSITION',
    CREATE_USER_POSITION: 'CREATE_USER_POSITION',
    UPDATE_USER_POSITION: 'UPDATE_USER_POSITION',
    DELETE_USER_POSITION: 'DELETE_USER_POSITION',

    // Phòng ban
    LIST_DEPARTMENT: 'LIST_DEPARTMENT',
    VIEW_DEPARTMENT: 'VIEW_DEPARTMENT',
    CREATE_DEPARTMENT: 'CREATE_DEPARTMENT',
    UPDATE_DEPARTMENT: 'UPDATE_DEPARTMENT',
    DELETE_DEPARTMENT: 'DELETE_DEPARTMENT'
  };
  switch (cmsInfo?.key) {
    case 'CMS_FINBLUE':
      permissions = {
        ...permissions,
        ...FINBLUE_PERMISSIONS
      };
      break;
    case 'CMS_INVEST':
      permissions = {
        ...permissions,
        ...INVEST_PERMISSIONS
      };
    default:
      break;
  }
  return {
    ...permissions
  };
}

export const PERMISSIONS = getPermissions();
