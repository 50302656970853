import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Tabs, Col, Row, Image} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminDetailHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
// import customerServices from "../../../services/invest/customer";
import customerServices from "../../../services/invest/customer";
import fmarketServices from "../../../services/invest/fmarket";
import { useParams } from 'react-router-dom';
import AvatarDefault from './../../../assets/images/Avatar-default.png';
import moment from 'moment';
import FaInfoTab from '../fmarket-accounts/fa_info_tab';

const CustomerFmkAccountTab = (props) => {
    const {customer} = props;
    const [loading, setLoading] = useState(false);
    const [fmarketAccount, setFmarketAccount] = useState({});

    console.log('CustomerFmkAccountTab customer', customer);


    useEffect(() => {
        console.log('getCustomerTradingContract');
        fetchData();
    }, []);


    const fetchData = async () => {
        console.log('fetchData');
        setLoading(true);
        let account_id = customer.rowId;
        await customerServices.customer_fmarket_account({account_id})
            .then((response) => {
                setFmarketAccount(response?.data || {});
                // setTotal(response?.page?.total_elements);
            })
            .catch(() => {
                console.log('customer_fmarket_account error');
                setFmarketAccount({});
            });
        setLoading(false);
    };


    return (
        <div className="">
            {fmarketAccount?.faFullName ?
                <FaInfoTab fmarketAccount={fmarketAccount} customerId={customer?.rowId}/>
                :
                <Row justify='center' style={{marginTop: 30}}>
                    Không có dữ liệu
                </Row>
            }

            {/*{fmarketAccount?.faFullName ?*/}
                {/*<Row gutter={[30, 30]}>*/}
                    {/*<Col align-content='center'>*/}
                        {/*<img className='profile-avatar' src={AvatarDefault}/>*/}
                    {/*</Col>*/}

                    {/*<Col>*/}
                        {/*<div className='profile-name'>*/}
                            {/*{fmarketAccount?.faFullName}*/}
                        {/*</div>*/}
                        {/*<table>*/}
                            {/*<tbody>*/}
                            {/*<tr>*/}
                                {/*<td className='content-label'>Fmarket id:</td>*/}
                                {/*<td className='content-value'>{fmarketAccount?.fmarketId}</td>*/}
                            {/*</tr>*/}

                            {/*<tr>*/}
                                {/*<td className='content-label'>Phone number:</td>*/}
                                {/*<td className='content-value'>{fmarketAccount?.faCellPhone}</td>*/}
                            {/*</tr>*/}

                            {/*<tr>*/}
                                {/*<td className='content-label'>Email:</td>*/}
                                {/*<td className='content-value'>{fmarketAccount?.faEmail}</td>*/}
                            {/*</tr>*/}

                            {/*<tr>*/}
                                {/*<td className='content-label'>Date of birth:</td>*/}
                                {/*<td className='content-value'>{moment(fmarketAccount?.faDob).format('DD/MM/YYYY')}</td>*/}
                            {/*</tr>*/}

                            {/*<tr>*/}
                                {/*<td className='content-label'>Gender:</td>*/}
                                {/*<td className='content-value'>{fmarketAccount?.faGender ? 'Male' : 'Female'}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                                {/*<td className='content-label'>Bank no:</td>*/}
                                {/*<td className='content-value'>{fmarketAccount?.faBankAccountNo}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                                {/*<td className='content-label'>Bank branch:</td>*/}
                                {/*<td className='content-value'>{fmarketAccount?.faBankBranch}</td>*/}
                            {/*</tr>*/}


                            {/*<tr>*/}
                                {/*<td className='content-label'>Id no:</td>*/}
                                {/*<td className='content-value'>{fmarketAccount?.faIdNo || 'Chưa cập nhật'}</td>*/}
                            {/*</tr>*/}

                            {/*<tr>*/}
                                {/*<td className='content-label'>Id type:</td>*/}
                                {/*<td className='content-value'>{fmarketAccount?.faIdType}</td>*/}
                            {/*</tr>*/}



                            {/*<tr>*/}
                                {/*<td className='content-label'>Id place of issue:</td>*/}
                                {/*<td className='content-value'>{fmarketAccount?.faIdPlaceOfIssue}</td>*/}
                            {/*</tr>*/}

                            {/*<tr>*/}
                                {/*<td className='content-label'>Permanent address:</td>*/}
                                {/*<td className='content-value'>{fmarketAccount?.faPermanentAddress}</td>*/}
                            {/*</tr>*/}

                            {/*<tr>*/}
                                {/*<td className='content-label'>Mailing address:</td>*/}
                                {/*<td className='content-value'>{fmarketAccount?.faMailingAddress}</td>*/}
                            {/*</tr>*/}

                            {/*<tr>*/}
                                {/*<td className='content-label'>Id front photo:</td>*/}
                                {/*<td className='content-value'>*/}
                                    {/*<Image*/}
                                        {/*width={300}*/}
                                        {/*// height={200}*/}
                                        {/*src={fmarketAccount?.faIdFrontPhotoBase64}*/}
                                    {/*/>*/}
                                {/*</td>*/}
                            {/*</tr>*/}

                            {/*<tr>*/}
                                {/*<td className='content-label'>Id back photo:</td>*/}
                                {/*<td className='content-value'>*/}
                                    {/*<Image*/}
                                        {/*width={200}*/}
                                        {/*// height={200}*/}
                                        {/*src={fmarketAccount?.faIdBackPhotoBase64}*/}
                                    {/*/>*/}
                                {/*</td>*/}
                            {/*</tr>*/}

                            {/*</tbody>*/}
                        {/*</table>*/}
                    {/*</Col>*/}
                {/*</Row>*/}
                {/*:*/}
                {/*<Row justify='center' style={{marginTop: 30}}>*/}
                    {/*Không có dữ liệu*/}
                {/*</Row>*/}
            {/*}*/}

        </div>
    );
};

export default CustomerFmkAccountTab;
