import React, { useState, useEffect } from "react";
import {
  Row,
} from 'antd';

import { Statistic } from '../../../../../../components'


import contractServices from '../../../../../../services/finblue/contract'
import CONSTANTS from '../../../../../../config/constants'
import dayjs from 'dayjs'

const Statistics = (props) => {
  const {
    params,
    setParams = () => { }
  } = props
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState([]);

  useEffect(() => {
    fetchData();
  }, [params.dates])

  const fetchData = async () => {
    setLoading(true);
    await contractServices.get_customer_statistics({
      customerId: params?.customerId,
      begin: dayjs(dayjs(params.dates[0]).format('YYYY-MM-DD 00:00:00')).unix() * 1000,
      end: dayjs(dayjs(params.dates[1]).format('YYYY-MM-DD 23:59:59')).unix() * 1000,
    }).then((response) => {
      const statistic = response?.data
      setStatistics(CONSTANTS.CONTRACT_STATUS.map((s) => ({
        ...s,
        total: statistic[s.statistic_key] || 0
      })));
    }).catch(() => {
      setStatistics(CONSTANTS.CONTRACT_STATUS.map((s) => ({
        ...s,
        total: 0
      })))
    })
    setLoading(false);
  }

  return (
    <Row
      className="statistics"
      justify={'left'}
    >
      <Statistic
        statistics={statistics}
        setParams={(s) => setParams({ ...params, status: s.value })}
      />
    </Row>
  );
}

export default Statistics;