import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function search_finances(params = {}) {
  return request({
    url: ENDPOINT.FINANCES,
    method: "get",
    params,
  });
}

function search_customer_finances(params = {}) {
  return request({
    url: ENDPOINT.FINANCES_CUSTOMER,
    method: "get",
    params,
  });
}

function search_finances_finblue(params = {}) {
  return request({
    url: ENDPOINT.FINANCES_FINBLUE,
    method: "get",
    params,
  });
}

function create(data = {}, params = {}) {
  return request({
    url: ENDPOINT.FINANCES_ADD,
    method: "post",
    data,
    params,
  });
}

function create_finblue(data = {}, params = {}) {
  return request({
    url: ENDPOINT.FINANCES_FINBLUE_ADD,
    method: "post",
    data,
    params,
  });
}

function update(data = {}, params = {}) {
  return request({
    url: ENDPOINT.FINANCES_UPDATE,
    method: "put",
    data,
    params,
  });
}

function remove(params = {}) {
  return request({
    url: ENDPOINT.FINANCES_DELETE,
    method: "delete",
    params,
  });
}

export default {
  search_finances,
  search_customer_finances,
  search_finances_finblue,
  create,
  create_finblue,
  update,
  remove
};
