import React, { useEffect, useMemo, useState } from 'react';
import { Layout, Image } from 'antd';
import { useLocation } from 'react-router-dom';

import {} from '@ant-design/icons';

import './css/index.scss';
import ROUTERS from '../../config/routers';

import Header from './Header';
import Sidebar from './Sidebar';
import Breadcrumb from './Breadcrumb';

import PageContent from '../../routes';
import { ROUTE_HIDE_BREADCRUMB } from '../../config/routers';
import { getSideBarMenus } from '../../utils/common'


function AdminLayout(props) {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [hideBreadcrumb, setHideBreadcrumb] = useState(true);

  useEffect(() => {
    let hideBreadcrumb = ROUTE_HIDE_BREADCRUMB.findIndex(e => e === location.pathname) > -1;
    setHideBreadcrumb(hideBreadcrumb);
  }, [location]);

  console.log('getSideBarMenus()', getSideBarMenus());

  return (
    <Layout className='admin-layout'>
      <Layout.Header style={{ padding: 0, background: '#ffff' }}>
        <Header
          className="admin-layout-header"
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      </Layout.Header>
      <Layout className='admin-layout-center'>
        {
          getSideBarMenus().length > 0 &&
          <Layout.Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <Sidebar
              className="admin-layout-sidebar"
              collapsed={collapsed}
              menus={getSideBarMenus()}
            />
          </Layout.Sider>
        }
        <Layout.Content
          className={`admin-layout-content ${getSideBarMenus().length === 0 ? 'full-page' : ''}`}
        >
          <div className='admin-layout-content-data'>
              {hideBreadcrumb ? null : <Breadcrumb />}
            <PageContent routers={ROUTERS.ADMIN_ROUTERS} />
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default AdminLayout;
