import React from "react";
import {
  Table,
  Input,
  Radio
} from 'antd';

import { TableContent } from '../../../../../components'

import {
} from "@ant-design/icons";

import CONSTANTS from '../../../../../config/constants'
import { fieldAction } from '../../../../../utils/common'

const TableDataDetail = (props) => {
  const {
    data = [],
    loading = false,
    isShowFooter = false,
    change = () => { },
  } = props

  const handleAction = (action, record) => {
    change({ ...record, verified: Number(action) })
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 100
    },
    {
      title: 'Trường thông tin',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 150
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      key: 'content',
      align: 'left',
      width: 200,
      render: (_, record) => (
        <TableContent record={record} />
      ),
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: 200,
      render: (_, record) => (
        <Radio.Group
          disabled={!isShowFooter}
          value={record.verified}
          onChange={(event) => handleAction(event.target.value, record)}
        >
          {
            CONSTANTS.FIELD_ACTIONS.map((item) => <Radio
              key={item.value}
              value={item.value}
            >
              <span style={{
                color: fieldAction(item.value)?.color || '',
              }}>
                {item.label}
              </span>
            </Radio>)
          }
        </Radio.Group>
      ),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'comment',
      key: 'comment',
      align: 'left',
      fixed: 'right',
      width: 200,
      render: (_, record) => (
        isShowFooter ? <Input.TextArea
          value={record.comment}
          placeholder="Nhập bình luận"
          disabled={!isShowFooter}
          autoSize={{ minRows: 2, maxRows: 6 }}
          onChange={(e) => change({ ...record, comment: e.target.value })}
        /> : record.comment || 'Không có bình luận'
      ),
    },
  ]

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 }))}
      loading={loading}
      pagination={false}
      rowKey={(record) => record?.id}
      scroll={{ x: 1024 }}
      size="small"
    />
  );
}

export default TableDataDetail;