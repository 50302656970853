import Cookies from "js-cookie";

function update_cms_Info(data) {
  Cookies.set("cms_info", JSON.stringify(data));
}

function cms_info() {
  try {
    return JSON.parse(Cookies.get("cms_info"));
  } catch (_) {
    return null;
  }
}

export default {
  update_cms_Info,
  cms_info,
};
