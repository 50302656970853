import { useState, useEffect } from 'react'

import {
    UploadOutlined, 
    LoadingOutlined
} from "@ant-design/icons";

import {Upload, Button, Spin} from 'antd'
import './uploadImage.scss'
import contentService from '../../services/invest/content';
import ENDPOINT from "../../config/endpoints/invest";
// import api from '../../services/invest/upload_file';
// import {actionUploadImage} from './action';
import {message} from "antd/es/index";
import {formatFileName} from "../../utils/common";

const UploadFile = (props) => {

//    const [cropData, setCropData] = useState(image || null);
//    const [loading, setLoading] = useState(false);
    const [fileUrl, setFileUrl] = useState();
    const [isFetching, setIsFetching] = useState(false);
    const [files, setFiles] = useState([]);


    const {
        label = 'Upload',
        image,
        onCropSuccess,
        onUploadSuccess,
        maxCount = 1
    } = props;


    useEffect(() => {

    }, []);


    const uploadFile = (file) => {
        setIsFetching(true);
//        file.name = formatFileName(file?.name || 'no_name');
        console.log('uploadFile', file);
        let bodyFormData = new FormData();
        bodyFormData.append('file', file, formatFileName(file?.name || 'no_name'));

        try {
            contentService.upload_file_other(bodyFormData).then((res) => {
                let fileUrl = res?.data;
                setFileUrl(fileUrl);
                onUploadSuccess?.(fileUrl);
                setIsFetching(false);
            });
        } catch (error) {
            console.log(error);
            setIsFetching(false);
        }
    };


    return (
        <Upload
            className='upload-image-component'
            maxCount={maxCount}
            action={(file) => {
                uploadFile(file);
                return null;
            }}
//            beforeUpload={(file) => {
//                setFiles([...files, file]);
//                return false;
//            }}
        >
            <Spin spinning={isFetching} 
                indicator={<LoadingOutlined />} 
                size='large'>
                <Button icon={<UploadOutlined />}>{label}</Button>
            </Spin>
        </Upload>
    )
};

export default UploadFile;