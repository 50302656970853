import React, {
  useState,
  useEffect,
  useMemo
} from "react";

import {
  Form,
  Input,
  Row,
  Col,
  Button,
  message,
  Spin
} from 'antd';

import { AdminDetailHeader } from "../../../components";
import { ROLE_NAME_RULES } from "../../../config/rules";

import PermissionListData from "../permissions/components/ListData";
import roleServices from "../../../services/admin/role";

const CreateRole = (props) => {
  const { router } = props;
  const [form] = Form.useForm();
  const [role, setRole] = useState({});
  const [loading, setLoading] = useState(true);
  const [callingAPI, setCallingAPI] = useState(false);

  useEffect(() => {
    fetchRole();
  }, [])

  const fetchRole = async () => {
    setLoading(true)
    setRole({
      name: null,
      roleCategories: []
    })
    form.setFieldValue('name', null),
      setLoading(false)
  }

  const handleCreateRole = async () => {
    form.validateFields().then(async (values) => {
      setCallingAPI(true)
      await roleServices
        .create_role({
          name: values.name,
          permissionIds: (role?.roleCategories || []).map((c) => c.permissions.filter((p) => p.isChoose).map((p) => p.permissionId)).flat()
        })
        .then(() => {
          message.success('Thêm mới quyền hạn thành công')
          setCallingAPI(false)
          window.navigatePage('ROLES')
        })
        .catch((error) => {
          setCallingAPI(false)
          message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        });
    })
  }

  return (
    <div
      className="role-create"
    >
      <AdminDetailHeader title={router.label} />
      <Spin spinning={loading}>
        <Form
          form={form}
          layout='vertical'
        >
          <Row gutter={[16, 0]}>
            <Col lg={12} sm={24}>
              <Form.Item
                label="Tên vị trí"
                name={'name'}
                rules={ROLE_NAME_RULES}
              >
                <Input placeholder="Nhập tên vị trí" />
              </Form.Item>
            </Col>
            <Col lg={24} sm={24}>
              <Form.Item
                label="Quyền truy cập"
                name={'name'}
                rules={[]}
              >
                {
                  !loading && <PermissionListData
                    role={role}
                    setRole={setRole}
                  />
                }
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row gutter={[8, 8]} className="mt-0">
          <Col lg={12} align="right">
            <Button
              size="large"
              className="w-30"
              disabled={callingAPI}
              onClick={() => window.navigatePage('BACK')}
            >
              Huỷ
            </Button>
          </Col>
          <Col lg={12}>
            <Button
              size="large"
              type="primary"
              loading={callingAPI}
              className="w-30"
              onClick={() => handleCreateRole()}
            >
              Lưu
            </Button>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default CreateRole;
