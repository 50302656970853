import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/invest";

function get_log_call_partner(params) {
    return request({
        url: ENDPOINT.GET_LOG_CALL_PARTNER,
        method: "get",
        params,
    });
}


export default {
    get_log_call_partner
};
