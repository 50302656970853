import React from "react";
import {Row, Col, Input, Select, DatePicker} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const InputSearch = (props) => {
    const {
        params = {},
        setParams = () => {}
    } = props;

    const { t } = useTranslation();
    const texts = t;

    return (
        <Input
            prefix={<SearchOutlined/>}
            value={params.keyword}
            placeholder={texts('search')}
            onChange={(e) => setParams({...params, keyword: e.target.value})}
        />
    );
};

export default InputSearch;