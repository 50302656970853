import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Tabs, Col, Row} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminDetailHeader} from "../../../components";

import Filter from "../savings-transactions/components/Filter";
import TableData from "../customers/components/TableData";
import userServices from "../../../services/admin/user";
import salesService from "../../../services/invest/sales";
import { useParams } from 'react-router-dom';
import AvatarDefault from './../../../assets/images/Avatar-default.png';
import moment from 'moment';
import ModalSavingsTransactionDetail from "../savings-transactions/components/modals/ModalSavingsTransactionDetail";

const SalesmanCustomerTab = (props) => {
    const {
        salesman,
        isRefresh,
        setIsRefresh
    } = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [formVisible, setFormVisible] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [params, setParams] = useState({
        page: 1,
        size: 10,
    });

    useEffect(() => {
        fetchData();
    }, [params]);

    useEffect(() => {
        if(isRefresh){
            fetchData();
            setIsRefresh(false);
        }
    }, [isRefresh]);

    const fetchData = async () => {
        console.log('salesman 111', salesman);
        setLoading(true);
        let accountId = salesman.rowId;
        await salesService.salesman_customer_list({
            accountId,
            ...params,
            page: params.page - 1
        }).then((response) => {
            setData(response?.data || {});
            setTotal(response?.page?.total_elements || 0);
        })
            .catch(() => {
                // setData([]);
            });
        setLoading(false);
    };

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const handleShowDetail = (transaction = null) => {
        setSelectedTransaction(transaction);
        setFormVisible(true)
    };

    return (
        <div className="">

            {/*<Filter params={params} setParams={setParams}/>*/}

            <TableData
                loading={loading}
                data={data}
                params={params}
                showDetail={(item) => handleShowDetail(item)}
            />

            <Pagination
                className="pagination"
                current={params.page}
                pageSize={params.size}
                total={total}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `Tổng số ${total}`}
                onChange={handleChangePage}
            />

            {
                formVisible && <ModalSavingsTransactionDetail
                    visible={formVisible}
                    transaction={selectedTransaction}
                    onClose={() => setFormVisible(false)}
                    reload={() => fetchData()}
                />
            }
        </div>
    );
};

export default SalesmanCustomerTab;
