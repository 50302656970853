import React, {
  useState,
  useEffect,
  useMemo
} from "react";

import {
  Form,
  Input,
  Row,
  Col,
  Button,
  message,
  Spin
} from 'antd';
import { useParams } from 'react-router-dom';

import { AdminDetailHeader } from "../../../components";
import { ROLE_NAME_RULES } from "../../../config/rules";

import PermissionListData from "../permissions/components/ListData";
import roleServices from "../../../services/admin/role";

import { PERMISSIONS } from "../../../config/permissions";
import { isPermission } from "../../../utils/common";

const DetailRole = (props) => {
  const { router } = props;
  const { id } = useParams();
  const [form] = Form.useForm();
  const [role, setRole] = useState({});
  const [loading, setLoading] = useState(true);
  const [callingAPI, setCallingAPI] = useState(false);

  useEffect(() => {
    fetchRole();
  }, [])

  const fetchRole = async () => {
    setLoading(true)
    await roleServices
      .get_role(id)
      .then((response) => {
        setRole(response?.data || {});
        form.setFieldValue('name', response.data?.name)
      })
      .catch(() => {
        setRole({});
      });
    setLoading(false)
  }

  const handleUpdateRole = async () => {
    form.validateFields().then(async (values) => {
      setCallingAPI(true)
      await roleServices
        .update_role(id, {
          name: values.name,
          system: role.system,
          permissionIds: (role?.roleCategories || []).map((c) => c.permissions.filter((p) => p.isChoose).map((p) => p.permissionId)).flat()
        })
        .then(() => {
          message.success('Cập nhật quyền hạn thành công')
          setCallingAPI(false)
        })
        .catch((error) => {
          setCallingAPI(false)
          message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        });
    })
  }

  return (
    <div
      className="role-detail"
    >
      <AdminDetailHeader title={router.label} />
      <Spin spinning={loading}>
        <Form
          form={form}
          layout='vertical'
        >
          <Row gutter={[16, 0]}>
            <Col lg={12} sm={24}>
              <Form.Item
                label="Tên quyền hạn"
                name={'name'}
                rules={ROLE_NAME_RULES}
              >
                <Input
                  placeholder="Nhập tên quyền hạn"
                  disabled={callingAPI || !isPermission([PERMISSIONS.UPDATE_ROLE])}
                />
              </Form.Item>
            </Col>
            <Col lg={24} sm={24}>
              <Form.Item
                label="Quyền truy cập"
                name={'name'}
                rules={[]}
              >
                {
                  !loading && <PermissionListData
                    role={role}
                    setRole={setRole}
                    disabled={callingAPI || !isPermission([PERMISSIONS.UPDATE_ROLE])}
                  />
                }
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {
          isPermission([PERMISSIONS.UPDATE_ROLE]) && <Row gutter={[8, 8]} className="mt-0">
            <Col lg={12} align="right">
              <Button
                size="large"
                className="w-30"
                disabled={callingAPI}
                onClick={() => window.navigatePage('BACK')}
              >
                Huỷ
              </Button>
            </Col>
            <Col lg={12}>
              <Button
                size="large"
                type="primary"
                loading={callingAPI}
                className="w-30"
                onClick={() => handleUpdateRole()}
              >
                Lưu
              </Button>
            </Col>
          </Row>
        }
      </Spin>
    </div>
  );
};

export default DetailRole;
