import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function list(params = {}) {
  return request({
    url: ENDPOINT.BUSINESS,
    method: "get",
    params,
  });
}

function list_child(params = {}) {
  return request({
    url: ENDPOINT.BUSINESS_CHILD,
    method: "get",
    params,
  });
}

export default {
  list,
  list_child
};
