import React, { useEffect, useState, useMemo } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Form,
  Select,
  message,
  Switch
} from 'antd';

import {
} from "@ant-design/icons";

import CONSTANTS from "../../../../../config/constants";
import {
  CATEGORY_RULES,
  PERMISSION_NAME_RULES,
  METHOD_RULES,
  PATTERN_PATH_RULES
} from "../../../../../config/rules";

import permisionServices from '../../../../../services/admin/permision';
import { PERMISSIONS } from "../../../../../config/permissions/index";

const PermissionFormModal = (props) => {
  const [form] = Form.useForm();
  const [callingAPI, setCallingAPI] = useState(false);

  const {
    categories = [],
    permission = null,
    category = null,
    visible = false,
    onClose = () => { },
    onReload = () => { },
    setSelectedCategory = () => {}
  } = props

  useEffect(() => {
  }, [permission])

  const allPermissions = useMemo(() => {
    return categories.map((c) => c.permissions || []).flat().map((c) => c.code)
  }, categories)

  useEffect(() => {
    if (permission) {
      form.setFieldsValue({
        categoryId: category?.categoryId || null,
        publicField: permission.publicField === 1,
        name: permission.name,
        code: permission.code,
        method: permission.method,
        patternPath: permission.patternPath,
        description: permission.description
      })
    } else {
      form.setFieldsValue({
        categoryId: category?.categoryId || null,
        publicField: false,
        name: null,
        code: null,
        method: 'GET',
        patternPath: null,
        description: null
      })
    }
  }, [permission])

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      setCallingAPI(true);
      const payload = {
        ...values,
        code: values.code || '',
        publicField: values.publicField ? 1 : 0
      };
      if (permission) {
        await handleUpdate(payload)
      } else {
        await handleCreate(payload)
      }
      setCallingAPI(false);
    })
  }

  const handleCreate = async (data) => {
    await permisionServices.create(data).then(() => {
      message.success('Thêm quyền truy cập mới thành công')
      onReload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const handleUpdate = async (data) => {
    await permisionServices.update(permission.permissionId, data).then(() => {
      message.success('Cập nhật quyền truy cập thành công')
      onReload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  return (
    <Modal
      title={permission ? "Chỉnh sửa quyền truy cập" : "Thêm mới quyền truy cập"}
      width={700}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => onClose()}
      okButtonProps={{
        loading: callingAPI
      }}
      cancelButtonProps={{
        disabled: callingAPI
      }}
      okText='Lưu'
      cancelText='Hủy'
    >
      <Form
        form={form}
        layout='vertical'
      >
        <Row
          justify={'left'}
          gutter={[16, 8]}
        >
          <Col lg={12} sm={12} xs={24}>
            <Form.Item
              label={'Danh mục'}
              name={'categoryId'}
              rules={CATEGORY_RULES}
            >
              <Select
                placeholder={"Vui lòng chọn danh mục"}
                disabled={callingAPI}
                showSearch={true}
                optionFilterProp="children"
                className="w-full"
                onChange={(v) => setSelectedCategory(categories.find((c) => c.categoryId === v))}
                filterOption={(input, option) =>
                  `${option.children}`
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {categories.map((it) => (
                  <Select.Option
                    value={it.categoryId}
                    key={it.categoryId}
                  >
                    {it.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} sm={12} xs={24}>
            <Form.Item
              label={'Trạng thái hoạt động'}
              name={'publicField'}
              valuePropName="checked"
              rules={[]}
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col lg={12} sm={12} xs={24}>
            <Form.Item
              label={'Tên quyền'}
              name={'name'}
              rules={PERMISSION_NAME_RULES}
            >
              <Input
                placeholder={"Vui lòng nhập tên quyền truy cập"}
                disabled={callingAPI}
              ></Input>
            </Form.Item>
          </Col>
          <Col lg={12} sm={12} xs={24}>
            <Form.Item
              label={'Mã quyền'}
              name={'code'}
              rules={[]}
            >
              <Select
                placeholder={"Vui lòng chọn mã quyền truy cập"}
                disabled={callingAPI}
                showSearch={true}
                allowClear
                optionFilterProp="children"
                className="w-full"
                filterOption={(input, option) =>
                  `${option.children}`
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {Object.keys(PERMISSIONS).map((permision, index) => (
                  <Select.Option
                    value={permision}
                    key={index}
                    // disabled={allPermissions.includes(permision)}
                  >
                    {permision}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} sm={12} xs={24}>
            <Form.Item
              label={'Phương thức'}
              name={'method'}
              rules={METHOD_RULES}
            >
              <Select
                placeholder={"Vui lòng chọn phương thức"}
                disabled={callingAPI}
                showSearch={true}
                optionFilterProp="children"
                className="w-full"
                filterOption={(input, option) =>
                  `${option.children}`
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {CONSTANTS.PERMISSION_METHODS.map((it) => (
                  <Select.Option
                    value={it.value}
                    key={it.value}
                  >
                    {it.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} sm={12} xs={24}>
            <Form.Item
              label={'Pattern Path'}
              name={'patternPath'}
              rules={PATTERN_PATH_RULES}
            >
              <Input
                placeholder={"EX: /cms/example"}
                disabled={callingAPI}
              ></Input>
            </Form.Item>
          </Col>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              name={'description'}
              label={'Mô tả'}
              rules={[]}
            >
              <Input.TextArea
                placeholder="Vui lòng nhập mô tả"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>

  );
}

export default PermissionFormModal;