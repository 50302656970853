import React from "react";
import {
  Row,
  Table,
  Button,
  Tag
} from 'antd';

import {
  EditOutlined 
} from "@ant-design/icons";

import {
  convertDateTime,
  ekycStatus,
  convertStringToQuery
} from '../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    params
  } = props

  const query = convertStringToQuery(window.location.search);

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Mã khách hàng',
      dataIndex: 'customerId',
      key: 'customerId',
      align: 'left',
      width: 150,
      render: (_, record) => `KH_${record.customerId}`
    },
    {
      title: 'Họ tên',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: 120,
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
      width: 200,
      render: (_, record) => convertDateTime(record.time)
    },
    {
      title: 'Trạng thái phê duyệt',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Tag color={ekycStatus(record.status)?.tag_color}>
          {ekycStatus(record.status)?.label}
        </Tag>
      ),
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (_, record) => (
        <Row gutter={[0, 8]}>
          <Button
            icon={<EditOutlined />}
            type='primary'
            size="small"
            ghost
            onClick={() => window.navigatePage(
              'EKYC_APPROVAL_DETAIL',
              { id: record.id },
              {
                ...query,
                customerId: record.customerId
              }
            )}
          >Chi tiết</Button>
        </Row>
      ),
    },
  ]

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 + (params.page - 1) * params.pageSize }))}
      loading={loading}
      pagination={false}
      scroll={{ x: 1024 }}
      rowKey={(record) => record?.id}
      size="small"
    />
  );
}

export default TableData;