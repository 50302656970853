import React, {useState, useEffect} from "react";
//import "./css/index.scss";
import {Pagination, Tabs, Spin} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminDetailHeader} from "../../../components";

import { useParams } from 'react-router-dom';

import SavingForceSettle from '../savings-force-settle';
import SavingSettleNormal from './saving_settle_normal';

const SavingSettle = (props) => {
    const {router} = props;
    const [loading, setLoading] = useState(false);


    return (
        <div className="cms-users">
            <AdminDetailHeader
                title={router.label}
            />

            <div className="content-wrapper">
                <div className="page-content">

                    <div className="main-content">
                        <div className='extra-custome-info'>
                            <Spin spinning={loading}>
                                <Tabs
                                    defaultActiveKey={"CUSTOMER-INFO"}
                                    className="tab-info"
                                    items={[
                                        {
                                            label: 'Tất toán đúng hạn',
                                            key: 'SAVING-SETTLE',
                                            children: <SavingSettleNormal />,
                                        },
                                        {
                                            label: 'Tất toán trước hạn',
                                            key: 'SAVING-FORCE-SETTLE',
                                            children: <SavingForceSettle/>,
                                        },
                                    ]}
                                />
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SavingSettle;
