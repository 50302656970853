import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Input,
    Modal,
    Form,
    Select,
    message,
    Card
} from 'antd';

import {
} from "@ant-design/icons";
import moment from 'moment';
import CONSTANTS from "../../../../../config/constants";
import { PROJECT_RULES } from "../../../../../config/rules";
// import productServices from '../../../../../services/finblue/product';
import customerService from "../../../../../services/invest/customer";
import {displayInvestProfitCapitalDeal, formatMoneyCurrency} from "../../../../../utils/common";

const ModalSavingsTransactionDetail = (props) => {
    const [customer, setCustomer] = useState({});
    const [form] = Form.useForm();
    const {
        transaction = null,
        visible = false,
        onClose = () => {},
        reload = () => {}
    } = props

    useEffect(() => {
        fetchData();
        // if (product) {
        //     form.setFieldsValue({
        //         name: product.name,
        //         active: product.active
        //     })
        // } else {
        //     form.setFieldsValue({
        //         name: null,
        //         active: true
        //     })
        // }
    }, [transaction])

    const [callingAPI, setCallingAPI] = useState(false);

    const fetchData = async () => {
        await customerService.customer_detail({
                account_id: transaction?.userId
            })
            .then((response) => {
                setCustomer(response?.data || {});
            })
            .catch(() => {
                setCustomer({});
            });
    };

    const handleSubmit = () => {
        form.validateFields().then(async (valid) => {
            if (valid) {
                setCallingAPI(true);
                const values = form.getFieldsValue();
                if (transaction) {
                    await handleUpdate({
                        id: transaction.id,
                        ...values,
                    })
                } else {
                    await handleCreate(values)
                }
                setCallingAPI(false);
            }
        })
    }

    const handleCreate = async (data) => {
        await productServices.create(data).then(() => {
            message.success('Thêm gói sản phẩm thành công')
            reload();
            onClose();
        }).catch((error) => {
            message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
    }

    const handleUpdate = async (data) => {
        await productServices.update(data).then(() => {
            message.success('Cập nhật gói sản phẩm thành công')
            reload();
            onClose();
        }).catch((error) => {
            message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
    }

    return (
        <Modal
            title={"Chi tiết giao dịch"}
            width={500}
            open={visible}
            footer={null}
            // onOk={() => handleSubmit()}
            onCancel={() => onClose()}
            // okButtonProps={{
            //     loading: callingAPI
            // }}
            // cancelButtonProps={{
            //     disabled: callingAPI
            // }}
            // okText='Lưu'
            // okButtonProps={{ disabled: true, className: "modal-footer-hiden-button" }}
            // cancelText='Đóng'
        >
            <table className="table-transaction-detail">
                <tr>
                    <td className="transaction-detail-col-1">Tên khách hàng</td>
                    <td className="transaction-detail-col-2">{customer?.accFullname}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">Số điện thoại</td>
                    <td className="transaction-detail-col-2">{customer?.accPhone}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">Tên sản phẩm</td>
                    <td className="transaction-detail-col-2">{transaction?.productName}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">Mã hợp đồng</td>
                    <td className="transaction-detail-col-2">{transaction?.contractCode}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">Số tiền</td>
                    <td className="transaction-detail-col-2">{formatMoneyCurrency(transaction?.transactionAmount)}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">Lãi suất</td>
                    <td className="transaction-detail-col-2">{transaction?.interest} %</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">Ngày mua</td>
                    <td className="transaction-detail-col-2">{moment(transaction?.createdDate).format('DD/MM/YYYY')}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">Thời hạn</td>
                    <td className="transaction-detail-col-2">
                        {transaction?.cycleCount} tháng
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">Ngày tất toán</td>
                    <td className="transaction-detail-col-2">
                        {moment(transaction?.contractCompleteAt).format('DD/MM/YYYY')}
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">Lợi nhuận hiện tại</td>
                    <td className="transaction-detail-col-2">
                        {formatMoneyCurrency(transaction?.profitTmp)}
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">Hình thức tất toán</td>
                    <td className="transaction-detail-col-2">
                        {displayInvestProfitCapitalDeal(transaction?.profitCapitalDeal)}
                    </td>
                </tr>

            </table>


        </Modal>

    );
}

export default ModalSavingsTransactionDetail;