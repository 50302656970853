import Dashboard from "./dashboard";
import Customers from "./customers";
import CustomerDetail from "./customers/detail";
import EkycApprove from "./ekyc-approve";
import EkycApproveDetail from "./ekyc-approve/detail";

import Salesman from "./sales/Salesman";
import SalesmanDetail from "./sales/detail";

import BalanceLogs from "./balance-logs";

import FmarketAccounts from "./fmarket-accounts";
import FmarketAccountDetail from "./fmarket-accounts/detail";
import FmarketOrders from "./fmarket-orders";
import FmarketTransactions from "./fmarket-transactions";
import ProductFunds from "./product-funds";

import SavingsOrders from "./savings-orders";
import SavingsTransactions from "./savings-transactions";
import ProductSavings from "./product-savings";
import SavingSettle from "./savings-settle/saving_settle";
import SavingsForceSettle from "./savings-force-settle";
import SavingsForceSettleDetail from "./savings-force-settle/detail";

import FundsInfo from "./funds-info";

import News from "./content-news";
import NewsDetail from "./content-news/detail";
import Policy from "./content-policy";
import QuestionAnswer from "./content-question-answer";

// import TransactionsTopup from "./transactions-withdraw-request/topup_log";
import TransactionsWithdrawLogs from "./transactions-balance-logs/withdraw";
import TransactionsTopupLogs from "./transactions-balance-logs/topup";
import TransactionsWithdrawRequest from "./transactions-withdraw-request/withdraw_request";
import TransactionsAppotaFirmBanking from "./transactions-appota-firm";

import PartnerAmber from "./partner-amber";
import PartnerFmarket from "./partner-fmarket";
import HelpCenter from "./content-help-center";

export default {
    Dashboard,
    Customers,
    CustomerDetail,
    EkycApprove,
    EkycApproveDetail,

    Salesman,
    SalesmanDetail,

    BalanceLogs,

    FmarketAccounts,
    FmarketAccountDetail,
    FmarketOrders,
    FmarketTransactions,
    ProductFunds,
    FundsInfo,

    SavingsTransactions,
    SavingsOrders,
    ProductSavings,
    SavingSettle,
    SavingsForceSettle,
    SavingsForceSettleDetail,

    TransactionsWithdrawLogs,
    TransactionsTopupLogs,
    TransactionsWithdrawRequest,
    TransactionsAppotaFirmBanking,

    News,
    NewsDetail,
    Policy,
    QuestionAnswer,

    PartnerAmber,
    PartnerFmarket,
    HelpCenter,
}
