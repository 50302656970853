import React, { useMemo } from "react";
import {
  Row,
  Col,
  Tag,
  Card,
} from 'antd';

import {
} from "@ant-design/icons";

import { PaymentSchedules } from "../../../../../components";
import { formatCurrency, marketStatus, lenderStatus } from '../../../../../utils/common'


const Infomation = (props) => {
  const {
    loading = false,
    loanInfo = {},
  } = props

  const loanInfoData = useMemo(() => {
    return [
      {
        name: 'Mã đơn vay',
        value: loanInfo?.loanCode,
        lg: 24
      },
      {
        name: 'Giá trị đơn vay',
        value: formatCurrency(loanInfo?.moneyOrder),
        lg: 12
      },
      {
        name: 'Phí nền tảng (%/năm)',
        value: `${loanInfo.appSurchargeInterest}% _ ${formatCurrency(loanInfo?.appSurcharge)}`,
        lg: 12
      },
      {
        name: `Loại sản phẩm`,
        value: loanInfo?.businessName,
        lg: 12
      },
      {
        name: 'Phí thanh toán',
        value: `${loanInfo.lenderSurchargeInterest}% _ ${formatCurrency(loanInfo?.lenderSurcharge)}`,
        lg: 12
      },
      {
        name: 'Hạn mức',
        value: formatCurrency(loanInfo?.moneyApproved),
        lg: 12
      },
      {
        name: 'Hoa hồng',
        value: `${loanInfo.marketSurchargeInterest}% _ ${formatCurrency(loanInfo?.marketSurcharge)}`,
        lg: 12
      },
      {
        name: 'Khả dụng',
        value: formatCurrency(loanInfo?.moneyAvailable),
        lg: 12
      },
      {
        name: 'Tiền lãi',
        value: formatCurrency(loanInfo?.interestMoney),
        lg: 12
      },
      {
        name: `Lãi suất`,
        value: `${loanInfo?.interestRate}% / Năm`,
        lg: 12
      },
      {
        name: 'Khách hàng cần thanh toán',
        value: formatCurrency(loanInfo?.totalMoney) + `(Giá trị đơn vay + Lãi + Phí nền tảng)`,
        lg: 24
      },
      {
        name: 'Kỳ hạn',
        value: `${loanInfo?.numMonth} Tháng`,
        lg: 12
      },
      {
        name: 'Tổng tiền phí dịch vụ',
        value: formatCurrency(loanInfo?.totalSurcharge),
        lg: 12
      },
      {
        name: `Trạng thái CTTC duyệt`,
        value: <div className="flex">
          <Tag color={lenderStatus(loanInfo?.lenderStatus)?.tag_color}>
            {lenderStatus(loanInfo?.lenderStatus)?.label}
          </Tag>
        </div>,
        lg: 12
      },
      {
        name: `Trạng thái sàn TMĐT duyệt`,
        hide: loanInfo?.businessId !== 1,
        value: <div className="flex">
          <Tag color={marketStatus(loanInfo?.marketStatus)?.tag_color}>
            {marketStatus(loanInfo?.marketStatus)?.label}
          </Tag>
        </div>,
        lg: 12
      },
    ].filter((d) => !d.hide)
  }, [loanInfo])

  return (
    <div>
      <Card
        size="small"
        className="w-100 mb-m"
        loading={loading}
        title={'Thông tin đơn vay'}
      >
        <Row gutter={[16, 8]}>
          {
            loanInfoData.map((info, index) => <Col
              key={index}
              lg={info.lg || 12}
              sm={24}
              xs={24}
            >
              <div className="flex align-items-center">
                <p className="text-gray mr-xs">{info.name}:</p>
                {info.value}
                <span>{info.note}</span>
              </div>
            </Col>)
          }
          <Col lg={24}>
            <p className="text-bold mt-m">
              Lịch đóng tiền dự kiến
            </p>
            <p>Phí nền tảng: {formatCurrency(loanInfo?.appSurcharge)} (Đóng kỳ đầu)</p>
            <PaymentSchedules
              bodered={false}
              data={loanInfo?.calculatePaymentSchedule || []}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default Infomation;