import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Tabs, Badge} from "antd";
import {PlusOutlined, CloseOutlined, CheckOutlined} from "@ant-design/icons";
import {AdminDetailHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import userServices from "../../../services/admin/user";
import customerServices from "../../../services/invest/customer";
import salesServices from "../../../services/invest/sales";
import { useParams } from 'react-router-dom';
import CustomerInfoTab from './../customers/customer_info_tab';
import SalesmanCustomerTab from './salesman_customer_tab';
import CONSTANTS from "../../../config/constants_invest";
import {genRequestId} from "../../../utils/common_invest";
import moment from 'moment';
import authServices from "../../../services/auth";
import ModalAddCustomerToSale from './components/modals/ModalAddCustomerToSale';
import {useTranslation} from "react-i18next";

const SalesmanDetail = (props) => {
    const {router} = props;
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState({});
    const [salemanInfo, setSalemanInfo] = useState({});
    const [isRefresh, setIsRefresh] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [actions, setActions] = useState([]);
    const [showModalAddCustomer, setShowModalAddCustomer] = useState(false);

    const [params, setParams] = useState({
        page: 1,
        size: 10,
    });

    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);
        await customerServices.customer_detail({account_id: id})
            .then((response) => {
                console.log('response', response?.data);
                setCustomer({...response?.data});
                // setActions(getActions(0));
                // setTotal(response?.page?.total_elements);
            })
            .catch(() => {
                // setData([]);
            });

        await salesServices.salesman_info({account_reference_id: id})
            .then((response) => {
                let _salesmanInfo = response?.data || {};
                console.log('_salesmanInfo', _salesmanInfo);
                setSalemanInfo(_salesmanInfo);
                setActions(getActions(_salesmanInfo?.roleStatus));
                // setTotal(response?.page?.total_elements);
            })
            .catch(() => {
                // setData([]);
            });

        setLoading(false);
    };

    const approveSales = async () => {
        setLoading(true);
        console.log('customer', customer);
        let data = {
            username: customer?.username,
            request_id: genRequestId(),
            client_ip: '',
            client_time: moment().format('YYYYMMDDHH'),
            device_id: 'web',
            account_job_contract: '01',
            account_block: '01',
            admin_user: authServices?.loginInfo()?.phone || "",
            account_id: customer?.rowId
        };
        try{
            let res = await salesServices.salesman_approve(data);
            await updateSalesInfo(CONSTANTS.SALES_STATUS_OBJ.ACTIVE.value);
        }
        catch(err){
            console.log('err', err);
            setLoading(false);
        }
        setLoading(false);
    };

    const updateSalesInfo = async (role_status) => {
        console.log('salemanInfo', salemanInfo);
        setLoading(true);
        let params = {
            role_status,
            role_type: 0,
            account_id: salemanInfo?.accountId
        };
        console.log('params', params);
        try{
            let res = await salesServices.salesman_update({}, params);
            fetchData();
        }
        catch(err){
            console.log('err', err);
            setLoading(false);
        }
        setLoading(false);
    };


    const onShowModalAddCustomer = () => {
        setShowModalAddCustomer(true);
    };

    const onAssignSuccess = () => {
        setIsRefresh(true);
    };


    const getActions = (status) => {
        switch (status){
            case CONSTANTS.SALES_STATUS[0].value:
                return([
                    {
                        key: "approve",
                        label: texts("approve"),
                        type: "primary",
                        icon: <CheckOutlined />,
                        click: () => {approveSales()},
                    },
                ]);
                break;
            case CONSTANTS.SALES_STATUS[1].value:
                return([
                    {
                        danger: true,
                        key: "un_active",
                        label: texts("unactive"),
                        type: "primary",
                        icon: <CloseOutlined />,
                        click: () => {updateSalesInfo(CONSTANTS.SALES_STATUS_OBJ.UN_ACTIVE.value)},
                    },
                    {
                        key: "add_customer",
                        label: texts("assign_customer"),
                        type: "primary",
                        icon: <PlusOutlined/>,
                        click: () => {onShowModalAddCustomer()},
                    }
                ]);
                break;
            case CONSTANTS.SALES_STATUS[2].value:
                return([
                    {
                        key: "active",
                        label: texts("active"),
                        type: "primary",
                        icon: <CheckOutlined />,
                        click: () => {updateSalesInfo(CONSTANTS.SALES_STATUS_OBJ.ACTIVE.value)},
                    },
                ]);
                break;
            default: break;
        }
        return [];
    };

    return (
        <div className="cms-users">
            <AdminDetailHeader
                title={router.label}
                // total={total}
                actions={actions}
            />

            <div className="content-wrapper">
                <div className="page-content">

                    <div className="main-content">
                        <div className='extra-custome-info'>
                            <Tabs
                                defaultActiveKey="1"
                                className="tab-info"
                                items={[
                                    {
                                        label: texts('salesman_info'),
                                        key: 'CUSTOMER-INFO',
                                        children: <CustomerInfoTab customer={customer} salesman={salemanInfo}/>,
                                    },
                                    {
                                        label: texts('customers'),
                                        key: 'CUSTOMER-ORDER',
                                        children:
                                            <SalesmanCustomerTab
                                                salesman={customer}
                                                isRefresh={isRefresh}
                                                setIsRefresh={setIsRefresh}
                                            />
                                    },
                                    // {
                                    //     label: 'Thông tin tài khoản Fmarket',
                                    //     key: 'CUSTOMER-FMK-INFO',
                                    //     children: <CustomerFmkAccountTab customer={customer}/>,
                                    // },
                                    // {
                                    //     label: 'Lịch sử GD Fmarket',
                                    //     key: 'CUSTOMER-FMK-TRANSACTIONS',
                                    //     children: <CustomerFmkTransactionsTab customer={customer}/>
                                    // },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <ModalAddCustomerToSale
                visible={showModalAddCustomer}
                onSuccess={onAssignSuccess}
                onClose={() => {setShowModalAddCustomer(false)}}
                salesAccountId={salemanInfo.accountId}
            />

            {/*<Filter params={params} setParams={setParams}/>*/}
            {/*<TableData loading={loading} data={data} params={params}/>*/}

            {/*<Pagination*/}
            {/*className="pagination"*/}
            {/*current={params.page}*/}
            {/*pageSize={params.size}*/}
            {/*total={total}*/}
            {/*showSizeChanger*/}
            {/*showQuickJumper*/}
            {/*showTotal={(total) => `Tổng số ${total}`}*/}
            {/*onChange={handleChangePage}*/}
            {/*/>*/}
        </div>
    );
};

export default SalesmanDetail;
