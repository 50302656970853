import React from "react";
import {
  Table,
} from 'antd';

import {
} from "@ant-design/icons";

import {
} from '../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    params,
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Mã ngân hàng',
      dataIndex: 'itemCode',
      key: 'itemCode',
      align: 'center',
    },
    {
      title: 'Tên ngân hàng',
      dataIndex: 'itemName',
      key: 'itemName',
      align: 'left',
    },
  ]

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 + (params.page - 1) * params.pageSize }))}
      loading={loading}
      pagination={false}
      scroll={{ x: 1024 }}
      rowKey={(record) => record?.id}
      size="small"
    />
  );
}

export default TableData;