import React from "react";
import {
  Table,
  Button,
  Space,
  Tag
} from "antd";

import {
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import { RouterLink } from '../../../../components'

import { PERMISSIONS } from "../../../../config/permissions";
import { isPermission } from "../../../../utils/common";

const TableData = (props) => {
  const {
    data,
    loading,
    params,
    onDelete = () => { }
  } = props;

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      align: "center",
      width: 80,
    },
    {
      title: "Vai trò",
      dataIndex: "name",
      key: "name",
      width: 150,
      render: (_, record) => <RouterLink
        label={record.name}
        routerName={'ROLE_DETAIL'}
        routerParams={{ id: record.roleId }}
        isPermission={isPermission([PERMISSIONS.VIEW_ROLE])}
      />
    },
    {
      title: "Quyền truy cập",
      dataIndex: "permission",
      key: "permission",
      render: (_, record) => {
        return <Space size={[0, 8]} wrap>
          {
            record.permission.slice(0, 5).map((r, index) => <Tag key={index}>
              {r}
            </Tag>)
          }
          {
            record.permission.length > 5 && <Tag color={'blue'}>
              + {record.permission.length - 5}
            </Tag>
          }
        </Space>
      }
    },
    {
      title: "Hành động",
      dataIndex: "roleId",
      key: "roleId",
      align: "center",
      width: 150,
      hide: !isPermission([PERMISSIONS.UPDATE_ROLE, PERMISSIONS.VIEW_ROLE]) && !isPermission([PERMISSIONS.DELETE_ROLE]),
      render: (_, record) => {
        return (
          <Space size={[8, 8]} wrap>
            {
              isPermission([PERMISSIONS.UPDATE_ROLE, PERMISSIONS.VIEW_ROLE]) && <Button
                icon={<EditOutlined />}
                type="primary"
                size="small"
                ghost
                onClick={() =>
                  window.navigatePage("ROLE_DETAIL", { id: record.roleId })
                }
              >
                Sửa
              </Button>
            }
            {
              isPermission([PERMISSIONS.DELETE_ROLE]) && <Button
                icon={<DeleteOutlined />}
                type="primary"
                size="small"
                danger
                ghost
                onClick={() => onDelete(record)}
              >
                Xoá
              </Button>
            }
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({
        ...d,
        stt: index + 1 + (params.page - 1) * params.size,
      }))}
      loading={loading}
      pagination={false}
      rowKey={(record) => record?.roleId}
      scroll={{ x: 1024 }}
      size="small"
    />
  );
};

export default TableData;
