import {
    AppstoreOutlined,
    UserOutlined,
    FundOutlined,
    BankOutlined,
    BookOutlined,
    FileTextOutlined,
    FileProtectOutlined,
    DollarOutlined,
    TeamOutlined
} from '@ant-design/icons'
import {getRouterByName, getRoutersByMenu} from '../../utils/common'

import { Trans } from 'react-i18next';

export default {
    MENUS: [
        {
            ...getRouterByName('DASHBOARD'),
        },
        {
            icon: <AppstoreOutlined/>,
            label: <Trans i18nKey='admin_account' />,
            key: 'ADMIN',
            name: getRoutersByMenu('ADMIN')[0]?.name,
            children: getRoutersByMenu('ADMIN'),
        },
        {
            icon: <UserOutlined/>,
            label: <Trans i18nKey='customer' />,
            key: 'CUSTOMER',
            name: getRoutersByMenu('CUSTOMER')[0]?.name,
            children: getRoutersByMenu('CUSTOMER'),
        },

//        {
//            icon: <DollarOutlined />,
//            label: <Trans i18nKey='sales' />,
//            key: 'SALES',
//            name: getRoutersByMenu('SALES')[0]?.name,
//            children: getRoutersByMenu('SALES'),
//        },

        // {
        //     icon: <BookOutlined />,
        //     label: 'Nghiệp vụ',
        //     key: 'MAJOR',
        //     name: getRoutersByMenu('MAJOR')[0]?.name,
        //     children: getRoutersByMenu('MAJOR'),
        // },
        {
            icon: <BankOutlined />,
            label: <Trans i18nKey='product' />,
            key: 'PRODUCT',
            name: 'PRODUCT',
            children: [
                {
//                    icon: <BankOutlined />,
                    label: <Trans i18nKey='saving_invest' />,
                    key: 'SAVINGS',
                    name: getRoutersByMenu('SAVINGS')[0]?.name,
                    children: getRoutersByMenu('SAVINGS'),
                },

                {
//                    icon: <FundOutlined />,
                    label: <Trans i18nKey='funds_invest' />,
                    key: 'FUNDS',
                    name: getRoutersByMenu('FUNDS')[0]?.name,
                    children: getRoutersByMenu('FUNDS'),
                },
            ],
        },

        {
            icon: <TeamOutlined />,
            label: <Trans i18nKey='partner' />,
            key: 'PARTNER',
            name: getRoutersByMenu('PARTNER')[0]?.name,
            children: getRoutersByMenu('PARTNER'),
        },

        {
            icon: <FileProtectOutlined />,
            label: <Trans i18nKey='reconciliation' />,
            key: 'TRANSACTIONS',
            name: getRoutersByMenu('TRANSACTIONS')[0]?.name,
            children: getRoutersByMenu('TRANSACTIONS'),
        },

        {
            icon: <FileTextOutlined />,
            label: <Trans i18nKey='information' />,
            key: 'INFORMATION',
            name: getRoutersByMenu('INFORMATION')[0]?.name,
            children: getRoutersByMenu('INFORMATION'),
        },

        {
            icon: <FileTextOutlined />,
            label: <Trans i18nKey='legal_contract' />,
            key: 'LEGAL_CONTRACT',
            name: getRoutersByMenu('LEGAL_CONTRACT')[0]?.name,
            children: getRoutersByMenu('LEGAL_CONTRACT'),
        },
    ]
}
