import React, { useState, useEffect, useMemo } from "react";
import { useParams } from 'react-router-dom';

import { AdminDetailHeader } from "../../../components";
import DetailCustomer from "./components/detail/Customer";
import DetailBankAccounts from "./components/detail/BankAccounts";
import DetailTransactions from "./components/detail/Transactions";
import DetailContracts from "./components/detail/Contracts";
import DetailLoans from "./components/detail/Loans";

import {
  convertStringToQuery,
  blackListStatus
} from '../../../utils/common'

import profileServices from '../../../services/finblue/profile';

const CustomerDetail = (props) => {
  const { router } = props
  const { id } = useParams();
  const query = convertStringToQuery(window.location.search);
  const [customerInfo, setCustomerInfo] = useState({})
  const [loading, setLoading] = useState(false)

  const params = useMemo(() => {
    return {
      id: Number(id),
    }
  }, [id, query]);


  useEffect(() => {
    if (params.id) {
      fetchData()
    }
  }, [params.id])

  const fetchData = async () => {
    setLoading(true);
    await profileServices.get(params.id).then((response) => {
      setCustomerInfo(response?.data || {})
    }).catch(() => {
      setCustomerInfo({})
    })
    setLoading(false);
  }


  return (
    <div className="limit-approval-detail">
      <AdminDetailHeader
        title={router.label}
        status={blackListStatus(0)}
        showScore={true}
        score={customerInfo.score}
        scoreBy={customerInfo.scoreBy}
      />
      <DetailCustomer
        loading={loading}
        customerInfo={customerInfo}
      />
      <DetailBankAccounts
        params={params}
      />
      <DetailTransactions
        loading={loading}
        params={params}
      />
      <DetailContracts
        loading={loading}
        customerId={params.id}
      />
      <DetailLoans
        loading={loading}
        customerId={params.id}
      />
    </div>
  );
}

export default CustomerDetail;