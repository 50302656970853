import React from "react";
import {
  Table,
} from 'antd';

import {
} from "@ant-design/icons";

import {
  formatCurrency,
  formatNumber
} from '../../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
      fixed: 'left'
    },
    {
      title: 'Tên CTTC',
      dataIndex: 'lenderName',
      key: 'lenderName',
      align: 'left',
      width: 200,
      render: (_, record) => <a>
        {record.lenderName}
      </a>,
      fixed: 'left'
    },
    {
      title: 'Số đơn',
      dataIndex: 'totalOrder',
      key: 'totalOrder',
      align: 'right',
      width: 100,
      render: (_, record) => formatNumber(record.totalOrder)
    },
    {
      title: 'Tổng phí nền tảng',
      dataIndex: 'totalInterest',
      key: 'totalInterest',
      align: 'right',
      width: 200,
      render: (_, record) => formatCurrency(record.totalInterest)
    },
    {
      title: 'Tổng giá trị đơn vay',
      dataIndex: 'totalMoneyOrder',
      key: 'totalMoneyOrder',
      align: 'right',
      width: 200,
      render: (_, record) => formatCurrency(record.totalMoneyOrder)
    },
    {
      title: 'Tổng tiền đã trả',
      dataIndex: 'totalMoneyPaid',
      key: 'totalMoneyPaid',
      align: 'right',
      width: 200,
      render: (_, record) => formatCurrency(record.totalMoneyPaid)
    },
    {
      title: 'Đơn quá hạn',
      dataIndex: 'totalDebt',
      key: 'totalDebt',
      align: 'right',
      width: 200,
      render: (_, record) => formatNumber(record.totalDebt)
    },
    {
      title: 'Quá hạn 30 ngày',
      dataIndex: 'totalDebt30',
      key: 'totalDebt30',
      align: 'right',
      width: 200,
      render: (_, record) => formatNumber(record.totalDebt30)
    },
    {
      title: 'Quá hạn 60 ngày',
      dataIndex: 'totalDebt60',
      key: 'totalDebt60',
      align: 'right',
      width: 200,
      render: (_, record) => formatNumber(record.totalDebt60)
    },
    {
      title: 'Quá hạn 90 ngày',
      dataIndex: 'totalDebt90',
      key: 'totalDebt90',
      align: 'right',
      width: 200,
      render: (_, record) => formatNumber(record.totalDebt90)
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 }))}
      loading={loading}
      pagination={false}
      rowKey={(record) => record?.stt}
      scroll={{ x: 1200 }}
      size="small"
    />
  );
}

export default TableData;