import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints";

import systemServices from '../../services/system';

const cmsInfo = systemServices.cms_info();

function search_roles(params) {
  return request({
    url: ENDPOINT.ROLES,
    method: "get",
    params: {
      ...params,
      system: cmsInfo?.value
    },
  });
}

function get_role(id, params = {}) {
  return request({
    url: ENDPOINT.ROLE_DETAIL.replace(':id', id),
    method: "get",
    params: {
      ...params,
      system: cmsInfo?.value
    },
  });
}

function create_role(data) {
  return request({
    url: ENDPOINT.ROLE,
    method: "post",
    data: {
      ...data,
      system: cmsInfo?.value
    }
  });
}

function update_role(id, data) {
  return request({
    url: ENDPOINT.ROLE_DETAIL.replace(':id', id),
    method: "put",
    data
  });
}

function remove_role(id) {
  return request({
    url: ENDPOINT.ROLE_DETAIL.replace(':id', id),
    method: "delete"
  });
}

export default {
  search_roles,
  get_role,
  create_role,
  update_role,
  remove_role
};
