import React, { useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  Card
} from 'antd';

import { TableContent } from "../../../../../components";
import {
  convertDateTime,
  convertGender,
  customerRank
} from '../../../../../utils/common';

const Customer = (props) => {
  const {
    customerInfo = {},
    loading = false
  } = props

  const customerInfoData = useMemo(() => {
    return [
      {
        name: 'Họ tên',
        content: customerInfo?.name,
        unit: 'string',
        lg: 12,
      },
      {
        name: 'Phân hạng',
        content: customerRank(customerInfo?.rank)?.label,
        unit: 'string',
        lg: 12,
      },
      {
        name: 'Địa chỉ Email',
        content: customerInfo?.email,
        unit: 'string',
        lg: 12,
      },
      {
        name: 'Số điện thoại',
        content: customerInfo?.phone,
        unit: 'string',
        lg: 12,
      },
      {
        name: 'Ngày sinh',
        content: convertDateTime(customerInfo?.birthday, 'DD/MM/YYYY'),
        unit: 'date',
        lg: 12,
      },
      {
        name: 'Giới tính',
        content: convertGender(customerInfo?.gender)?.label,
        unit: 'string',
        lg: 12,
      },
      {
        name: 'CMND/CCCD',
        content: customerInfo.nationalId,
        unit: 'string',
        lg: 12,
      },
      {
        name: 'Nơi cấp CMND/CCCD',
        content: customerInfo.placeOfIssue,
        unit: 'string',
        lg: 24,
      },
      {
        name: 'Nghề nghiệp',
        content: customerInfo?.jobCategoryName,
        unit: 'string',
        lg: 12,
      },
      {
        name: 'Kinh nghiệm',
        content: customerInfo?.experienceName,
        unit: 'string',
        lg: 12,
      },
      {
        name: 'Mức lương',
        content: customerInfo?.salaryName,
        unit: 'string',
        lg: 12,
      },
      {
        name: 'Quê quán',
        content: customerInfo?.homeTown,
        unit: 'string',
        lg: 24,
      },
      {
        name: 'Địa chỉ thường trú',
        content: `${customerInfo?.address} - ${customerInfo?.wardName} - ${customerInfo?.districtName} - ${customerInfo?.provinceName}`,
        unit: 'string',
        lg: 24,
      }
    ]
  }, [customerInfo])

  const images = useMemo(() => {
    return [
      {
        name: 'Ảnh mặt trước CCCD/CMND',
        content: customerInfo?.citizenIdentityCardUrlFront,
        unit: 'image',
      },
      {
        name: 'Ảnh mặt sau CCCD/CMND',
        content: customerInfo?.citizenIdentityCardUrlBack,
        unit: 'image',
      },
      {
        name: 'Ảnh Selfie',
        content: customerInfo?.selfieUrl,
        unit: 'image',
      },
    ]
  }, [customerInfo])

  return (
    <Card
      size="small"
      className="w-100 mt-m mb-m"
      loading={loading}
      title={'Thông tin khách hàng'}
    >
      <Row gutter={[16, 8]}>
        {
          customerInfoData.map((info, index) => <Col
            key={index}
            lg={info.lg || 12}
            sm={24}
            xs={24}
          >
            <div className="flex align-items-center">
              <p className="text-gray mr-xs">{info.name}:</p>
              <TableContent record={info} />
            </div>
          </Col>)
        }
      </Row>
      <Row gutter={[16, 8]}>
        {
          images.map((image, index) => <Col
            className="mt-m"
            key={index}
            lg={8}
            sm={8}
            xs={24}
          >
            <div className="text-center">
              <TableContent record={image} fullWidth={true} />
              <p className="text-gray mt-m">{image.name}</p>
            </div>
          </Col>)
        }
      </Row>
    </Card>
  );
}

export default Customer;