import React, { useState } from "react";
import './css/login.scss';
import { useDispatch } from 'react-redux';
import { LogoApp } from '../../components';

import { USERNAME_RULES, PASSWORD_RULES } from '../../config/rules'

import authServices from '../../services/auth'

import {
  Form, Input, Button,
  Divider, Row, Col,
  Spin, message
} from 'antd';

const Login = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [submitTing, setSubmitTing] = useState(false);

  const handleSubmit = async (values) => {
    setSubmitTing(true);
    authServices.login(values).then((response) => {
      const { data, status } = response;
      if (data && data?.token) {
        authServices.update_login_info(data);
        window.location.href = window.location.origin
        setTimeout(() => {
          window.location.reload();
        }, 100)
      } else {
        message.error(status?.message);
      }
      setSubmitTing(false);
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
      setSubmitTing(false);
    })

  }

  return (
    <Spin spinning={submitTing}>
      <div className="login-page">
        <div className="page-content">
          <div className="page-content__top">
            <Row gutter={[16, 0]}>
              <Col className="page-content__top__left">
                <LogoApp />
              </Col>
              <Col className="page-content__top__right">
                <Divider type='vertical' />
                <span className="text text--bold">CMS</span>
              </Col>
            </Row>
          </div>

          <div className="page-content__bottom">
            <Form
              form={form}
              initialValues={
                {
                  username: '',
                  password: ''
                }
              }
              onFinish={handleSubmit}
            >
              <Form.Item name='username'
                rules={USERNAME_RULES}
              >
                <Input placeholder="Nhập tên đăng nhập" />
              </Form.Item>

              <Form.Item name='password'
                rules={PASSWORD_RULES}
              >
                <Input.Password placeholder="Nhập mật khẩu" />
              </Form.Item>

              <Button className="btn-submit"
                type='primary'
                size='large'
                htmlType='submit'
              >
                Đăng nhập
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default Login;