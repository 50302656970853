import React, { useState, useEffect } from "react";
import { Pagination, Modal, message } from 'antd';
import { AdminHeader } from "../../../components";
import { PlusOutlined, ExclamationCircleFilled } from "@ant-design/icons";

import Filter from "./components/customer-accounts/Filter";
import TableData from "./components/customer-accounts/TableData";
import BankAccountFormModal from "./components/modals/BankAccountForm"
import financeServices from '../../../services/finblue/finance';

import { convertStringToQuery } from '../../../utils/common';

const CustomerBankAccounts = (props) => {
  const { router } = props
  const query = convertStringToQuery(window.location.search);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [formVisible, setFormVisible] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [params, setParams] = useState({
    page: Number(query?.page || 1),
    pageSize: 10,
    keyword: query?.keyword || null,
  });

  useEffect(() => {
    fetchData();
    window.navigatePage('CUSTOMER_BANK_ACCOUNTS', {}, {
      page: params.page,
      keyword: params.keyword,
    })
  }, [params])

  const handleChangePage = (page, pageSize) => {
    setParams({
      ...params,
      page,
      pageSize
    })
  };

  const fetchData = async () => {
    setLoading(true);
    await financeServices.search_finances({
      pageSize: params.pageSize,
      page: params.page - 1,
      keyword: params.keyword,
    }).then((response) => {
      let allBanks = (response?.data || []).reverse()
      setData(allBanks.slice((params.page - 1) * params.pageSize, params.page * params.pageSize));
      setTotal(allBanks.length || 0)
    }).catch(() => {
      setData([])
      setTotal(0)
    })
    setLoading(false);
  }

  const handleOpenFormModal = (market = null) => {
    setSelectedBankAccount(market);
    setFormVisible(true)
  };

  const handleDelete = async (account) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xoá không?',
      onOk() {
        financeServices.remove({
          id: account.id
        }).then(() => {
          message.success('Xoá tài khoản ngân hàng thành công')
          fetchData();
        }).catch((error) => {
          message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
      },
      onCancel() { },
    });
  }

  return (
    <div className="customer-bank-accounts">
      <AdminHeader
        title={router.label}
        total={total}
        actions={[
          // {
          //   key: 'add',
          //   label: 'Thêm mới',
          //   type: 'primary',
          //   icon: <PlusOutlined />,
          //   click: () => handleOpenFormModal(null)
          // }
        ]}
      />
      <Filter
        params={params}
        setParams={(v) => setParams({
          ...v,
          page: 1,
        })}
      />
      <TableData
        loading={loading}
        data={data}
        params={params}
        remove={(v) => handleDelete(v)}
        edit={(v) => handleOpenFormModal(v)}
      />
      <Pagination
        className="pagination"
        current={params.page}
        pageSize={params.pageSize}
        total={total}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Tổng số ${total}`}
        onChange={handleChangePage}
      />
      {
        formVisible && <BankAccountFormModal
          visible={formVisible}
          bankAccount={selectedBankAccount}
          onClose={() => setFormVisible(false)}
          reload={() => fetchData()}
        />
      }
    </div>
  );
}

export default CustomerBankAccounts;