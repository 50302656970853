import authServices from "../services/auth";

export function handleResponseErrorMessage(err) {
  if (!err.response) {
    return err;
  } else if (err.response.status === 401) {
    authServices.logout();
  } else if (err.response.status === 403) {
    // window.navigatePage('403')
  } else if (err.response.status === 500) {
    window.navigatePage('500')
  } else if (err.response.status === 502) {
    window.navigatePage('502')
  } else {
    return err?.response?.data || {
      data: null,
      status: {
        label: '',
        message: 'Có lỗi hệ thống. Vui lòng thử lại sau!'
      }
    };
  }
}
