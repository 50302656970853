import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Form,
  Select,
  InputNumber,
  message
} from 'antd';

import {
} from "@ant-design/icons";

import CONSTANTS from "../../../../../config/constants";
import {
  TRANSACTION_NAME_RULES,
  TRANSACTION_AMOUNT_RULES,
  TRANSACTION_INTERES_RATE_RULES
} from "../../../../../config/rules";
import { numMonths } from '../../../../../utils/common'
import productServices from '../../../../../services/finblue/product'

const ProductFormModal = (props) => {
  const [form] = Form.useForm();
  const numMonthsData = numMonths() || [];
  const {
    transaction = null,
    visible = false,
    onClose = () => { },
    reload = () => { }
  } = props

  useEffect(() => {
    if (transaction) {
      form.setFieldsValue({
        name: transaction.name || transaction.limitName,
        active: transaction.active,
        amount: transaction.amount,
        numMonth: transaction.numMonth,
        interestRate: transaction.interestRate,
        appSurcharge: transaction.appSurcharge
      })
    } else {
      form.setFieldsValue({
        name: null,
        active: true,
        amount: 1000000,
        numMonth: 6,
        interestRate: 10,
        appSurcharge: 1,
      })
    }
  }, [transaction])

  const [callingAPI, setCallingAPI] = useState(false);

  const handleSubmit = () => {
    form.validateFields().then(async (valid) => {
      if (valid) {
        setCallingAPI(true);
        const values = form.getFieldsValue();
        if (transaction) {
          await handleUpdate({
            id: transaction.id,
            ...values,
          })
        } else {
          await handleCreate(values)
        }
        setCallingAPI(false);
      }
    })
  }

  const handleCreate = async (data) => {
    await productServices.create_transaction(data).then(() => {
      message.success('Thêm hạn mức thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const handleUpdate = async (data) => {
    await productServices.update_transaction(data).then(() => {
      message.success('Cập nhật hạn mức thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  return (
    <Modal
      title={transaction ? "Chỉnh sửa hạn mức" : "Thêm mới hạn mức"}
      width={500}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => onClose()}
      okButtonProps={{
        loading: callingAPI
      }}
      cancelButtonProps={{
        disabled: callingAPI
      }}
      okText='Lưu'
      cancelText='Hủy'
    >
      <Form
        form={form}
        layout='vertical'
      >
        <Row
          justify={'left'}
          gutter={[8, 8]}
        >
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Tên hạn mức'}
              name={'name'}
              rules={TRANSACTION_NAME_RULES}
            >
              <Input
                disabled={callingAPI}
                placeholder="Vui lòng nhập tên hạn mức"
              />
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Số tiền'}
              name={'amount'}
              rules={TRANSACTION_AMOUNT_RULES}
            >
              <InputNumber
                className="w-full"
                min={1000000}
                disabled={callingAPI}
                placeholder={'Nhập số tiền'}
                formatter={v => `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={v => v.replace(/\$\s?|(,*)/g, '')}
                addonAfter={'VNĐ'}
              />
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Phí nền tảng (%/năm)'}
              name={'appSurcharge'}
              rules={[]}
            >
              <InputNumber
                className="w-full"
                min={0}
                max={100}
                disabled={callingAPI}
                placeholder={'Nhập % phí trên tổng đơn hàng'}
                formatter={v => `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={v => v.replace(/\$\s?|(,*)/g, '')}
                addonAfter={'%'}
              />
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Thời hạn (Tháng)'}
              name={'numMonth'}
              rules={[]}
            >
              <Select
                className="w-100"
                placeholder="Chọn thời hạn cho hạn mức"
                disabled={callingAPI}
              >
                {numMonthsData.map((num) => (
                  <Select.Option
                    value={num.value}
                    key={num.value}
                  >
                    {num.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Lãi suất (%/năm)'}
              name={'interestRate'}
              rules={TRANSACTION_INTERES_RATE_RULES}
            >
              <InputNumber
                className="w-full"
                disabled={callingAPI}
                min={0}
                max={100}
                placeholder={'Nhập lãi suất'}
                formatter={v => `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={v => v.replace(/\$\s?|(,*)/g, '')}
                addonAfter={'%'}
              />
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              name={'active'}
              label={'Trạng thái hiển thị'}
              rules={[]}
            >
              <Select
                className="w-100"
                disabled={callingAPI}
              >
                {CONSTANTS.ACTIVE_STATUS.map((status) => (
                  <Select.Option
                    value={status.value}
                    key={status.value}
                  >
                    {status.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>

  );
}

export default ProductFormModal;