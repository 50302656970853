import React from "react";
import "../css/components/Filter.scss";
import { Row, Col, Input, Select } from "antd";

import { SearchOutlined } from "@ant-design/icons";
import CONSTANTS from "../../../../config/constants_invest";
import {useTranslation} from "react-i18next";

const Filter = (props) => {
  const { params = {}, setParams = () => {} } = props;

    const { t } = useTranslation();
    const texts = t;

  return (
      <Row className="filter" gutter={[8, 8]}>
          <Col span={3} lg={6} sm={12} xs={24}>
              <Input
                  prefix={<SearchOutlined/>}
                  value={params.keyword}
                  placeholder={texts('search')}
                  onChange={(e) => setParams({...params, keyword: e.target.value})}
              />
          </Col>

          <Col span={3} lg={6} sm={12} xs={24}>
              <Select
                  placeholder={"Danh mục"}
                  value={params.category}
                  onChange={(v) => setParams({...params, category: v})}
                  showSearch={true}
                  optionFilterProp="children"
                  className="w-full"
                  filterOption={(input, option) =>
                      `${option.children}`.toLowerCase().includes(input.toLowerCase())
                  }
              >
                  {CONSTANTS.NEWS_CATEGORIES.map((type) => (
                      <Select.Option value={type.value} key={type?.value}>
                          {type?.name}
                      </Select.Option>
                  ))}
              </Select>
          </Col>

          <Col span={3} lg={6} sm={12} xs={24}>
              <Select
                  placeholder={"Phân loại"}
                  value={params.newsType}
                  onChange={(v) => setParams({...params, newsType: v})}
                  showSearch={true}
                  optionFilterProp="children"
                  className="w-full"
                  filterOption={(input, option) =>
                      `${option.children}`.toLowerCase().includes(input.toLowerCase())
                  }
              >
                  {CONSTANTS.NEWS_TYPES.map((type) => (
                      <Select.Option value={type.value} key={type?.value}>
                          {type?.name}
                      </Select.Option>
                  ))}
              </Select>
          </Col>
      </Row>
  );
};

export default Filter;
