import React from "react";
import {
  Input,
  Modal,
  Form,
} from 'antd';

import {
} from "@ant-design/icons";

const Unapproval = (props) => {
  const [form] = Form.useForm();
  const {
    title = 'Từ chối duyệt đơn vay',
    visible = false,
    callingAPI = false,
    onClose = () => { },
    submit = () => { },
  } = props

  const handleSubmit = () => {
    form.validateFields().then((valid) => {
      if (valid) {
        const values = form.getFieldsValue();
        submit({
          comment: values.comment
        });
      }
    })
  }
  return (
    <Modal
      title={title}
      width={500}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => onClose()}
      cancelButtonProps={{
        disabled: callingAPI
      }}
      okButtonProps={{
        loading: callingAPI,
        danger: true
      }}
      okText='Xác nhận'
      cancelText='Hủy'
    >
      <Form
        form={form}
        name="basic"
        layout='vertical'
      >
        <Form.Item
          label={'Lý do từ chối'}
          name={'comment'}
        >
          <Input.TextArea
            disabled={callingAPI}
            rows={4}
            placeholder="Vui lòng nhập lý do"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Unapproval;