import React, { useEffect, useState, useMemo } from "react";
import {
  Pagination
} from 'antd';
import { useParams } from 'react-router-dom';

import {
} from "@ant-design/icons";

import { ReceiptTable, AdminHeader } from '../../../components'
import receiptServices from '../../../services/finblue/receipt';
import Filter from './components/receipts/Filter';

const BankAccountReceipts = (props) => {
  const {
    router
  } = props
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const { id } = useParams();
  const [receipts, setReceipts] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    keyword: null,
  });

  useEffect(() => {
    fetchData();
  }, [id, params])

  const fetchData = () => {
    setLoading(true);
    receiptServices.get_receipts({
      ...params,
      page: params.page - 1,
      financeId: id,
      type: 1
    }).then((response) => {
      setReceipts(response.data)
      setTotal(response.page.total_elements)
      setLoading(false);
    }).catch(() => {
      setReceipts([])
      setTotal(0)
      setLoading(false);
    })
  }

  const handleChangePage = (page, size) => {
    setParams({
      ...params,
      page,
      size,
    });
  };

  return (
    <div className="product-packages">
      <AdminHeader
        title={router.label}
        total={total}
        actions={[]}
      />
      <Filter
        params={params}
        setParams={(v) => setParams({
          ...v,
          page: 1,
        })}
      />
      <ReceiptTable
        data={receipts}
        loading={loading}
        bordered={true}
        className="table-data"
      />
      <Pagination
        className="pagination"
        current={params.page}
        pageSize={params.pageSize}
        total={total}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Tổng số ${total}`}
        onChange={handleChangePage}
      />
    </div>
  );
}

export default BankAccountReceipts;