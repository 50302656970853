import React, { useEffect, useState } from "react";
import {
  Card
} from 'antd';

import { Histories } from '../../../../../components';
import loanServices from '../../../../../services/finblue/loan';

const ApprovalHistory = (props) => {
  const {
    loading = false,
    params = {},
    loanInfo = {},
  } = props

  const [getting, setGetting] = useState(false);
  const [reviewHistory, setReviewHistory] = useState([])

  useEffect(() => {
    getReviewHistory()
  }, [loanInfo])

  const getReviewHistory = async () => {
    setGetting(true);
    await loanServices.get_review_history(params.id).then((response) => {
      setReviewHistory(response?.data || [])
    }).catch(() => {
      setReviewHistory([])
    })
    setGetting(false);
  }

  return (
    <Card
      size="small"
      className="w-100 mb-m"
      loading={loading || getting}
      title={'Lịch sử xét duyệt'}
    >
      <Histories
        bordered={false}
        data={reviewHistory}
      />
    </Card>
  );
}

export default ApprovalHistory;