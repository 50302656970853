import React from "react";
import "../css/components/TableData.scss";
import { Row, Table, Button, Modal, Tag } from "antd";

import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import { formatMoneyCurrency } from "../../../../utils/common";
import { getAccountHref, getObjectStatus } from "../../../../utils/common_invest";
import CONSTANTS from "../../../../config/constants_invest";
import moment from 'moment';
import {useTranslation} from "react-i18next";

const TableData = (props) => {
    const {
        data,
        loading,
        params,
        showDetail = () => { }
    } = props;

    const { t } = useTranslation();
    const texts = t;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: texts('no.'),
            dataIndex: "stt",
            key: "stt",
            align: "center",
        },
        {
            title: texts('customer'),
            dataIndex: "accFullname",
            key: "accFullname",
//            align: "center",
            render: (_, record) => (
                <a href={getAccountHref(record?.sysUserId)}>{record?.accFullname}</a>
            ),
        },
        {
            title: texts('contract_code'),
            dataIndex: "orderCode",
            key: "orderCode",
//            align: "center",
        },
        {
            title: texts('product'),
            dataIndex: "productName",
            key: "productName",
//            align: "center",
        },
        {
            title: <div style={{textAlign: 'center', fontWeight: 600}}>{texts('amount')}</div>,
            dataIndex: "amount",
            key: "amount",
            align: "right",
            render: (_, record) => formatMoneyCurrency(record?.amount)
        },
        {
            title: <div style={{textAlign: 'center', fontWeight: 600}}>{texts('interest')}</div>,
            dataIndex: "interestRate",
            key: "interestRate",
            align: "right",
            render: (_, record) => record?.interestRate + '%'
        },
        {
            title: texts('transaction_date'),
            dataIndex: "createdAt",
            key: "createdAt",
//            align: "center",
            render: (_, record) => (
                moment(record?.createdAt).format('HH:mm DD/MM/YYYY')
            ),
        },
        {
            title: texts('status'),
            dataIndex: "status",
            key: "status",
            align: "center",
            render: (_, record) => {
                let objectStatus = getObjectStatus(CONSTANTS.SAVINGS_TRANSACTION_STATUS, record.status);
                return (
                    <Tag
                        size={'default'}
                        color={objectStatus?.color}>
                        {texts(objectStatus?.label)}
                    </Tag>
                )
            }
        },
        {
            title: texts('actions'),
            dataIndex: "actions",
            key: "actions",
            align: "center",
            render: (_, record) => (
                <Row gutter={[8, 8]} justify={'center'}>
                    <Button ghost type='primary' size='small' onClick={() => showDetail(record)}>
                        {texts('detail')}
                    </Button>
                </Row>
            ),
        },
    ];

    return (
        <Table
            bordered
            className="table-data"
            columns={columns}
            dataSource={data.map((d, index) => ({
                ...d,
                stt: index + 1 + (params.page - 1) * params.size,
            }))}
            loading={loading}
            pagination={false}
            rowKey={(record) => record?.id}
            size="small"
        />
    );
};

export default TableData;
