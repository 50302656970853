import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function search_ekyc(params) {
  return request({
    url: ENDPOINT.PROFILES_CUSTOMER_EKYC_SEARCH,
    method: "get",
    params,
  });
}

function get_ekyc_statistics(params = {}) {
  return request({
    url: ENDPOINT.PROFILES_CUSTOMER_EKYC_STATISTIC,
    method: "get",
    params,
  });
}

function get(id, params = {}) {
  return request({
    url: `${ENDPOINT.PROFILES_CUSTOMER}/${id}`,
    method: "get",
    params,
  });
}

function ekyc_approved(id, params = {}) {
  return request({
    url: ENDPOINT.PROFILES_CUSTOMER_EKYC_APPROVED.replace(':id', id),
    method: "post",
    params,
  });
}

function list_customer_manage(params) {
  return request({
    url: ENDPOINT.PROFILES_CUSTOMER_MANAGER,
    method: "get",
    params,
  });
}

function get_customer_statistic(params) {
  return request({
    url: ENDPOINT.PROFILES_CUSTOMER_STATISTIC,
    method: "get",
    params,
  });
}

export default {
  search_ekyc,
  get_ekyc_statistics,
  get,
  ekyc_approved,
  list_customer_manage,
  get_customer_statistic
};
