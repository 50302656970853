import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Tabs, Spin} from "antd";
import CustomerGoldTransaction from "./customer_gold_transaction";

const CustomerGoldTab = (props) => {
    const {customer} = props;

    return (
        <Tabs
            defaultActiveKey={"CUSTOMER-INFO"}
            className="tab-info"
            items={[
                {
                    label: 'Lịch sử giao dịch',
                    key: 'CUSTOMER-ORDER',
                    children: <CustomerGoldTransaction customer={customer}/>
                },
            ]}
        />
    );
};

export default CustomerGoldTab;
