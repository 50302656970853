import React from "react";
import "../css/components/TableData.scss";
import { Row, Table, Button, Modal, Tag } from "antd";

import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import { formatMoneyCurrency } from "../../../../utils/common";
import { getAccountHref, getObjectStatus } from "../../../../utils/common_invest";
import CONSTANTS from "../../../../config/constants_invest";
import moment from 'moment';
import {useTranslation} from "react-i18next";

const TableData = (props) => {
    const {
        data,
        loading,
        params,
        showDetail = () => { }
    } = props;

    const { t } = useTranslation();
    const texts = t;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const genTagStatus = (item) => {
        let requestStatus = item?.balanceChangeRequests?.[0]?.requestStatus;
        let status = CONSTANTS.BALANCE_CHANGE_REQUEST_STATUS.find(e => e?.value === requestStatus);
        if(status){
            return <Tag color={status?.color}>{status?.name}</Tag>
        }
        return <Tag color={'red'}>Thất bại</Tag>
    };

    const columns = [
        {
            title: texts('no.'),
            dataIndex: "stt",
            key: "stt",
            align: "center",
            width: 50
        },
        {
            title: texts('customer'),
            dataIndex: "accFullname",
            key: "accFullname",
            width: 180,
//            align: "center",
            render: (_, record) => (
                <a href={getAccountHref(record?.accountInfo?.rowId)}>{record?.accountInfo?.accFullname}</a>
            ),
        },
        {
            title: texts('contract_code'),
            dataIndex: "orderCode",
            key: "orderCode",
            width: 120,
//            align: "center",
            render: (_, record) => (
                <div>{record?.paymentTransaction?.contractCode}</div>
            )
        },
        {
            title: texts('product'),
            dataIndex: "productName",
            key: "productName",
            width: 100,
            render: (_, record) => (
                <div>{record?.productDetails?.itemName}</div>
            ),
//            align: "center",
        },
        {
            title: <div style={{textAlign: 'center', fontWeight: 600}}>{texts('interest')}</div>,
            dataIndex: "interestRate",
            key: "interestRate",
            align: "right",
            width: 70,
            render: (_, record) => record?.paymentTransaction?.interest + '%'
        },
        {
            title: <div style={{textAlign: 'center', fontWeight: 600}}>Tiền gốc</div>,
            dataIndex: "amount",
            key: "amount",
            align: "right",
            width: 140,
            render: (_, record) => formatMoneyCurrency(record?.paymentTransaction?.transactionAmount)
        },
//        {
//            title: <div style={{textAlign: 'center', fontWeight: 600}}>Tiền lãi</div>,
//            dataIndex: "amount",
//            key: "amount",
//            align: "right",
//            width: 120,
//            render: (_, record) => formatMoneyCurrency(record?.paymentForceSettleRequests?.[0]?.forceSettleAmount)
//        },
        {
            title: <div style={{textAlign: 'center', fontWeight: 600}}>Tiền tất toán</div>,
            dataIndex: "amount",
            key: "amount",
            align: "right",
            width: 140,
            render: (_, record) => formatMoneyCurrency((record?.paymentTransaction?.transactionAmount || 0) + (record?.paymentForceSettleRequests?.[0]?.forceSettleAmount || 0))
        },
        {
            title: 'Ngày tất toán',
            dataIndex: "createdAt",
            key: "createdAt",
            width: 140,
//            align: "center",
            render: (_, record) => (
                moment(record?.paymentTransaction?.contractCompleteAt).format('DD/MM/YYYY')
            ),
        },
        {
            title: texts('status'),
            dataIndex: "status",
            key: "status",
            align: "center",
            width: 120,
            fixed: 'right',
            render: (_, record) => genTagStatus(record)
        },
        {
            title: texts('actions'),
            dataIndex: "actions",
            key: "actions",
            align: "center",
            fixed: 'right',
            width: 100,
            render: (_, record) => (
                <Row gutter={[8, 8]} justify={'center'}>
                    <Button ghost type='primary' size='small' onClick={() => showDetail(record)}>
                        {texts('detail')}
                    </Button>
                </Row>
            ),
        },
    ];

    return (
        <Table
            bordered
            className="table-data"
            columns={columns}
            dataSource={data.map((d, index) => ({
                ...d,
                stt: index + 1 + (params.page - 1) * params.size,
            }))}
            loading={loading}
            pagination={false}
            rowKey={(record) => record?.transactionId}
            size="small"
            scroll={{x: 1500}}
        />
    );
};

export default TableData;
