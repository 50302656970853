import React from "react";
import {
  Row,
  Col,
  DatePicker
} from 'antd';

import {
} from "@ant-design/icons";

import CONSTANTS from '../../../../config/constants'

const Filter = (props) => {
  const {
    params = {},
    setParams = () => { }
  } = props
  return (
    <Row
      className="filter mb-m"
      justify={'space-between'}
      gutter={[0, 8]}
    >
      <Col>
      </Col>
      <Col align="right">
        <Row
          justify={'end'}
          gutter={[8, 8]}
        >
          <Col span={24} lg={24} sm={24} xs={24}>
            <DatePicker.RangePicker
              className="filter-filed"
              format={CONSTANTS.DATE_FORMAT}
              value={params?.dates || []}
              onChange={(v) => setParams({ ...params, dates: v })}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Filter;