import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Form,
  Select,
  message
} from 'antd';

import {
} from "@ant-design/icons";

import CONSTANTS from "../../../../../config/constants";
import { TRANSACTION_SELECT_RULES } from "../../../../../config/rules";
import productServices from '../../../../../services/finblue/product'

const AddTransactionModal = (props) => {
  const [form] = Form.useForm();
  const {
    transaction = null,
    productId = '',
    visible = false,
    onClose = () => { },
    reload = () => { }
  } = props;
  const [loading, setLoading] = useState(false);
  const [callingAPI, setCallingAPI] = useState(false);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    if (transaction) {
      setTransactions([{
        name: transaction.limitName,
        id: transaction.id
      }])
      form.setFieldsValue({
        transactionLimitId: transaction.id,
        active: transaction.active
      })
    } else {
      listAllTransactions();
      form.setFieldsValue({
        transactionLimitId: null,
        active: true
      })
    }
  }, [transaction])

  const listAllTransactions = async (keyword = '') => {
    setLoading(true);
    await productServices.list_transactions({
      page: 0,
      pageSize: 10,
      keyword: keyword,
    }).then((response) => {
      setTransactions(response?.data || []);
    }).catch(() => {
      setTransactions([])
    })
    setLoading(false);
  }


  const handleSubmit = () => {
    form.validateFields().then(async (valid) => {
      if (valid) {
        setCallingAPI(true);
        const values = form.getFieldsValue();
        if (transaction) {
          await handleUpdate({
            productId: transaction.id,
            active: values.active
          })
        } else {
          await handleAdd({
            productId: Number(productId),
            ...values
          })
        }
        setCallingAPI(false);
      }
    })
  }

  const handleAdd = async (data) => {
    await productServices.add_product_transaction(data).then(() => {
      message.success('Thêm hạn mức trong gói thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const handleUpdate = async (data) => {
    await productServices.update_product_transaction(data).then(() => {
      message.success('Sửa hạn mức trong gói thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  return (
    <Modal
      title={"Thêm hạn mức trong gói"}
      width={500}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => onClose()}
      okButtonProps={{
        loading: callingAPI
      }}
      cancelButtonProps={{
        disabled: callingAPI
      }}
      okText='Lưu'
      cancelText='Hủy'
    >
      <Form
        form={form}
        layout='vertical'
      >
        <Row
          justify={'left'}
          gutter={[8, 8]}
        >
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Tên hạn mức'}
              name={'transactionLimitId'}
              rules={TRANSACTION_SELECT_RULES}
            >
              <Select
                placeholder={"Vui lòng chọn hạn mức"}
                onSearch={(v) => listAllTransactions(v)}
                showSearch={true}
                loading={loading}
                disabled={!!transaction || callingAPI}
                optionFilterProp="children"
                className="w-full"
                filterOption={(input, option) =>
                  `${option.children}`
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {transactions.map((it) => (
                  <Select.Option
                    value={it.id}
                    key={it.id}
                  >
                    {it.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              name={'active'}
              label={'Trạng thái hiển thị'}
              rules={[]}
            >
              <Select
                className="w-100"
                disabled={callingAPI || loading}
              >
                {CONSTANTS.ACTIVE_STATUS.map((status) => (
                  <Select.Option
                    value={status.value}
                    key={status.value}
                  >
                    {status.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>

  );
}

export default AddTransactionModal;