import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Form,
  Select,
  message
} from 'antd';

import {
} from "@ant-design/icons";

import CONSTANTS from "../../../../../config/constants";
import { PROJECT_RULES } from "../../../../../config/rules";
import productServices from '../../../../../services/finblue/product'

const ProductFormModal = (props) => {
  const [form] = Form.useForm();
  const {
    product = null,
    visible = false,
    onClose = () => {},
    reload = () => {}
  } = props

  useEffect(() => {
    if (product) {
      form.setFieldsValue({
        name: product.name,
        active: product.active
      })
    } else {
      form.setFieldsValue({
        name: null,
        active: true
      })
    }
  }, [product])

  const [callingAPI, setCallingAPI] = useState(false);

  const handleSubmit = () => {
    form.validateFields().then(async (valid) => {
      if (valid) {
        setCallingAPI(true);
        const values = form.getFieldsValue();
        if (product) {
          await handleUpdate({
            id: product.id,
            ...values,
          })
        } else {
          await handleCreate(values)
        }
        setCallingAPI(false);
      }
    })
  }

  const handleCreate = async (data) => {
    await productServices.create(data).then(() => {
      message.success('Thêm gói sản phẩm thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const handleUpdate = async (data) => {
    await productServices.update(data).then(() => {
      message.success('Cập nhật gói sản phẩm thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  return (
    <Modal
      title={product ? "Chỉnh sửa gói sản phẩm" : "Thêm mới gói sản phẩm"}
      width={500}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => onClose()}
      okButtonProps={{
        loading: callingAPI
      }}
      cancelButtonProps={{
        disabled: callingAPI
      }}
      okText='Lưu'
      cancelText='Hủy'
    >
      <Form
        form={form}
        layout='vertical'
      >
        <Row
          justify={'left'}
          gutter={[8, 8]}
        >
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Tên gói sản phẩm'}
              name={'name'}
              rules={PROJECT_RULES}
            >
              <Input
                placeholder="Vui lòng nhập tên gói sản phẩm"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              name={'active'}
              label={'Trạng thái hiển thị'}
              rules={[]}
            >
              <Select
                className="w-100"
                disabled={callingAPI}
              >
                {CONSTANTS.ACTIVE_STATUS.map((status) => (
                  <Select.Option
                    value={status.value}
                    key={status.value}
                  >
                    {status.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
    
  );
}

export default ProductFormModal;