import React, { useEffect, useMemo, useState } from 'react';
import './css/index.scss';
import { Layout } from 'antd';
import ROUTERS from '../../config/routers';

import PageContent from '../../routes';

function ErrorsLayout() {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout className="errors-layout">
      <Layout.Content className="errors-layout__content">
        <PageContent routers={ROUTERS.ERROR_ROUTERS} />
      </Layout.Content>
    </Layout>
  );
}

export default ErrorsLayout;
