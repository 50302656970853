import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  Spin,
  message
} from "antd";
import { PlusOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { AdminHeader } from "../../../components";

import Filter from "./components/Filter";
import CardData from "./components/CardData";
import FormModal from "./components/modals/Form";
import CategoryFormModal from "./components/modals/CategoryForm";

import permisionServices from "../../../services/admin/permision";
import categoryServices from "../../../services/admin/category";

import { PERMISSIONS } from "../../../config/permissions";
import { isPermission } from "../../../utils/common";

const Permissions = (props) => {
  const { router } = props;
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isReload, setIsReload] = useState(true);
  const [formVisible, setFormVisible] = useState(false);
  const [categoryFormVisible, setCategoryFormVisible] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    if (isReload) {
      fetchPermissions()
    }
  }, [isReload])

  const fetchPermissions = async () => {
    setLoading(true)
    await permisionServices
      .all_permissions({})
      .then(async (response) => {
        const permisions = response?.data || []
        await fetchCategories(permisions)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      });
    setIsReload(false);
  };

  const fetchCategories = async (permisions) => {
    await categoryServices
      .list()
      .then((response) => {
        setCategories((response?.data || []).map((c) => ({
          ...c,
          permissions: permisions.filter((p) => p.category == c.name)
        })))
      })
      .catch(() => {
        setCategories([]);
      });
  };


  const openFormModal = (value) => {
    setSelectedData(value);
    setFormVisible(true);
  };

  const openCategoryFormModal = (value) => {
    setSelectedCategory(value);
    setCategoryFormVisible(true);
  };

  const handleDelete = async (permission) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xoá không?',
      onOk() {
        permisionServices.remove_permission(permission.permissionId).then(() => {
          message.success('Xoá quyền truy cập thành công')
          setIsReload(true);
        }).catch((error) => {
          message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
      },
      onCancel() { },
    });
  }

  return (
    <div className="permissions">
      <AdminHeader
        title={router.label}
        actions={[
          {
            key: "add",
            label: "Thêm quyền",
            type: "primary",
            icon: <PlusOutlined />,
            hide: !isPermission([
              PERMISSIONS.CREATE_PERMISSION
            ]),
            click: () => {
              setSelectedCategory(null)
              openFormModal(null)
            },
          },
          {
            key: "add-category",
            label: "Thêm danh mục",
            type: "primary",
            icon: <PlusOutlined />,
            hide: !isPermission([
              PERMISSIONS.CREATE_ROLE_CATEGORY
            ]),
            click: () => {
              openCategoryFormModal(null)
            },
          },
        ]}
      />
      <Filter />
      <Spin spinning={loading} style={{ minHeight: 300 }}>
        <Row className="mt-m" gutter={[16, 16]}>
          {
            categories.map((category) => <Col
              lg={8}
              sm={12}
              xs={24}
              key={category.categoryId}
            >
              <CardData
                category={category}
                onReload={() => setIsReload(true)}
                onCreate={() => {
                  setSelectedCategory(category)
                  openFormModal(null)
                }}
                onUpdate={(v) => {
                  setSelectedCategory(category)
                  openFormModal(v)
                }}
                onUpdateCategory={() => {
                  openCategoryFormModal(category)
                }}
                onDelete={(v) => handleDelete(v)}
              />
            </Col>)
          }
        </Row>
      </Spin>
      {
        formVisible && <FormModal
          visible={formVisible}
          permission={selectedData}
          category={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          categories={categories}
          onClose={() => setFormVisible(false)}
          onReload={() => setIsReload(true)}
        />
      }
      {
        categoryFormVisible && <CategoryFormModal
          visible={categoryFormVisible}
          category={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          onClose={() => setCategoryFormVisible(false)}
          onReload={() => setIsReload(true)}
        />
      }
    </div>
  );
};

export default Permissions;
