import React, { useMemo, useState } from "react";
import {
  Row,
  Col,
  Button,
  message
} from 'antd';

import contractServices from '../../../../../services/finblue/contract'

const Footer = (props) => {
  const {
    params = {},
    data = [],
    loading = false,
    isApproval = false,
    isResubmmit = false,
    openApprovalModal = () => {}
  } = props

  const [callingAPI, setCallingAPI] = useState(false)
  const [actionKey, setActionKey] = useState('')

  const handleVerifyFiled = (key) => {
    setActionKey(key)
    setCallingAPI(true);
    const p = { ...params, status: 0 };
    let success = 'Lưu nháp thành công!'
    if (key === 'unapproval') {
      p.status = 3
      success = 'Từ chối duyệt thành công';
    } else if (key === 'resubmit') {
      p.status = 2
      success = 'Nộp lại thành công'
    }
    contractServices.verify_filed(data.map((d) => ({
      contractDataId: d.id,
      comment: d.comment,
      dataType: d.dataType,
      verified: d.verified
    })), p).then(() => {
      message.success(success)
      setCallingAPI(false);
    }).catch((error) => {
      setCallingAPI(false);
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
      
    })
  }

  return (
    <Row gutter={[8, 8]} className="detail-footer">
      <Col>
          <Button
            size="large"
            disabled={loading || (callingAPI && actionKey !== 'save')}
            loading={callingAPI && actionKey === 'save'}
            onClick={() => handleVerifyFiled('save')}
          >
            Lưu nháp
          </Button>
        </Col>
      <Col>
        {
          isApproval && <Button
            size="large"
            type="primary"
            disabled={loading || (callingAPI && actionKey !== 'approval')}
            loading={callingAPI && actionKey === 'approval'}
            onClick={() => openApprovalModal()}
          >
            Duyệt
          </Button>
        }
        {
          isResubmmit && <Button
            size="large"
            type="primary"
            disabled={loading || (callingAPI && actionKey !== 'resubmit')}
            loading={callingAPI && actionKey === 'resubmit'}
            onClick={() => handleVerifyFiled('resubmit')}
          >
            Nộp lại
          </Button>
        }
      </Col>
      <Col>
        <Button
          size="large"
          danger 
          type="primary"
          disabled={loading || (callingAPI && actionKey !== 'unapproval')}
          loading={callingAPI && actionKey === 'unapproval'}
          onClick={() => handleVerifyFiled('unapproval')}
        >
          Từ chối duyệt
        </Button>
      </Col>
    </Row>
  );
}

export default Footer;