import React from "react";
import {
  Row,
  Table,
  Button,
  Tag
} from 'antd';

import {
  EditOutlined
} from "@ant-design/icons";

import {
  formatCurrency,
  convertDateTime,
  marketOrderStatus
} from '../../../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    params
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Tag color={marketOrderStatus(record.status)?.tag_color}>
          {marketOrderStatus(record.status)?.label}
        </Tag>
      ),
    },
    {
      title: 'Mã đơn vay',
      dataIndex: 'loanCode',
      key: 'loanCode',
      align: 'center',
      width: 200,
    },
    {
      title: 'Cty Tài chính (CTTC)',
      dataIndex: 'lenderName',
      key: 'lenderName',
      align: 'center',
      width: 200,
    },
    {
      title: 'Số tiền vay',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      width: 200,
      render: (_, record) => formatCurrency(record.amount)
    },
    {
      title: 'Kỳ hạn (Tháng)',
      dataIndex: 'numMonth',
      key: 'numMonth',
      align: 'center',
      width: 200,
      render: (_, record) => `${record.numMonth}`
    },
    {
      title: 'Lãi suất (%)',
      dataIndex: 'interestRate',
      key: 'interestRate',
      align: 'center',
      width: 200,
      render: (_, record) => `${record.interestRate}`
    },
    {
      title: 'Phí nền tảng',
      dataIndex: 'surcharge',
      key: 'surcharge',
      align: 'center',
      width: 200,
      render: (_, record) => formatCurrency(record.surcharge)
    },
    {
      title: 'Thời bắt đầu',
      dataIndex: 'startTime',
      key: 'startTime',
      align: 'center',
      width: 200,
      render: (_, record) => convertDateTime(record.startTime)
    },
    {
      title: 'Thới gian kết thúc',
      dataIndex: 'endTime',
      key: 'endTime',
      align: 'center',
      width: 200,
      render: (_, record) => convertDateTime(record.endTime)
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: '200px',
      render: (_, record) => (
        <Row gutter={[0, 8]} justify='center'>
          <Button
            icon={<EditOutlined />}
            type='primary'
            size="small"
            ghost
            onClick={() => window.navigatePage('MARKET_ORDER_DETAIL', { id: params.marketId, order_id: record.orderId })}
          >Chi tiết</Button>
        </Row>
      ),
    },
  ]

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 + (params.page - 1) * params.pageSize }))}
      loading={loading}
      pagination={false}
      rowKey={(record) => record?.orderId}
      scroll={{ x: 1024 }}
      size="small"
    />
  );
}

export default TableData;