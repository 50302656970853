import React from "react";
import "../css/components/TableData.scss";
import {Row, Table, Button, Modal, Col, Tag, Popconfirm} from "antd";
import moment from 'moment';

import {EditOutlined, DeleteOutlined} from "@ant-design/icons";
import {useState, useEffect} from "react";
import {convertStringToQuery, formatMoneyCurrency} from "../../../../utils/common";
import salesService from '../../../../services/invest/sales';
import CONSTANTS from "../../../../config/constants_invest";
import { useTranslation } from 'react-i18next';

const TableData = (props) => {
    const {
        data = [],
        loading,
        params,
        source = '',
        salesAccountId,
        onSuccess = () => {},
        onRefresh = () => {},
    } = props;

    const { t } = useTranslation();
    const texts = t;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const query = convertStringToQuery(window.location.search);

    const assignCustomerToSales = async(account) => {
        // salesAccountId
        try{
            let res = await salesService.assign_customer_to_salse(account?.rowId, salesAccountId);
            onRefresh?.();
            onSuccess?.();
        }
        catch (err){
            console.log('err', err);
        }
    };

    const confirmAssign = () => {

    };

    const navToCustomerDetail = (customer) => {
        window.navigatePage('CUSTOMER_DETAIL', { id: customer.rowId }, {...query})
    };

    const columns = [
        {
            title: texts('no.'),
            dataIndex: "stt",
            key: "stt",
            align: "center",
        },
        {
            title: texts('fullname'),
            dataIndex: "accFullname",
            key: "accFullname",
//            align: "center",
        },
        {
            title: texts('username'),
            dataIndex: "username",
            key: "username",
//            align: "center",
        },

        {
            title: texts('register_date'),
            dataIndex: "createdDate",
            key: "createdDate",
//            align: "center",
            render: (_, record) => {
                return (<p>{record?.createdDate ? moment(record?.createdDate).format('DD/MM/YYYY') : ''}</p>)
            }
        },
        {
            title: texts('actions'),
            dataIndex: "actions",
            key: "actions",
            align: "center",
            render: (_, record) => (
                <Row gutter={[8, 8]} justify={'center'}>
                    {source === 'SALES' && (
                            <Col>
                                <Popconfirm
                                    title={`Assign customer`}
                                    description={`Are you sure to assign customer "${record?.accFullname}" to this sales?`}
                                    onConfirm={() => assignCustomerToSales(record)}
                                    // onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button
                                        // onClick={() => assignCustomerToSales(record)}
                                        ghost
                                        type='primary'
                                        size='small'>
                                        {texts('assign')}
                                    </Button>
                                </Popconfirm>

                            </Col>
                    )}
                    <Col>
                        <Button
                            onClick={() => navToCustomerDetail(record)}
                            ghost
                            type='primary'
                            size='small'>
                            {texts('detail')}
                        </Button>
                    </Col>
                    {/*<Col>*/}
                    {/*<Button icon={<DeleteOutlined />} danger ghost size='small' onClick={showModal} primary>Xóa</Button>*/}
                    {/*</Col>*/}

                </Row>
            ),
        },
    ];

    const columnSalesStatus = {
        title: "Assign status",
        dataIndex: "stt",
        key: "stt",
        align: "center",
        render: (_, record) =>
            <Tag color={record?.accountReferenceId ? "red" : 'green'}>
                {record?.accountReferenceId ? 'Assigned' : 'Unassigned'}
            </Tag>
    };


    const columnCustomerSaving = [...columns];
    columnCustomerSaving.splice(4, 0, {
        title: "Tổng tiền đầu tư",
        dataIndex: "totalTransactionAmount",
        key: "totalTransactionAmount",
        align: "center",
        render: (_, record) => <Row justify={'end'}>{formatMoneyCurrency(record?.totalTransactionAmount)}</Row>
    });


    useEffect(() => {
        console.log('params', params);
        if(source === 'SALES'){
            columns.splice(4, 0, columnSalesStatus);
        }
        // if(params?.customer_investment_type === CONSTANTS.CUSTOMER_INVESTMENT_TYPE?.[1]?.value){
        //     columns.splice(4, 0, columnCustomerSaving);
        // }
    }, []);

    return (
        <Table
            bordered
            className="table-data"
            columns={
                params?.customer_investment_type === CONSTANTS.CUSTOMER_INVESTMENT_TYPE?.[0]?.value ? columns : columnCustomerSaving}
            dataSource={data.map((d, index) => ({
                ...d,
                stt: index + 1 + (params.page - 1) * params.size,
            }))}
            loading={loading}
            pagination={false}
            rowKey={(record) => record?.id}
            size="small"
        />
    );
};

export default TableData;