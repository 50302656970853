import React from "react";
import { Row, Col, Input, Select } from "antd";

import { SearchOutlined } from "@ant-design/icons";

const Filter = (props) => {
  const {
    params = {},
    setParams = () => { },
    departments = []
  } = props;
  return (
    <Row className="filter" gutter={[8, 8]}>
      <Col>
        <Select
          placeholder={'Phòng ban'}
          className="filter-filed"
          value={params.departmentId}
          onChange={(v) => setParams({ ...params, departmentId: v })}
          optionFilterProp="children"
          filterOption={(input, option) =>
            `${option.children}`
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {departments.map((d) => (
            <Select.Option
              value={d.departmentId}
              key={d.departmentId}
            >
              {d.departmentName}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col>
      </Col>
    </Row>
  );
};

export default Filter;
