import React from "react";
import "../css/components/Filter.scss";
import {Row, Col, Input, Select} from "antd";

import {SearchOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

 const TYPE_SELECTS = [
     {name: 'Tất cả', value: null},
     {name: 'Chưa chuyển tiền', value: 0},
     {name: 'Đã chuyển tiền', value: 1},
 ];

const Filter = (props) => {
    const {
        params = {}, setParams = () => {
        }
    } = props;

    const { t } = useTranslation();
    const texts = t;


    const onRequestedChange = (v) => {
        if(v == null){
            delete params?.isRequested;
            setParams({...params});
        }
        else{
            setParams({...params, isRequested: v})
        }
    };

    return (
        <Row className="filter" gutter={[8, 8]}>
            <Col span={3} lg={6} sm={12} xs={24}>
                <Select
                    placeholder={"Trạng thái thanh toán"}
                    value={params.type}
                    onChange={onRequestedChange}
                    showSearch={true}
                    optionFilterProp="children"
                    className="w-full"
                    filterOption={(input, option) =>
                        `${option.children}`.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {TYPE_SELECTS.map((type) => (
                        <Select.Option value={type.value} key={type.name}>
                            {type.name}
                        </Select.Option>
                    ))}
                </Select>
            </Col>

            {/*<Col span={3} lg={6} sm={12} xs={24}>*/}
                {/*<Input*/}
                    {/*prefix={<SearchOutlined/>}*/}
                    {/*value={params.keyword}*/}
                    {/*placeholder={texts('search')}*/}
                    {/*onChange={(e) => setParams({...params, keyword: e.target.value})}*/}
                {/*/>*/}
            {/*</Col>*/}

            {/*<Col span={3} lg={6} sm={12} xs={24}>*/}
                {/*<Select*/}
                    {/*placeholder={"Trạng thái thanh toán"}*/}
                    {/*value={params.type}*/}
                    {/*onChange={onRequestedChange}*/}
                    {/*showSearch={true}*/}
                    {/*optionFilterProp="children"*/}
                    {/*className="w-full"*/}
                    {/*filterOption={(input, option) =>*/}
                        {/*`${option.children}`.toLowerCase().includes(input.toLowerCase())*/}
                    {/*}*/}
                {/*>*/}
                    {/*{TYPE_SELECTS.map((type) => (*/}
                        {/*<Select.Option value={type.value} key={type.name}>*/}
                            {/*{type.name}*/}
                        {/*</Select.Option>*/}
                    {/*))}*/}
                {/*</Select>*/}
            {/*</Col>*/}
        </Row>
    );
};

export default Filter;
