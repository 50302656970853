import React, { useState, useEffect } from "react";
import { Pagination } from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import { AdminHeader } from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import MarketFormModal from "./components/modals/MarketForm"
import marketServices from '../../../services/finblue/market';

import { convertStringToQuery } from '../../../utils/common';

const Markets = (props) => {
  const { router } = props
  const query = convertStringToQuery(window.location.search);
  const [loading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState({
    page: Number(query?.page || 1),
    pageSize: 10,
    keyword: query?.keyword || null,
    type: Number(query?.type) || '',
  });

  useEffect(() => {
    fetchData();
    window.navigatePage('MARKETS', {}, {
      page: params.page,
      keyword: params.keyword,
      type: params.type,
    })
  }, [params])

  const handleChangePage = (page, pageSize) => {
    setParams({
      ...params,
      page,
      pageSize
    })
  };

  const fetchData = async () => {
    setLoading(true);
    await marketServices.list({
      pageSize: params.pageSize,
      page: params.page - 1,
      keyword: params.keyword,
      type: params.type
    }).then((response) => {
      setData(response?.data || []);
      setTotal(response?.page?.total_elements)
    }).catch(() => {
      setData([])
      setTotal(0)
    })
    setLoading(false);
  }

  const handleOpenFormModal = (market = null) => {
    setSelectedMarket(market);
    setFormVisible(true)
  };

  return (
    <div className="markets">
      <AdminHeader
        title={router.label}
        total={total}
        actions={[
          {
            key: 'add',
            label: 'Thêm mới',
            type: 'primary',
            icon: <PlusOutlined />,
            click: () => handleOpenFormModal()
          }
        ]}
      />
      <Filter
        params={params}
        setParams={(v) => setParams({
          ...v,
          page: 1,
        })}
      />
      <TableData
        loading={loading}
        data={data}
        params={params}
        edit={(v) => handleOpenFormModal(v)}
      />
      <Pagination
        className="pagination"
        current={params.page}
        pageSize={params.pageSize}
        total={total}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Tổng số ${total}`}
        onChange={handleChangePage}
      />
      {
        formVisible && <MarketFormModal
          visible={formVisible}
          market={selectedMarket}
          onClose={() => setFormVisible(false)}
          reload={() => fetchData()}
        />
      }
    </div>
  );
}

export default Markets;