import React, { useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  Card,
  Image
} from 'antd';

import { } from '../../../../../utils/common'

const Infomation = (props) => {
  const {
    loading = false,
    lenderInfo = {}
  } = props

  const marketData = useMemo(() => {
    return [
      {
        name: 'Tên công ty tài chính',
        value: lenderInfo?.name,
        lg: 24
      },
      {
        name: 'Mã công ty tài chính',
        value: `CTTC_${lenderInfo.id}`,
        lg: 12
      },
      {
        name: 'Người đại diện',
        value: lenderInfo?.representative,
        lg: 12
      },
      {
        name: 'Email',
        value: lenderInfo?.email,
        lg: 12
      },
      {
        name: 'Tài khoản ngân hàng',
        value: lenderInfo?.bankAccountNumber,
        lg: 12
      },
      {
        name: 'Tên ngân hàng',
        value: lenderInfo?.bankName,
        lg: 12
      },
      {
        name: 'Tên chủ tài khoản',
        value: lenderInfo?.bankAccountName,
        lg: 12
      },
      {
        name: 'Chi nhánh',
        value: lenderInfo?.bankBranchName,
        lg: 12
      },
      {
        name: 'Hoa hồng',
        value: `${lenderInfo?.surcharge || 0} % / Tổng hoá đơn`,
        lg: 12
      },
      {
        name: 'Địa chỉ',
        value: lenderInfo?.address,
        lg: 24
      },
    ]
  }, [lenderInfo])

  return (
    <Card
      size="small"
      className="w-100 my-m"
      loading={loading}
      title={'Thông tin công ty tài chính'}
    >
      <Row gutter={[16, 8]}>
        <Col lg={8}>
          <Image
            className={'w-100'}
            style={{
              maxHeight: 200
            }}
            src={lenderInfo.logo}
          />
        </Col>
        <Col lg={16}>
          <Row gutter={[16, 8]}>
            {
              marketData.map((info, index) => <Col
                key={index}
                lg={info.lg || 12}
                sm={24}
                xs={24}
              >
                <div className="flex align-items-center">
                  <p className="text-gray mr-xs">{info.name}:</p>
                  <p>{info.value || 'Không có dữ liệu'}</p>
                  <span>{info.note}</span>
                </div>
              </Col>)
            }
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

export default Infomation;