import React, { useState, useEffect, useMemo } from "react";
import { useParams } from 'react-router-dom';

import { AdminDetailHeader } from "../../../components";
import DetailTop from "./components/detail/Top";
import DetailCustomer from "./components/detail/Customer";
import DetailOrder from "./components/detail/Order";
import DetailInfomation from "./components/detail/Infomation";
import DetailApprovalHistory from "./components/detail/ApprovalHistory";

import DetailFooter from "./components/detail/Footer";

import loanServices from '../../../services/finblue/loan';

import { convertStringToQuery, loanStatus } from '../../../utils/common'


const LoanApprovalDetail = (props) => {
  const { router } = props
  const { id } = useParams();
  const query = convertStringToQuery(window.location.search);
  const [loading, setLoading] = useState(false)
  const [loanInfo, setLoanInfo] = useState({})
  const [contractInfo, setContractInfo] = useState({})

  const params = useMemo(() => {
    return {
      id: Number(id),
    }
  }, [id, query]);

  useEffect(() => {
    getLoanInfo()
  }, [])

  const getLoanInfo = (loading = true) => {
    setLoading(loading)
    loanServices.get_loan_info(params?.id).then((response) => {
      setLoanInfo(response.data)
      setLoading(false)
    }).catch(() => {
      setLoanInfo({})
      setLoading(false)
    })
  }

  return (
    <div className="limit-approval-detail">
      <AdminDetailHeader
        title={router.label}
        showScore={true}
        score={contractInfo.score}
        scoreBy={contractInfo.scoreBy}
        status={loanStatus(loanInfo.loanStatus)}
      />
      <DetailTop
        loading={loading}
        loanInfo={loanInfo}
      />
      <DetailCustomer
        loading={loading}
        loanInfo={loanInfo}
        contractInfo={contractInfo}
        setContractInfo={setContractInfo}
      />
      <DetailOrder
        loading={loading}
        params={params}
      />
      <DetailInfomation
        loading={loading}
        loanInfo={loanInfo}
        params={params}
        onReload={getLoanInfo}
      />
      <DetailApprovalHistory
        loading={loading}
        loanInfo={loanInfo}
        params={params}
      />
      <DetailFooter
        loading={loading}
        data={loanInfo}
        params={params}
        onReload={getLoanInfo}
      />
    </div>
  );
}

export default LoanApprovalDetail;