import React from "react";
import "../css/components/TableData.scss";
import { Row, Table, Button, Modal, Col, Tag } from "antd";
import moment from 'moment';

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
import { convertStringToQuery, getFmkTransactionTypeColorByStatus } from "../../../../utils/common";
import {useTranslation} from "react-i18next";

const TableData = (props) => {
    const { data, loading, params } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { t } = useTranslation();
    const texts = t;

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const query = convertStringToQuery(window.location.search);

    const kycStatus = (status) => {
        console.log('status', status);
        switch (status) {
            case 1:
                return (
                    <Tag
                        size={'default'}
                        color={'green'}>
                        {texts('approve')}
                    </Tag>
                );
            case 2:
                return (
                    <Tag
                        size={'default'}
                        color={'red'}>
                        {texts('pending')}
                    </Tag>
                );
            case 3:
                return (
                    <Tag
                        size={'default'}
                        color={'red'}>
                        {texts('reject')}
                    </Tag>
                );
            default:
                return (
                    <Tag
                        size={'default'}
                        color={'red'}>
                        {texts('init')}
                    </Tag>
                );
        }
    };

    const columns = [
        {
            title: texts('no.'),
            dataIndex: "stt",
            key: "stt",
            align: "center",
        },
        {
            title: texts('username'),
            dataIndex: "username",
            key: "username",
//            align: "center",
        },

        {
            title: texts('fullname'),
            dataIndex: "accFullname",
            key: "accFullname",
//            align: "center",
        },
        {
            title: texts('id_status'),
            dataIndex: "accKyc",
            key: "accKyc",
            align: "center",
            render: (_, record) => {
                return kycStatus(record.accKyc);
            }
        },
        {
            title: texts('selfie_status'),
            dataIndex: "accKyc",
            key: "accKyc",
            align: "center",
            render: (_, record) => {
                return kycStatus(record.checkSelfie);
            }
        },

        // {
        //     title: "Ngày đăng ký",
        //     dataIndex: "createdDate",
        //     key: "createdDate",
        //     align: "center",
        //     render: (_, record) => {
        //         console.log('record', record);
        //         return (
        //             <p>{record?.createdDate ? moment(record?.createdDate).format('DD/MM/YYYY') : ''}</p>
        //         )
        //     }
        //
        // },
        {
            title: texts('actions'),
            dataIndex: "actions",
            key: "actions",
            align: "center",
            render: (_, record) => (
                <Row gutter={[8, 8]} justify={'center'}>
                    <Col>
                        <Button
                            onClick={() => window.navigatePage(
                                'EKYC_APPROVE_DETAIL',
                                { id: record.rowId },
                                {
                                    ...query
                                }
                            )}
                            ghost
                            type='primary'
                            size='small'>
                            {texts('detail')}
                        </Button>
                    </Col>
                    {/*<Col>*/}
                    {/*<Button icon={<DeleteOutlined />} danger ghost size='small' onClick={showModal} primary>Xóa</Button>*/}
                    {/*</Col>*/}

                </Row>
            ),
        },
    ];

    return (
        <Table
            bordered
            className="table-data"
            columns={columns}
            dataSource={data.map((d, index) => ({
                ...d,
                stt: index + 1 + (params.page - 1) * params.size,
            }))}
            loading={loading}
            pagination={false}
            rowKey={(record) => record?.rowId}
            size="small"
        />
    );
};

export default TableData;
