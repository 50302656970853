import React, { useState, useEffect, useMemo } from "react";
import {
  Row,
  Col,
  Modal,
  Spin
} from "antd";
import { PlusOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { AdminHeader } from "../../../components";

import Filter from "./components/Filter";
import CardData from "./components/CardData";
import FormModal from "./components/modals/Form";
import DepartmentFormModal from "./components/modals/DepartmentForm";

import departmentServices from "../../../services/admin/department";
import positionServices from "../../../services/admin/position";
import roleServices from "../../../services/admin/role";
import staffServices from "../../../services/admin/staff";

import { convertStringToQuery } from '../../../utils/common'


const Staffs = (props) => {
  const { router } = props;
  const query = convertStringToQuery(window.location.search);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [positions, setPositions] = useState([]);
  const [isReload, setIsReload] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [departmentFormVisible, setDepartmentFormVisible] = useState(false);

  const [params, setParams] = useState({
    departmentId: query?.departmentId ? Number(query?.departmentId) : '',
  });

  const selectedDepartment = useMemo(() => {
    return departments.find((d) => d.departmentId === params.departmentId)
  }, [params, departments])

  useEffect(() => {
    fetchData()
    fetchRoles()
    fetchPostions()
  }, [])

  const fetchPostions = async () => {
    await positionServices.list()
      .then((response) => {
        setPositions(response.data || []);
      })
      .catch(() => {
        setPositions([]);
      });
  };

  const fetchRoles = async () => {
    await roleServices
      .search_roles()
      .then((response) => {
        setRoles(response?.data || []);
      })
      .catch(() => {
        setRoles([]);
      });
  };

  const fetchData = async () => {
    setLoading(true)
    await staffServices
      .list()
      .then(async (response) => {
        setDepartments(response?.data || [])
        setParams({ departmentId: response?.data ? response?.data[0]?.departmentId || null : null })
      })
      .catch(() => {
        setDepartments([])
        setParams({ departmentId: null })
      });
    setLoading(false)
  };

  const openFormModal = (value) => {
    setSelectedData(value);
    setFormVisible(true);
  };

  const openDepartmentFormModal = (value) => {
    setDepartmentFormVisible(true);
  };

  const handleDelete = (department) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xoá phòng ban?',
      onOk() {
        departmentServices.remove(department.id).then(() => {
          message.success('Xoá phòng ban thành công')
          fetchData();
        }).catch((error) => {
          message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
      },
      onCancel() { },
    });
  }

  return (
    <div className="departments">
      <AdminHeader
        title={router.label}
        actions={[
          {
            key: "add",
            label: "Thêm nhân viên",
            type: "primary",
            icon: <PlusOutlined />,
            click: () => {
              openFormModal(null)
            },
          },
          {
            key: "add-department",
            label: "Thêm phòng ban",
            type: "primary",
            icon: <PlusOutlined />,
            click: () => {
              openDepartmentFormModal(null)
            },
          },
        ]}
      />
      <Filter
        params={params}
        setParams={setParams}
        departments={departments}
      />
      <Spin spinning={loading} style={{ minHeight: 300 }}>
        {
          selectedDepartment && <CardData
            department={selectedDepartment}
            onReload={fetchData}
            onCreate={() => {
              openFormModal(null)
            }}
            onUpdate={(v) => {
              openFormModal(v)
            }}
            onUpdateDepartment={() => {
              openDepartmentFormModal(selectedDepartment)
            }}
            onDelete={(v) => handleDelete(v)}
          />
        }
      </Spin>
      {
        formVisible && <FormModal
          visible={formVisible}
          staff={selectedData}
          roles={roles}
          positions={positions}
          department={selectedDepartment}
          departments={departments}
          onClose={() => setFormVisible(false)}
          onReload={() => setIsReload(true)}
        />
      }
      {
        departmentFormVisible && <DepartmentFormModal
          visible={departmentFormVisible}
          department={selectedDepartment}
          onClose={() => setDepartmentFormVisible(false)}
          onReload={() => setIsReload(true)}
        />
      }
    </div>
  );
};

export default Staffs;
