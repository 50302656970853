import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Row, Pagination, Tabs, Spin} from "antd";
import CustomerTransactionTab from "./customer_transaction_tab";

const CustomerGoldTransaction = (props) => {
    const {customer} = props;

    return (
        <Row justify='center' style={{marginTop: 30}}>
            Không có dữ liệu
        </Row>
    );
};

export default CustomerGoldTransaction;
