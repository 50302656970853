import React, { useState, useEffect, useMemo } from "react";
import { useParams } from 'react-router-dom';

import { AdminDetailHeader } from "../../../components";
import TableDataDetail from "./components/detail/TableData";
import FooterDetail from "./components/detail/Footer";

import profileServices from '../../../services/finblue/profile';

import { convertStringToQuery, ekycStatus, convertDateTime } from '../../../utils/common'


const LimitApprovalDetail = (props) => {
  const { router } = props
  const { id } = useParams();
  const query = convertStringToQuery(window.location.search);
  const [loading, setLoading] = useState(false)
  const [profileInfo, setProfileInfo] = useState({})

  const params = useMemo(() => {
    return {
      id: Number(id),
      customerId: Number(query.customerId)
    }
  }, [id, query]);

  useEffect(() => {
    fetchData()
  }, [id])

  const fetchData = async () => {
    setLoading(true);
    await profileServices.get(params.customerId).then((response) => {
      setProfileInfo(response?.data || {})
    }).catch(() => {
      setProfileInfo({})
    })
    setLoading(false);
  }

  return (
    <div className="limit-approval-detail">
      <AdminDetailHeader
        title={router.label}
        status={ekycStatus(profileInfo.status)}
        subtitle={`Thời gian tạo: ${convertDateTime(profileInfo.createdDate) }`}
      />
      <TableDataDetail
        type={0}
        loading={loading}
        data={profileInfo}
      />
      {
        ![1, 2].includes(profileInfo.status) && <FooterDetail
          loading={loading}
          data={profileInfo}
          params={params}
        />
      }
    </div>
  );
}

export default LimitApprovalDetail;