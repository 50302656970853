import React, { useEffect, useState } from "react";
import {
  Pagination,
  Card,
} from 'antd';

import Statistics from "./order/Statistics";
import Filter from "./order/Filter";
import TableData from "./order/TableData";

import assessorServices from '../../../../../services/finblue/assessor';

import {
  convertStringToQuery
} from '../../../../../utils/common'

import dayjs from 'dayjs';

const Orders = (props) => {
  const {
    loading,
    customerId,
  } = props;
  const query = convertStringToQuery(window.location.search);

  const [getting, setGetting] = useState(false)
  const [data, setData] = useState([]);
  const [extraData, setExtraData] = useState({});
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    keyword: null,
    status: '',
    customerId,
    dates: [
      query?.begin ? dayjs(Number(query?.begin) * 1000) : dayjs().add(-7, 'days'),
      query?.end ? dayjs(Number(query?.end) * 1000) : dayjs()
    ]
  });

  useEffect(() => {
    fetchData();
  }, [params])

  const handleChangePage = (page, pageSize) => {
    setParams({
      ...params,
      page,
      pageSize
    })
  };

  const fetchData = async () => {
    setGetting(true);
    await assessorServices.get_assessor_orders({
      customerId: customerId,
      begin: dayjs(dayjs(params.dates[0]).format('YYYY-MM-DD 00:00:00')).unix() * 1000,
      end: dayjs(dayjs(params.dates[1]).format('YYYY-MM-DD 23:59:59')).unix() * 1000,
      pageSize: params.pageSize,
      page: params.page - 1,
      keyword: params.keyword,
      status: params.status,
    }).then((response) => {
      setData(response?.data || []);
      setTotal(response?.page?.total_elements);
      setExtraData(response?.extraData)
    }).catch(() => {
      setData([])
      setTotal(0)
      setExtraData({})
    })
    setGetting(false);
  }

  return (
    <Card
      size="small"
      className="w-100 my-m"
      loading={loading || getting}
      title={'Danh sách đơn vay'}
    >
      <Statistics
        extraData={extraData}
      />
      <Filter
        params={params}
        setParams={(v) => setParams({
          ...v,
          page: 1,
        })}
      />
      <TableData
        loading={loading}
        data={data}
        params={params}
      />
      <Pagination
        className="pagination"
        current={params.page}
        pageSize={params.pageSize}
        total={total}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Tổng số ${total}`}
        onChange={handleChangePage}
      />
    </Card>
  );
}

export default Orders;