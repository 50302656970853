import React from "react";
import "../css/components/TableData.scss";
import { Row, Table, Button, Modal, Tag } from "antd";

import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import { convertDateTime, formatMoneyCurrency, getObjectStatus, getSavingsProductStatus } from "../../../../utils/common";
import CONSTANTS from '../../../../config/constants';
import CONSTANTS_INVEST from '../../../../config/constants_invest';
import {useTranslation} from "react-i18next";

const TableData = (props) => {
    const {
        data,
        loading,
        params,
        showDetail = () => { }
    } = props;

    const { t } = useTranslation();
    const texts = t;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: texts('no.'),
            dataIndex: "stt",
            key: "stt",
            align: "center",
        },
        {
            title: texts('customer'),
            dataIndex: "acc_fullname",
            key: "acc_fullname",
            align: "center",
        },
        {
            title: texts('username'),
            dataIndex: "username",
            key: "username",
            align: "center",
        },
        {
            title: texts('amount'),
            dataIndex: "changeAmount",
            key: "changeAmount",
            align: "center",
            render: (_, record) => formatMoneyCurrency(record?.changeAmount)
        },
        {
            title: texts('transaction_date'),
            dataIndex: "createdDate",
            key: "createdDate",
            align: "center",
            render: (_, record) => convertDateTime(record.createdDate, CONSTANTS.DATE_FORMAT)
        },
        {
            title: texts('description'),
            dataIndex: "remark",
            key: "remark",
            align: "center",
            width: '30%'
        },
        {
            title: texts('actions'),
            dataIndex: "actions",
            key: "actions",
            align: "center",
            render: (_, record) => (
                <Row gutter={[8, 8]} justify={'center'}>
                    <Button ghost type='primary' size='small' onClick={() => showDetail(record)}>
                        {texts('detail')}
                    </Button>
                </Row>
            ),
        },
    ];

    return (
        <Table
            bordered
            className="table-data"
            columns={columns}
            dataSource={data.map((d, index) => ({
                ...d,
                stt: index + 1 + (params.page - 1) * params.size,
            }))}
            loading={loading}
            pagination={false}
            rowKey={(record) => record?.id}
            size="small"
        />
    );
};

export default TableData;
