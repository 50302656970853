import React, { useEffect, useState } from "react";
import {
  Card,
  Pagination
} from 'antd';

import TableData from "./debt/TableData";

import reportDebtServices from '../../../../services/finblue/report-debt';

import dayjs from 'dayjs';

const ReportDebts = (props) => {
  const { params } = props;

  const [getting, setGetting] = useState(false)
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [newParams, setNewParams] = useState({
    page: 1,
    pageSize: 10
  });

  useEffect(() => {
    fetchData();
  }, [params, newParams])

  const fetchData = async () => {
    setGetting(true);
    await reportDebtServices.get_report_debts({
      begin: dayjs(dayjs(params.dates[0]).format('YYYY-MM-DD 00:00:00')).unix() * 1000,
      end: dayjs(dayjs(params.dates[1]).format('YYYY-MM-DD 23:59:59')).unix() * 1000,
      pageSize: newParams.pageSize,
      page: newParams.page - 1,
    }).then((response) => {
      setData(response?.data || []);
      setTotal(response?.page?.total_elements)
    }).catch(() => {
      setData([])
      setTotal(0)
    })
    setGetting(false);
  }

  const handleChangePage = (page, pageSize) => {
    setNewParams({
      ...newParams,
      page,
      pageSize
    })
  };

  return (
    <Card
      size="small"
      className="w-100 mt-m"
      loading={getting}
      title={'Báo cáo nợ xấu'}
    >
      <TableData
        loading={getting}
        data={data}
        params={params}
      />
      <Pagination
        className="pagination"
        current={newParams.page}
        pageSize={newParams.pageSize}
        total={total}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Tổng số ${total}`}
        onChange={handleChangePage}
      />
    </Card>
  );
}

export default ReportDebts;