import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Tabs, Col, Row, Image, Table, Spin} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminDetailHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import userServices from "../../../services/admin/user";
import customerServices from "../../../services/invest/customer";
import { useParams } from 'react-router-dom';
import AvatarDefault from './../../../assets/images/Avatar-default.png';
import moment from 'moment';
import fmarketServices from "../../../services/invest/fmarket";
import {useTranslation} from "react-i18next";

const FaInfoTab = (props) => {
    const {
        fmarketAccount,
        customerId
    } = props;

    const { t } = useTranslation();
    const texts = t;

    const [tradingContracts, setTradingContracts] = useState([]);
    const [loading, setLoading] = useState(false);

    console.log('fmarketAccount', fmarketAccount);

    useEffect(() => {
        // console.log('customerId', customerId);
        // if(customerId){}
        getCustomerTradingContracts();
    }, [fmarketAccount]);

    const fetchData = async () => {
        // setLoading(true);
        // let account_id = id;
        // await customerServices.customer_detail({account_id})
        //     .then((response) => {
        //         setCustomer(response?.data || {});
        //         // setTotal(response?.page?.total_elements);
        //     })
        //     .catch(() => {
        //         // setData([]);
        //     });
        // setLoading(false);
    };

    const getCustomerTradingContracts = async () => {
        // console.log('getCustomerTradingContracts');
        // let _customerId = customerId;
        // if(!_customerId){
        //     _customerId = fmarketAccount?.sysAccountId;
        // }
        // console.log('_customerId', _customerId);
        let _customerId = fmarketAccount?.sysAccountId;
        if(_customerId){
            setLoading(true);
            let res = await fmarketServices.fmarket_trading_contract(_customerId);
            let tradingContracts = res?.data || [];
            console.log('tradingContracts', tradingContracts);

            let res1 = await fmarketServices.fmarket_trading_contract_sign(_customerId);
            let signs_by_account_id = res1?.data || [];
            console.log('signs_by_account_id', signs_by_account_id);

            tradingContracts = tradingContracts.map(e => ({
                ...e,
                finblue_sign_url: signs_by_account_id.find(s => s.mutualCode === e?.issuer_info?.name || s.mutualCode === e?.issuer_info?.short_name)?.signUrl
            }));
            console.log('tradingContracts 2', tradingContracts);
            setTradingContracts(tradingContracts);
            setLoading(false);
        }

        // setTradingContracts([
        //     {
        //         "trading_contract_id": 5941,
        //         "trading_contract_no": "2dasdsaC986317",
        //         "trading_contract_url": "https://s3-ap-southeast-1.amazonaws.com/fmarket-upload/dev/HDGD_ce111e12-f43_NGUYEN_THANH_TUNG_007F973986317_DFVN_convert.pdf",
        //         "status": "INVESTMENT_PROFILE_APPROVE",
        //         "is_sign": true,
        //         "is_allow_sign": false,
        //         "issuer_info": {
        //             "id": 399,
        //             "name": "CONG TY TNHH MTV QUAN LY QUY DAI-ICHI LIFE VIET NAM",
        //             "short_name": "DFVN",
        //             "website": "",
        //             "avatar_url": "https://s3-ap-southeast-1.amazonaws.com/fmarket-upload/dev/temp/8c578b52-f94a-499c-9f3f-ef9228a6a813.png"
        //         }
        //     },
        //     {
        //         "trading_contract_id": 5939,
        //         "trading_contract_no": "VSDDLPPVINAC986317",
        //         "trading_contract_url": "https://s3-ap-southeast-1.amazonaws.com/fmarket-upload/dev/HDGD_fb53e9e9-7f7_NGUYEN_THANH_TUNG_007F973986317_VINACAPITAL_convert.pdf",
        //         "status": "INVESTMENT_PROFILE_APPROVE",
        //         "is_sign": true,
        //         "is_allow_sign": false,
        //         "issuer_info": {
        //             "id": 386,
        //             "name": "VINACAPITAL",
        //             "short_name": "VINACAPITAL",
        //             "website": "https://vinacapital.com/",
        //             "avatar_url": "https://s3-ap-southeast-1.amazonaws.com/fmarket-upload/dev/temp/42b84ef8-c64f-4ed2-9d85-1cc973cb9366.png"
        //         }
        //     }
        // ])
    };


    const contractColumns = [
        {
            title: texts('no.'),
            dataIndex: "stt",
            key: "stt",
            align: "center",
        },
        {
            title: texts('fullname'),
            dataIndex: "faFullName",
            key: "faFullName",
            // align: "center",
            render: (_, record) => record?.issuer_info?.name,
        },
        // {
        //     title: texts('contract_id'),
        //     dataIndex: "trading_contract_id",
        //     key: "trading_contract_id",
        //     // align: "center",
        // },
        {
            title: texts('contract_code'),
            dataIndex: "trading_contract_no",
            key: "trading_contract_no",
            // align: "center"
        },
        {
            title: texts('sign'),
            dataIndex: "finblue_sign_url",
            key: "finblue_sign_url",
            // align: "center"
            width: 100,
            render: (_, record) => (
                <Image
                    src={record?.finblue_sign_url}
                    preview={true}
                    style={{ width: '100%' }}
                />
            ),
        },
        {
            title: texts('actions'),
            dataIndex: "actions",
            key: "actions",
            align: "center",
            render: (_, record) => (
                <a href={record?.trading_contract_url} target="_blank">{texts('detail')}</a>
            ),
        },
    ];

    return (
        <div className="">
            <Row gutter={[30, 30]}>
                <Col align-content='center'>
                    <img className='profile-avatar' src={AvatarDefault}/>
                </Col>

                <Col>
                    <div className='profile-name'>
                        {fmarketAccount?.faFullName}
                    </div>
                    <table>
                        <tbody>
                        <tr>
                            <td className='content-label'>{texts('fmarket_id')}:</td>
                            <td className='content-value'>{fmarketAccount?.fmarketId}</td>
                        </tr>

                        <tr>
                            <td className='content-label'>{texts('phone_number')}:</td>
                            <td className='content-value'>{fmarketAccount?.faCellPhone}</td>
                        </tr>

                        <tr>
                            <td className='content-label'>{texts('email')}:</td>
                            <td className='content-value'>{fmarketAccount?.faEmail}</td>
                        </tr>

                        <tr>
                            <td className='content-label'>{texts('birthday')}:</td>
                            <td className='content-value'>{moment(fmarketAccount?.faDob).format('DD/MM/YYYY')}</td>
                        </tr>

                        <tr>
                            <td className='content-label'>{texts('gender')}:</td>
                            <td className='content-value'>{fmarketAccount?.faGender ? 'Male' : 'Female'}</td>
                        </tr>
                        <tr>
                            <td className='content-label'>{texts('bank_no')}:</td>
                            <td className='content-value'>{fmarketAccount?.faBankAccountNo}</td>
                        </tr>
                        <tr>
                            <td className='content-label'>{texts('bank_branch')}:</td>
                            <td className='content-value'>{fmarketAccount?.faBankBranch}</td>
                        </tr>

                        <tr>
                            <td className='content-label'>{texts('id_no')}:</td>
                            <td className='content-value'>{fmarketAccount?.faIdNo || 'Chưa cập nhật'}</td>
                        </tr>

                        {/*<tr>*/}
                            {/*<td className='content-label'>{texts('id_type')}:</td>*/}
                            {/*<td className='content-value'>{fmarketAccount?.faIdType}</td>*/}
                        {/*</tr>*/}

                        <tr>
                            <td className='content-label'>{texts('id_issue_place')}:</td>
                            <td className='content-value'>{fmarketAccount?.faIdPlaceOfIssue}</td>
                        </tr>

                        <tr>
                            <td className='content-label'>{texts('permanent_address')}:</td>
                            <td className='content-value'>{fmarketAccount?.faPermanentAddress}</td>
                        </tr>

                        <tr>
                            <td className='content-label'>{texts('mailing_address')}:</td>
                            <td className='content-value'>{fmarketAccount?.faMailingAddress}</td>
                        </tr>

                        <tr>
                            <td className='content-label'>{texts('id_front_photo')}:</td>
                            <td className='content-value'>
                                <Image
                                    width={300}
                                    // height={200}
                                    src={fmarketAccount?.faIdFrontPhotoBase64}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td className='content-label'>{texts('id_back_photo')}:</td>
                            <td className='content-value'>
                                <Image
                                    width={200}
                                    // height={200}
                                    src={fmarketAccount?.faIdBackPhotoBase64}
                                />
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </Col>
            </Row>

            <div style={{fontSize: 18, fontWeight: '500', marginTop: 24}}>{texts('fmarket_trading_contract')}</div>

            <Spin spinning={loading}>
                <Table
                    bordered
                    className="table-data"
                    columns={contractColumns}
                    dataSource={tradingContracts.map((d, index) => ({
                        ...d,
                        stt: index + 1
                    }))}
                    // loading={loading}
                    pagination={false}
                    rowKey={(record) => record?.trading_contract_id}
                    size="small"
                />
            </Spin>

        </div>
    );
};

export default FaInfoTab;
