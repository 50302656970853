import React, { useMemo } from "react";
import {
  Row,
  Col
} from 'antd';

import {
} from "@ant-design/icons";

const AssessorInformation = (props) => {
  const {
    assessor = {},
  } = props

  const assessorInfo = useMemo(() => {
    return [
      {
        name: 'Mã nhân viên',
        value: assessor?.customerId,
        lg: 12
      },
      {
        name: 'Họ tên',
        value: assessor?.name,
        lg: 12
      },
      {
        name: 'Số điện thoại',
        value: assessor?.phone,
        lg: 24
      },
      {
        name: 'Hợp đồng đã duyệt',
        value: assessor?.contractApproved || 0,
        lg: 12
      },
      {
        name: 'Hợp đồng chưa duyệt',
        value: assessor?.contractUnApproved || 0,
        lg: 12
      },
      {
        name: 'Đơn vay đã duyệt',
        value: assessor?.orderApproved || 0,
        lg: 12
      },
      {
        name: 'Đơn vay chưa duyệt',
        value: assessor?.orderUnApproved || 0,
        lg: 12
      },
    ]
  }, [assessor])

  return (
    <Row gutter={[16, 8]} className="my-m">
      {
        assessorInfo.map((info, index) => <Col
          key={index}
          lg={info.lg || 12}
          sm={24}
          xs={24}
        >
          <div className="flex align-items-center">
            <p className="text-gray mr-xs">{info.name}:</p>
            <p>{info.value}</p>
          </div>
        </Col>)
      }
    </Row>
  );
}

export default AssessorInformation;