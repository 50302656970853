import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination} from "antd";
import {PlusOutlined, CarryOutOutlined, CheckOutlined, FormOutlined, EditOutlined} from "@ant-design/icons";
import {AdminHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import ProductFormModal from "./components/modals/ProductForm";
import contentService from "../../../services/invest/content";
import {convertStringToQuery} from "../../../utils/common";


// import { CarryOutOutlined, CheckOutlined, FormOutlined } from '@ant-design/icons';
import { Select, Switch, Tree } from 'antd';
import { DataNode } from 'antd/es/tree';
import {message} from "antd/es/index";
import {useTranslation} from "react-i18next";

const QuestionAnswer = (props) => {
    const {router} = props;
    const [loading, setLoading] = useState(false);
    const [formVisible, setFormVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [params, setParams] = useState({
        page: 1,
        size: 200,
    });

    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);
        await contentService.get_question_info({
                ...params,
                page: params.page - 1,
                pageSize: params.size,
            })
            .then((response) => {
                let data = buildTreeDatas(response?.data || []);
                console.log('data', data);
                setData(data);
                setTotal(response?.page?.total_elements || 0);
            })
            .catch(() => {
                setData([]);
            });
        setLoading(false);
    };

    const findChild = (datas, parent_id) => {
        let flag = true;
        let childs = [];
        while(flag){
            let index = datas.findIndex((e)=>{
                return e.parent_id === parent_id;
            });
            if(index > -1){
                let data = datas[index];
                let p_id = data.id;
                datas.splice(index, 1);
                data.child = findChild(datas, p_id);
                childs.push(data);
            }
            else{
                if(childs.length){
                    childs[childs.length - 1].end = 1;
                }
                flag = false;
            }
        }
        return childs;
    };

    const buildTreeDatas = (dataSource) => {
        let questionList = dataSource.filter((e) => {return !e.parent_id}) || [];
        console.log('dataSource', dataSource);
        console.log('questionList', questionList);
        let dataTree = [];
        questionList.forEach((e, i) => {
            console.log('e', e);
            let children = [];
            let findChilds = findChild(dataSource, e.id);
            console.log('findChilds', findChilds);
            findChilds.forEach((child, index_child) => {
                children.push({
                    ...child,
                    title: child?.question,
                    key: `${i}-${index_child}`,
                    icon: <EditOutlined />,
                })
            });
            dataTree.push({
                ...e,
                title: e?.question,
                key: `${i}`,
                icon: <EditOutlined />,
                children
            });
        });
        console.log('dataTree', dataTree);
        return dataTree;
    };

    const treeData = [
        {
            title: 'parent 1',
            key: '0',
            icon: <EditOutlined />,
            children: [
                {
                    title: 'parent 1-0',
                    key: '0-0',
                    icon: <EditOutlined />
                },
                {
                    title: 'parent 1-1',
                    key: '0-1',
                    icon: <EditOutlined />
                },
                {
                    title: 'parent 1-2',
                    key: '0-2',
                    icon: <EditOutlined />
                },
            ],
        },
        {
            title: 'parent 2',
            key: '1',
            icon: <EditOutlined />,
            children: [
                {
                    title: 'parent 2-0',
                    key: '1-0',
                    icon: <EditOutlined />
                },
            ],
        },
    ];

    const onSelect = (selectedKeys, info) => {
        console.log('selected', selectedKeys, info);
        // if(!info?.node?.children?.length){
            setSelectedItem(info.node);
            setFormVisible(true);
        // }
    };

    const onSave = (callback) => {
        fetchData().then(() => {
            callback?.();
        });
    };

    const onDelete = () => {
        contentService.delete_question({}, {}).then(()=>{
            setFormVisible(false);
        });
    };

    const onShowModalCreate = () => {
        setSelectedItem();
        setFormVisible(true);
    };

    return (
        <div className="cms-users">
            <AdminHeader
                title={router.label}
                total={total}
                actions={[
                    {
                        key: "add",
                        label: texts('create'),
                        type: "primary",
                        icon: <PlusOutlined/>,
                        click: () => {onShowModalCreate()},
                    },
                ]}
            />

            <Tree
                showLine={true}
                showIcon={false}
                defaultExpandedKeys={['0-0-0']}
                onSelect={onSelect}
                treeData={data}
                style={{marginTop: 20}}
            />

            {
                formVisible && <ProductFormModal
                    visible={formVisible}
                    item={selectedItem}
                    parent={data}
                    onClose={() => setFormVisible(false)}
                    onSave={onSave}
                    onDelete={onDelete}
                    reload={() => fetchData()}
                />
            }

            {/*<Filter params={params} setParams={setParams}/>*/}

            {/*<TableData*/}
                {/*loading={loading}*/}
                {/*data={data}*/}
                {/*params={params}*/}
                {/*edit={(v) => handleOpenFormModal(v)}*/}
            {/*/>*/}

            {/*<Pagination*/}
                {/*className="pagination"*/}
                {/*current={params.page}*/}
                {/*pageSize={params.size}*/}
                {/*total={total}*/}
                {/*showSizeChanger*/}
                {/*showQuickJumper*/}
                {/*showTotal={(total) => `Tổng số ${total}`}*/}
                {/*onChange={handleChangePage}*/}
            {/*/>*/}

            {/*{*/}
                {/*formVisible && <ProductFormModal*/}
                    {/*visible={formVisible}*/}
                    {/*product={selectedItem}*/}
                    {/*onClose={() => setFormVisible(false)}*/}
                    {/*reload={() => fetchData()}*/}
                {/*/>*/}
            {/*}*/}
        </div>
    );
};

export default QuestionAnswer;
