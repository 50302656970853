import systemServices from '../../services/system';
import FINBLUE_MENUS from './finblue';
import INVEST_MENUS from './invest';

const getMenus = () => {
  const cmsInfo = systemServices.cms_info();
  let adminMenus = FINBLUE_MENUS.MENUS;
  switch (cmsInfo?.key) {
    case 'CMS_FINBLUE':
      adminMenus = FINBLUE_MENUS.MENUS;
      break;
    case 'CMS_INVEST':
      adminMenus = INVEST_MENUS.MENUS;
    default:
      break;
  }
  return adminMenus;
}

export const MENUS = getMenus();
