import IconLogo from '../../assets/images/logos/icon-logo.png';
import './css/index.scss';

function LogoApp() {
    return (
        <div className="logo-app">
            <img src={IconLogo} className="icon-logo" />

            <span className="text-logo text-logo--bold">FIN</span>
            <span className="text-logo" >BLUE</span>
        </div>
    )
}

export default LogoApp;