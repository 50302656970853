import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Tabs, Col, Row, Spin} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminDetailHeader} from "../../../components";

import Filter from "../savings-transactions/components/Filter";
import TableData from "../savings-transactions/components/TableData";
import userServices from "../../../services/admin/user";
import savingsService from "../../../services/invest/savings";
import { useParams } from 'react-router-dom';
import AvatarDefault from './../../../assets/images/Avatar-default.png';
import moment from 'moment';
import ModalSavingsTransactionDetail from "../savings-transactions/components/modals/ModalSavingsTransactionDetail";
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const CustomerTransactionTab = (props) => {
    const {customer} = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [formVisible, setFormVisible] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [params, setParams] = useState({
        page: 1,
        size: 10,
    });

    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {
        fetchData();
    }, [params]);

    const fetchData = async () => {
        console.log('customer 111', customer);
        setLoading(true);
        let accountId = customer.rowId;
        let requestParams = {
            accountId,
            ...params,
        };
        if(params?.dates){
            requestParams = {
                ...requestParams,
                from_date: dayjs(params?.dates?.[0]).unix()*1000,
                to_date: dayjs(params?.dates?.[1]).unix()*1000,
            }
        }
        delete requestParams.dates;
        await savingsService.savings_transactions(requestParams).then((response) => {
            setData(response?.data || {});
            setTotal(response?.page?.total_elements || 0);
        })
        .catch(() => {
            // setData([]);
        });
        setLoading(false);
    };

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const handleShowDetail = (transaction = null) => {
        setSelectedTransaction(transaction);
        setFormVisible(true)
    };

    return (
        <div className="">
            <Filter params={params} setParams={setParams} hideSearch/>
            <TableData
                loading={loading}
                data={data}
                params={params}
                showDetail={(item) => handleShowDetail(item)}
            />

            <Pagination
                className="pagination"
                current={params.page}
                pageSize={params.size}
                total={total}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `Tổng số ${total}`}
                onChange={handleChangePage}
            />

            {
                formVisible && <ModalSavingsTransactionDetail
                    visible={formVisible}
                    transaction={selectedTransaction}
                    onClose={() => setFormVisible(false)}
                    reload={() => fetchData()}
                />
            }
        </div>
    );
};

export default CustomerTransactionTab;
