import React, { useEffect, useMemo, useState } from 'react';
import './css/index.scss';
import { Layout } from 'antd';
import ROUTERS from '../../config/routers';
import PageContent from '../../routes';

function AuthLayout() {
  return (
    <Layout className="auth-layout">
      <Layout.Content className="auth-layout__content">
        <PageContent routers={ROUTERS.AUTH_ROUTERS} />
      </Layout.Content>
    </Layout>
  );
}

export default AuthLayout;
