import React, { useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Dropdown,
  Image,
  Modal,
  Button,
  Badge
} from 'antd';
import {
  UserOutlined,
  ExclamationCircleFilled,
  DownOutlined,
  BellOutlined
} from '@ant-design/icons';
import './css/Header.scss';

import IconLogo from '../../assets/images/logos/icon-logo.png'
import IconNameLogo from '../../assets/images/logos/icon-name-logo.png'

import authServices from '../../services/auth';
import systemServices from '../../services/system';
import { useDispatch, useSelector } from 'react-redux';

import { actionUpdateCmsInfo } from '../../store/actions/system';

import { getFirstMenu } from "../../utils/common";

import CONSTANTS from '../../config/constants';

function Header(props) {
  const dispatch = useDispatch();
  const loginInfo = useSelector((state) => state.userInfo.loginInfo);
  const cmsInfo = useSelector((state) => state.system.cmsInfo);

  const items = [
    {
      key: 'account',
      label: 'Tài khoản',
    },
    {
      key: 'logout',
      label: 'Đăng xuất',
    },
  ]

  const onClick = (item) => {
    if (item.key === 'logout') {
      Modal.confirm({
        title: 'Xác nhận',
        icon: <ExclamationCircleFilled />,
        content: 'Bạn có chắc chắn muốn đăng xuất?',
        onOk() {
          authServices.logout();
        },
        onCancel() { },
      });
    }
  }

  const switchCmsApp = ({ key }) => {
    if (systemServices.cms_info().key === key) {
      return
    }
    const cmsInfo = CONSTANTS.CMS_APPS.find((app) => app.key == key) || CONSTANTS.CMS_APPS[0]
    systemServices.update_cms_Info(cmsInfo);
    dispatch(actionUpdateCmsInfo(cmsInfo));
    window.location.href = window.location.origin
    setTimeout(() => {
      window.location.reload();
    }, 100)
  }

  return (
    <div className={props.className}>
      <div
        className="admin-layout__logo"
        onClick={() => window.navigatePage(getFirstMenu()?.name || 'DASHBOARD')}
      >
        <Image className='icon-logo' src={IconLogo} preview={false} />
        <Image className='icon-name-logo' src={IconNameLogo} preview={false} />
      </div>
      <div className='flex align-items-center'>
        <Dropdown
          menu={{ items: CONSTANTS.CMS_APPS, onClick: switchCmsApp }}
          placement="topRight"
          trigger={['click']}
        >
          <Button
            size='small'
            className='flex align-items-center mr-m'
          >
            <span className='p-xs'>{cmsInfo.label}</span>
            <DownOutlined style={{ fontSize: 12 }} />
          </Button>
        </Dropdown>
        {/*<Button size='small' className='mr-m'>*/}
          {/*<Badge count={5} size="small">*/}
            {/*<BellOutlined style={{ fontSize: 16 }} />*/}
          {/*</Badge>*/}
        {/*</Button>*/}
        <Dropdown menu={{ items, onClick }} placement="topRight" trigger={['click']}>
          <Button
            size='large'
            type='text'
            className='flex align-items-center'
            style={{
              color: '#ffff'
            }}
          >
            <Avatar size={32} icon={<UserOutlined />} />
            <span className='p-xs'>{loginInfo?.name || loginInfo?.email || loginInfo?.phone}</span>
            <DownOutlined style={{ fontSize: 12 }} />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
}

export default Header;
