

import React, {useEffect} from "react";
import "../css/components/TableData.scss";
import {Row, Col, Table, Button, Modal, Tag, Space} from "antd";

import {EditOutlined} from "@ant-design/icons";
import {useState} from "react";
import {formatMoneyCurrency} from "../../../../utils/common";
import dayjs from 'dayjs';
import {getAccountHref, getObjectStatus} from "../../../../utils/common_invest";
import CONSTANTS from "../../../../config/constants_invest";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";

const TableData = (props) => {
    const {
        data,
        loading,
        params,
        showDetail = () => {},
        onConfirmTransferred = () => {},
    } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [totalAmountSelect, setTotalAmountSelect] = useState(0);

    const { t } = useTranslation();
    const texts = t;

    const onSelectChange = (newSelectedRowKeys, newSelectedRow) => {
        let _totalAmountSelect = 0;
        newSelectedRow.forEach(e => _totalAmountSelect += e.transactionAmount);
        setTotalAmountSelect(_totalAmountSelect);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    useEffect(() => {
        setSelectedRowKeys([]);
    }, [data]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: texts('no.'),
            dataIndex: "stt",
            key: "stt",
            align: "center",
            width: 50,
        },
        {
            title: texts('fullname'),
            dataIndex: "accFullname",
            key: "accFullname",
            width: 200,
//            align: "center",
            render: (_, record) =>
                <div>
                    <a href={getAccountHref(record?.userId)}>{record?.accFullname}</a>
                    <div>({record?.username})</div>
                </div>
        },
//        {
//            title: texts('username'),
//            dataIndex: "username",
//            key: "username",
////            align: "center",
//        },

        {
            title: texts('product'),
            dataIndex: "productName",
            key: "productName",
//            align: "center",
            width: 90,
        },
        {
            title: <div style={{textAlign: 'center', fontWeight: 600}}>{texts('amount')}</div>,
            dataIndex: "transactionAmount",
            key: "transactionAmount",
            align: "right",
            width: 120,
            render: (_, record) => formatMoneyCurrency(record?.transactionAmount)
        },
        {
            title: <div style={{textAlign: 'center', fontWeight: 600}}>{texts('interest')}</div>,
            dataIndex: "transactionAmount",
            key: "transactionAmount",
            align: "center",
            width: 80,
            render: (_, record) => <div>{record?.interest}%</div>
        },
        {
            title: texts('contract_code'),
            dataIndex: "contractCode",
            key: "contractCode",
            align: "center",
            width: 120,
        },
        {
            title: texts('transaction_date'),
            dataIndex: "createdDate",
            key: "createdDate",
//            align: "center",
            width: 120,
            render: (_, record) => (
                moment(record?.createdDate).format('HH:mm DD/MM/YYYY')
            ),
        },
        {
            title: texts('processing_status'),
            dataIndex: "processingStatus",
            key: "processingStatus",
            align: "center",
            width: 140,
            render: (_, record) => {
                let objectStatus = getObjectStatus(CONSTANTS.SAVINGS_TRANSACTION_PROCESSING_STATUS, record.processingStatus);
                return (
                    <Tag
                        size={'default'}
                        color={objectStatus?.color}>
                        {texts(objectStatus?.label)}
                    </Tag>
                )
            }
        },
        {
            title: texts('actions'),
            dataIndex: "actions",
            key: "actions",
            align: "center",
            fixed: 'right',
            width: 100,
            render: (_, record) => (
                <Row gutter={[8, 8]} justify={'center'}>
                    <Button ghost type='primary' size='small' onClick={() => showDetail(record)}>
                        {texts('detail')}
                    </Button>
                </Row>
            ),
        },
    ];

    return (
        <div>
            <Row gutter={[12, 0]} style={{marginTop: 20}}>
                <Col span={12} lg={12} sm={24} xs={24}>
                    {params?.processing_status &&
                    <Button type="primary" onClick={() => onConfirmTransferred(selectedRowKeys)} disabled={!hasSelected} loading={loading}>
                        {params?.processing_status === 'PENDING' ? texts('confirm_transferred') : texts('confirm_pending')}
                    </Button>
                    }
                    <span style={{marginLeft: 8}}>
                        {hasSelected ? `Selected ${selectedRowKeys.length} items (${formatMoneyCurrency(totalAmountSelect)})` : ''}
                    </span>
                </Col>
                <Col span={12} lg={12} sm={24} xs={24} style={{marginTop: 6}}>
                    <Row style={{alignItems: 'center', justifyContent: 'flex-end'}}>
                        <Col className="text-capitalize-first">{`${texts('data')} ${texts('from')}`}</Col>
                        <Col style={{marginLeft: 6}}>
                            <Tag
                                size={'default'}
                                color={'red'}>
                                15:00:00 {dayjs(params?.dates?.[0]).subtract(1, 'day').format('DD/MM/YYYY')}
                            </Tag>
                        </Col>
                        <Col>{texts('to')}</Col>
                        <Col style={{marginLeft: 6}}>
                            <Tag
                                size={'default'}
                                color={'red'}>
                                14:59:59 {dayjs(params?.dates?.[1]).format('DD/MM/YYYY')}
                            </Tag>
                        </Col>
                    </Row>
                </Col>

            </Row>
            <Table
                bordered
                className="table-data"
                columns={columns}
                rowSelection={rowSelection}
                dataSource={data.map((d, index) => ({
                    ...d,
                    stt: index + 1 + (params.page - 1) * params.size
                }))}
                loading={loading}
                pagination={false}
                rowKey={(record) => record?.transactionId}
                size="small"
                scroll={{
                    x: 1300,
                }}
//                width={1500}
            />
        </div>
    );
};

export default TableData;
