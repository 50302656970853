import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function search(params = {}) {
  return request({
    url: ENDPOINT.CONTRACT_SEARCH,
    method: "get",
    params,
  });
}

function filter(params = {}) {
  return request({
    url: ENDPOINT.CONTRACT_FILTER,
    method: "get",
    params,
  });
}


function get(id, params = {}) {
  return request({
    url: `${ENDPOINT.CONTRACT}/${id}`,
    method: "get",
    params,
  });
}

function get_detail(params = {}) {
  return request({
    url: ENDPOINT.CONTRACT_DETAIL,
    method: "get",
    params,
  });
}

function create(data = {}) {
  return request({
    url: ENDPOINT.CONTRACT,
    method: "post",
    data,
  });
}

function verify_filed(data = {}, params) {
  return request({
    url: ENDPOINT.CONTRACT_VERIFY_FILED,
    method: "post",
    data,
    params,
  });
}

function verify(data = {}) {
  return request({
    url: ENDPOINT.CONTRACT_VERIFY,
    method: "post",
    data,
  });
}

function get_statistics(params = {}) {
  return request({
    url: ENDPOINT.CONTRACT_STATISTIC,
    method: "get",
    params,
  });
}

function get_customer_statistics(params = {}) {
  return request({
    url: ENDPOINT.CONTRACT_CUSTOMER_STATISTIC,
    method: "get",
    params,
  });
}

function get_products_approved(params = {}) {
  return request({
    url: ENDPOINT.CONTRACT_PRODUCTS_APPROVED,
    method: "get",
    params,
  });
}

function get_products_approved_by_id(id, params = {}) {
  return request({
    url: ENDPOINT.CONTRACT_PRODUCTS_APPROVED_BY_ID.replace(':id', id),
    method: "get",
    params,
  });
}

export default {
  search,
  create,
  filter,
  get,
  get_detail,
  verify,
  verify_filed,
  get_statistics,
  get_customer_statistics,
  get_products_approved,
  get_products_approved_by_id
};
