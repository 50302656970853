import React, { useState, useEffect } from "react";
import {
  Row,
} from 'antd';

import { Statistic } from '../../../../../../components'

import CONSTANTS from '../../../../../../config/constants'

const Statistics = (props) => {
  const {
    extraData = {}
  } = props
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState([]);

  useEffect(() => {
    fetchData();
  }, [extraData])

  const fetchData = async () => {
    setLoading(true);
    setStatistics(CONSTANTS.ASSESSOR_CONTRACT_STATUS.map((s) => ({
      ...s,
      total: extraData ? extraData[s.statistic_key] || 0 : 0
    })));
    setLoading(false);
  }

  return (
    <Row
      className="statistics"
      justify={'left'}
    >
      <Statistic
        statistics={statistics}
        setParams={() => { }}
      />
    </Row>
  );
}

export default Statistics;