import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col
} from 'antd';

import Filter from "./loan/Filter";
import OrderReport from "./loan/Report";
import OrderMoney from "./loan/Money";

import reportServices from '../../../../services/finblue/report';

import dayjs from 'dayjs';

const ReportLoan = (props) => {
  const { params } = props;

  const [getting, setGetting] = useState(false)
  const [orderReport, setOrderReport] = useState({});
  const [orderMoney, setOrderMoney] = useState({});
  const [currentParams, setCurrentParams] = useState({
    lenderId: '',
    businessId: ''
  });

  useEffect(() => {
    fetchOrderReport();
    fetchOrderMoney();
  }, [params, currentParams])

  const fetchOrderReport = async () => {
    setGetting(true);
    await reportServices.report_order({
      begin: dayjs(dayjs(params.dates[0]).format('YYYY-MM-DD 00:00:00')).unix() * 1000,
      end: dayjs(dayjs(params.dates[1]).format('YYYY-MM-DD 23:59:59')).unix() * 1000,
      ...currentParams
    }).then((response) => {
      setOrderReport(response?.data || {});
    }).catch(() => {
      setOrderReport({})
    })
    setGetting(false);
  }

  const fetchOrderMoney = async () => {
    setGetting(true);
    await reportServices.report_order_money({
      begin: dayjs(dayjs(params.dates[0]).format('YYYY-MM-DD 00:00:00')).unix() * 1000,
      end: dayjs(dayjs(params.dates[1]).format('YYYY-MM-DD 23:59:59')).unix() * 1000,
      ...currentParams
    }).then((response) => {
      setOrderMoney(response?.data || {});
    }).catch(() => {
      setOrderMoney({})
    })
    setGetting(false);
  }

  return (
    <Card
      size="small"
      className="w-100 mt-m"
      loading={getting}
      title={'Báo cáo đơn vay'}
    >
      <Filter
        params={currentParams}
        setParams={setCurrentParams}
      />
      <Row gutter={[24, 24]}>
        <Col xl={12} lg={16} sm={24} xs={24}>
          <OrderMoney
            orderMoney={orderMoney}
          />
        </Col>
        <Col xl={12} lg={16} sm={24} xs={24}>
          <OrderReport
            orderReport={orderReport}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default ReportLoan;