import React from "react";
import {
  Row,
  Table,
  Button,
  Tag
} from 'antd';

import {
  EditOutlined
} from "@ant-design/icons";

import {
  formatCurrency,
  convertDateTime,
  assessorOrderStatus
} from '../../../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    rowSelection = null
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 150,
      render: (_, record) => (
        <Tag color={assessorOrderStatus(record.status)?.tag_color}>
          {assessorOrderStatus(record.status)?.label}
        </Tag>
      ),
    },
    {
      title: 'Mã khách hàng',
      dataIndex: 'customerId',
      key: 'customerId',
      align: 'center',
      width: 150,
      render: (_, record) => (
        `KH_${record.customerId}`
      ),
    },
    {
      title: 'Mã đơn vay',
      dataIndex: 'loanCode',
      key: 'loanCode',
      align: 'center',
      width: 120,
    },
    {
      title: 'Giá trị đơn vay',
      dataIndex: 'moneyAvailable',
      key: 'moneyAvailable',
      align: 'right',
      width: 150,
      render: (_, record) => formatCurrency(record.moneyAvailable)
    },
    {
      title: 'Loại dịch vụ',
      dataIndex: 'business',
      key: 'business',
      align: 'center',
      width: 150,
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      width: 150,
      render: (_, record) => convertDateTime(record.createdDate)
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (_, record) => (
        <Row gutter={[0, 8]} justify='center'>
          <Button
            icon={<EditOutlined />}
            type='primary'
            size="small"
            ghost
            onClick={() => window.navigatePage('LENDER_ORDER_DETAIL', { id: params.lenderId, order_id: record.id })}
          >Chi tiết</Button>
        </Row>
      ),
    },
  ].filter((c) => !rowSelection || c.key !== 'actions')

  return (
    <Table
      bordered
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 }))}
      loading={loading}
      pagination={false}
      rowKey={(record) => record?.id}
      scroll={{ x: 1024 }}
      size="small"
      rowSelection={rowSelection ? {
        type: 'checkbox',
        ...rowSelection,
      } : null}
    />
  );
}

export default TableData;