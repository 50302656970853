import React, { useEffect, useState } from "react";
import {
  Table,
  Card,
} from 'antd';

import {
} from '../../../../../utils/common'

import { TransactionTable } from '../../../../../components'

import contractServices from '../../../../../services/finblue/contract';


const Transactions = (props) => {
  const {
    loading = false,
    params = {},
  } = props

  const [transactions, setTransactions] = useState([])
  const [getting, setGetting] = useState(false)

  useEffect(() => {
    if (params?.id) {
      fetchTransactions();
    }
  }, [])

  const fetchTransactions = async () => {
    setGetting(true);
    await contractServices.get_products_approved({
      customerId: params?.id,
      active: true
    }).then((response) => {
      setTransactions(response?.data || []);
    }).catch(() => {
      setTransactions([])
    })
    setGetting(false);
  }

  return (
    <Card
      size="small"
      className="w-100 my-m"
      loading={loading || getting}
      title={'Danh sách hạn mức'}
    >
      <TransactionTable
        data={transactions}
        loading={getting}
      />
    </Card>
  );
}

export default Transactions;