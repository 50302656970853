import CmsUsers from "./cms-users";
import CmsUserCreate from "./cms-users/create";
import CmsUserDetail from "./cms-users/detail";
import Roles from "./roles";
import RoleDetail from "./roles/detail";
import RoleCreate from "./roles/create";
import Positions from "./positions";
import Permissions from "./permissions";
import Staffs from '../admin/staffs';

export default {
  CmsUsers,
  CmsUserCreate,
  CmsUserDetail,
  Roles,
  RoleDetail,
  RoleCreate,
  Positions,
  Permissions,
  Staffs
}
