import React, { useState, useMemo, useEffect } from "react";
import {
  Table,
  Modal,
  Tag,
  message,
  InputNumber,
  Button,
  DatePicker,
  Form,
  Input,
  Select
} from 'antd';

import {
} from "@ant-design/icons";

import {
  paymentStatus,
  formatCurrency,
  convertDateTime
} from '../../../../../utils/common';

import { RECEIPT_DAY_RULES, BANK_ACCOUNT_RULES } from "../../../../../config/rules";

import paymentServices from "../../../../../services/finblue/payment";
import receiptServices from "../../../../../services/finblue/receipt";
import financeServices from "../../../../../services/finblue/finance";

import dayjs from 'dayjs'

const SettlementModal = (props) => {
  const {
    visible = false,
    loanInfo = {},
    selectedSchedule = {},
    onClose = () => { },
    onReload = () => { },
  } = props;

  const [getting, setGetting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [callingAPI, setCallingAPI] = useState(false);
  const [fee, setFee] = useState(0)
  const [paymentInfo, setPaymentInfo] = useState(null)
  const [allFinblueFinances, setAllFinblueFinances] = useState([]);
  const [allCustomerFinances, setAllCustomerFinances] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchCustomerFinances();
    fetchFinblueFinances();
  }, [])

  const fetchCustomerFinances = async () => {
    setLoading(true);
    await financeServices.search_customer_finances({
      customerId: selectedSchedule.customerId,
    }).then((response) => {
      setAllCustomerFinances(response.data || []);
    }).catch(() => {
      setAllCustomerFinances([])
    })
    setLoading(false);
  }

  const fetchFinblueFinances = async () => {
    setLoading(true);
    await financeServices.search_finances_finblue().then((response) => {
      setAllFinblueFinances(response.data || []);
    }).catch(() => {
      setAllFinblueFinances([])
    })
    setLoading(false);
  }

  useEffect(() => {
    if (selectedSchedule?.id && selectedSchedule?.status === 0) {
      getPaymentInfo()
    }
  }, [selectedSchedule])

  const getPaymentInfo = () => {
    setGetting(true)
    receiptServices.get_receipt_by_schedule({
      scheduleId: selectedSchedule.id
    }).then((response) => {
      setPaymentInfo(response.data)
      setGetting(false)
    }).catch(() => {
      setPaymentInfo({})
      setGetting(false)
    })
  }

  const columns = [
    {
      title: 'Các khoản thông tin',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Giá trị',
      dataIndex: 'value',
      key: 'value',
      align: 'right',
    },
  ];
  const isSettlement = useMemo(() => {
    return selectedSchedule.status !== 0
  }, [selectedSchedule])
  const inforData = useMemo(() => {
    return [
      {
        name: 'Tiền thanh toán',
        value: formatCurrency(selectedSchedule?.amount),
      },
      {
        name: 'Tiền gốc',
        value: formatCurrency(selectedSchedule?.money),
      },
      {
        name: 'Tiền lãi',
        value: formatCurrency(selectedSchedule?.interest),
      },
      {
        name: 'Phí nền tảng (%/năm)',
        value: formatCurrency(0),
      },
      {
        name: 'Phí trả chậm',
        hide: selectedSchedule?.status !== 2,
        value: formatCurrency(selectedSchedule?.latePaymentFree),
      },
      {
        name: 'Thời gian tất toán',
        hide: !paymentInfo,
        value: convertDateTime(paymentInfo?.day),
      },
      {
        name: 'Mã giao dịch chuyển tiền',
        hide: !paymentInfo,
        value: paymentInfo?.expensesCode,
      },
      {
        name: 'Tài khoản ngân hàng gửi',
        hide: !paymentInfo,
        value: <div>
          <p><b>{paymentInfo?.bankTransferAccountNumber}</b></p>
          <p><small>{paymentInfo?.bankTransferName}</small></p>
        </div>
      },
      {
        name: 'Tài khoản ngân hàng nhận',
        hide: !paymentInfo,
        value: <div>
          <p><b>{paymentInfo?.bankReceiveAccountNumber}</b></p>
          <p><small>{paymentInfo?.bankReceiveName}</small></p>
        </div>
      },
      {
        name: 'Nội dung',
        hide: !paymentInfo,
        value: paymentInfo?.reason,
      },
      {
        name: <p>{'Thời gian tất toán'}<span style={{
          color: 'red'
        }}> *</span></p>,
        hide: !!paymentInfo,
        value: <Form.Item
          name='day'
          rules={[
            ...RECEIPT_DAY_RULES
          ]}
          noStyle
        >
          <DatePicker
            className="w-full"
            placeholder="Chọn thời gian"
            disabled={callingAPI}
            format={'HH:mm DD-MM-YYYY'}
          />
        </Form.Item>
      },
      {
        name: 'Mã giao dịch chuyển tiền',
        hide: !!paymentInfo,
        value: <Form.Item
          name='expensesCode'
          rules={[]}
          noStyle
        >
          <Input placeholder='EX: 123456' disabled={callingAPI}></Input>
        </Form.Item>
      },
      {
        name: <p>{'Tài khoản chuyển tiền'}<span style={{
          color: 'red'
        }}> *</span></p>,
        hide: !!paymentInfo,
        value: <Form.Item
          name='financeTransferId'
          rules={[
            ...BANK_ACCOUNT_RULES
          ]}
          noStyle
          className="w-full"
        >
          <Select
            placeholder={"Vui lòng chọn tài khoản"}
            disabled={callingAPI}
            className="w-full text-left"
          >
            {allCustomerFinances.map((it) => (
              <Select.Option
                key={it.id}
                value={it.id}
              >
                <span className="">{it.bankAccountName} (<small>{it.bankAccountNumber}</small>)</span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      },
      {
        name: <p>{'Tài khoản nhận'}<span style={{
          color: 'red'
        }}> *</span></p>,
        hide: !!paymentInfo,
        value: <Form.Item
          name='financeReceiveId'
          rules={[
            ...BANK_ACCOUNT_RULES
          ]}
          noStyle
        >
          <Select
            placeholder={"Vui lòng chọn tài khoản"}
            disabled={callingAPI}
            className="w-full text-left"
          >
            {allFinblueFinances.map((it) => (
              <Select.Option
                key={it.id}
                value={it.id}
              >
                <span className="m-0">{it.bankAccountName} (<small>{it.bankAccountNumber}</small>)</span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      },
      {
        name: 'Nội dung',
        hide: !!paymentInfo,
        value: <Form.Item
          name='reason'
          rules={[]}
          noStyle
        >
          <Input.TextArea placeholder='Nhập Nội dung' disabled={callingAPI}></Input.TextArea>
        </Form.Item>
      }
    ].filter((d) => !d.hide)
  }, [selectedSchedule, callingAPI, allFinblueFinances, allCustomerFinances, paymentInfo])

  const handleSubmit = async () => {
    form.validateFields().then(async (values) => {
      setCallingAPI(true);
      await handleDebtBrick(values);
      setCallingAPI(false);
    })
  }

  const handleDebtBrick = async (values) => {
    await paymentServices.loan_debt_brick_schedule({
      scheduleId: selectedSchedule?.id,
      day: dayjs(values.day).unix() * 1000,
      expensesCode: values.expensesCode,
      financeReceiveId: values.financeReceiveId,
      financeTransferId: values.financeTransferId,
      reason: values.reason,
      money: selectedSchedule.money,
      orderId: selectedSchedule.orderId,
      fee: selectedSchedule.latePaymentFree || 0,
      createdBy: 0
    }).then(() => {
      message.success('Tất toán thành công!')
      onReload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  return (
    <Modal
      title={`Tất toán > Kỳ ${selectedSchedule.stt} (${convertDateTime(selectedSchedule.expectedDate)})`}
      width={700}
      open={visible}
      onCancel={() => onClose()}
      okText='Tất toán'
      cancelText='Hủy'
      loading={getting}
      style={{
        top: 50
      }}
      footer={<div className="flex align-items-center justify-content-end">
        <Button
          className="mr-2xs"
          onClick={() => onClose()}
          disabled={callingAPI}
        >{isSettlement ? 'Huỷ' : 'Đóng'}</Button>
        {
          isSettlement && <Button
            type="primary"
            loading={callingAPI}
            onClick={() => handleSubmit()}
          >Tất toán</Button>
        }
      </div>}
      okButtonProps={{
        loading: callingAPI
      }}
      cancelButtonProps={{
        disabled: callingAPI
      }}
    >
      <div className="flex align-items-center justify-content-between mb-m">
        <p><span className="text-gray mr-xs">Mã đơn vay: </span> {loanInfo?.loanCode}</p>
        <Tag
          color={paymentStatus(selectedSchedule?.status).tag_color}
          className="m-0"
        >
          {paymentStatus(selectedSchedule?.status).label}
        </Tag>
      </div>
      <Form form={form}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={inforData.map((d, index) => ({ ...d, stt: index }))}
          pagination={false}
          rowKey={(record) => record?.stt}
          size="small"
        />
      </Form>
    </Modal>

  );
}

export default SettlementModal;