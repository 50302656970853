import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/invest";

function customer_search(params) {
    return request({
        url: ENDPOINT.CUSTOMERS,
        method: "get",
        params,
    });
}

function customer_detail(params) {
    return request({
        url: ENDPOINT.CUSTOMER_DETAIL,
        method: "get",
        params,
    });
}

function customer_fmarket_account(params) {
    return request({
        url: ENDPOINT.GET_FA_BY_ACC_ID,
        method: "get",
        params,
    });
}

function get_account_kyc_error(params) {
    return request({
        url: ENDPOINT.GET_ACCOUNT_KYC_ERROR,
        method: "get",
        params,
    });
}

function verify_kyc(data, params = {}) {
    return request({
        url: ENDPOINT.VERIFY_KYC,
        method: "post",
        params,
        data
    });
}


function get_saving_contract(accountId) {
    // console.log('accountId', accountId);
    return request({
        url: ENDPOINT.GET_SAVING_CONTRACT,
        method: "get",
        params: {accountId}
    });
}


function report_saving_transactions_customer(fromDate, toDate) {
    return request({
        url: ENDPOINT.REPORT_SAVING_TRANSACTIONS_CUSTOMER,
        method: "get",
        // params: {fromDate, toDate}
    });
}

function report_fmarket_transactions_customer(fromDate, toDate) {
    return request({
        url: ENDPOINT.REPORT_FMARKET_TRANSACTIONS_CUSTOMER,
        method: "get",
        // params: {fromDate, toDate}
    });
}




export default {
    customer_search,
    customer_detail,
    customer_fmarket_account,
    get_account_kyc_error,
    verify_kyc,
    get_saving_contract,
    report_saving_transactions_customer,
    report_fmarket_transactions_customer
};