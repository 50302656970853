import React, { useMemo } from "react";
import {
  Table,
} from 'antd';

import { TableContent } from '../../../../../components'

import {
  convertDateTime,
  convertGender,
  matchValueStatus,
  reviewedStatus
} from '../../../../../utils/common'

const TableDataDetail = (props) => {
  const {
    data = {},
    loading = false,
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Trường thông tin',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 250
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      key: 'content',
      align: 'left',
      render: (_, record) => (
        <TableContent record={record} />
      ),
    },
  ]

  const profileInfoTable = useMemo(() => {
    return [
      {
        name: 'Họ tên',
        content: data?.name,
        unit: 'string',
      },
      {
        name: 'Địa chỉ Email',
        content: data?.email,
        unit: 'string',
      },
      {
        name: 'Số điện thoại',
        content: data?.phone,
        unit: 'string',
      },
      {
        name: 'Ngày sinh',
        content: convertDateTime(data?.birthday, 'DD/MM/YYYY'),
        unit: 'date',
      },
      {
        name: 'Giới tính',
        content: convertGender(data?.gender)?.label,
        unit: 'string',
      },
      {
        name: 'Quốc gia',
        content: data?.countryCode,
        unit: 'string',
      },
      {
        name: 'Quê quán',
        content: data?.provinceName,
        unit: 'string',
      },
      {
        name: 'Địa chỉ thường trú',
        content: `${data?.address} - ${data?.wardName} - ${data?.districtName}`,
        unit: 'string',
      },
      {
        name: 'Ảnh mặt trước CCCD/CMND',
        content: data?.citizenIdentityCardUrlFront,
        unit: 'image',
      },
      {
        name: 'Ảnh mặt sau CCCD/CMND',
        content: data?.citizenIdentityCardUrlBack,
        unit: 'image',
      },
      {
        name: 'Ảnh Selfie',
        content: data?.selfieUrl,
        unit: 'image',
      },
      {
        name: 'Chấm điểm trùng khớp',
        content: data?.matchScore,
        unit: 'string',
      },
      {
        name: 'Đánh giá trùng khớp',
        content: matchValueStatus(data?.matchValue)?.label,
        unit: 'string',
      },
      {
        name: 'Kiểm tra lại',
        content: reviewedStatus(data?.toBeReviewed)?.label,
        unit: 'string',
      },
    ]
  }, [data]);

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={profileInfoTable.map((d, index) => ({ ...d, stt: index + 1 }))}
      loading={loading}
      pagination={false}
      rowKey={(record) => record?.name}
      scroll={{ x: 1024 }}
      size="small"
    />
  );
}

export default TableDataDetail;