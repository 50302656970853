import React from "react";
import {
  Row,
  Table,
  Button,
  Tag
} from 'antd';

import {
  EditOutlined
} from "@ant-design/icons";

import {
  formatCurrency,
  convertDateTime,
  customerPaymentStatus
} from '../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    params
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Mã đơn vay',
      dataIndex: 'loanCode',
      key: 'loanCode',
      align: 'center',
      width: 200,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Tag color={customerPaymentStatus(record.status)?.tag_color}>
          {customerPaymentStatus(record.status)?.label}
        </Tag>
      ),
    },
    {
      title: 'Số tiền vay',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      width: 200,
      render: (_, record) => formatCurrency(record.amount)
    },
    {
      title: 'Thời gian vay',
      dataIndex: 'startedDated',
      key: 'startedDated',
      align: 'center',
      width: 200,
      render: (_, record) => convertDateTime(record.startedDated)
    },
    {
      title: 'Đã thanh toán',
      dataIndex: 'totalPaid',
      key: 'totalPaid',
      align: 'right',
      width: 200,
      render: (_, record) => formatCurrency(record.totalPaid)
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: 120,
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: '200px',
      render: (_, record) => (
        <Row gutter={[0, 8]} justify='center'>
          <Button
            icon={<EditOutlined />}
            type='primary'
            size="small"
            ghost
            onClick={() => window.navigatePage('CUSTOMER_LOAN_DETAIL', { id: record.customerId, order_id: record.orderId })}
          >Chi tiết</Button>
        </Row>
      ),
    },
  ]

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 + (params.page - 1) * params.pageSize }))}
      loading={loading}
      pagination={false}
      rowKey={(record) => record?.orderId}
      scroll={{ x: 1024 }}
      size="small"
    />
  );
}

export default TableData;