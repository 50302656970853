import Dashboard from "./dashboard";
import DebtDetail from "./dashboard/debt-detail";
import LimitApproval from './limit-approval';
import LimitApprovalDetail from './limit-approval/detail';
import LoanApproval from './loan-approval';
import LoanApprovalDetail from './loan-approval/detail';
import LenderLoanApproval from './lender-loan-approval';
import LenderLoanApprovalDetail from './lender-loan-approval/detail';
import MarketLoanApproval from './market-loan-approval';
import MarketLoanApprovalDetail from './market-loan-approval/detail';
import EKYCApproval from './ekyc-approval';
import EKYCApprovalDetail from './ekyc-approval/detail';
import CustomerPayment from './customer-payment';
import ProductPackages from './product-packages';
import ProductPackageDetail from './product-packages/detail';
import ProductTransactions from './product-transactions';
import Customers from './customers';
import CustomerDetail from './customers/detail';
import CustomerLoanDetail from './customers/loan-detail';
import Markets from './markets';
import MarketDetail from './markets/detail';
import MarketOrderDetail from './markets/order-detail';
import Lenders from './lenders';
import LenderDetail from './lenders/detail';
import LenderOrderDetail from './lenders/order-detail';
import Appota from './appota';
import AppotaDetail from './appota/detail';
import Banks from './banks';
import CustomerBankAccounts from './banks/customer-accounts';
import SystemBankAccounts from './banks/system-accounts';
import BankAccountReceipts from './banks/bank-account-receipts';
import Assessors from './assessors';
import AssessorDetail from './assessors/detail';

export default {
  Dashboard,
  DebtDetail,
  LimitApproval,
  LimitApprovalDetail,
  LoanApproval,
  LoanApprovalDetail,
  LenderLoanApproval,
  LenderLoanApprovalDetail,
  MarketLoanApproval,
  MarketLoanApprovalDetail,
  EKYCApproval,
  EKYCApprovalDetail,
  CustomerPayment,
  ProductPackages,
  ProductPackageDetail,
  ProductTransactions,
  Customers,
  CustomerDetail,
  CustomerLoanDetail,
  Markets,
  MarketDetail,
  MarketOrderDetail,
  Lenders,
  LenderDetail,
  LenderOrderDetail,
  Appota,
  AppotaDetail,
  Banks,
  CustomerBankAccounts,
  SystemBankAccounts,
  BankAccountReceipts,
  Assessors,
  AssessorDetail
}
