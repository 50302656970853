import React, { useState, useEffect, useMemo } from "react";
import { useParams } from 'react-router-dom';

import { AdminDetailHeader } from "../../../components";
import DetailCustomer from "../customers/components/detail/Customer";
import DetailOrder from "../loan-approval/components/detail/Order";
import HistorySchedule from "../customers/components/loan-detail/HistorySchedule";

import loanServices from '../../../services/finblue/loan';

import { convertStringToQuery } from '../../../utils/common'


const LenderOrderDetail = (props) => {
  const { router } = props
  const { id, order_id } = useParams();
  const query = convertStringToQuery(window.location.search);
  const [loading, setLoading] = useState(false)
  const [loanInfo, setLoanInfo] = useState({})

  const params = useMemo(() => {
    return {
      lenderId: Number(id),
      id: Number(order_id),
    }
  }, [id, order_id, query]);

  useEffect(() => {
    getLoanInfo()
  }, [])

  const getLoanInfo = () => {
    setLoading(true)
    loanServices.get_loan_info(params?.id).then((response) => {
      setLoanInfo(response.data)
      setLoading(false)
    }).catch(() => {
      setLoanInfo({})
      setLoading(false)
    })
  }

  return (
    <div className="lender-order-detail">
      <AdminDetailHeader
        title={router.label}
        showScore={false}
        className="mb-m"
      />
      <DetailCustomer
        params={{ id: loanInfo?.customerId }}
      />
      <DetailOrder
        loading={loading}
        params={params}
      />
      <HistorySchedule
        loading={loading}
        loanInfo={loanInfo}
        params={params}
      />
    </div>
  );
}

export default LenderOrderDetail;