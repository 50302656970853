import React from "react";
import "../css/components/Filter.scss";
import {Row, Col, Input, Select, DatePicker} from "antd";

import {SearchOutlined} from "@ant-design/icons";
import CONSTANTS from "../../../../config/constants";
import CONSTANTS_INVEST from "../../../../config/constants_invest";
import {useTranslation} from "react-i18next";

const Filter = (props) => {
    const {
        params = {}, setParams = () => {}
    } = props;

    const { t } = useTranslation();
    const texts = t;

    return (
        <Row className="filter" gutter={[8, 8]}>
            <Col span={3} lg={6} sm={12} xs={24}>
                <Input
                    prefix={<SearchOutlined/>}
                    value={params.keyword}
                    placeholder={texts('search')}
                    onChange={(e) => setParams({...params, keyword: e.target.value})}
                />
            </Col>
            {/*<Col span={3} lg={6} sm={12} xs={24}>*/}
                {/*<Select*/}
                    {/*placeholder={"Balance"}*/}
                    {/*value={params.type}*/}
                    {/*onChange={(v) => setParams({...params, type: v})}*/}
                    {/*showSearch={true}*/}
                    {/*optionFilterProp="children"*/}
                    {/*className="w-full"*/}
                    {/*filterOption={(input, option) =>*/}
                        {/*`${option.children}`.toLowerCase().includes(input.toLowerCase())*/}
                    {/*}*/}
                {/*>*/}
                    {/*{CONSTANTS_INVEST.BALANCE_CHANGE_REQUEST_TYPE.map((type) => (*/}
                        {/*<Select.Option value={type.value} key={type.name}>*/}
                            {/*{type.name}*/}
                        {/*</Select.Option>*/}
                    {/*))}*/}
                {/*</Select>*/}
            {/*</Col>*/}
            <Col span={6} lg={6} sm={12} xs={24}>
                <DatePicker.RangePicker
                    className="filter-filed"
                    format={CONSTANTS.DATE_FORMAT}
                    value={params?.dates || []}
                    onChange={(v) => setParams({...params, dates: v})}
                />
            </Col>
        </Row>
    );
};

export default Filter;
