import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints";

import systemServices from '../../services/system';

const cmsInfo = systemServices.cms_info();

function list(params = {}) {
  return request({
    url: ENDPOINT.CATEGORIES,
    method: "get",
    params: {
      ...params,
      system: cmsInfo?.value
    },
  });
}

function remove(id) {
  return request({
    url: ENDPOINT.CATEGORY_DETAIL.replace(':id', id),
    method: "delete",
  });
}

function create(data) {
  return request({
    url: ENDPOINT.CATEGORY,
    method: "post",
    data: {
      ...data,
      system: cmsInfo?.value
    },
  });
}

function update(id, data) {
  return request({
    url: ENDPOINT.CATEGORY_DETAIL.replace(':id', id),
    method: "put",
    data
  });
}

export default {
  list,
  remove,
  create,
  update
};
