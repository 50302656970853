const BACKEND_URL = process.env.REACT_APP_FINBLUE_API_URL;
export default {
//  LOGIN: `${BACKEND_URL}/auth/v2/user/login`,
  LOGIN: `${BACKEND_URL}/auth/v2/user/login-cms`,

  ROLES: `${BACKEND_URL}/auth/v2/role/get-all`,
  ROLE: `${BACKEND_URL}/auth/v2/role`,
  ROLE_DETAIL: `${BACKEND_URL}/auth/v2/role/:id`,

  CATEGORIES: `${BACKEND_URL}/auth/v2/role/get-role-category`,
  CATEGORY: `${BACKEND_URL}/auth/v2/role/category`,
  CATEGORY_DETAIL: `${BACKEND_URL}/auth/v2/role/category/:id`,

  USERS: `${BACKEND_URL}/auth/v2/role/user/get-all`,
  USER: `${BACKEND_URL}/auth/v2/role/user`,
  USER_DETAIL: `${BACKEND_URL}/auth/v2/role/user/:id`,

  PERMISSIONS: `${BACKEND_URL}/auth/v2/role/permission/get-all`,
  PERMISSIONS_ALL: `${BACKEND_URL}/auth/v2/role/permission/get-all-v2`,
  PERMISSION: `${BACKEND_URL}/auth/v2/role/permission`,
  PERMISSION_DETAIL: `${BACKEND_URL}/auth/v2/role/permission/:id`,

  POSITION: `${BACKEND_URL}/auth/position`,
  POSITION_DETAIL: `${BACKEND_URL}/auth/position/:id`,

  USER_POSITION: `${BACKEND_URL}/auth/user-position`,
  USER_POSITION_DETAIL: `${BACKEND_URL}/auth/user-position/:id`,

  DEPARTMENT: `${BACKEND_URL}/auth/department`,
  DEPARTMENT_DETAIL: `${BACKEND_URL}/auth/department/:id`
};
