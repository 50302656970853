import React, { } from "react";
import {
  Table,
  Tag
} from 'antd';

import {
  formatCurrency,
  convertDateTime,
  activeStatus
} from '../../utils/common'

const TransactionTable = (props) => {
  const {
    loading = false,
    bordered = false,
    data = [],
    className
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 100
    },
    {
      title: 'Trạng thái',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Tag color={activeStatus(record.active)?.tag_color}>
          {activeStatus(record.active)?.label}
        </Tag>
      ),
    },
    {
      title: 'Gói hạn mức',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200
    },
    {
      title: 'Số tiền được phê duyệt',
      dataIndex: 'moneyApproved',
      key: 'moneyApproved',
      align: 'right',
      width: 200,
      render: (_, record) => (
        formatCurrency(record.moneyApproved)
      ),
    },
    {
      title: 'Số tiền khả dụng',
      dataIndex: 'moneyAvailable',
      key: 'moneyAvailable',
      align: 'right',
      width: 200,
      render: (_, record) => (
        formatCurrency(record.moneyAvailable)
      ),
    },
    {
      title: 'Lãi suất (%/năm)',
      dataIndex: 'interest',
      key: 'interest',
      align: 'right',
      width: 200,
    },
    {
      title: 'Thời hạn (Tháng)',
      dataIndex: 'numMonth',
      key: 'numMonth',
      align: 'center',
      width: 200,
    },
    {
      title: 'Ngày bắt đầu',
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'center',
      width: 200,
      render: (_, record) => (
        convertDateTime(record.startDate, 'DD-MM-YYYY')
      ),
    },
    {
      title: 'Người phê duyệt',
      dataIndex: 'approvedName',
      key: 'approvedName',
      align: 'left',
      width: 200,
    }
  ]

  return (
    <Table
      loading={loading}
      bordered={bordered}
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 }))}
      pagination={false}
      rowKey={(record) => record?.id}
      size="small"
      scroll={{ x: 1024 }}
      className={className}
    />
  );
}

export default TransactionTable;