import React, { useEffect, useState, useMemo } from "react";
import {
  Modal,
  message,
  Tabs
} from 'antd';

import {
} from "@ant-design/icons";

import {
} from "../../../../../config/rules";

import assessorServices from '../../../../../services/finblue/assessor';
import AssessorInformation from '../Infomation';
import OrderTableData from '../detail/order/TableData';
import ContractTableData from '../detail/contract/TableData';

const DivideModal = (props) => {
  const {
    visible = false,
    assessor = {},
    onClose = () => { },
    reload = () => { }
  } = props

  const [loading, setLoading] = useState(false);
  const [callingAPI, setCallingAPI] = useState(false);
  const [works, setWorks] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedContracts, setSelectedContracts] = useState([]);

  const contractRowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedContracts(selectedRowKeys)
    }
  };

  const orderRowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedOrders(selectedRowKeys)
    }
  };

  const items = useMemo(() => {
    return [
      {
        label: 'Hợp đồng hạn mức',
        value: 1,
        key: 'contract',
        children: <ContractTableData
          loading={loading}
          data={works.contractResponses || []}
          rowSelection={contractRowSelection}
        />
      },
      {
        label: 'Đơn vay',
        value: 2,
        key: 'order',
        children: <OrderTableData
          loading={loading}
          data={works.orderResponses || []}
          rowSelection={orderRowSelection}
        />
      }
    ]
  }, [works, loading])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true);
    await assessorServices.get_assessor_works().then((response) => {
      setWorks(response?.data || []);
    }).catch(() => {
      setWorks([])
    })
    setLoading(false);
  }

  const handleConfirm = async () => {
    setCallingAPI(true)
    await assessorServices.divide({
      contractIds: selectedContracts,
      customerId: assessor.customerId,
      orderIds: selectedOrders
    }).then(() => {
      message.success('Giao việc thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
    setCallingAPI(false)
  }

  return (
    <Modal
      title={'Giao việc thủ công'}
      width={1000}
      open={visible}
      onOk={() => handleConfirm()}
      onCancel={() => onClose()}
      okButtonProps={{
        loading: callingAPI,
        disabled: selectedOrders.length === 0 && selectedContracts.length === 0
      }}
      cancelButtonProps={{
        disabled: callingAPI
      }}
      okText='Xác nhận'
      cancelText='Hủy'
    >
      <AssessorInformation assessor={assessor} />
      <div className="mt-m">
        <Tabs
          items={items}
          onChange={() => { }}
        />
      </div>
    </Modal>

  );
}

export default DivideModal;