import React, {useState} from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import {actionUploadImage} from './actions'
import contentService from '../../services/invest/content'

ClassicEditor
    .create( document.querySelector( '#editor' ), {
        toolbar: [ 'heading', '|', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
            ]
        }
    } )
    .catch( error => {
        console.log( error );
    } );

const Editor = ({initValue, onChange}) => {

    const [value, setValue] = useState(initValue || '');

    const handleChange = (v) => {
        setValue(v);
        onChange(v);
    }

    // sử dụng const function CKEditor bị lỗi
    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {                    
                    loader.file.then(async (file) => {
                        try {
                            const bodyFormData = new FormData();
                            bodyFormData.append('file', file);

                            contentService.upload_file(bodyFormData).then((res) => {
                                let imgUrl = res?.data;
                                resolve({default: imgUrl});
                            });

                            // const { status, data } = await contentService.upload_file(bodyForData);
                            // // console.log()
                            // if (status == 200) {
                            //     resolve({default: `${data?.data[0]}`});
                            // }

                        } catch (error) {
                            reject(error);
                        }
                    });
                });
            }
        };
    }

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    return (
        <div className="App">
            <CKEditor
                config={{
                    extraPlugins: [uploadPlugin]
                }}
                // config={{ckfinder: {
                //         // Upload the images to the server using the CKFinder QuickUpload command.
                //         uploadUrl: 'http://95.216.124.130:8082/image-service/auth/upload-image'
                //     }}}
                editor={ClassicEditor}
                onChange={(event, editor) => {
                    handleChange(editor.getData());
                }}
                data={value}
            />
        </div>
    );
}

export default Editor;