import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/invest";

function balance_logs(params) {
    return request({
        url: ENDPOINT.BALANCE_LOGS,
        method: "get",
        params,
    });
}

function balance_logs_detail(params) {
    return request({
        url: ENDPOINT.BALANCE_LOGS,
        method: "get",
        params,
    });
}

function get_balance_change_request(params) {
    return request({
        url: ENDPOINT.GET_BALANCE_CHANGE_REQUEST,
        method: "get",
        params,
    });
}


export default {
    balance_logs,
    balance_logs_detail,
    get_balance_change_request
};
