import React from "react";
import './css/index.scss'
import { Button, Space } from 'antd'

const AdminHeader = (props) => {
  const {
    title = '',
    total = null,
    actions = []
  } = props
  return (
    <div className="admin-header mb-m">
      <div className="admin-header__left">
        <h1 className="admin-header__left--title">
          {title}
        </h1>
        {![null, undefined, ''].includes(total) ?
          <p className="admin-header__left--subtitle">
            Tổng số: {total}
          </p>
          : null}
      </div>
      <div className="admin-header__right">
        <Space size={[8, 8]}>
          {
            actions.filter((a) => !a.hide).map((a) =>
              <Button
                key={a.key}
                type={a.type}
                disabled={a.disabled}
                onClick={() => a.click()}
                icon={a.icon}
              >
                {a.label}
              </Button>
            )
          }
        </Space>
      </div>
    </div>
  );
}

export default AdminHeader;