import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import salesService from "../../../services/invest/sales";
// import ModalSavingsTransactionDetail from "./components/modals/ModalSavingsTransactionDetail";
import {convertStringToQuery} from "../../../utils/common";
const query = convertStringToQuery(window.location.search);
import dayjs from 'dayjs';
import moment from 'moment';
import CONSTANTS from '../../../config/constants_invest'
import SalesmanList from './SalesmanList';
import {useTranslation} from "react-i18next";

const Salesman = (props) => {
    const {router} = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [formVisible, setFormVisible] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [params, setParams] = useState({
        keyword: '',
        role_status: CONSTANTS.SALES_STATUS?.[1]?.value || 0,
        role_type: 0,
        page: 1,
        size: 20,
    });

    const { t } = useTranslation();
    const texts = t;

    // useEffect(() => {
    //     fetchData();
    // }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);
        console.log('params', params);
        console.log('dates[0]', params?.dates?.[0]);
        await salesService
            .salesman_list({
                ...params,
                page: params.page - 1,
            })
            .then((response) => {
                setData(response?.data || []);
                setTotal(response?.page?.total_elements || 0);
            })
            .catch((err) => {
                console.log('error', err);
                setData([]);
            });
        setLoading(false);
    };

    const handleShowDetail = (item = null) => {
        console.log('item', item);
        window.navigatePage(
            'SALESMAN_DETAIL',
            {id: item.accountId},
            {...query}
        )
        // setSelectedTransaction(transaction);
        // setFormVisible(true)
    };

    return (
        <div className="cms-users">
            <AdminHeader
                title={router.label}
                total={total}
                // actions={[
                //     {
                //         key: "add",
                //         label: "Create",
                //         type: "primary",
                //         icon: <PlusOutlined/>,
                //         click: () => {
                //         },
                //     },
                // ]}
            />
            <SalesmanList />
        </div>
    );
};

export default Salesman;
