import React, {useState, useEffect} from "react";
import {Row, Col, Input, Select, DatePicker} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import savingsService from "../../../../services/invest/savings";
import {useTranslation} from "react-i18next";


const SelectProduct = (props) => {
    const {
        params = {},
        onChange = () => {}
    } = props;

    const { t } = useTranslation();
    const texts = t;

    const [selectValue, setSelectValue] = useState();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getListProduct();
    }, []);

    const getListProduct = async () => {
        await savingsService.savings_products({
            page: 0,
            pageSize: 10,
        })
            .then((response) => {
                let filterResponse = response?.data || [];
                let filterData = ['1T', '3T', '6T', '9T', '12T', '1200T'];
                filterResponse = filterResponse.filter(e => filterData.indexOf(e?.itemCode) > -1);
                console.log('filterResponse', filterResponse);
                setOptions(filterResponse);
            })
            .catch(() => {
                setOptions([]);
            });
    };

    return (
        <Select
            placeholder={texts('select_product')}
            value={selectValue}
            onChange={(value) => onChange(value)}
            showSearch={true}
            optionFilterProp="children"
            className="w-full"
            filterOption={(input, option) =>
                `${option.children}`.toLowerCase().includes(input.toLowerCase())
            }
        >
            {options.map((option) => (
                <Select.Option value={option?.rowId} key={option?.id}>
                    {texts('product')}: {option?.itemName}
                </Select.Option>
            ))}
        </Select>
    );
};

export default SelectProduct;