import React, { useEffect, useState } from "react";
import {
  Card,
} from 'antd';

import TableData from "./market/TableData";

import reportServices from '../../../../services/finblue/report';

import dayjs from 'dayjs';

const ReportMarket = (props) => {
  const { params } = props;

  const [getting, setGetting] = useState(false)
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [params])

  const fetchData = async () => {
    setGetting(true);
    await reportServices.report_market({
      begin: dayjs(dayjs(params.dates[0]).format('YYYY-MM-DD 00:00:00')).unix() * 1000,
      end: dayjs(dayjs(params.dates[1]).format('YYYY-MM-DD 23:59:59')).unix() * 1000,
    }).then((response) => {
      setData(response?.data || []);
    }).catch(() => {
      setData([])
    })
    setGetting(false);
  }

  return (
    <Card
      size="small"
      className="w-100"
      loading={getting}
      title={'Báo cáo vận hành Sàn thương mại điện tử'}
    >
      <TableData
        loading={getting}
        data={data}
      />
    </Card>
  );
}

export default ReportMarket;