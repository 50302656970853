import React, { useEffect, useState, useMemo } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Form,
  message,
  InputNumber,
  Image,
  Select
} from 'antd';

import {
} from "@ant-design/icons";

import {
  LENDER_NAME_RULES,
  EMAIL_RULES,
  LINK_RULES,
  BANK_ACCOUNT_NUMBER_RULES
} from "../../../../../config/rules";

import lenderServices from '../../../../../services/finblue/lender';
import bankServices from '../../../../../services/finblue/bank';

const LenderFormModal = (props) => {
  const [form] = Form.useForm();
  const logo = Form.useWatch('logo', form) || '';
  const bankNameId = Form.useWatch('bankNameId', form) || '';
  const bankBranchId = Form.useWatch('bankBranchId', form) || '';

  const [callingAPI, setCallingAPI] = useState(false);

  const [banks, setBanks] = useState([]);
  const [bankBranchs, setBankBranchs] = useState([]);

  const {
    lender = null,
    id,
    visible = false,
    onClose = () => { },
    reload = () => { }
  } = props

  useEffect(() => {
    handleSearchBanks();
  }, [])

  useEffect(() => {
    if (bankNameId) {
      handleSearchBankBranchs();
    } else {
      setBankBranchs([])
    }
  }, [bankNameId])

  useEffect(() => {
    if (lender) {
      form.setFieldsValue({
        ...lender
      })
    } else {
      form.setFieldsValue({
        name: null,
        bankAccountNumber: null,
        bankAccountName: null,
        bankNameId: null,
        bankBranchId: null,
        email: null,
        address: null,
        surcharge: 1,
        logo: null
      })
    }
  }, [lender])

  const selectedBank = useMemo(() => {
    return banks.find((b) => b.id == bankNameId)
  }, [banks, bankNameId])

  const selectedBankBranch = useMemo(() => {
    return bankBranchs.find((b) => b.id == bankBranchId)
  }, [bankBranchs, bankBranchId])

  const handleSubmit = () => {
    form.validateFields().then(async (valid) => {
      if (valid) {
        setCallingAPI(true);
        const values = {
          ...form.getFieldsValue(),
          bankName: selectedBank?.itemName || null,
          bankCode: selectedBank?.itemCode || null,
          bankBranchName: selectedBankBranch?.itemName || null,
        };
        if (lender) {
          await handleUpdate({
            id: lender.id,
            ...values
          })
        } else {
          await handleCreate(values)
        }
        setCallingAPI(false);
      }
    })
  }

  const handleCreate = async (data) => {
    await lenderServices.create(data).then(() => {
      message.success('Thêm công ty tài chính mới thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const handleUpdate = async (data) => {
    await lenderServices.update(data, { id }).then(() => {
      message.success('Cập thông tin công ty tài chính thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const handleSearchBanks = () => {
    bankServices.search_banks().then((response) => {
      setBanks(response.data)
    }).catch(() => {
      setBanks([])
    })
  }

  const handleSearchBankBranchs = () => {
    bankServices.search_bank_branchs(bankNameId).then((response) => {
      setBankBranchs(response.data);
    }).catch(() => {
      setBankBranchs([])
    })
  }

  return (
    <Modal
      title={lender ? "Chỉnh sửa thông tin công ty tài chính" : "Thêm mới công tài chính"}
      width={700}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => onClose()}
      okButtonProps={{
        loading: callingAPI
      }}
      cancelButtonProps={{
        disabled: callingAPI
      }}
      okText='Lưu'
      cancelText='Hủy'
    >
      <Form
        form={form}
        layout='vertical'
      >
        <Row
          justify={'left'}
          gutter={[16, 8]}
        >
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Tên công ty'}
              name={'name'}
              rules={LENDER_NAME_RULES}
            >
              <Input
                placeholder="Vui lòng nhập tên công ty tài chính"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Người đại diện'}
              name={'representative'}
              rules={[]}
            >
              <Input
                placeholder="Vui lòng nhập tên người đại diện"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
          <Col span={12} lg={12} sm={24} xs={24}>
            <Form.Item
              label={'Tài khoản ngân hàng'}
              name={'bankAccountNumber'}
              rules={[
                ...BANK_ACCOUNT_NUMBER_RULES
              ]}
            >
              <Input
                placeholder="Vui lòng nhập tài khoản"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
          <Col span={12} lg={12} sm={24} xs={24}>
            <Form.Item
              label={'Chủ tài khoản'}
              name={'bankAccountName'}
            >
              <Input
                placeholder="Vui lòng nhập chủ tài khoản"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
          <Col span={12} lg={12} sm={24} xs={24}>
            <Form.Item
              label={'Ngân hàng'}
              name={'bankNameId'}
            >
              <Select
                placeholder={"Vui lòng chọn ngân hàng"}
                disabled={callingAPI}
                showSearch={true}
                onChange={() => {
                  form.setFieldValue('bankBranchId', null)
                }}
                optionFilterProp="children"
                className="w-full"
                filterOption={(input, option) =>
                  `${option.children}`
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {banks.map((it) => (
                  <Select.Option
                    value={it.id}
                    key={it.id}
                  >
                    {it.itemName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} lg={12} sm={24} xs={24}>
            <Form.Item
              label={'Chi nhánh'}
              name={'bankBranchId'}
            >
              <Select
                placeholder={"Vui lòng chọn chi nhánh"}
                disabled={callingAPI}
                showSearch={true}
                optionFilterProp="children"
                className="w-full"
                filterOption={(input, option) =>
                  `${option.children}`
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {bankBranchs.map((it) => (
                  <Select.Option
                    value={it.id}
                    key={it.id}
                  >
                    {it.itemName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} lg={12} sm={24} xs={24}>
            <Form.Item
              label={'Email'}
              name={'email'}
              rules={EMAIL_RULES}
            >
              <Input
                placeholder="Vui lòng nhập email"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
          <Col span={12} lg={12} sm={24} xs={24}>
            <Form.Item
              label={'Hoa hồng (%/Tổng giá trị đơn hàng)'}
              name={'surcharge'}
              rules={[]}
            >
              <InputNumber
                className="w-full"
                disabled={callingAPI}
                formatter={v => `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={v => v.replace(/\$\s?|(,*)/g, '')}
                min={1}
                max={100}
                addonAfter={'%'}
              />
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              name={'address'}
              label={'Địa chỉ'}
              rules={[]}
            >
              <Input
                placeholder="Vui lòng nhập địa chỉ"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
          <Col lg={logo ? 18 : 24} sm={logo ? 18 : 24} xs={24}>
            <Form.Item
              label={'Logo Url'}
              name={'logo'}
              rules={LINK_RULES}
            >
              <Input
                placeholder="Vui lòng nhập link Logo"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
          {
            logo && <Col lg={6} sm={6} xs={12}>
              <Image
                src={logo}
                preview={true}
                style={{ width: '100%' }}
              />
            </Col>
          }
        </Row>
      </Form>
    </Modal>

  );
}

export default LenderFormModal;