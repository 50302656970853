import React, { useState, useEffect, useMemo } from "react";
import { Pagination, Modal, message } from 'antd';
import {
  PlusOutlined,
  ExclamationCircleFilled
} from "@ant-design/icons";
import { AdminHeader } from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import TransactionFormModal from "./components/modals/TransactionForm"
import productServices from '../../../services/finblue/product';

import { convertStringToQuery } from '../../../utils/common';

const ProductTransactions = (props) => {
  const {
    router,
    showHeader = true,
    productId,
    isReload,
    setDetailTotal = () => { },
    detailEdit = () => { }
  } = props
  const query = convertStringToQuery(window.location.search);
  const [loading, setLoading] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState({
    page: Number(query?.page || 1),
    pageSize: 10,
    keyword: query?.keyword || null,
    active: query?.active ? (query?.active == 'true' ? true : false) : '',
  });

  const payload = useMemo(() => {
    let p = {
      pageSize: params.pageSize,
      page: params.page - 1,
      keyword: params.keyword,
      active: params.active
    }
    if (productId) {
      p = {
        ...p,
        productId
      }
    }
    return p;
  }, [params, productId])

  useEffect(() => {
    const queryData = {
      page: params.page,
      keyword: params.keyword,
      active: params.active,
    }
    if (!productId) {
      fetchData();
      window.navigatePage('PRODUCT_TRANSACTIONS', {}, queryData)
    } else {
      fetchDetailData();
      window.navigatePage('PRODUCT_PACKAGE_DETAIL', { id: productId }, queryData)
    }
  }, [params, productId, payload, isReload])

  const handleChangePage = (page, pageSize) => {
    setParams({
      ...params,
      page,
      pageSize
    })
  };

  const fetchData = async () => {
    setLoading(true);
    await productServices.list_transactions(payload).then((response) => {
      setData(response?.data || []);
      setTotal(response?.page?.total_elements)
    }).catch(() => {
      setData([])
      setTotal(0)
    })
    setLoading(false);
  }

  const fetchDetailData = async () => {
    setLoading(true);
    await productServices.list_product_transactions(payload).then((response) => {
      setData(response?.data || []);
      setDetailTotal(response?.page?.total_elements)
    }).catch(() => {
      setData([])
      setDetailTotal(0)
    })
    setLoading(false);
  }

  const handleOpenFormModal = (product = null) => {
    setSelectedTransaction(product);
    setFormVisible(true)
  };

  const handleRemoveTransaction = (transaction) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xoá ?',
      okButtonProps: {
        loading: removing,
      },
      cancelButtonProps: {
        disabled: removing
      },
      onOk() {
        setRemoving(true);
        productServices.remove_product_transaction(transaction.id).then(() => {
          message.success('Xoá thành công')
          fetchDetailData();
          setRemoving(false);
        }).catch((error) => {
          message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
          setRemoving(false);
        });
      },
      onCancel() { },
    });
  }

  return (
    <div className="product-transactions">
      {
        showHeader && <AdminHeader
          title={router?.label}
          total={total}
          actions={[
            {
              key: 'add',
              label: 'Thêm mới',
              type: 'primary',
              icon: <PlusOutlined />,
              click: () => handleOpenFormModal()
            }
          ]}
        />
      }
      <Filter
        params={params}
        setParams={(v) => setParams({
          ...v,
          page: 1,
        })}
      />
      <TableData
        loading={loading}
        data={data}
        params={params}
        productId={productId}
        detailEdit={(v) => detailEdit(v)}
        edit={(v) => handleOpenFormModal(v)}
        remove={handleRemoveTransaction}
      />
      <Pagination
        className="pagination"
        current={params.page}
        pageSize={params.pageSize}
        total={total}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Tổng số ${total}`}
        onChange={handleChangePage}
      />
      {
        formVisible && <TransactionFormModal
          visible={formVisible}
          transaction={selectedTransaction}
          onClose={() => setFormVisible(false)}
          reload={() => fetchData()}
        />
      }
    </div>
  );
}

export default ProductTransactions;