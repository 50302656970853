import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Input,
    Modal,
    Form,
    Select,
    message,
    Card,
    Tag,
    Typography
} from 'antd';

import {
} from "@ant-design/icons";
import moment from 'moment';
import CONSTANTS from "../../../../../config/constants";
import { PROJECT_RULES } from "../../../../../config/rules";
// import productServices from '../../../../../services/finblue/product';
import customerService from "../../../../../services/invest/customer";
import {displayInvestProfitCapitalDeal, formatMoneyCurrency, getObjectStatus} from "../../../../../utils/common";
import CONSTANTS_INVEST from "../../../../../config/constants_invest";
import {useTranslation} from "react-i18next";

const { Paragraph, Text } = Typography;

const ModalSavingsTransactionDetail = (props) => {
    const [customer, setCustomer] = useState({});
    const [form] = Form.useForm();
    const {
        transaction = null,
        visible = false,
        onClose = () => {},
        reload = () => {}
    } = props;

    const { t } = useTranslation();
    const texts = t;

    const logBody = JSON.parse(transaction?.requestBody);
    // console.log('logBody', logBody);

    useEffect(() => {
        // console.log('transaction', transaction);
        // console.log('requestBody', JSON.parse(transaction?.requestBody));
        // fetchData();
    }, [transaction]);

    const [callingAPI, setCallingAPI] = useState(false);

    const fetchData = async () => {
        await customerService.customer_detail({
                account_id: transaction?.accId
            })
            .then((response) => {
                setCustomer(response?.data || {});
            })
            .catch(() => {
                setCustomer({});
            });
    };

    // const accountHref = `${window.location.origin}/customers/${customer?.rowId}`;
    const accountHref = `#`;

    return (
        <Modal
            title={"Chi tiết giao dịch"}
            width={700}
            open={visible}
            footer={null}
            // onOk={() => handleSubmit()}
            onCancel={() => onClose()}
            // okButtonProps={{
            //     loading: callingAPI
            // }}
            // cancelButtonProps={{
            //     disabled: callingAPI
            // }}
            // okText='Lưu'
            // okButtonProps={{ disabled: true, className: "modal-footer-hiden-button" }}
            // cancelText='Đóng'
        >
            <table className="table-transaction-detail">
                <tr>
                    <td className="transaction-detail-col-1">{texts('customer')}</td>
                    <td className="transaction-detail-col-2"><a href={accountHref}>{logBody?.accountName}</a></td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('phone_number')}</td>
                    <td className="transaction-detail-col-2"><a href={accountHref}>{logBody?.customerPhoneNumber}</a></td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('amount')}</td>
                    <td className="transaction-detail-col-2">{formatMoneyCurrency(logBody?.amount)}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('transaction_date')}</td>
                    <td className="transaction-detail-col-2">{moment(transaction?.createdAt).format('DD/MM/YYYY')}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('bank_account')}</td>
                    <td className="transaction-detail-col-2">{logBody?.accountName}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('bank_name')}</td>
                    <td className="transaction-detail-col-2">{logBody?.bankCode}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('bank_no')}</td>
                    <td className="transaction-detail-col-2">{logBody?.accountNo}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('request_id')}</td>
                    <td className="transaction-detail-col-2">
                        <a>{transaction?.requestId}</a>
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('request_status')}</td>
                    <td className="transaction-detail-col-2">
                        <Tag
                            size={'small'}
                            color={getObjectStatus(CONSTANTS_INVEST.FINAL_STATUS, transaction.status)?.color}>
                            {texts(getObjectStatus(CONSTANTS_INVEST.FINAL_STATUS, transaction?.status)?.label)}
                        </Tag>
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('request_url')}</td>
                    <td className="transaction-detail-col-2">
                        <Paragraph copyable>{transaction?.url}</Paragraph>
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('request_body')}</td>
                    <td className="transaction-detail-col-2">
                        <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }} copyable>{transaction?.requestBody}</Paragraph>
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('response')}</td>
                    <td className="transaction-detail-col-2">
                        <Paragraph copyable>{transaction?.response}</Paragraph>
                    </td>
                </tr>


            </table>


        </Modal>

    );
}

export default ModalSavingsTransactionDetail;