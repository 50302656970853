import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Select
} from 'antd';

import {
} from "@ant-design/icons";

import lenderServices from '../../../../../services/finblue/lender'
import businessServices from '../../../../../services/finblue/business'

const Filter = (props) => {
  const {
    params = {},
    setParams = () => { }
  } = props

  const [lenders, setLenders] = useState([]);
  const [business, setBusiness] = useState([]);

  useEffect(() => {
    fetchLenders();
    fetchBusiness();
  }, [])

  const fetchLenders = async (text = '') => {
    await lenderServices.list({
      pageSize: 10,
      page: 0,
      keyword: text,
      active: ''
    }).then((response) => {
      setLenders(response?.data || []);
    }).catch(() => {
      setLenders([])
    })
  }

  const fetchBusiness = async () => {
    await businessServices.list({}).then((response) => {
      setBusiness(response?.data || []);
    }).catch(() => {
      setBusiness([])
    })
  }

  return (
    <Row
      className="filter mt-0 mb-m"
      justify={'space-between'}
      gutter={[0, 8]}
    >
      <Col lg={20}>
        <Row justify={"left"} gutter={[8, 8]}>
          <Col>
            <Select
              placeholder={"Công ty tài chính"}
              className="filter-filed"
              value={params.lenderId}
              onChange={(v) => setParams({ ...params, lenderId: v })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                `${option.children}`.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Select.Option
                value={''}
                key={'all'}
              >
                Tất cả
              </Select.Option>
              {lenders.map((lender) => (
                <Select.Option value={lender.id} key={lender.id}>
                  {lender.name}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col>
            <Select
              placeholder={"Hình thức vay"}
              className="filter-filed"
              value={params.businessId}
              onChange={(v) => setParams({ ...params, businessId: v })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                `${option.children}`.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Select.Option
                value={''}
                key={'all'}
              >
                Tất cả
              </Select.Option>
              {business.map((b) => (
                <Select.Option value={b.id} key={b.id}>
                  {b.businessType}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Col>
      <Col align="right">
      </Col>
    </Row>
  );
}

export default Filter;