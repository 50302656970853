import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import userServices from "../../../services/admin/user";
import customerServices from "../../../services/invest/customer";

const EkycApprove = (props) => {
    const {router} = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [params, setParams] = useState({
        page: 1,
        size: 10,
    });

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);
        await customerServices
            .get_account_kyc_error({
                ...params,
                page: params.page - 1,
                size: params.size,
            })
            .then((response) => {
                setData(response?.data || []);
                setTotal(response?.page?.total_elements);
            })
            .catch(() => {
                setData([]);
            });
        setLoading(false);
    };

    return (
        <div className="cms-users">
            <AdminHeader
                title={router.label}
                total={total}
            />
            <Filter params={params} setParams={setParams}/>
            <TableData loading={loading} data={data} params={params}/>

            <Pagination
                className="pagination"
                current={params.page}
                pageSize={params.size}
                total={total}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `Tổng số ${total}`}
                onChange={handleChangePage}
            />
        </div>
    );
};

export default EkycApprove;
