import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function search(params) {
  return request({
    url: ENDPOINT.LOAN_SEARCH,
    method: "get",
    params,
  });
}

function lender_search(params) {
  return request({
    url: ENDPOINT.LOAN_LENDER_SEARCH,
    method: "get",
    params,
  });
}

function market_search(params) {
  return request({
    url: ENDPOINT.LOAN_MARKET_SEARCH,
    method: "get",
    params,
  });
}

function filter(params) {
  return request({
    url: ENDPOINT.LOAN_FILTER,
    method: "get",
    params,
  });
}

function get_statistics(params = {}) {
  return request({
    url: ENDPOINT.LOAN_STATISTIC,
    method: "get",
    params,
  });
}

function get_lender_statistics(params = {}) {
  return request({
    url: ENDPOINT.LOAN_LENDER_STATISTIC,
    method: "get",
    params,
  });
}

function get_market_statistics(params = {}) {
  return request({
    url: ENDPOINT.LOAN_MARKET_STATISTIC,
    method: "get",
    params,
  });
}

function get_customer_statistics(params = {}) {
  return request({
    url: ENDPOINT.LOAN_CUSTOMER_STATISTIC,
    method: "get",
    params,
  });
}

function get(id, params = {}) {
  return request({
    url: ENDPOINT.LOAN_DETAIL.replace(':id', id),
    method: "get",
    params,
  });
}

function get_info_order(id, params = {}) {
  return request({
    url: ENDPOINT.LOAN_INFO_ORDER.replace(':id', id),
    method: "get",
    params,
  });
}

function get_loan_info(id, params = {}) {
  return request({
    url: ENDPOINT.LOAN_INFO.replace(':id', id),
    method: "get",
    params,
  });
}

function get_review_history(id, params = {}) {
  return request({
    url: ENDPOINT.LOAN_REVIEW_HISTORY.replace(':id', id),
    method: "get",
    params,
  });
}

function approved(data = {}) {
  return request({
    url: ENDPOINT.LOAN_APPROVED,
    method: "post",
    data,
  });
}

function lender_approved(data = {}, params = {}) {
  return request({
    url: ENDPOINT.LOAN_LENDER_APPROVED,
    method: "post",
    data,
    params,
  });
}

function verify(data = {}) {
  return request({
    url: ENDPOINT.LOAN_VERIFY,
    method: "post",
    data,
  });
}

function lender_verify(data = {}) {
  return request({
    url: ENDPOINT.LOAN_LENDER_VERIFY,
    method: "post",
    data,
  });
}

function ecommerce_verify(params = {}) {
  return request({
    url: ENDPOINT.LOAN_ECOMMERCE_VERIFY,
    method: "post",
    params,
  });
}

export default {
  search,
  lender_search,
  market_search,
  filter,
  get_statistics,
  get_lender_statistics,
  get_market_statistics,
  get_customer_statistics,
  get,
  get_info_order,
  get_loan_info,
  get_review_history,
  approved,
  lender_approved,
  verify,
  lender_verify,
  ecommerce_verify
};
