import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Tabs, Spin} from "antd";
import CustomerTransactionTab from "./customer_transaction_tab";

const CustomerAmberTab = (props) => {
    const {customer} = props;

    return (
        <Tabs
            defaultActiveKey={"CUSTOMER-INFO"}
            className="tab-info"
            items={[
                {
                    label: 'Lịch sử giao dịch',
                    key: 'CUSTOMER-ORDER',
                    children: <CustomerTransactionTab customer={customer}/>
                },
            ]}
        />
    );
};

export default CustomerAmberTab;
