import React from "react";
import {
  Row,
  Table,
  Col,
  Button,
  Image
} from 'antd';

import {
  EditOutlined
} from "@ant-design/icons";

import {
} from '../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    params,
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Mã CTTC',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 150,
      render: (_, record) => `CTTC_${record.id}`
    },
    {
      title: 'Tên CTTC',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
      render: (_, record) => <a>
        {record.name}
      </a>
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: 200,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'left',
      width: 200,
    },
    {
      title: 'Logo',
      dataIndex: 'logo',
      key: 'logo',
      align: 'left',
      width: 200,
      render: (_, record) => <Image
        src={record.logo}
        height={56}
      />
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      align: 'left',
      width: 200,
    },
    {
      title: 'Phí thanh toán (%)',
      dataIndex: 'surcharge',
      key: 'surcharge',
      align: 'center',
      width: 200,
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <Row gutter={[8, 8]} justify={'center'}>
          <Col>
            <Button
              icon={<EditOutlined />}
              type='primary'
              size="small"
              ghost
              onClick={() => window.navigatePage('LENDER_DETAIL', { id: record?.id })}
            >Chi tiết</Button>
          </Col>
        </Row>
      ),
    },
  ]

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 + (params.page - 1) * params.pageSize }))}
      loading={loading}
      pagination={false}
      scroll={{ x: 1024 }}
      rowKey={(record) => record?.id}
      size="small"
    />
  );
}

export default TableData;