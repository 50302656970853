import React, { useEffect, useState } from "react";
import {
  Row,
  Card
} from 'antd';

import { PaymentScheduleDetails } from '../../../../../components'
import paymentServices from '../../../../../services/finblue/payment';
import SettlementModal from "../../components/modals/Settlement";

const HistorySchedule = (props) => {
  const {
    loading = false,
    loanInfo = {},
    params = {},
    onReload = () => { }
  } = props

  const [getting, setGetting] = useState(false)
  const [historySchedule, setHistorySchedule] = useState([])
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const [settlementVisible, setSettlementVisible] = useState(false);

  useEffect(() => {
    getLoanHistorySchedule()
  }, [])

  const getLoanHistorySchedule = () => {
    setGetting(true)
    paymentServices.list_loan_history_schedule(params?.id).then((response) => {
      setHistorySchedule(response.data?.paymentResponses)
      setGetting(false)
    }).catch(() => {
      setHistorySchedule([])
      setGetting(false)
    })
  }

  return (
    <Card
      size="small"
      className="w-100 mb-m"
      loading={loading || getting}
      title={'Lịch thanh toán'}
    >
      <Row gutter={[16, 8]}>
        <PaymentScheduleDetails
          loading={loading || getting}
          bodered={false}
          data={historySchedule}
          settlement={(v) => {
            setSelectedSchedule(v);
            setSettlementVisible(true);
          }}
        />
      </Row>
      {
        settlementVisible && <SettlementModal
          visible={settlementVisible}
          loanInfo={loanInfo}
          selectedSchedule={selectedSchedule}
          onClose={() => setSettlementVisible(false)}
          onReload={() => {
            getLoanHistorySchedule();
            onReload();
          }}
        />
      }
    </Card>
  );
}

export default HistorySchedule;