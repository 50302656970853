import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function get_report_debts(params = {}) {
  return request({
    url: ENDPOINT.REPORT_DEBTS,
    method: "get",
    params,
  });
}

function report_debt_orders(params = {}) {
  return request({
    url: ENDPOINT.REPORT_DEBTS_CUSTOMER_ORDERS,
    method: "get",
    params,
  });
}

export default {
  get_report_debts,
  report_debt_orders
};
