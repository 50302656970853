import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Select,
  DatePicker
} from 'antd';

import {
  SearchOutlined
} from "@ant-design/icons";

import CONSTANTS from '../../../../config/constants'
import businessServices from '../../../../services/finblue/business'

const Filter = (props) => {
  const {
    params = {},
    setParams = () => { }
  } = props

  const [business, setBusiness] = useState([])
  const [businessType, setBusinessType] = useState(null)
  const [businessChild, setBusinessChild] = useState([])

  useEffect(() => {
    fetchBusiness()
  }, [])

  useEffect(() => {
    if (businessType) {
      setParams({ ...params, type: null })
      fetchBusinessChild()
    }
  }, [businessType])

  const fetchBusiness = () => {
    businessServices.list().then((response) => {
      setBusiness(response.data || [])
    }).catch(() => {
      setBusiness([])
    })
  }

  const fetchBusinessChild = () => {
    businessServices.list_child({ businessId: businessType }).then((response) => {
      setBusinessChild(response.data || [])
    }).catch(() => {
      setBusinessChild([])
    })
  }

  return (
    <Row
      className="filter"
      justify={'space-between'}
      gutter={[0, 8]}
    >
      <Col>
        <Row
          justify={'left'}
          gutter={[8, 8]}
        >
          <Col>
            <Input
              className="filter-filed"
              prefix={<SearchOutlined />}
              value={params.keyword}
              placeholder="Nhập thông tin tìm kiếm..."
              onChange={(e) => setParams({ ...params, keyword: e.target.value })}
            />
          </Col>
          <Col>
            <Select
              placeholder={'Loại sản phẩm'}
              className="filter-filed"
              value={businessType || null}
              onChange={(v) => setBusinessType(v)}
            >
              {business.map((type) => (
                <Select.Option
                  value={type.id}
                  key={type.id}
                >
                  {type.businessType}
                </Select.Option>
              ))}
            </Select>
          </Col>
          {
            !!businessType && <Col>
              <Select
                placeholder={'Loại hoá đơn'}
                className="filter-filed"
                value={params.type || null}
                onChange={(v) => setParams({ ...params, type: v })}
              >
                {businessChild.map((type) => (
                  <Select.Option
                    value={type.id}
                    key={type.id}
                  >
                    {type.businessType}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          }
          <Col>
            <Select
              placeholder={'Trạng thái'}
              className="filter-filed"
              value={params.status || ''}
              onChange={(v) => setParams({ ...params, status: v })}
            >
              <Select.Option
                value={''}
                key={'all'}
              >
                Tất cả
              </Select.Option>
              {CONSTANTS.APPOTA_STATUS.filter((s) => [0, 1].includes(s.value)).map((s, index) => (
                <Select.Option
                  value={s.value}
                  key={index}
                >
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Col>
      <Col align="right">
        <Row
          justify={'end'}
          gutter={[8, 8]}
        >
          <Col span={24} lg={24} sm={24} xs={24}>
            <DatePicker.RangePicker
              className="filter-filed"
              format={CONSTANTS.DATE_FORMAT}
              value={params?.dates || []}
              onChange={(v) => setParams({ ...params, dates: v })}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Filter;