export default {
  // Gói sản phẩm
  VIEW_PRODUCTS: 'VIEW_PRODUCTS',
  VIEW_PRODUCT_DETAIL: 'VIEW_PRODUCT_DETAIL',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  CREATE_PRODUCT_CREDIT: 'CREATE_PRODUCT_CREDIT',
  UPDATE_PRODUCT_CREDIT: 'UPDATE_PRODUCT_CREDIT',
  DELETE_PRODUCT_CREDIT: 'DELETE_PRODUCT_CREDIT',
  VIEW_PRODUCT_CREDITS: 'VIEW_PRODUCT_CREDITS',

  // Hạn mức
  VIEW_CREDITS: 'VIEW_CREDITS',
  VIEW_CREDIT_DETAIL: 'VIEW_CREDIT_DETAIL',
  CREATE_CREDIT: 'CREATE_CREDIT',
  UPDATE_CREDIT: 'UPDATE_CREDIT',

  // Phê duyệt hạn mức
  VIEW_CONTRACTS: 'VIEW_CONTRACTS',
  VIEW_CONTRACT_DETAIL: 'VIEW_CONTRACT_DETAIL',
  VIEW_ID_CONTRACT: 'VIEW_ID_CONTRACT',
  VERIFY_FIELD_CONTRACT: 'VERIFY_FIELD_CONTRACT',
  VERIFY_CONTRACT: 'VERIFY_CONTRACT',
  VIEW_PRODUCT_OF_CONTRACT: 'VIEW_PRODUCT_OF_CONTRACT',
  VIEW_CREDITS_V2: 'VIEW_CREDITS_V2',

  // Phê duyệt đơn vay
  VIEW_LOANS: 'VIEW_LOANS',
  VIEW_LOAN_ORDER: 'VIEW_LOAN_ORDER',
  VIEW_LOAN: 'VIEW_LOAN',
  VIEW_LOAN_HISTORY: 'VIEW_LOAN_HISTORY',
  APPROVED_LOAN: 'APPROVED_LOAN',
  VERIFY_LOAN: 'VERIFY_LOAN',
  LENDER_APPROVED_LOAN: 'LENDER_APPROVED_LOAN',
  LENDER_VERIFY_LOAN: 'LENDER_VERIFY_LOAN',
  REPLACE_LENDER_VERIFY_LOAN: 'REPLACE_LENDER_VERIFY_LOAN',
  UPDATE_LOAN_APPOTA: 'UPDATE_LOAN_APPOTA',
  MARKET_VERIFY_LOAN: 'MARKET_VERIFY_LOAN',

  // Duyệt EKYC
  VIEW_EKYC: 'VIEW_EKYC',
  STATISTIC_EKYC: 'STATISTIC_EKYC',
  APPROVED_EKYC: 'APPROVED_EKYC',

  // Gạch nợ
  VIEW_LOANS_CUSTOMER: 'VIEW_LOANS_CUSTOMER',
  VIEW_PAYMENT_SCHEDULE_ORDER: 'VIEW_PAYMENT_SCHEDULE_ORDER',
  VIEW_PAYMENT_SCHEDULE: 'VIEW_PAYMENT_SCHEDULE',
  DEBT_BRICK: 'DEBT_BRICK',
  VIEW_PAYMENT_HISTORY_CUSTOMER: 'VIEW_PAYMENT_HISTORY_CUSTOMER',
  VIEW_PAYMENT_HISTORYS_ORDER: 'VIEW_PAYMENT_HISTORYS_ORDER',
  VIEW_PAYMENT_SCHEDULES_ORDER: 'VIEW_PAYMENT_SCHEDULES_ORDER',
  VIEW_LIST_PAYMENT_CUSTOMER: 'VIEW_LIST_PAYMENT_CUSTOMER',
  VIEW_RECEIPT_SCHEDULE: 'VIEW_RECEIPT_SCHEDULE',

  // Sàn TMĐT
  VIEW_MARKETS: 'VIEW_MARKETS',
  VIEW_MARKET_PROFILE: 'VIEW_MARKET_PROFILE',
  CREATE_MARKET: 'CREATE_MARKET',
  UPDATE_MARKET: 'UPDATE_MARKET',
  DELETE_MARKET: 'DELETE_MARKET',
  VIEW_MARKET_ORDER: 'VIEW_MARKET_ORDER',
  STATISTIC_ORDER_MARKET: 'STATISTIC_ORDER_MARKET',
  VIEW_LOANS_MARKET: 'VIEW_LOANS_MARKET',

  // Công ty Tài chính
  VIEW_BUSINESS: 'VIEW_BUSINESS',
  VIEW_SUB_BUSINESS: 'VIEW_SUB_BUSINESS',
  VIEW_LENDER: 'VIEW_LENDER',
  VIEW_LENDER_PROFILE: 'VIEW_LENDER_PROFILE',
  VIEW_LENDER_ORDER: 'VIEW_LENDER_ORDER',
  STATISTIC_LENDER_ORDER_BUSINESS: 'STATISTIC_LENDER_ORDER_BUSINESS',
  STATISTIC_LENDER_ORDER: 'STATISTIC_LENDER_ORDER',
  UPDATE_LENDER: 'UPDATE_LENDER',
  CREATE_LENDER: 'CREATE_LENDER',
  DELETE_LENDER: 'DELETE_LENDER',
  VIEW_LOANS_LENDER: 'VIEW_LOANS_LENDER',

  // Ngân hàng
  VIEW_BANK: 'VIEW_BANK',
  VIEW_BANK_BRANCH: 'VIEW_BANK_BRANCH',
  VIEW_FINANCES: 'VIEW_FINANCES',
  CREATE_FINANCE_SYSTEM: 'CREATE_FINANCE_SYSTEM',
  UPDATE_FINANCE_SYSTEM: 'UPDATE_FINANCE_SYSTEM',
  DELETE_FINANCE_SYSTEM: 'DELETE_FINANCE_SYSTEM',
  VIEW_FINANCES_SYSTEM: 'VIEW_FINANCES_SYSTEM',
  VIEW_DETAIL_FINANCE_SYSTEM: 'VIEW_DETAIL_FINANCE_SYSTEM',
  VIEW_RECEIPTS_SYSTEM: 'VIEW_RECEIPTS_SYSTEM',

  // Khách hàng
  VIEW_CONTRACT_CUSTOMER: 'VIEW_CONTRACT_CUSTOMER',
  STATISTIC_CONTRACT: 'STATISTIC_CONTRACT',
  STATISTIC_CONTRACT_CUSTOMER: 'STATISTIC_CONTRACT_CUSTOMER',
  UPDATE_CONTRACT: 'UPDATE_CONTRACT',
  VIEW_PRODUCT_CONTRACT: 'VIEW_PRODUCT_CONTRACT',
  VIEW_FINANCE_CUSTOMER: 'VIEW_FINANCE_CUSTOMER',
  STATISTIC_LOAN: 'STATISTIC_LOAN',
  STATISTIC_LOAN_CUSTOMER: 'STATISTIC_LOAN_CUSTOMER',
  VIEW_PAYMENT_SCHEDULE_CUSTOMER: 'VIEW_PAYMENT_SCHEDULE_CUSTOMER',
  VIEW_CUSTOMER_PROFILE: 'VIEW_CUSTOMER_PROFILE',
  VIEW_LIST_CUSTOMER: 'VIEW_LIST_CUSTOMER',
  STATISTIC_CUSTOMER: 'STATISTIC_CUSTOMER',
  UPDATE_RANK_CUSTOMER: 'UPDATE_RANK_CUSTOMER',

  // Dashboard
  VIEW_REPORT_LENDER: 'VIEW_REPORT_LENDER',
  VIEW_REPORT_MARKET: 'VIEW_REPORT_MARKET',
  VIEW_REPORT_ORDER: 'VIEW_REPORT_ORDER',
  VIEW_REPORT_ORDER_MONEY: 'VIEW_REPORT_ORDER_MONEY',
  VIEW_REPORT_CUSTOMER: 'VIEW_REPORT_CUSTOMER',
  VIEW_REPORT_SYSTEM: 'VIEW_REPORT_SYSTEM',
  VIEW_REPORT_DEBT_CUSTOMER: 'VIEW_REPORT_DEBT_CUSTOMER',
  VIEW_REPORT_DEBT_CUSTOMER_ORDER: 'VIEW_REPORT_DEBT_CUSTOMER_ORDER',

  // Thẩm định viên
  LIST_ASSESSOR: 'LIST_ASSESSOR',
  VIEW_ASSESSOR_EMPLOYEE: 'VIEW_ASSESSOR_EMPLOYEE',
  LIST_ASSESSOR_ORDERS: 'LIST_ASSESSOR_ORDERS',
  LIST_ASSESSOR_CONTRACTS: 'LIST_ASSESSOR_CONTRACTS',
  LIST_ASSESSOR_WORKS: 'LIST_ASSESSOR_WORKS',
  ASSIGN_ASSESSOR: 'ASSIGN_ASSESSOR',
  ASSIGN_ASSESSOR_DIVIDE_EQUALLY: 'ASSIGN_ASSESSOR_DIVIDE_EQUALLY',

  // Đối soát
  LOG_APPOTA: 'LOG_APPOTA',
  LOG_EKYC: 'LOG_EKYC'
}