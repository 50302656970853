import { combineReducers } from "redux";
import system from "./system";
import userInfo from "./userInfo";

const rootReducer = combineReducers({
  system,
  userInfo
});

export default rootReducer;
