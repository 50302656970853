import React, { useEffect, useMemo, useState } from 'react';
import { Menu } from 'antd';
import './css/Sidebar.scss';
import { useLocation } from 'react-router-dom';

import { getRoutersByLocation, getMenuByLocation } from "../../utils/common";

function Sidebar(props) {
  const location = useLocation();

  const handleMenuClick = (menu) => {
    window.navigatePage(menu.key);
  }
  const selectedKey = useMemo(() => {
    if (location) {
      const routers = getRoutersByLocation(location).filter((r) => !r.parent);
      return routers[routers.length - 1]?.name
    }
  }, [location])

  return (
    <div className={props.className}>
      <Menu
        defaultOpenKeys={[getMenuByLocation(location)?.key]}
        selectedKeys={[selectedKey]}
        mode="inline"
        collapsed={props.collapsed.toString()}
        items={props.menus}
        onClick={handleMenuClick}
        style={{backgroundColor: 'transparent'}}
      />
    </div>
  );
}

export default Sidebar;
