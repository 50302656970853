import React from "react";
import {
  Table,
  Button,
  Space
} from 'antd';

import {
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import { PERMISSIONS } from "../../../../config/permissions";
import { isPermission } from "../../../../utils/common";

import {
  convertDateTime,
} from '../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    params,
    edit = () => { },
    onDelete = () => { }
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Tên chức vụ',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      width: 200,
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      width: 120,
      render: (_, record) => convertDateTime(record.createdDate)
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: 120,
      hide: !isPermission([PERMISSIONS.UPDATE_POSITION]) && !isPermission([PERMISSIONS.DELETE_POSITION]),
      render: (_, record) => (
        <Space size={[8, 8]} wrap>
          {
            isPermission([PERMISSIONS.UPDATE_POSITION]) && <Button
              icon={<EditOutlined />}
              type='primary'
              size="small"
              ghost
              onClick={() => edit(record)}
            >
              Sửa
            </Button>
          }
          {
            isPermission([PERMISSIONS.DELETE_POSITION]) && <Button
              icon={<DeleteOutlined />}
              ghost
              danger
              type='primary'
              size="small"
              onClick={() => onDelete(record)}
            >
              Xoá
            </Button>
          }
        </Space>
      ),
    },
  ].filter((c) => !c.hide)

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 + (params.page - 1) * params.pageSize }))}
      loading={loading}
      pagination={false}
      scroll={{ x: 1024 }}
      rowKey={(record) => record?.id}
      size="small"
    />
  );
}

export default TableData;