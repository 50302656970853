import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function list_loan_history_schedule(loan_id, params = {}) {
  return request({
    url: ENDPOINT.PAYMENT_LOAN_HISTORY_SCHEDULE.replace(':loan_id', loan_id),
    method: "get",
    params,
  });
}

function loan_debt_brick_schedule(data = {}) {
  return request({
    url: ENDPOINT.PAYMENT_LOAN_DEBT_BRICK,
    method: "post",
    data,
  });
}

function list_payment_customer(params = {}) {
  return request({
    url: ENDPOINT.PAYMENT_CUSTOMER,
    method: "get",
    params,
  });
}

export default {
  list_loan_history_schedule,
  loan_debt_brick_schedule,
  list_payment_customer
};
