import React, { useEffect, useState, useMemo } from "react";
import {
  Row,
  Col,
  Card
} from 'antd';

import reportServices from '../../../../services/finblue/report';
import { formatCurrency, formatNumber } from '../../../../utils/common';

import dayjs from 'dayjs';

const ReportSystem = (props) => {
  const {
    params = {},
  } = props

  const [getting, setGetting] = useState(false)
  const [data, setData] = useState({});

  useEffect(() => {
    fetchData();
  }, [params])

  const fetchData = async () => {
    setGetting(true);
    await reportServices.report_system({
      begin: dayjs(dayjs(params.dates[0]).format('YYYY-MM-DD 00:00:00')).unix() * 1000,
      end: dayjs(dayjs(params.dates[1]).format('YYYY-MM-DD 23:59:59')).unix() * 1000,
    }).then((response) => {
      setData(response?.data);
    }).catch(() => {
      setData({})
    })
    setGetting(false);
  }

  const reportData = useMemo(() => {
    return [
      {
        label: 'Khách hàng',
        value: <div className="">
          <p>Premium: <b className="text-bold">{formatNumber(data.premium)}</b></p>
          <p>Standard: <b className="text-bold">{formatNumber(data.standard)}</b></p>
        </div>
      },
      {
        label: 'Dự nợ hệ thống',
        value: <div className="">
          <p><b className="text-bold">{formatCurrency(data.totalMoney - data.totalPaid)}</b></p>
        </div>
      },
      {
        label: 'Phí nền tảng (%/năm)',
        value: <div className="">
          <p>Đã thu: <b className="text-bold">{formatCurrency(data.totalSurchargePaid)}</b></p>
          <p>Chưa thu : <b className="text-bold">{formatCurrency(data.totalSurcharge)}</b></p>
        </div>
      },
      {
        label: 'Đơn chờ phê duyệt',
        value: <div className="">
          <p><b className="text-bold">{formatNumber(data.totalOrderWaitDisburse)}</b></p>
        </div>
      },
    ]
  }, [data])

  return (
    <Row
      className="report-system mb-m"
      justify={'left'}
      gutter={[16, 16]}
    >
      {
        reportData.map((s, index) => <Col key={index} lg={6} sm={6} xs={8}>
          <Card
            size="small"
            loading={getting}
            className="report-system__item cursor-pointer w-100"
            bodyStyle={{
              padding: 12,
              textAlign: 'center',
            }}
          >
            <div className="report-system__item--label">{s.label}</div>
            <div className="report-system__item--value">{s.value}</div>
          </Card>
        </Col>)
      }
    </Row>
  );
}

export default ReportSystem;