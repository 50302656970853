import React from "react";
import {
  Row,
  Table,
  Button,
  Tag
} from 'antd';

import {
  EditOutlined
} from "@ant-design/icons";

import {
  convertDateTime,
  formatCurrency,
  contractStatus,
  convertStringToQuery
} from '../../../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    params
  } = props

  const query = convertStringToQuery(window.location.search);

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Tag color={contractStatus(record.status)?.tag_color}>
          {contractStatus(record.status)?.label}
        </Tag>
      ),
    },
    {
      title: 'Mã hợp đồng',
      dataIndex: 'contractId',
      key: 'contractId',
      align: 'center',
      width: 120,
      render: (_, record) => `HĐ_${record.contractId}`
    },
    {
      title: 'Mã khách hàng',
      dataIndex: 'customerId',
      key: 'customerId',
      align: 'left',
      width: 120,
      render: (_, record) => `KH_${record.customerId}`
    },
    {
      title: 'Họ tên',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: 120,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdTime',
      key: 'createdTime',
      align: 'center',
      width: 200,
      render: (_, record) => convertDateTime(record.createdTime)
    },
    {
      title: 'Hạn mức',
      dataIndex: 'amountOrder',
      key: 'amountOrder',
      align: 'right',
      width: 200,
      render: (_, record) => formatCurrency(record.amountOrder)
    },
    {
      title: 'Điểm tín dụng',
      dataIndex: 'score',
      key: 'score',
      align: 'left',
      width: 200,
    },
    {
      title: 'Chấm điểm bởi',
      dataIndex: 'scoreBy',
      key: 'scoreBy',
      align: 'left',
      width: 200,
    },
    {
      title: 'Thời gian phê duyệt',
      dataIndex: 'approvedTime',
      key: 'approvedTime',
      align: 'center',
      width: 200,
      render: (_, record) => convertDateTime(record.approvedTime)
    },
    {
      title: 'Người phê duyệt',
      dataIndex: 'approvedName',
      key: 'approvedName',
      align: 'left',
      width: 200,
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (_, record) => (
        <Row gutter={[0, 8]}>
          <Button
            icon={<EditOutlined />}
            type='primary'
            size="small"
            ghost
            onClick={() => window.navigatePage(
              'LIMIT_APPROVAL_DETAIL',
              { id: record.contractId },
              {
                ...query,
                customerId: record.customerId
              }
            )}
          >Chi tiết</Button>
        </Row>
      ),
    },
  ]

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 + (params.page - 1) * params.pageSize }))}
      loading={loading}
      pagination={false}
      scroll={{ x: 1024 }}
      rowKey={(record) => record?.contractId}
      size="small"
    />
  );
}

export default TableData;