import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints";

import systemServices from '../../services/system';

const cmsInfo = systemServices.cms_info();

function search_users(params = {}) {
  return request({
    url: ENDPOINT.USERS,
    method: "get",
    params: {
      ...params,
      system: cmsInfo?.value
    },
  });
}

function get_user(id, params = {}) {
  return request({
    url: ENDPOINT.USER_DETAIL.replace(':id', id),
    method: "get",
    params: {
      ...params,
      system: cmsInfo?.value
    },
  });
}

function create_user(params, data = {}) {
  return request({
    url: ENDPOINT.USER,
    method: "post",
    params,
    data: {
      ...data,
      system: cmsInfo?.value
    }
  });
}

function update_user(id, data) {
  return request({
    url: ENDPOINT.USER_DETAIL.replace(':id', id),
    method: "put",
    data
  });
}

function remove_user(id) {
  return request({
    url: ENDPOINT.USER_DETAIL.replace(':id', id),
    method: "delete"
  });
}


export default {
  search_users,
  get_user,
  create_user,
  update_user,
  remove_user
};
