import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import ProductFormModal from "./components/modals/ProductForm";
import fmkService from "../../../services/invest/fmarket";

const ProductSavings = (props) => {
    const {router} = props;
    const [loading, setLoading] = useState(false);
    const [formVisible, setFormVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [params, setParams] = useState({
        page: 1,
        size: 20,
    });

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);
        await fmkService.fmarket_funds({
                ...params,
                page: params.page - 1,
                pageSize: params.size,
            })
            .then((response) => {
                // let filterResponse = response?.data || [];
                // let filterData = ['1T', '3T', '6T', '9T', '12T'];
                // filterResponse = filterResponse.filter(e => filterData.indexOf(e?.itemCode) > -1);
                // console.log('filterResponse', filterResponse);
                setData(response?.data || []);
                setTotal(response?.data?.length || 0);
            })
            .catch(() => {
                setData([]);
            });
        setLoading(false);
    };

    const handleOpenFormModal = (product = null) => {
        setSelectedProduct(product);
        setFormVisible(true)
    };

    return (
        <div className="cms-users">
            <AdminHeader
                title={router.label}
                total={total}
                actions={[
                    {
                        key: "add",
                        label: "Thêm mới",
                        type: "primary",
                        icon: <PlusOutlined/>,
                        click: () => {
                        },
                    },
                ]}
            />

            {/*<Filter params={params} setParams={setParams}/>*/}

            <TableData
                loading={loading}
                data={data}
                params={params}
                edit={(v) => handleOpenFormModal(v)}
            />

            <Pagination
                className="pagination"
                current={params.page}
                pageSize={params.size}
                total={total}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `Tổng số ${total}`}
                onChange={handleChangePage}
            />

            {
                formVisible && <ProductFormModal
                    visible={formVisible}
                    product={selectedProduct}
                    onClose={() => setFormVisible(false)}
                    reload={() => fetchData()}
                />
            }
        </div>
    );
};

export default ProductSavings;
