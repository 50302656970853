import React, { useEffect, useState } from "react";
import {
  Input,
  Modal,
  Form,
  Select
} from 'antd';

import {
} from "@ant-design/icons";

import lenderServices from '../../services/finblue/lender'

const Approval = (props) => {
  const [form] = Form.useForm();
  const {
    title = 'Duyệt đơn vay',
    visible = false,
    callingAPI = false,
    isSelectLender = false,
    onClose = () => { },
    submit = () => { },
  } = props

  const [lenders, setLenders] = useState([]);

  useEffect(() => {
    if (isSelectLender) {
      fetchLenders();
    }
  }, [isSelectLender])

  const fetchLenders = async (text = '') => {
    await lenderServices.list({
      pageSize: 10000,
      page: 0,
      keyword: text,
    }).then((response) => {
      setLenders(response?.data || []);
      form.setFieldValue('lenderId', response?.data[0].id)
    }).catch(() => {
      setLenders([])
    })
  }

  const handleSubmit = () => {
    form.validateFields().then((valid) => {
      if (valid) {
        const values = form.getFieldsValue();
        submit({
          comment: values.comment,
          lenderId: values.lenderId || null
        });
      }
    })
  }
  return (
    <Modal
      title={title}
      width={500}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => onClose()}
      cancelButtonProps={{
        disabled: callingAPI
      }}
      okButtonProps={{
        loading: callingAPI
      }}
      okText='Xác nhận'
      cancelText='Hủy'
    >
      <Form
        form={form}
        name="basic"
        layout='vertical'
      >
        {
          isSelectLender && <Form.Item
            label={'Công ty tài chính'}
            name={'lenderId'}
          >
            <Select
              placeholder={'Chọn cty tài chính'}
              className="filter-filed"
              optionFilterProp="children"
              filterOption={(input, option) =>
                `${option.children}`
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {lenders.map((lender) => (
                <Select.Option
                  value={lender.id}
                  key={lender.id}
                >
                  {lender.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        <Form.Item
          label={'Nội dung'}
          name={'comment'}
        >
          <Input.TextArea
            disabled={callingAPI}
            rows={4}
            placeholder="Vui lòng nhập nội dung"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Approval;