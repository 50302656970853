import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints";

import systemServices from '../system';

const cmsInfo = systemServices.cms_info();

function list(params = {}) {
  return request({
    url: ENDPOINT.POSITION,
    method: "get",
    params: {
      ...params,
      system: cmsInfo?.value
    },
  });
}

function get(id, params = {}) {
  return request({
    url: ENDPOINT.POSITION_DETAIL.replace(':id', id),
    method: "get",
    params: {
      ...params,
      system: cmsInfo?.value
    },
  });
}

function create(data) {
  return request({
    url: ENDPOINT.POSITION,
    method: "post",
    data: {
      ...data,
      system: cmsInfo?.value
    }
  });
}

function update(id, data) {
  return request({
    url: ENDPOINT.POSITION_DETAIL.replace(':id', id),
    method: "put",
    data
  });
}

function remove(id) {
  return request({
    url: ENDPOINT.POSITION_DETAIL.replace(':id', id),
    method: "delete",
  });
}

export default {
  list,
  get,
  create,
  update,
  remove
};
