import React from "react";
import '../css/components/TableData.scss'
import {
  Row,
  Table,
  Button,
  Tag
} from 'antd';

import {
  EditOutlined
} from "@ant-design/icons";

import {
  convertDateTime,
  loanStatus,
  marketStatus,
  lenderStatus
} from '../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    params
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Mã đơn vay',
      dataIndex: 'loanCode',
      key: 'loanCode',
      align: 'center',
      width: 200,
    },
    {
      title: 'Trạng thái Finblue duyệt',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Tag color={loanStatus(record.status)?.tag_color}>
          {loanStatus(record.status)?.label}
        </Tag>
      ),
    },
    {
      title: 'Thời gian Finblue duyệt',
      dataIndex: 'approvedTime',
      key: 'approvedTime',
      align: 'center',
      width: 200,
      render: (_, record) => convertDateTime(record.approvedTime)
    },
    {
      title: 'Mã khách hàng',
      dataIndex: 'customerId',
      key: 'customerId',
      align: 'left',
      width: 120,
      render: (_, record) => `KH_${record.customerId}`
    },
    {
      title: 'Họ tên',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: 120,
    },
    {
      title: 'Thời gian vay',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      width: 200,
      render: (_, record) => convertDateTime(record.createdDate)
    },
    {
      title: 'Người duyệt',
      dataIndex: 'approvedName',
      key: 'approvedName',
      align: 'center',
      width: 200,
    },
    {
      title: 'Cty Tài chính (CTTC)',
      dataIndex: 'lenderName',
      key: 'lenderName',
      align: 'center',
      width: 200,
    },
    {
      title: 'Trạng thái CTTC duyệt',
      dataIndex: 'lenderStatus',
      key: 'lenderStatus',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Tag color={lenderStatus(record.lenderStatus)?.tag_color}>
          {lenderStatus(record.lenderStatus)?.label}
        </Tag>
      ),
    },
    {
      title: 'Thời gian CTTC duyệt',
      dataIndex: 'lenderApprovedTime',
      key: 'lenderApprovedTime',
      align: 'center',
      width: 200,
      render: (_, record) => convertDateTime(record.lenderApprovedTime)
    },
    {
      title: 'Sàn TMĐT',
      dataIndex: 'marketName',
      key: 'marketName',
      align: 'center',
      width: 200,
    },
    {
      title: 'Trạng thái sàn duyệt',
      dataIndex: 'martStatus',
      key: 'martStatus',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <>
          {
            record.business === 'Mua trước trả sau' && <Tag color={marketStatus(record.martStatus)?.tag_color}>
              {marketStatus(record.martStatus)?.label}
            </Tag>
          }
        </>
        
      ),
    },
    {
      title: 'Thời gian sàn duyệt',
      dataIndex: 'marketApprovedTime',
      key: 'marketApprovedTime',
      align: 'center',
      width: 200,
      render: (_, record) => convertDateTime(record.marketApprovedTime)
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: '200px',
      render: (_, record) => (
        <Row gutter={[0, 8]} justify='center'>
          <Button
            icon={<EditOutlined />}
            type='primary'
            size="small"
            ghost
            onClick={() => window.navigatePage('LOAN_APPROVAL_DETAIL', { id: record.id }, { customerId: record.customerId })}
          >Chi tiết</Button>
        </Row>
      ),
    },
  ]

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 + (params.page - 1) * params.pageSize }))}
      loading={loading}
      pagination={false}
      rowKey={(record) => record?.id}
      scroll={{ x: 1024 }}
      size="small"
    />
  );
}

export default TableData;