import React, { useMemo, useState } from "react";
import {
} from 'antd';

import {
} from "@ant-design/icons";

import { formatNumber } from "../../../../../utils/common";

import Chart from "react-apexcharts";
import { Statistic } from '../../../../../components'

const OrderReport = (props) => {
  const {
    orderReport = {},
  } = props

  const total = useMemo(() => {
    return (orderReport.due30 || 0) + (orderReport.due60 || 0) + (orderReport.due90 || 0)
  }, [orderReport])

  const chartData = useMemo(() => {
    return [
      {
        label: 'Chưa tới hạn',
        total: orderReport.notDue || 0,
        tag_color: '#2ECC71'
      },
      {
        label: 'Quá hạn 1-30 ngày',
        total: orderReport.due30 || 0,
        tag_color: '#00A9F1'
      },
      {
        label: 'Quá hạn 31-60 ngày',
        total: orderReport.due60 || 0,
        tag_color: '#F1C40F'
      },
      {
        label: 'Quá hạn 61-90 ngày',
        total: orderReport.due90 || 0,
        tag_color: '#FF0000'
      }
    ]
  }, [orderReport])

  return (
    <div>
      <Chart
        options={{
          chart: {
            type: 'donut'
          },
          tooltip: {
            enabled: false,
          },
          labels: chartData.map((d) => d.label),
          colors: chartData.map((d) => d.tag_color),
          legend: {
            position: 'right',
            horizontalAlign: 'center',
          },
          plotOptions: {
            pie: {
              donut: {
                size: '80%',
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: '14px',
                    fontWeight: 400,
                    formatter: function (w) {
                      const data = chartData.find((c) => c.label === w)
                      return data ? data.label.toUpperCase() : w
                    }
                  },
                  total: {
                    show: true,
                    label: 'Tổng đơn nợ xấu'.toUpperCase(),
                    fontWeight: 400,
                    fontSize: '14px',
                    color: '#000000',
                    formatter: function (w) {
                      return formatNumber(total) + ' Đơn'
                    }
                  },
                  value: {
                    show: true,
                    fontSize: '22px',
                    formatter: function (w) {
                      return formatNumber(w) + ' Đơn'
                    }
                  }
                }
              }
            }
          }
        }}
        series={chartData.map((d) => d.total)}
        type="donut"
        height={360}
      />
      <Statistic
        statistics={chartData}
      />
    </div>
  );
}

export default OrderReport;