import React from "react";
import "../css/components/Filter.scss";
import {Row, Col, Input, Select} from "antd";

import {SearchOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const Filter = (props) => {
    const {
        params = {}, setParams = () => {}
    } = props;

    const { t } = useTranslation();
    const texts = t;

    return (
        <Row className="filter" gutter={[8, 8]}>
            <Col span={3} lg={6} sm={12} xs={24}>
                <Input
                    prefix={<SearchOutlined/>}
                    value={params.keyword}
                    placeholder={texts('search')}
                    onChange={(e) => setParams({...params, keyword: e.target.value})}
                />
            </Col>


        </Row>
    );
};

export default Filter;
