import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import partnerLogService from "../../../services/invest/partner_log";
import ModalSavingsTransactionDetail from "./components/modals/ModalSavingsTransactionDetail";
import dayjs from 'dayjs';
import {convertStringToQuery} from "../../../utils/common";
import {useTranslation} from "react-i18next";

const query = convertStringToQuery(window.location.search);

const TransactionsAppotaFirmBanking = (props) => {
    const {router} = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(10);
    const [formVisible, setFormVisible] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [params, setParams] = useState({
        is_handle: 0,
        status: 'all',
        partner: 'APPOTA',
        page: 1,
        size: 10,
        dates: [
            query?.from_date ? dayjs(Number(query?.begin) * 1000) : dayjs().startOf('month'),
            query?.to_date ? dayjs(Number(query?.end) * 1000) : dayjs().endOf('month'),
        ]
    });

    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);
        let newParams = {
            ...params,
            page: params.page - 1,
            from_date: dayjs(dayjs(params.dates[0])).unix()*1000,
            to_date: dayjs(dayjs(params.dates[1])).unix()*1000,
            sort: 'createdAt,DESC',
        };
        delete newParams.dates;
        await partnerLogService
            .get_log_call_partner(newParams)
            .then((response) => {
                setData(response?.data || []);
                setTotal(response?.page?.total_elements);
            })
            .catch(() => {
                setData([]);
            });
        setLoading(false);
    };

    const handleShowDetail = (transaction = null) => {
        setSelectedTransaction(transaction);
        setFormVisible(true)
    };

    return (
        <div className="cms-users">
            <AdminHeader
                title={router.label}
                total={total}
                // actions={[
                //     {
                //         key: "add",
                //         label: "Create",
                //         type: "primary",
                //         icon: <PlusOutlined/>,
                //         click: () => {
                //         },
                //     },
                // ]}
            />
            <Filter params={params} setParams={setParams}/>
            <TableData
                loading={loading}
                data={data}
                params={params}
                showDetail={(item) => handleShowDetail(item)}
            />

            <Pagination
                className="pagination"
                current={params.page}
                pageSize={params.size}
                total={total}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `Tổng số ${total}`}
                onChange={handleChangePage}
            />

            {
                formVisible && <ModalSavingsTransactionDetail
                    visible={formVisible}
                    transaction={selectedTransaction}
                    onClose={() => setFormVisible(false)}
                    reload={() => fetchData()}
                />
            }
        </div>
    );
};

export default TransactionsAppotaFirmBanking;
