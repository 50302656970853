import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Modal,
  message,
  Card,
  Table
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleFilled
} from "@ant-design/icons";

import staffServices from "../../../../services/admin/staff";

const CardData = (props) => {
  const {
    department = {},
    loading = false,
    onCreate = () => { },
    onUpdate = () => { },
    onDelete = () => { },
    onUpdateDepartment = () => { },
  } = props;


  const handleDeleteStaff = async (staff) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xoá không?',
      onOk() {
        staffServices.remove({
          id: staff.id
        }).then(() => {
          message.success('Xoá tài quyền hạn thành công')
          fetchData();
        }).catch((error) => {
          message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
      },
      onCancel() { },
    });
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 100
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: 150,
    },
    {
      title: 'Chức vụ',
      dataIndex: 'positionName',
      key: 'positionName',
      align: 'left',
      width: 200,
    },
    {
      title: 'Vị trí',
      dataIndex: 'roleName',
      key: 'roleName',
      align: 'left',
      width: 200,
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 150,
      render: (_, record) => <Space size={[4, 4]}>
        <Button
          size="small"
          type="primary"
          ghost
          icon={<EditOutlined />}
          onClick={() => onUpdate(record)}
        >Sửa</Button>
        <Button
          size="small"
          icon={<DeleteOutlined />}
          danger
          onClick={() => handleDeleteStaff(record)}
        >Xoá</Button>
      </Space>
    }
  ]

  return (
    <Card
      size="small"
      className="w-100 mt-m"
      loading={loading}
      title={<div className="flex align-items-center justify-content-between">
        <p className="text-bold">
          {department.departmentName}
          <span>({department?.number})</span>
        </p>
        <Space size={[4, 4]}>
          <Button
            size="small"
            icon={<PlusOutlined />}
            type="link"
            onClick={() => onCreate()}
          ></Button>
          <Button
            size="small"
            icon={<EditOutlined />}
            type="link"
            onClick={() => onUpdateDepartment()}
          ></Button>
          <Button
            size="small"
            icon={<DeleteOutlined />}
            type="link"
            danger
            onClick={() => onDelete()}
          ></Button>
        </Space>
      </div>}
    >
      <Table
        loading={loading}
        bordered={false}
        columns={columns}
        dataSource={(department?.users || []).map((d, index) => ({ ...d, stt: index + 1 }))}
        pagination={false}
        rowKey={(record) => record?.id}
        size="small"
        scroll={{ x: 1024 }}
      />
    </Card>
  );
};

export default CardData;
