import React from "react";
import "../css/components/TableData.scss";
import { Row, Table, Button, Modal } from "antd";

import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";

const TableData = (props) => {
  const { data, loading, params } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      align: "center",
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      align: "center",
    },

    {
      title: "Quyền hạn",
      dataIndex: "roles",
      key: "roles",
      align: "center",
    },
    {
      title: "Quyền truy cập đặc biệt",
      dataIndex: "permissions",
      key: "permissions",
      align: "center",
    },
    {
      title: "Hành động",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (_, record) => (
        <Row gutter={[0, 8]}>
          <Button icon={<EditOutlined />} primary>
            Sửa
          </Button>
          <Button icon={<EditOutlined />} onClick={showModal} primary>
            Xóa
          </Button>
          <Modal
            title="Basic Modal"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>Some contents...</p>
          </Modal>
        </Row>
      ),
    },
  ];

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({
        ...d,
        stt: index + 1 + (params.page - 1) * params.size,
      }))}
      loading={loading}
      pagination={false}
      rowKey={(record) => record?.id}
      size="small"
    />
  );
};

export default TableData;
