import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Tabs} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminDetailHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import fmarketServices from "../../../services/invest/fmarket";
import customerServices from "../../../services/invest/customer";
import { useParams } from 'react-router-dom';
import FaInfoTab from './fa_info_tab';
import FaOrderTab from './fa_order_tab';
import FaTransactionTab from './fa_transaction_tab';

const FmarketAccountDetail = (props) => {
    const {router} = props;
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [fmarketAccount, setfmarketAccount] = useState({});
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [params, setParams] = useState({
        page: 1,
        size: 10,
    });

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);
        let keyword = id;
        console.log('keyword', keyword);
        await fmarketServices.fmarket_accounts({keyword})
            .then((response) => {
                setfmarketAccount(response?.data?.[0] || {});
                // setTotal(response?.page?.total_elements);
            })
            .catch(() => {
                // setData([]);
            });
        setLoading(false);
    };

    return (
        <div className="cms-users">
            <AdminDetailHeader
                title={router.label}
            />

            <div className="content-wrapper">
                <div className="page-content">

                    <div className="main-content">
                        <div className='extra-custome-info'>
                            <Tabs
                                defaultActiveKey="1"
                                className="tab-info"
                                items={[
                                    {
                                        label: 'Thông tin tài khoản',
                                        key: 'FA-INFO',
                                        children: <FaInfoTab fmarketAccount={fmarketAccount}/>,
                                    },
                                    {
                                        label: 'Lịch sử giao dịch',
                                        key: 'FA-TRANSACTION',
                                        children: <FaTransactionTab fmarketAccount={fmarketAccount}/>
                                    },
                                    {
                                        label: 'Lịch sử đơn hàng',
                                        key: 'FA-ORDER',
                                        children: <FaOrderTab fmarketAccount={fmarketAccount}/>
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/*<Filter params={params} setParams={setParams}/>*/}
            {/*<TableData loading={loading} data={data} params={params}/>*/}

            {/*<Pagination*/}
                {/*className="pagination"*/}
                {/*current={params.page}*/}
                {/*pageSize={params.size}*/}
                {/*total={total}*/}
                {/*showSizeChanger*/}
                {/*showQuickJumper*/}
                {/*showTotal={(total) => `Tổng số ${total}`}*/}
                {/*onChange={handleChangePage}*/}
            {/*/>*/}
        </div>
    );
};

export default FmarketAccountDetail;
