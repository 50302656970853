import React, { useEffect, useState } from "react";
import {
  Table,
  Card
} from 'antd';

import {
  convertDateTime
} from '../../../../../utils/common'

const ApiHistories = (props) => {
  const {
    loading,
    data = [],
    bodered = true
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 100
    },
    {
      title: 'Thời gian gọi',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
      width: 200,
      render: (_, record) => (
        convertDateTime(record.time)
      ),
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
    },
    {
      title: 'Mô tả loại',
      dataIndex: 'message',
      key: 'message',
      align: 'left',
    },
    {
      title: 'Mã lỗi',
      dataIndex: 'errorCode',
      key: 'errorCode',
      align: 'center',
    },
    {
      title: 'Mô tả mã lỗi',
      dataIndex: 'second',
      key: 'second',
      align: 'left',
    }
  ]

  return (
    <Card
      size="small"
      loading={loading}
      title={'Lịch sử gọi API'}
    >
      <Table
        bodered={bodered}
        columns={columns}
        dataSource={data.map((d, index) => ({ ...d, stt: index + 1 }))}
        pagination={false}
        rowKey={(record) => record?.stt}
        scroll={{ x: 1024 }}
        size="small"
      />
    </Card>
  );
}

export default ApiHistories;