import React from "react";
import {
  Table,
  Button,
  Tag,
  Space
} from "antd";

import { RouterLink } from '../../../../components'

import { EditOutlined, DeleteOutlined, PhoneOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";

import { PERMISSIONS } from "../../../../config/permissions";
import { isPermission } from "../../../../utils/common";

const TableData = (props) => {
  const {
    data,
    loading,
    params,
    onDelete = () => { }
  } = props;

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      align: "center",
      width: 80,
    },
      {
          title: "Họ tên",
          dataIndex: "name",
          key: "name",
          width: 120,
      },
    {
      title: "Thông tin",
      dataIndex: "phone",
      key: "phone",
      width: 140,
      render: (_, record) =>
          <div>
              <Space>
                  <UserOutlined style={{fontSize: 16, color: '#999'}}/>
                  <div>{record?.username}</div>
              </Space>
              {record?.phone ?
                  <Space>
                      <PhoneOutlined style={{fontSize: 16, color: '#999'}}/>
                      <div>{record?.phone}</div>
                  </Space>
                  : null}
              {record?.email ?
                  <Space style={{marginTop: 4}}>
                      <MailOutlined style={{fontSize: 16, color: '#999'}}/>
                      <div>{record?.email}</div>
                  </Space>
                  : null}
          </div>
    },
    {
      title: "Vị trí",
      dataIndex: "roles",
      key: "roles",
      width: 250,
      render: (_, record) => {
        const roles = record.roles.split(',').filter(Boolean)
        return <Space size={[0, 8]} wrap>
          {
            roles.map((r, index) => <Tag color={'blue'} key={index}>
              {r}
            </Tag>)
          }
        </Space>
      }
    },
    {
      title: "Quyền truy cập đặc biệt",
      dataIndex: "permissions",
      key: "permissions",
      render: (_, record) => {
        const permissions = record.permissions.split(',').filter(Boolean)
        return <Space size={[0, 8]} wrap>
          {
            permissions.slice(0, 5).map((r, index) => <Tag key={index}>
              {r}
            </Tag>)
          }
          {
            permissions.length > 5 && <Tag color={'blue'}>
              + {permissions.length - 5}
            </Tag>
          }
        </Space>
      }
    },
    {
      title: "Hành động",
      dataIndex: "actions",
      key: "actions",
      align: "center",
//      fixed: 'right',
//      width: 120,
      hide: !isPermission([PERMISSIONS.UPDATE_USER_ROLE, PERMISSIONS.VIEW_LIST_ROLE]) && !isPermission([PERMISSIONS.DELETE_USER_ROLE]),
      render: (_, record) => (
        <Space size={[8, 8]}>
          {
            isPermission([PERMISSIONS.UPDATE_USER_ROLE, PERMISSIONS.VIEW_LIST_ROLE]) && <Button
              icon={<EditOutlined />}
              type="primary"
              size="small"
              ghost
              onClick={() => window.navigatePage('CMS_USER_DETAIL', { id: record?.userId })}
            >
              Sửa
            </Button>
          }
          {
            isPermission([PERMISSIONS.DELETE_USER_ROLE]) && <Button
              icon={<DeleteOutlined />}
              type="primary"
              danger
              size="small"
              ghost
              onClick={() => onDelete(record)}
            >
              Xóa
            </Button>
          }
        </Space>
      ),
    },
  ].filter((c) => !c.hide);

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({
        ...d,
        stt: index + 1 + (params.page - 1) * params.size,
      }))}
      loading={loading}
      pagination={false}
      rowKey={(record) => record?.customerId}
      size="small"
      scroll={{ x: 1024 }}
    />
  );
};

export default TableData;
