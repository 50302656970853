import {
  AppstoreOutlined,
  BookOutlined,
  CodeSandboxOutlined,
  AuditOutlined,
  BankOutlined,
  TeamOutlined,
  SafetyCertificateOutlined,
  PayCircleOutlined
} from '@ant-design/icons'
import { getRouterByName, getRoutersByMenu } from '../../utils/common'

export default {
  MENUS: [
    {
      ...getRouterByName('DASHBOARD'),
      key: 'DASHBOARD'
    },
    {
      icon: <AppstoreOutlined />,
      label: 'Quản trị',
      key: 'ADMIN',
      name: getRoutersByMenu('ADMIN')[0]?.name,
      children: getRoutersByMenu('ADMIN'),
    },
    {
      ...getRouterByName('STAFFS'),
      icon: <TeamOutlined />,
      key: 'STAFFS',
    },
    {
      ...getRouterByName('CUSTOMERS')
    },
    {
      icon: <CodeSandboxOutlined />,
      label: 'Sản phẩm',
      key: 'PRODUCT',
      name: getRoutersByMenu('PRODUCT')[0]?.name,
      children: getRoutersByMenu('PRODUCT'),
    },
    {
      icon: <SafetyCertificateOutlined />,
      label: 'Thẩm định',
      key: 'MAJOR',
      name: getRoutersByMenu('MAJOR')[0]?.name,
      children: getRoutersByMenu('MAJOR'),
    },
    {
      ...getRouterByName('CUSTOMER_PAYMENT'),
      icon: <PayCircleOutlined />,
      label: 'Gạch nợ',
      key: 'CUSTOMER_PAYMENT',
    },
    {
      ...getRouterByName('LENDERS')
    },
    {
      ...getRouterByName('MARKETS')
    },
    {
      ...getRouterByName('APPOTA')
    },
    {
      icon: <BankOutlined />,
      label: 'Ngân hàng',
      key: 'BANK',
      name: getRoutersByMenu('BANK')[0]?.name,
      children: getRoutersByMenu('BANK'),
    }
  ]
}
