import { green, red, orange } from "@ant-design/colors";

export default {
    DATE_FORMAT: "DD-MM-YYYY",
    DATE_TIME_FORMAT: "HH:mm DD-MM-YYYY",
    EKYC_STATUS: [
        {
            value: 0,
            tag_color: '',
            label: 'pending',
            statistic_key: 'waitApproved'
        },
        {
            value: 1,
            tag_color: 'green',
            label: 'approve',
            statistic_key: 'approved'
        },
        {
            value: 2,
            tag_color: 'red',
            label: 'reject',
            statistic_key: 'cancel'
        },
    ],

    ACTIVE_STATUS: [
        {
            value: false,
            tag_color: 'red',
            label: 'unactive',
        },
        {
            value: true,
            tag_color: 'green',
            label: 'active',
        },
    ],
    INVEST_PROFIT_CAPITAL_DEAL: [
        { value: 1, name: 'Lãi và gốc trả về tài khoản' },
        { value: 2, name: 'Lãi trả về tài khoản, gốc tái đầu tư' },
        { value: 3, name: 'Lãi và gốc tái đầu tư' },
    ],

    FMARKET_TRANSACTION_STATUS: [
        { value: 'SELL', color: 'red' },
        { value: 'BUY', color: 'green' },
        { value: 'TRANSFER_SELL', color: 'gold' },
        { value: 'TRANSFER_BUY', color: 'blue' },
        { value: 'INIT', color: 'red' },
        { value: 'PENDING', color: 'gold' },
    ],

    FMARKET_ORDER_STATUS: [
        { value: 'INIT', color: 'red' },
        { value: 'DONE', color: 'green' },
        // { value: 'TRANSFER_SELL', color: 'gold' },
        // { value: 'TRANSFER_BUY', color: 'blue' },
        // { value: 'INIT', color: 'red' },
        { value: 'PENDING', color: 'gold' },
    ],

    SAVINGS_TRANSACTION_STATUS: [
        { value: 'INIT', label: 'init', color: 'red' },
        { value: 'PENDING', label: 'pending', color: 'gold' },
        { value: 'SUCCESS', label: 'success', color: 'green' },
    ],

    SAVINGS_PRODUCT_ACTIVE_STATUS: [
        {
            value: 0,
            color: 'red',
            label: 'Ẩn',
        },
        {
            value: 1,
            color: 'green',
            label: 'Hiện',
        },
    ],

    PAYMENT_STATUS: [
        {
            value: 0,
            tag_color: 'green',
            label: 'Đã tất toán',
        },
        {
            value: 1,
            tag_color: 'blue',
            label: 'Chưa đến hạn',
        },
        {
            value: 2,
            tag_color: 'red',
            label: 'Đã quá hạn',
        },
    ],

    SAVINGS_PRODUCT_DISPLAY_STATUS: [
        {
            value: 0,
            color: 'red',
            label: 'Hide',
        },
        {
            value: 1,
            color: 'green',
            label: 'Show',
        },
    ],

    QUESTION_ANSWER_STATUS: [
        {
            value: 0,
            color: 'red',
            label: 'Hide',
        },
        {
            value: 1,
            color: 'green',
            label: 'Show',
        },
    ],

    NEWS_DISPLAY_STATUS: [
        {
            value: 0,
            color: 'red',
            label: 'Hide',
        },
        {
            value: 1,
            color: 'green',
            label: 'Show',
        },
    ],

    NEWS_CATEGORIES: [
        {name: "Tin tức", value: "NEWS", color: "cyan"},
        {name: "Tổng hợp", value: "SYNTHETIC", color: "purple"},
        {name: "Kiến thức đầu tư", value: "INVEST_KNOWLEDGE", color: "magenta"},
    ],

    NEWS_TYPES: [
        {name: "Tin tiêu điểm", value: "HOT_NEWS", color: "volcano"},
        {name: "Tin thường", value: "GENERAL_NEWS", color: "blue"},
    ],


    FORCE_SETTLE_STATUS: [
        {name: 'Complete', value: 200, label: 'approve'},
        {name: 'Pending', value: 0, label: 'pending'},
        // {name: 'Reject', value: 1},
        {name: 'Reject', value: 3, label: 'reject'},
        // {name: 'Review', value: 2, label: 'review'},
        // {name: 'Undue', value: 5, label: 'undue'},
        // {name: 'undue', value: 5},
        // 0: requets cho` xu lý;200: requets da hoan tat; 3: bi tu choi;5: request tra? lai~ chua den han xu ly
    ],

    BALANCE_CHANGE_REQUEST_TYPE: [
        {name: 'Credit', value: 1, color: 'green', label: 'credit'},
        {name: 'Debit', value: 2, color: 'red', label: 'debit'},
    ],

    BALANCE_CHANGE_REQUEST_SUB_TYPE: [
        {type: 1, name: 'CREDIT_FROM_BANK_INTERNET', value: 1000, color: 'green', label: "credit_from_internet_banking"},
        {type: 1, name: 'CREDIT_FROM_ADMIN', value: 1100, color: 'green', label: 'credit_from_admin'},
        {type: 1, name: 'CREDIT_FROM_ADMIN_THROUGH_TRANSFER', value: 1200, color: 'green', label: 'credit_from_admin_transfer'},
        {type: 1, name: 'CREDIT_FROM_AUTO_SETTLE', value: 1300, color: 'green', label: 'credit_from_auto_settle'},
        {type: 1, name: 'CREDIT_FROM_VA_TRANSFER', value: 1400, color: 'green', label: 'credit_from_va'},
        {type: 2, name: 'DEBIT_BY_WITHDRAWAL', value: 2000, color: 'red', label: 'debit_by_withdraw'},
        {type: 2, name: 'DEBIT_BY_PAY_FOR_PRODUCT', value: 2100, color: 'red', label: 'debit_by_buy_product'},
        {type: 2, name: 'DEBIT_FROM_ADMIN', value: 2200, color: 'red', label: 'debit_by_admin'},
        {type: 2, name: 'DEBIT_BY_WITHDRAWAL_FIRM', value: 2300, color: 'red', label: 'debit_by_withdraw_firm_banking'},
    ],

    BALANCE_CHANGE_REQUEST_STATUS: [
        {
            value: 99,
            color: 'orange',
            label: 'pending',
            name: 'Đang xử lý',
        },
        {
            value: 100,
            color: 'red',
            label: 'fail',
            name: 'Thất bại'
        },
        {
            value: 200,
            color: 'green',
            label: 'success',
            name: 'Thành công'
        },
        // Số khác: thất bại
    ],

    FINAL_STATUS: [
        {
            value: 99,
            color: 'orange',
            label: 'pending',
            name: 'Đang xử lý',
        },
        {
            value: 100,
            color: 'red',
            label: 'fail',
            name: 'Thất bại'
        },
        {
            value: 200,
            color: 'green',
            label: 'success',
            name: 'Thành công'
        },
        // Số khác: thất bại
    ],

    CUSTOMER_TYPE: [
        {value: 1, label: 'New register'},
        {value: 2, label: 'Online'},
    ],

    FUNDS_TYPE: {
        CUSTOMER: {value: 1},
        TRANSACTION: {value: 2}
    },

    DATE_TYPE: {
        BY_DATE: 1,
        BY_MONTH: 2
    },

    SALES_STATUS: [
        {value: 0, label: 'pending', name: 'Pending', color: 'orange'},
        {value: 1, label: 'active', name: 'Active', color: 'green'},
        {value: 2, label: 'unactive',  name: 'Un active', color: 'red'},
    ],

    SALES_STATUS_OBJ: {
        PENDING: {value: 0, label: 'Pending', name: 'Pending', color: 'orange'},
        ACTIVE: {value: 1, label: 'Active', name: 'Active', color: 'green'},
        UN_ACTIVE: {value: 2, label: 'Un active',  name: 'Un active', color: 'red'},
    },

    SAVINGS_TRANSACTION_PROCESSING_STATUS: [
        {value: 'PENDING', label: 'investment_pending', name: 'Pending', color: 'orange'},
        {value: 'TRANSFERRED', label: 'investment_transferred', name: 'Transferred', color: 'green'},
    ],

    FILE_TYPE: {
        ID_FILE_FRONT: 1,
        ID_FILE_BACK: 2,
        ACCOUNT_AVATAR: 3,
        CONTRACT_FILES: 4,
        CERTIFICATE_FILE: 5,
        PRODUCT_FILE: 6,
        BILL_FILE: 7,
        OTHER_IMG: 8,
        SELFIE_IMG: 9,
        NEWS_IMG: 10
    },

    MARITAL_STATUS: [
        {value: 0, label: 'not_married', name: 'Chưa kết hôn'},
        {value: 1, label: 'married', name: 'Đã kết hôn'},
        {value: 2, label: 'divorce', name: 'Ly dị'},
        {value: 3, label: 'single', name: 'Độc thân'},
    ],

    CUSTOMER_INVESTMENT_TYPE: [
        {value: 0, label: 'all', name: 'All'},
        {value: 1, label: 'saving', name: 'Saving'},
        {value: 2, label: 'funds', name: 'Funds'},
    ],


};