import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function list_data_types(params) {
  return request({
    url: ENDPOINT.MENU_DATA_TYPE,
    method: "get",
    params,
  });
}

export default {
  list_data_types
};
