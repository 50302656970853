import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Spin
} from "antd";
import {

} from "@ant-design/icons";

import CardData from "./CardData";

import permisionServices from "../../../../services/admin/permision";
import categoryServices from "../../../../services/admin/category";

const PermissionListData = (props) => {
  const {
    role,
    rolePermissions = [],
    disabled = false,
    setRole = () => { },
  } = props

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (role.roleCategories && role.roleCategories.length > 0) {
      setCategories(role.roleCategories || [])
    } else {
      fetchPermissions()
    }
  }, [role.roleCategories])

  const fetchCategories = async (permisions) => {
    await categoryServices
      .list()
      .then((response) => {
        setCategories((response?.data || []).map((c) => ({
          ...c,
          permissions: permisions.filter((p) => p.category == c.name).map((p) => ({ ...p, isChoose: false }))
        })))
      })
      .catch(() => {
        setCategories([]);
      });
  };

  const fetchPermissions = async (category) => {
    setLoading(true)
    await permisionServices
      .all_permissions({})
      .then(async (response) => {
        const permisions = response?.data || []
        await fetchCategories(permisions)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      });
  };


  const selectPermission = async (category, permission) => {
    const newCategories = categories.map((c) => {
      if (category.categoryId === c.categoryId) {
        return {
          ...category,
          permissions: category.permissions.map((p) => p.permissionId === permission.permissionId ? permission : p)
        }
      }
      return c
    })
    setRole({
      ...role,
      roleCategories: newCategories
    })
  };

  return (
    <div className="permission-list-data">
      <Spin spinning={loading} style={{ minHeight: '100' }}>
        <Row gutter={[16, 16]}>
          {
            categories.map((category) => <Col
              lg={8}
              sm={12}
              xs={24}
              key={category.categoryId}
            >
              <CardData
                type="select"
                category={category}
                rolePermissions={rolePermissions}
                disabled={disabled}
                onSelect={(v) => selectPermission(category, v)}
              />
            </Col>)
          }
        </Row>
      </Spin>
    </div>
  );
};

export default PermissionListData;
