import React, { useMemo } from "react";
import './css/404.scss';

import { getFirstMenu } from "../../utils/common";

const PageNotFound = () => {
  return (
    <div className="page-not-found-page">
      <img src={require("../../assets/images/errors/404.jpg")} />
      <p className="content">Trang không tồn tại</p>
      <p className="note">
        <a onClick={() => window.navigatePage(getFirstMenu()?.name || 'DASHBOARD')}>Quay lại trang chủ</a>
      </p>
    </div>
  );
}

export default PageNotFound;