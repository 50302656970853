import React from "react";
import "../css/components/TableData.scss";
import { Row, Table, Button, Modal, Tag, Col } from "antd";

import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import {
    convertDateTime, formatMoneyCurrency, getFmkTransactionTypeColorByStatus,
    getSavingsProductStatus
} from "../../../../utils/common";
import moment from 'moment';
import {useTranslation} from "react-i18next";
import CONSTANTS from "../../../../config/constants_invest";

const TableData = (props) => {
    const {
        data,
        loading,
        params,
        edit = () => { }
    } = props;

    const { t } = useTranslation();
    const texts = t;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: texts('no.'),
            dataIndex: "stt",
            key: "stt",
            align: "center",
            width: '46px',
        },
        {
            title:  texts('title'),
            dataIndex: "title",
            key: "title",
            align: "left",
            maxWidth: '200px',
        },
        {
            title: texts('category'),
            dataIndex: "shortDescription",
            key: "shortDescription",
//            align: "center",
            maxWidth: "260px",
            render: (_, record) => {
                let category = CONSTANTS.NEWS_CATEGORIES.find(e => e?.value === record?.category);
                let type = CONSTANTS.NEWS_TYPES.find(e => e?.value === record?.newsType);
                return(
                    <div>
                        <Tag color={type?.color}>
                            {type?.name}
                        </Tag>
                        <Tag color={category?.color}>
                            {category?.name}
                        </Tag>
                    </div>
                )
            }
        },

        {
            title: texts('last_update'),
            dataIndex: "updatedAt",
            key: "updatedAt",
            align: "center",
            width: 100,
            render: (_, record) => moment(record?.updatedAt).format('HH:mm DD/MM/YYYY')
        },
        {
            title: texts('actions'),
            dataIndex: "actions",
            key: "actions",
            align: "center",
            render: (_, record) => (
                <Row gutter={[8, 8]} justify={'center'}>
                    {/*<Col>*/}
                    {/*<Button*/}
                    {/*onClick={() => edit(record)}*/}
                    {/*type='primary'*/}
                    {/*size='small'>*/}
                    {/*Sửa*/}
                    {/*</Button>*/}
                    {/*</Col>*/}
                    <Col>
                        <Button
                            onClick={() => edit(record)}
                            ghost
                            type='primary'
                            size='small'>
                            {texts('detail')}
                        </Button>
                    </Col>
                </Row>
            ),
        },
    ];

    const expandable = {
        expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.fundFullName}</p>,
        rowExpandable: (record) => record.fundFullName !== 'Not Expandable',
    };

    return (
        <Table
            bordered
            className="table-data"
            columns={columns}
            dataSource={data.map((d, index) => ({
                ...d,
                stt: index + 1 + (params.page - 1) * params.size,
            }))}
            // expandable={expandable}
            loading={loading}
            pagination={false}
            rowKey={(record) => record?.id}
            size="small"
        />
    );
};

export default TableData;
