import React from "react";
import './css/403.scss'

const Permission = () => {
  return (
    <div className="permission-page">
      <img src={require("../../assets/images/errors/403.jpg")} />
      <p className="content">Bạn không có quyền truy cập tính năng này</p>
      <p className="note">
        Đăng nhập vào tài khoản khác
        <a onClick={() => window.navigatePage('LOGIN')}> tại đây</a>
      </p>
    </div>
  );
}

export default Permission;