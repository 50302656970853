import request from "../../utils/request";
import request_invest from "../../utils/request_invest";
import ENDPOINT from "../../config/endpoints/invest";

function savings_transactions(params) {
    return request({
        url: ENDPOINT.SAVINGS_TRANSACTIONS,
        method: "get",
        params,
    });
}

function savings_orders(params) {
    return request({
        url: ENDPOINT.SAVINGS_ORDERS,
        method: "get",
        params,
    });
}

function savings_products(params) {
    return request({
        url: ENDPOINT.SAVINGS_PRODUCT_LIST,
        method: "get",
        params,
    });
}

function savings_product_update(data, params = {}) {
    return request({
        url: ENDPOINT.SAVINGS_PRODUCT_UPDATE,
        method: "put",
        params,
        data
    });
}

function list_intermediate_settled(data, params = {}) {
    return request_invest({
        url: ENDPOINT.LIST_INTERMEDIATE_SETTLED,
        method: "post",
        params,
        data
    });
}

function get_force_settle_detail(data, params = {}) {
    return request_invest({
        url: ENDPOINT.GET_FORCE_SETTLE_DETAIL,
        method: "post",
        params,
        data
    });
}

function approve_reject_force_settle(data, params = {}) {
    return request_invest({
        url: ENDPOINT.APPROVE_REJECT_FORCE_SETTLE,
        method: "post",
        params,
        data
    });
}

function update_transaction_processing_status(data, params = {transaction_ids: '', processing_status: "", admin_approve_id: 0, admin_approve_name: ''}) {
    return request_invest({
        url: ENDPOINT.UPDATE_TRANSACTION_PROCESSING_STATUS,
        method: "put",
        params,
        data
    });
}

function settle_firm_banking_status(params) {
    return request_invest({
        url: ENDPOINT.SETTLE_FIRM_BANKING,
        method: "get",
        params
    });
}


function payment_settle_firm_banking(params) {
    return request_invest({
        url: ENDPOINT.PAYMENT_SETTLE_FIRM_BANKING,
        method: "post",
        params
    });
}



export default {
    savings_transactions,
    savings_orders,
    savings_products,
    savings_product_update,
    list_intermediate_settled,
    get_force_settle_detail,
    approve_reject_force_settle,
    update_transaction_processing_status,
    settle_firm_banking_status,
    payment_settle_firm_banking
};
