import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Form,
  Select,
  message,
  InputNumber,
  Image
} from 'antd';

import {
} from "@ant-design/icons";

import CONSTANTS from "../../../../../config/constants";
import {
  MARKET_NAME_RULES,
  EMAIL_RULES,
  LINK_RULES
} from "../../../../../config/rules";

import marketServices from '../../../../../services/finblue/market';

const MarketFormModal = (props) => {
  const [form] = Form.useForm();
  const logo = Form.useWatch('logo', form) || '';
  const [callingAPI, setCallingAPI] = useState(false);
  const {
    market = null,
    visible = false,
    onClose = () => {},
    reload = () => {}
  } = props

  useEffect(() => {
    if (market) {
      form.setFieldsValue({
        name: market.name,
        typeName: market.typeName,
        email: market.email,
        link: market.link,
        linkOrder: market.linkOrder,
        surcharge: market.surcharge,
        logo: market.logo
      })
    } else {
      form.setFieldsValue({
        name: null,
        typeName: null,
        email: null,
        link: null,
        linkOrder: null,
        surcharge: 1,
        logo: null
      })
    }
  }, [market])

  const handleSubmit = () => {
    form.validateFields().then(async (valid) => {
      if (valid) {
        setCallingAPI(true);
        const values = form.getFieldsValue();
        if (market) {
          await handleUpdate({
            id: market.id,
            ...values,
            logo
          })
        } else {
          await handleCreate(values)
        }
        setCallingAPI(false);
      }
    })
  }

  const handleCreate = async (data) => {
    await marketServices.create(data).then(() => {
      message.success('Thêm sàn TMĐT mới thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const handleUpdate = async (data) => {
    await marketServices.update(data, { id: market?.id }).then(() => {
      message.success('Cập thông tin sàn thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  return (
    <Modal
      title={market ? "Chỉnh sửa thông tin sàn TMĐT" : "Thêm mới sàn TMĐT"}
      width={700}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => onClose()}
      okButtonProps={{
        loading: callingAPI
      }}
      cancelButtonProps={{
        disabled: callingAPI
      }}
      okText='Lưu'
      cancelText='Hủy'
    >
      <Form
        form={form}
        layout='vertical'
      >
        <Row
          justify={'left'}
          gutter={[16, 8]}
        >
          <Col span={12} lg={12} sm={24} xs={24}>
            <Form.Item
              label={'Tên sàn TMĐT'}
              name={'name'}
              rules={MARKET_NAME_RULES}
            >
              <Input
                placeholder="Vui lòng nhập tên sàn TMĐT"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
          <Col span={12} lg={12} sm={24} xs={24}>
            <Form.Item
              label={'Email'}
              name={'email'}
              rules={EMAIL_RULES}
            >
              <Input
                placeholder="Vui lòng nhập email"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
          <Col span={12} lg={12} sm={24} xs={24}>
            <Form.Item
              name={'typeName'}
              label={'Loại sàn'}
              rules={[]}
            >
              <Select
                className="w-100"
                placeholder="Vui lòng chọn loại sàn"
                disabled={callingAPI}
              >
                {CONSTANTS.MARKET_TYPES.map((type) => (
                  <Select.Option
                    value={type.value}
                    key={type.value}
                  >
                    {type.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} lg={12} sm={24} xs={24}>
            <Form.Item
              label={'Phí thanh toán (%/Tổng giá trị đơn hàng)'}
              name={'surcharge'}
              rules={[]}
            >
              <InputNumber
                className="w-full"
                disabled={callingAPI}
                formatter={v => `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={v => v.replace(/\$\s?|(,*)/g, '')}
                min={1}
                max={100}
                addonAfter={'%'}
              />
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Link truy cập'}
              name={'link'}
              rules={LINK_RULES}
            >
              <Input
                placeholder="Vui lòng nhập link truy cập"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Link Order'}
              name={'linkOrder'}
              rules={LINK_RULES}
            >
              <Input
                placeholder="Vui lòng nhập link Order"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
          <Col lg={logo ? 18 : 24} sm={logo ? 18 : 24} xs={24}>
            <Form.Item
              label={'Logo Url'}
              name={'logo'}
              rules={LINK_RULES}
            >
              <Input
                placeholder="Vui lòng nhập link Logo"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
          {
            logo && <Col lg={6} sm={6} xs={12}>
              <Image
                src={logo}
                preview={true}
                style={{ width: '100%' }}
              />
            </Col>
          }
        </Row>
      </Form>
    </Modal>
    
  );
}

export default MarketFormModal;