import React, { useEffect, useState } from "react";
import {
  Table,
  Row,
  Button,
  Col,
  Tag
} from 'antd';

import {
  formatCurrency,
  convertDateTime,
  paymentStatus
} from '../../utils/common'

const PaymentScheduleDetails = (props) => {
  const {
    loading = false,
    data = [],
    bodered = true,
    settlement = () => { }
  } = props

  const columns = [
    {
      title: 'Kỳ hạn',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 100
    },
    {
      title: 'Ngày đóng tiền',
      dataIndex: 'expectedDate',
      key: 'expectedDate',
      align: 'center',
      width: 200,
      render: (_, record) => (
        convertDateTime(record.expectedDate, 'DD-MM-YYYY')
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Tag color={paymentStatus(record.status)?.tag_color}>
          {paymentStatus(record.status)?.label}
        </Tag>
      ),
    },
    {
      title: 'Thời gian quá hạn',
      dataIndex: 'timeOver',
      key: 'timeOver',
      align: 'center',
      width: 200,
      render: (_, record) => (
        record.timeOver ? `${record.timeOver} ngày` : ''
      ),
    },
    {
      title: 'Số tiền phải đóng',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      width: 200,
      render: (_, record) => (
        formatCurrency(record.amount)
      ),
    },
    {
      title: 'Nợ gốc phải đóng',
      dataIndex: 'money',
      key: 'money',
      align: 'right',
      width: 200,
      render: (_, record) => (
        formatCurrency(record.money)
      ),
    },
    {
      title: 'Số tiền lãi',
      dataIndex: 'interest',
      key: 'interest',
      align: 'right',
      width: 200,
      render: (_, record) => (
        formatCurrency(record.interest)
      ),
    },
    {
      title: 'Người gạch nợ',
      dataIndex: 'approvedName',
      key: 'approvedName',
      align: 'left',
      width: 200,
    },
    {
      title: 'Phí trả chậm',
      dataIndex: 'latePaymentFree',
      key: 'latePaymentFree',
      align: 'right',
      width: 150,
      render: (_, record) => (
        formatCurrency(record.latePaymentFree)
      ),
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: '200px',
      render: (_, record) => (
        <Row gutter={[0, 8]} justify='center'>
          {
            record.status === 0 && <Col>
              <Button
                type='link'
                onClick={() => settlement(record)}
              >Xem phiếu thu</Button>
            </Col>
          }
          {
            record.status !== 0 && <Col>
              <Button
                type='primary'
                onClick={() => settlement(record)}
              >Tất toán</Button>
            </Col>
          }
        </Row>
      ),
    },
  ]

  return (
    <Table
      loading={loading}
      bodered={bodered}
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 }))}
      pagination={false}
      rowKey={(record) => record?.stt}
      scroll={{ x: 1024 }}
      size="small"
    />
  );
}

export default PaymentScheduleDetails;