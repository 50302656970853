import systemServices from '../services/system';

// Auth pages
import Login from "./auth/login";

// Errors pages
import Error403 from "./errors/403";
import Error404 from "./errors/404";

import AdminPages from './admin';
import FinbluePages from './finblue';
import InvestPages from './invest';

const getPages = () => {
  const cmsInfo = systemServices.cms_info();
  let servicePages = FinbluePages;
  switch (cmsInfo?.key) {
    case 'CMS_FINBLUE':
      servicePages = FinbluePages;
      break;
    case 'CMS_INVEST':
      servicePages = InvestPages;
    default:
      break;
  }
  return servicePages;
}



export default {
  // Auth pages
  Login,

  // Errors pages
  Error403,
  Error404,

  ...AdminPages,

  // Service pages
  ...getPages()
}
