import { useState, useEffect } from 'react'

import {
    UploadOutlined, 
    LoadingOutlined
} from "@ant-design/icons";

import {Upload, Button, Spin} from 'antd'
import './uploadImage.scss'
import contentService from '../../services/invest/content';
import ENDPOINT from "../../config/endpoints/invest";
// import api from '../../services/invest/upload_file';
// import {actionUploadImage} from './action';
import {message} from "antd/es/index";
import CONSTANTS from "../../config/constants_invest";
import {formatFileName} from "../../utils/common";

const UploadImage = (props) => {
    const { image, onCropSuccess, onUploadSuccess } = props;
    const [cropData, setCropData] = useState(image || null);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [isFetching, setFetching] = useState(false);

    const {
        file_type = CONSTANTS.FILE_TYPE.NEWS_IMG,
        defaultImg = ''
    } = props;

    useEffect(() => {
        setImageUrl(defaultImg);
    }, [defaultImg]);

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    // const getBase64 = (img, callback) => {
    //     const reader = new FileReader();
    //     reader.addEventListener('load', () => {
    //         console.log('reader', reader);
    //         callback(reader.result)
    //     });
    //     reader.readAsDataURL(img);
    // };
    //
    // const handleChange = (info) => {
    //     console.log('handleChange', info);
    //     if (info.file.status === 'uploading') {
    //         setLoading(true);
    //         console.log('uploading');
    //         return;
    //     }
    //     if (info.file.status === 'done') {
    //         console.log('done', info.file);
    //         // Get this url from response in real world.
    //         getBase64(info.file.originFileObj, (url) => {
    //             setLoading(false);
    //             setImageUrl(url);
    //         });
    //     }
    // };

    const uploadImage = (file) => {
        console.log('file_type', file_type);
        setFetching(true);
        let bodyFormData = new FormData();
        console.log('uploadImage', file);
        bodyFormData.append('file', file, formatFileName(file?.name || 'no_name'));
        bodyFormData.append('file_type', file_type);

        try {
            contentService.upload_file(bodyFormData).then((res) => {
                let imgUrl = res?.data;
                setImageUrl(imgUrl);
                onUploadSuccess?.(imgUrl);
                setFetching(false);
            });


            // actionUploadImage(bodyFormData).then(()=> {
            // setCropData(data?.data[0]);
            // onCropSuccess(data?.data[0]);
            // });

            // if (status == 200) {
            //     setCropData(data?.data[0]);
            //     onCropSuccess(data?.data[0]);
            // }
        } catch (error) {
            console.log(error);
            setFetching(false);
        }


    };

    return (
        <Upload
            className='upload-image-component'
            maxCount={1}
            listType='picture-card'
            showUploadList={false}
            accept='image/*'
            action={(file) => {
                uploadImage(file);
                return null;
            }}
            beforeUpload={beforeUpload}
            // onChange={handleChange}

            // action="http://95.216.124.130:4431/auth/upload-image"
            // action={ENDPOINT.UPLOAD_FILE}
            // beforeUpload={(file) => {
            //     uploadImage(file).then((res)=>{
            //         console.log('res', res);
            //     }).catch(error => {
            //         console.log('error', error);
            //     });
            // }}
        >
            <Spin spinning={isFetching} 
                indicator={<LoadingOutlined />} 
                size='large'
            >
                {/*{imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : <UploadOutlined />}*/}
                {imageUrl ?
                    (<img src={imageUrl}/>) :
                    (<Button icon={<UploadOutlined />} />)
                }
            </Spin>
        </Upload>


    )
}

export default UploadImage;