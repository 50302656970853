import React, { useEffect, useMemo, useState } from 'react';
import { Layout, Spin } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './assets/css/index.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-phone-number-input/style.css'

import { navigatePage } from "./utils/navigate";
import { isEmpty, getRouterByLocation, isPermission, getFirstMenu } from "./utils/common";

import AdminLayout from "./layouts/admin"
import AuthLayout from "./layouts/auth"
import ErrorsLayout from "./layouts/errors"

import userServices from "./services/admin/user";
import authServices from "./services/auth";
import systemServices from "./services/system";

import { actionUpdateLoginInfo } from "./store/actions/userInfo";
import { actionUpdateCmsInfo } from "./store/actions/system";

function App() {
  const navigate = useNavigate();
  window.navigatePage = (name, params = {}, query = {}) => navigatePage(navigate, name, params, query);
  
  const loginInfo = useSelector((state) => state.userInfo.loginInfo);
  const cmsInfo = useSelector((state) => state.system.cmsInfo);
  const location = useLocation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchData(loginInfo);
    const currentCmsInfo = systemServices.cms_info()
    if (currentCmsInfo) {
      dispatch(actionUpdateCmsInfo(currentCmsInfo))
    } else {
      systemServices.update_cms_Info(cmsInfo)
      dispatch(actionUpdateCmsInfo(cmsInfo))
    }
  }, [])

  useEffect(() => {
    const currentPage = getRouterByLocation(location)
    if (loginInfo && loginInfo.customerId && currentPage) {
      const firstMenu = getFirstMenu();
      if (['DASHBOARD'].includes(currentPage.name) && firstMenu) {
        window.navigatePage(firstMenu.name)
        return;
      }
      if (currentPage.not_auth && firstMenu && currentPage.name !== firstMenu.name) {
        window.navigatePage(firstMenu.name);
        return;
      }
      if (!isPermission(currentPage.permission_keys) && !currentPage.not_auth && !['403', '404'].includes(currentPage.name)) {
        window.navigatePage('403');
        return
      }
    }
  }, [loginInfo, cmsInfo])

  useEffect(() => {
    if (loginInfo) {
      const currentPage = getRouterByLocation(location)
      if (!currentPage) {
        window.navigatePage('404');
        return;
      }
      if ((isEmpty(loginInfo)) && !currentPage.not_auth) {
        window.navigatePage('LOGIN');
        return;
      }
    }
  }, [location, loginInfo])

  const fetchData = () => {
    const loginInfo = authServices.login_info()
    if (loginInfo && !isEmpty(loginInfo)) {
      setLoading(true)
      userServices.get_user(loginInfo.customerId).then((res) => {
        dispatch(actionUpdateLoginInfo({
          ...loginInfo,
          user: res.data || {}
        }))
        setLoading(false)
      }).catch((error) => {
        dispatch(actionUpdateLoginInfo(loginInfo))
        setLoading(false)
      })
    } else {
      dispatch(actionUpdateLoginInfo(loginInfo))
    }
  }

  const layoutType = useMemo(() => {
    if (location.pathname.includes('/errors/')) {
      return 'errors'
    }
    if (loginInfo && !isEmpty(loginInfo)) {
      return 'admin'
    }
    return 'auth'
  }, [location, loginInfo]);

  return (
    <Spin spinning={loading} style={{ minHeight: '100vh' }}>
      <Layout>
        {
          layoutType === 'admin' && <AdminLayout/>
        }
        {
          layoutType === 'auth' && <AuthLayout/>
        }
        {
          layoutType === 'errors' && <ErrorsLayout/>
        }
      </Layout>
    </Spin>
  );
}

export default App;
