import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/invest";

function dash_sum_customer(params) {
    // params: from_date, to_date
    return request({
        url: ENDPOINT.DASH_SUM_CUSTOMER,
        method: "get",
        params,
    });
}

function dash_cut_off_customer(params) {
    /* params: type_date, type_customer, date
        type_date = 1: cut off theo ngày, = 2 : cut off theo tháng
        type_customer = 1: cut off theo KH tạo mới, = 2 : cut off theo KH online
        date: truyền lên ngày dạng timestamp, cách nhau dấu phẩy
     */
    return request({
        url: ENDPOINT.DASH_CUT_OFF_CUSTOMER,
        method: "get",
        params,
    });
}

function dash_sum_amount_savings(params) {
    // params: from_date, to_date
    return request({
        url: ENDPOINT.DASH_TOTAL_AMOUNT_SAVINGS,
        method: "get",
        params,
    });
}

function dash_cut_off_saving_order(params) {
    /* params: type_date, type_customer, date
        type_date = 1: cut off theo ngày, = 2 : cut off theo tháng
        date: truyền lên ngày dạng timestamp, cách nhau dấu phẩy
     */
    return request({
        url: ENDPOINT.DASH_CUT_OFF_SAVING_ORDER,
        method: "get",
        params,
    });
}

function dash_sum_customer_funds(params) {
    // params: from_date, to_date
    return request({
        url: ENDPOINT.DASH_SUM_CUSTOMER_FUNDS,
        method: "get",
        params,
    });
}

function dash_sum_amount_funds(params) {
    // params: from_date, to_date
    return request({
        url: ENDPOINT.DASH_SUM_AMOUNT_FUNDS,
        method: "get",
        params,
    });
}

function dash_cut_off_transaction_funds(params) {
    /* params: type_date, type, date
        typeDate = 1: cut off theo ngày, = 2 : cut off theo tháng
        type = 1: cut off theo KH tạo mới, = 2 : cut off theo số lượng giao dịch theo ngày, tháng
     */
    return request({
        url: ENDPOINT.DASH_CUT_OFF_TRANSACTION_FUNDS,
        method: "get",
        params,
    });
}

export default {
    dash_sum_customer,
    dash_cut_off_customer,
    dash_sum_amount_savings,
    dash_cut_off_saving_order,
    dash_sum_customer_funds,
    dash_sum_amount_funds,
    dash_cut_off_transaction_funds
};