import React, { useMemo, useState } from "react";
import {
  Row,
  Col,
  Button,
  message
} from 'antd';

import loanServices from '../../../../../services/finblue/loan';
import { UnapprovalModal, ApprovalModal } from "../../../../../components";


const Footer = (props) => {
  const {
    data = {},
    params = {},
    loading = false,
    onReload = () => { }
  } = props

  const [action, setAction] = useState('approval')
  const [callingAPI, setCallingAPI] = useState(false)
  const [unapprovalVisible, setUnapprovalVisible] = useState(false)
  const [approvalVisible, setApprovalVisible] = useState(false)

  const loanUnapproval = async ({ comment }) => {
    setCallingAPI(true);
    loanServices.verify({
      comment: comment,
      orderId: params.id,
      status: 2
    }).then(() => {
      message.success('Từ chối duyệt thành công')
      setCallingAPI(false);
      window.navigatePage('BACK')
      setUnapprovalVisible(false);
    }).catch((error) => {
      setCallingAPI(false);
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const loanVerify = async ({ comment }) => {
    setCallingAPI(true);
    loanServices.verify({
      comment: comment,
      orderId: params.id,
      status: 1
    }).then(() => {
      message.success('Xác nhận giải ngân & Tạo đơn vay thành công')
      setCallingAPI(false);
      window.navigatePage('BACK')
      setApprovalVisible(false);
    }).catch((error) => {
      setCallingAPI(false);
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const loanApproval = (value) => {
    setCallingAPI(true);
    loanServices.approved({
      ...value,
      orderId: params.id,
      status: 3
    }).then(() => {
      message.success('Finblue phê duyệt thành công')
      setCallingAPI(false);
      onReload(false);
      setApprovalVisible(false)
    }).catch((error) => {
      setCallingAPI(false);
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const showApproval = useMemo(() => {
    return data.loanStatus == 0
  }, [data])

  const isCreateLoanOrder = useMemo(() => {
    if (data.businessId == 1) {
      return data.loanStatus == 3 && data.lenderStatus == 1 && data.marketStatus == 1
    }
    return data.loanStatus == 3 && data.lenderStatus == 1
  }, [data])

  return (
    <Row gutter={[8, 8]} className="detail-footer">
      {
        showApproval && <Col lg={12}>
          <Button
            size="large"
            danger
            type="primary"
            className="w-100"
            disabled={loading || callingAPI}
            loading={callingAPI}
            onClick={() => setUnapprovalVisible(true)}
          >
            Từ chối duyệt
          </Button>
        </Col>
      }
      {
        showApproval && <Col lg={12}>
          <Button
            size="large"
            type="primary"
            className="w-100"
            disabled={loading || callingAPI}
            loading={callingAPI}
            onClick={() => {
              setAction('approval')
              setApprovalVisible(true);
            }}
          >
            Duyệt
          </Button>
        </Col>
      }
      {
        isCreateLoanOrder && <Col lg={24}>
          <Button
            size="large"
            type="primary"
            className="w-100"
            disabled={loading || callingAPI}
            loading={callingAPI}
            onClick={() => {
              setAction('verify')
              setApprovalVisible(true);
            }}
          >
            Xác nhận giải ngân thành công & Tạo đơn vay
          </Button>
        </Col>
      }
      {
        unapprovalVisible && <UnapprovalModal
          visible={unapprovalVisible}
          callingAPI={callingAPI}
          onClose={() => setUnapprovalVisible(false)}
          submit={(value) => loanUnapproval(value)}
        />
      }
      {
        approvalVisible && <ApprovalModal
          title={action === 'approval' ? 'Phê duyệt đơn vay' : 'Xác nhận giải ngân & Tạo đơn vay'}
          visible={approvalVisible}
          callingAPI={callingAPI}
          isSelectLender={action === 'approval'}
          onClose={() => setApprovalVisible(false)}
          submit={(value) => action === 'approval' ? loanApproval(value) : loanVerify(value)}
        />
      }
    </Row>
  );
}

export default Footer;