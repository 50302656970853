import React, { useEffect, useState, useMemo } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Form,
  message,
} from 'antd';

import {
} from "@ant-design/icons";

import {
  DEPARTEMNT_NAME_RULES,
} from "../../../../../config/rules";

import departmentServices from '../../../../../services/admin/department';

const DepartmentFormModal = (props) => {
  const [form] = Form.useForm();
  const [callingAPI, setCallingAPI] = useState(false);

  const {
    department = null,
    visible = false,
    onClose = () => { },
    onReload = () => { },
  } = props

  useEffect(() => {
    if (department) {
      form.setFieldsValue({
        name: department.name,
        description: department.description
      })
    } else {
      form.setFieldsValue({
        name: null,
        description: null
      })
    }
  }, [department])

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      setCallingAPI(true);
      if (department) {
        await handleUpdate(values)
      } else {
        await handleCreate(values)
      }
      setCallingAPI(false);
    })
  }

  const handleCreate = async (data) => {
    await departmentServices.create(data).then(() => {
      message.success('Thêm phòng ban mới thành công')
      onReload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const handleUpdate = async (data) => {
    await departmentServices.update(department.id, data).then(() => {
      message.success('Cập nhật phòng ban thành công')
      onReload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  return (
    <Modal
      title={department ? "Chỉnh sửa phòng ban" : "Thêm mới phòng ban"}
      width={400}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => onClose()}
      okButtonProps={{
        loading: callingAPI
      }}
      cancelButtonProps={{
        disabled: callingAPI
      }}
      okText='Lưu'
      cancelText='Hủy'
    >
      <Form
        form={form}
        layout='vertical'
      >
        <Row
          justify={'left'}
          gutter={[16, 8]}
        >
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Tên phòng ban'}
              name={'name'}
              rules={DEPARTEMNT_NAME_RULES}
            >
              <Input
                placeholder={"Vui lòng nhập tên phòng ban"}
                disabled={callingAPI}
              ></Input>
            </Form.Item>
          </Col>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Mô tả'}
              name={'description'}
              rules={[]}
            >
              <Input.TextArea
                placeholder={"Vui lòng nhập mô tả"}
                disabled={callingAPI}
              ></Input.TextArea>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>

  );
}

export default DepartmentFormModal;