import React from "react";
import '../css/components/TableData.scss'
import {
  Row,
  Table,
  Button,
  Tag,
} from 'antd';

import {
  EditOutlined
} from "@ant-design/icons";

import {
  customerStatus
} from '../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    params
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Tag color={customerStatus(record.status)?.tag_color}>
          {customerStatus(record.status)?.label}
        </Tag>
      ),
    },
    {
      title: 'Mã khách hàng',
      dataIndex: 'customerId',
      key: 'customerId',
      align: 'left',
      width: 120,
      render: (_, record) => `KH_${record.customerId}`
    },
    {
      title: 'Họ tên',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: 120,
    },
    {
      title: 'Mã hợp đồng',
      dataIndex: 'contractId',
      key: 'contractId',
      align: 'left',
      width: 120,
      render: (_, record) => record.contractId ? `HĐ_${record.contractId}` : 'Chưa có HĐ'
    },
    {
      title: 'SL hạn mức',
      dataIndex: 'numberCreditLimit',
      key: 'numberCreditLimit',
      align: 'center',
      width: 120,
      render: (_, record) => record.numberCreditLimit || 0
    },
    {
      title: 'SL đơn vay',
      dataIndex: 'numberOrder',
      key: 'numberOrder',
      align: 'center',
      width: 120,
      render: (_, record) => record.numberOrder || 0
    },
    {
      title: 'Số đơn quá hạn',
      dataIndex: 'orderOver',
      key: 'orderOver',
      align: 'center',
      width: 120,
      render: (_, record) => record.orderOver || 0
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: '200px',
      render: (_, record) => (
        <Row gutter={[0, 8]} justify='center'>
          <Button
            icon={<EditOutlined />}
            type='primary'
            size="small"
            ghost
            onClick={() => window.navigatePage('CUSTOMER_DETAIL', { id: record.customerId }, window.location.search)}
          >Chi tiết</Button>
        </Row>
      ),
    },
  ]

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 + (params.page - 1) * params.pageSize }))}
      loading={loading}
      pagination={false}
      rowKey={(record) => record?.stt}
      scroll={{ x: 1024 }}
      size="small"
    />
  );
}

export default TableData;