import React from "react";
import "../css/components/TableData.scss";
import {Row, Table, Button, Modal, Tag, Popconfirm, Col} from "antd";

import {EditOutlined} from "@ant-design/icons";
import {useState} from "react";
import {formatMoneyCurrency} from "../../../../utils/common";
import moment from 'moment';
import CONSTANTS from "../../../../config/constants_invest";
import {getObjectStatus} from "../../../../utils/common_invest";
import {useTranslation} from "react-i18next";

const TableData = (props) => {
    const {
        data,
        loading,
        params,
        showDetail = () => {},
        source = '',
        onSelect = () => {}
    } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { t } = useTranslation();
    const texts = t;

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: texts('no.'),
            dataIndex: "stt",
            key: "stt",
            align: "center",
        },
        {
            title: texts('fullname'),
            dataIndex: "acc_fullname",
            key: "acc_fullname",
            align: "center",
            render: (_, record) => {return record.acc_fullname || record?.acc_phone}
        },

        {
            title: texts('code'),
            dataIndex: "sale_unique_code",
            key: "sale_unique_code",
            align: "center",
        },
        {
            title: texts('status'),
            dataIndex: "roleStatus",
            key: "roleStatus",
            align: "center",
            render: (_, record) =>
                <Tag
                    size={'default'}
                    color={getObjectStatus(CONSTANTS.SALES_STATUS, record.roleStatus)?.color}>
                    {getObjectStatus(CONSTANTS.SALES_STATUS, record.roleStatus)?.label}
                </Tag>
        },
        {
            title: texts('created_date'),
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            render: (_, record) => moment(record?.createdAt).format('DD/MM/YYYY')
        },
        {
            title: texts('actions'),
            dataIndex: "actions",
            key: "actions",
            align: "center",
            render: (_, record) => (
                <Row gutter={[8, 8]} justify={'center'}>
                    {source === 'CUSTOMER' && (
                        <Col>
                            <Popconfirm
                                title={`Assign customer`}
                                description={`Are you sure to assign salesman ${record?.acc_fullname} to this customer?`}
                                onConfirm={() => onSelect(record)}
                                // onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    // onClick={() => assignCustomerToSales(record)}
                                    ghost
                                    type='primary'
                                    size='small'>
                                    {texts('assign')}
                                </Button>
                            </Popconfirm>

                        </Col>
                    )}
                    <Col>
                        <Button
                            ghost
                            type='primary'
                            size='small'
                            onClick={() => showDetail(record)}>
                            {texts('detail')}
                        </Button>
                    </Col>
                </Row>
            ),
        },
    ];

    return (
        <Table
            bordered
            className="table-data"
            columns={columns}
            dataSource={data.map((d, index) => ({
                ...d,
                stt: index + 1 + (params.page - 1) * params.size,
            }))}
            loading={loading}
            pagination={false}
            rowKey={(record) => record?.id}
            size="middle"
        />
    );
};

export default TableData;
