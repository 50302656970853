import React from "react";
import {
  Row,
  Table,
  Col,
  Button
} from 'antd';

import {
  EditOutlined,
  ArrowDownOutlined
} from "@ant-design/icons";

import {
} from '../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    params,
    onDivide = () => { }
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Mã nhân viên',
      dataIndex: 'customerId',
      key: 'id',
      align: 'center',
      width: 150,
      render: (_, record) => `${record.customerId}`
    },
    {
      title: 'Họ tên',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
      render: (_, record) => <a>
        {record.name}
      </a>
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: 150,
    },
    {
      title: 'Hợp đồng hạn mức',
      key: 'contracts',
      children: [
        {
          title: 'Chưa duyệt',
          dataIndex: 'contractUnApproved',
          key: 'contractUnApproved',
          align: 'center',
          width: 120,
        },
        {
          title: 'Đã duyệt',
          dataIndex: 'contractApproved',
          key: 'contractApproved',
          align: 'center',
          width: 120,
        },
      ]
    },
    {
      title: 'Đơn vay',
      key: 'orders',
      children: [
        {
          title: 'Chưa duyệt',
          dataIndex: 'orderUnApproved',
          key: 'orderUnApproved',
          align: 'center',
          width: 120,
        },
        {
          title: 'Đã duyệt',
          dataIndex: 'orderApproved',
          key: 'orderApproved',
          align: 'center',
          width: 120,
        },
      ]
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: 250,
      render: (_, record) => (
        <Row gutter={[8, 8]} justify={'center'}>
          <Col>
            <Button
              icon={<EditOutlined />}
              type='primary'
              size="small"
              ghost
              onClick={() => window.navigatePage('ASSESSOR_DETAIL', { id: record?.customerId })}
            >Chi tiết</Button>
          </Col>
          <Col>
            <Button
              icon={<ArrowDownOutlined />}
              type='primary'
              size="small"
              onClick={() => onDivide(record)}
            >Giao việc</Button>
          </Col>
        </Row>
      ),
    },
  ]

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 + (params.page - 1) * params.pageSize }))}
      loading={loading}
      pagination={false}
      scroll={{ x: 1024 }}
      rowKey={(record) => record?.customerId}
      size="small"
    />
  );
}

export default TableData;