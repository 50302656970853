import React from "react";
import {
  Table,
  Row,
  Button
} from 'antd';

import {
  EditOutlined
} from "@ant-design/icons";

import {
  formatCurrency,
  formatNumber,
  customerRank
} from '../../../../../utils/common';

import dayjs from 'dayjs';

const TableData = (props) => {
  const {
    data,
    loading,
    params,
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Khách hàng',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
      render: (_, record) => <a onClick={() => window.navigatePage('CUSTOMER_DETAIL', { id: record.customerId })}>
        {record.name}
      </a>
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
      align: 'right',
      width: 120,
    },
    {
      title: 'Tiền nợ',
      dataIndex: 'totalMoneyDebt',
      key: 'totalMoneyDebt',
      align: 'right',
      width: 200,
      render: (_, record) => formatCurrency(record.totalMoneyDebt)
    },
    {
      title: 'Số đơn nợ',
      dataIndex: 'totalOrderDebt',
      key: 'totalOrderDebt',
      align: 'right',
      width: 200,
      render: (_, record) => formatNumber(record.totalOrderDebt)
    },
    {
      title: 'Phân hạng',
      dataIndex: 'rank',
      key: 'rank',
      align: 'center',
      width: 200,
      render: (_, record) => customerRank(record.rank).label,
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (_, record) => (
        <Row gutter={[0, 8]}>
          <Button
            icon={<EditOutlined />}
            type='link'
            onClick={() => window.navigatePage(
              'DEBT_DETAIL',
              { customer_id: record.customerId },
              {
                begin: dayjs(params.dates[0]).unix(),
                end: dayjs(params.dates[1]).unix(),
              }
            )}
          >Chi tiết</Button>
        </Row>
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 }))}
      loading={loading}
      pagination={false}
      rowKey={(record) => record?.stt}
      scroll={{ x: 1024 }}
      size="small"
    />
  );
}

export default TableData;