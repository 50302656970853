import React from "react";
import {
  Table,
  Tag,
  Button,
  Row,
  Col
} from 'antd';

import {
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import {
  activeStatus
} from '../../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    params,
    edit,
    remove,
    receipts = () => { }
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 60,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      width: 120,
      render: (_, record) => (
        <Tag color={activeStatus(record.active)?.tag_color}>
          {activeStatus(record.active)?.label}
        </Tag>
      ),
    },
    {
      title: 'Tên tài khoản',
      dataIndex: 'bankAccountName',
      key: 'bankAccountName',
      align: 'left',
      width: 150,
    },
    {
      title: 'Số tài khoản',
      dataIndex: 'bankAccountNumber',
      key: 'bankAccountNumber',
      align: 'left',
      width: 150,
    },
    {
      title: 'Ngân hàng',
      dataIndex: 'bankName',
      key: 'bankName',
      align: 'left',
      width: 200,
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'bankBranchName',
      key: 'bankBranchName',
      align: 'left',
      width: 200,
    },
    {
      title: 'Phiếu thu',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      width: 120,
      render: (_, record) => (
        <Button
          type='link'
          size="small"
          ghost
          onClick={() => window.navigatePage('BANK_ACCOUNT_RECEIPTS', { id: record.id })}
        >Chi tiết</Button>
      ),
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: 200,
      render: (_, record) => (
        <Row gutter={[8, 8]} justify={'center'}>
          <Col>
            <Button
              icon={<EditOutlined />}
              type='primary'
              size="small"
              ghost
              onClick={() => edit(record)}
            >Sửa</Button>
          </Col>
          <Col>
            <Button
              icon={<DeleteOutlined />}
              type='primary'
              size="small"
              danger
              ghost
              onClick={() => remove(record)}
            >Xoá</Button>
          </Col>
        </Row>
      ),
    },
  ]

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 + (params.page - 1) * params.pageSize }))}
      loading={loading}
      pagination={false}
      scroll={{ x: 1024 }}
      rowKey={(record) => record?.id}
      size="small"
    />
  );
}

export default TableData;