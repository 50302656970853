import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Tabs, Image, Row, Col, Form, Button, Popconfirm, Tag} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminDetailHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import userServices from "../../../services/admin/user";
import customerServices from "../../../services/invest/customer";
import { useParams } from 'react-router-dom';
import CustomerInfoTab from './customer_info_tab';
import CustomerTransactionTab from './customer_transaction_tab';
import moment from "moment/moment";
import {useTranslation} from "react-i18next";

const EkycApproveDetail = (props) => {
    const {router} = props;
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState({});
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [callingAPI, setCallingAPI] = useState(false)
    const [actionKey, setActionKey] = useState('')
    const [params, setParams] = useState({
        page: 1,
        size: 10,
    });

    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);
        let account_id = id;
        await customerServices.customer_detail({account_id})
            .then((response) => {
                setCustomer(response?.data || {});
                // setTotal(response?.page?.total_elements);
            })
            .catch(() => {
                // setData([]);
            });
        setLoading(false);
    };

    const reject_id_text = 'Reject the id';
    const approve_id_text = 'Approve the id';
    const reject_id_description = 'Are you sure to reject this id?';
    const approve_id_description = 'Are you sure to approve this id?';
    const reject_selfie_text = 'Reject the selfie image';
    const approve_selfie_text = 'Approve the selfie image';
    const reject_selfie_description = 'Are you sure to reject this selfie image?';
    const approve_selfie_description = 'Are you sure to approve this selfie image?';

    const approveIdCard = (kyc_status) => {
        setLoading(true);
        let data = {
            account_id: customer?.rowId || 0,
            kyc_status,
            check_selfie: customer.checkSelfie
        };
        customerServices.verify_kyc(data).then(() => {
            setLoading(false);
            fetchData();
        });
    };

    const approveSelfie = (check_selfie) => {
        setLoading(true);
        let data = {
            account_id: customer?.rowId || 0,
            kyc_status: customer.accKyc,
            check_selfie
        };
        customerServices.verify_kyc(data).then(() => {
            setLoading(false);
            fetchData();
        });
    };

    const kycStatus = (status) => {
        console.log('status', status);
        switch (status) {
            case 1:
                return (
                    <Tag
                        size={'default'}
                        color={'green'}>
                        {texts('approve')}
                    </Tag>
                );
            case 2:
                return (
                    <Tag
                        size={'default'}
                        color={'red'}>
                        {texts('pending')}
                    </Tag>
                );
            case 3:
                return (
                    <Tag
                        size={'default'}
                        color={'red'}>
                        {texts('reject')}
                    </Tag>
                );
            default:
                return (
                    <Tag
                        size={'default'}
                        color={'red'}>
                        {texts('init')}
                    </Tag>
                );
        }
    };

    return (
        <div className="ekyc-approve">
            <AdminDetailHeader
                title={texts('ekyc_approve')}
            />

            <Row justify={'left'} gutter={[8, 8]} style={{marginTop: 20}}>
                <Col span={12} lg={12} sm={24} xs={24}>
                    <Form.Item label={texts('id_front_photo')}>
                        <Image width={300} src={customer?.imageFrontCardId}/>
                    </Form.Item>
                    <Form.Item label={texts('id_back_photo')}>
                        <Image width={300} src={customer?.imageBackCardId}/>
                    </Form.Item>
                    <Form.Item label={texts('selfie_photo')}>
                        {customer?.imageSelfie ?
                            <Image width={300} src={customer?.imageSelfie}/>
                            :
                            texts('no_data')
                        }
                    </Form.Item>
                </Col>
                <Col span={12} lg={12} sm={24} xs={24}>
                    <table>
                        <tbody>
                            <tr>
                                <td className='content-label'>{texts('customer_id')}:</td>
                                <td className='content-value'>{customer?.rowId}</td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('phone_number')}:</td>
                                <td className='content-value'>{customer?.accPhone}</td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('fullname')}:</td>
                                <td className='content-value'>{customer?.accFullname || texts('no_data')}</td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('id_number')}:</td>
                                <td className='content-value'>{customer?.accIdNumber || texts('no_data')}</td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('id_issue_date')}:</td>
                                <td className='content-value'>{customer?.accIdIssueDate ? moment(customer?.accIdIssueDate).format('DD/MM/YYYY') : texts('no_data')}</td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('id_issue_place')}:</td>
                                <td className='content-value'>{customer?.accIdIssueBy || texts('no_data')}</td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('id_verify_status')}:</td>
                                <td className='content-value'>
                                    {customer?.accKyc === 1 ?
                                        texts('verified')
                                        :
                                        <Row gutter={[8, 8]}>
                                            <Col>
                                                <Popconfirm
                                                    placement="top"
                                                    title={reject_id_text}
                                                    description={reject_id_description}
                                                    onConfirm={()=>approveIdCard(0)}
                                                    okText="Yes"
                                                    cancelText="No">
                                                    <Button
                                                        danger
                                                        type="primary"
                                                        disabled={loading || (callingAPI && actionKey !== 'reject')}
                                                        loading={callingAPI && actionKey === 'reject'}
                                                    >
                                                        {texts('reject')}
                                                    </Button>
                                                </Popconfirm>

                                            </Col>
                                            <Col>
                                                <Popconfirm
                                                    placement="top"
                                                    title={approve_id_text}
                                                    description={approve_id_description}
                                                    onConfirm={()=>approveIdCard(1)}
                                                    okText="Yes"
                                                    cancelText="No">
                                                    <Button
                                                        type="primary"
                                                        disabled={loading || (callingAPI && actionKey !== 'approve')}
                                                        loading={callingAPI && actionKey === 'approve'}
                                                    >
                                                        {texts('approve')}
                                                    </Button>
                                                </Popconfirm>

                                            </Col>
                                        </Row>
                                    }
                                </td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('selfie_status')}:</td>
                                <td className='content-value'>
                                    {kycStatus(customer?.checkSelfie)}
                                </td>
                            </tr>

                            {customer?.checkSelfie !== 1 ?
                                <tr>
                                    <td className='content-label'></td>
                                    <td className='content-value'>
                                        <Row gutter={[8, 8]}>
                                            <Col>
                                                <Popconfirm
                                                    placement="top"
                                                    title={reject_selfie_text}
                                                    description={reject_selfie_description}
                                                    onConfirm={()=>approveSelfie(0)}
                                                    okText="Yes"
                                                    cancelText="No">
                                                    <Button
                                                        danger
                                                        type="primary"
                                                        disabled={loading || (callingAPI && actionKey !== 'reject')}
                                                        loading={callingAPI && actionKey === 'reject'}
                                                    >
                                                        {texts('reject')}
                                                    </Button>
                                                </Popconfirm>

                                            </Col>
                                            <Col>
                                                <Popconfirm
                                                    placement="top"
                                                    title={approve_selfie_text}
                                                    description={approve_selfie_description}
                                                    onConfirm={()=>approveSelfie(1)}
                                                    okText="Yes"
                                                    cancelText="No">
                                                    <Button
                                                        type="primary"
                                                        disabled={loading || (callingAPI && actionKey !== 'approve')}
                                                        loading={callingAPI && actionKey === 'approve'}
                                                    >
                                                        {texts('approve')}
                                                    </Button>
                                                </Popconfirm>

                                            </Col>
                                        </Row>
                                    </td>
                                </tr>
                                :
                                null
                            }

                        </tbody>
                    </table>


                </Col>
            </Row>
        </div>
    );
};

export default EkycApproveDetail;
