import React, { } from "react";
import {
  Row,
  Col,
  Card
} from 'antd';

const Statistics = (props) => {
  const {
    statistics = {},
    setParams = () => { }
  } = props

  return (
    <Row
      className="statistics"
      justify={'left'}
      gutter={[8, 8]}
    >
      {
        statistics.map((s, index) => <Col key={index}>
          <Card
            size="small"
            className="statistics__item cursor-pointer w-100"
            style={{
              borderColor: s.tag_color,
            }}
            bodyStyle={{
              padding: 12,
              textAlign: 'center',
              color: s.tag_color,
            }}
            onClick={() => setParams(s)}
          >
            <p className="statistics__item--label">{s.label}:</p>
            <p className="statistics__item--total">{s.total}</p>
          </Card>
        </Col>)
      }
    </Row>
  );
}

export default Statistics;