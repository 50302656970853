import {green, red, orange} from "@ant-design/colors";

export default {
    APP_TITLE: "Fibo Capital - Quản trị",
    DATE_FORMAT: "DD-MM-YYYY",
    DATE_TIME_FORMAT: "HH:mm DD-MM-YYYY",
    CMS_APPS: [
        {
            key: 'CMS_FINBLUE',
            value: 'bnpl',
            label: 'Blue Credit'
        },
        {
            key: 'CMS_INVEST',
            value: 'invest',
            label: 'Blue Invest'
        }
    ],
    CONTRACT_STATUS: [
        {
            value: 0,
            tag_color: "blue",
            label: "Chờ phê duyệt",
            statistic_key: "waitApproval",
        },
        {
            value: 1,
            tag_color: "green",
            label: "Đã phê duyệt",
            statistic_key: "approved",
        },
        {
            value: 2,
            tag_color: "orange",
            label: "Yêu cầu nộp lại",
            statistic_key: "requiredUpdate",
        },
        {
            value: 3,
            tag_color: 'red',
            label: 'Từ chối duyệt',
            statistic_key: 'cancelled'
        },
        {
            value: 4,
            tag_color: "#391A5F",
            label: "Cập nhật lại",
            statistic_key: "updated",
        },
    ],

    FIELD_ACTIONS: [
        {
            value: 1,
            color: green.primary,
            label: "Xác nhận",
        },
        {
            value: 2,
            color: orange.primary,
            label: "Nộp lại",
        },
        {
            value: 3,
            color: red.primary,
            label: "Từ chối",
        },
    ],

    EKYC_STATUS: [
        {
            value: 0,
            tag_color: 'blue',
            label: 'Chờ phê duyệt',
            statistic_key: 'waitApproved'
        },
        {
            value: 1,
            tag_color: 'green',
            label: 'Đã phê duyệt',
            statistic_key: 'approved'
        },
        {
            value: 2,
            tag_color: 'red',
            label: 'Từ chối duyệt',
            statistic_key: 'cancel'
        },
    ],

    LOAN_STATUS: [
        {
            value: 0,
            tag_color: 'blue',
            label: 'Chờ phê duyệt',
            statistic_key: 'waitApproval'
        },
        {
            value: 1,
            tag_color: "green",
            label: "Đã phê duyệt",
            statistic_key: "approved",
        },
        {
            value: 2,
            tag_color: 'red',
            label: 'Từ chối duyệt',
            statistic_key: 'cancelled'
        },
        {
            value: 3,
            tag_color: '#391A5F',
            label: 'Chờ phê duyệt 3 bên',
            statistic_key: 'tripartiteAuthentication'
        }
    ],

    LOAN_CUSTOMER_STATUS: [
        {
            value: 0,
            tag_color: "blue",
            label: "Đã tạo đơn",
            statistic_key: "total",
        },
        {
            value: 1,
            tag_color: "blue",
            label: "Đang vay",
            statistic_key: "orderActive",
        },
        {
            value: 2,
            tag_color: 'green',
            label: 'Đã hoàn thành',
            statistic_key: 'orderCompleted'
        },
        {
            value: 3,
            tag_color: 'red',
            label: 'Quá hạn',
            statistic_key: 'orderOverdue'
        }
    ],

    MARKET_STATUS: [
        {
            value: null,
            tag_color: 'blue',
            label: 'Chờ đối tác duyệt',
        },
        {
            value: 0,
            tag_color: 'blue',
            label: 'Chờ phê duyệt',
        },
        {
            value: 1,
            tag_color: "green",
            label: "Đã phê duyệt",
            action: 'Phê duyệt'
        },
        {
            value: 2,
            tag_color: 'red',
            label: 'Từ chối duyệt',
        }
    ],

    LENDER_STATUS: [
        {
            value: null,
            tag_color: 'blue',
            label: 'Chờ đối tác duyệt',
        },
        {
            value: 0,
            tag_color: 'blue',
            label: 'Chưa phê duyệt',
        },
        {
            value: 1,
            tag_color: "green",
            label: "Xác nhận giải ngân",
        },
        {
            value: 2,
            tag_color: 'red',
            label: 'Từ chối duyệt',
        },
        {
            value: 3,
            tag_color: 'blue',
            label: 'Đồng ý giải ngân',
        }
    ],

    GENDERS: [
        {
            value: 0,
            label: 'Nam',
        },
        {
            value: 1,
            label: 'Nữ',
            statistic_key: 'approved'
        },
        {
            value: 2,
            label: 'Khác',
        },
    ],

    MATCH_VALUES: [
        {
            value: 'yes',
            label: 'Trùng khớp',
        },
        {
            value: 'no',
            label: 'Không trùng khớp',
        },
        {
            value: '',
            label: 'Chưa có đánh giá',
        },
    ],

    REVIEWED_STATUS: [
        {
            value: 'yes',
            label: 'Đã kiểm tra lại',
        },
        {
            value: 'no',
            label: 'Không kiểm tra lại',
        },
    ],

    ACTIVE_STATUS: [
        {
            value: false,
            tag_color: 'red',
            label: 'Không hoạt động',
        },
        {
            value: true,
            tag_color: 'green',
            label: 'Hoạt động',
        },
    ],
    INVEST_PROFIT_CAPITAL_DEAL: [
        {value: 1, name: 'Lãi và gốc trả về tài khoản'},
        {value: 2, name: 'Lãi trả về tài khoản, gốc tái đầu tư'},
        {value: 3, name: 'Lãi và gốc tái đầu tư'},
    ],

    FMARKET_TRANSACTION_STATUS: [
        {value: 'SELL', color: 'red'},
        {value: 'BUY', color: 'green'},
        {value: 'TRANSFER_SELL', color: 'gold'},
        {value: 'TRANSFER_BUY', color: 'blue'},
        {value: 'INIT', color: 'red'},
        {value: 'PENDING', color: 'gold'},
    ],

    SAVINGS_PRODUCT_ACTIVE_STATUS: [
        {
            value: 0,
            color: 'red',
            label: 'hide',
        },
        {
            value: 1,
            color: 'green',
            label: 'show',
        },
    ],
    BLACK_LIST_STATUS: [
        {
            value: 0,
            tag_color: 'green',
            label: 'Không nằm trong black list',
        },
        {
            value: 1,
            tag_color: 'red',
            label: 'Nằm trong black list',
        },
    ],
    CUSTOMER_STATUS: [
        {
            value: 0,
            tag_color: 'blue',
            label: 'Đã tạo tài khoản',
            statistic_key: "createAccount",
        },
        {
            value: 1,
            tag_color: "blue",
            label: "Đã tạo hợp đồng",
            statistic_key: "createContract",
        },
        {
            value: 2,
            tag_color: 'blue',
            label: 'Đã tạo đơn vay',
            statistic_key: 'createLoanOrder'
        },
        {
            value: 3,
            tag_color: 'blue',
            label: 'Đang có đơn vay',
            statistic_key: "orderActive",
        },
        {
            value: 4,
            tag_color: 'green',
            label: 'Đã hoàn thành đơn vay',
            statistic_key: "orderCompleted",
        },
        {
            value: 5,
            day_num: 0,
            tag_color: 'red',
            label: 'Đơn vay quá hạn',
            statistic_key: "orderOverDue",
        },
        {
            value: 5,
            day_num: 31,
            tag_color: 'red',
            label: 'Đơn vay quá hạn 30 ngày',
            statistic_key: "orderOverDue30",
        },
        {
            value: 5,
            day_num: 61,
            tag_color: 'red',
            label: 'Đơn vay quá hạn 60 ngày',
            statistic_key: "orderOverDue60",
        },
        {
            value: 5,
            day_num: 91,
            tag_color: 'red',
            label: 'Đơn vay quá hạn 90 ngày',
            statistic_key: "orderOverDue90",
        }
    ],
    PAYMENT_STATUS: [
        {
            value: 0,
            tag_color: 'green',
            label: 'Đã tất toán',
        },
        {
            value: 1,
            tag_color: 'blue',
            label: 'Chưa đến hạn',
        },
        {
            value: 2,
            tag_color: 'red',
            label: 'Đã quá hạn',
        },
    ],
    MARKET_ORDER_STATUS: [
        {
            value: 0,
            tag_color: 'blue',
            label: 'Chưa thanh toán',
            statistic_key: 'orderActive'
        },
        {
            value: 1,
            tag_color: 'green',
            label: 'Đã thanh toán',
            statistic_key: 'orderCompleted'
        },
        {
            value: 2,
            tag_color: 'red',
            label: 'Đã quá hạn',
            statistic_key: 'orderOverDue'
        },
    ],
    LENDER_ORDER_STATUS: [
        {
            value: 0,
            tag_color: 'blue',
            label: 'Chưa thanh toán',
            statistic_key: 'orderActive'
        },
        {
            value: 1,
            tag_color: 'green',
            label: 'Đã thanh toán',
            statistic_key: 'orderCompleted'
        },
        {
            value: 2,
            tag_color: 'red',
            label: 'Đã quá hạn',
            statistic_key: 'orderOverdue'
        },
    ],

    FMARKET_TRANSACTION_STATUS: [
        {value: 'SELL', color: 'red'},
        {value: 'BUY', color: 'green'},
        {value: 'TRANSFER_SELL', color: 'gold'},
        {value: 'TRANSFER_BUY', color: 'blue'},
        {value: 'INIT', color: 'red'},
        {value: 'PENDING', color: 'gold'},
    ],

    SAVINGS_PRODUCT_DISPLAY_STATUS: [
        {
            value: 0,
            color: 'red',
            label: 'Hide',
        },
        {
            value: 1,
            color: 'green',
            label: 'Show',
        },
    ],

    QUESTION_ANSWER_STATUS: [
        {
            value: 0,
            color: 'red',
            label: 'hide',
        },
        {
            value: 1,
            color: 'green',
            label: 'show',
        },
    ],

    NEWS_DISPLAY_STATUS: [
        {
            value: 0,
            color: 'red',
            label: 'hide',
        },
        {
            value: 1,
            color: 'green',
            label: 'show',
        },
    ],

    MARKET_TYPES: [
        {
            value: 1,
            label: 'Multi vendors Platform'
        },
        {
            value: 2,
            label: 'Brand’s Platform'
        }
    ],

    CUSTOMER_PAYMENT_STATUS: [
        {
            value: 0,
            label: 'Chưa thanh toán',
            tag_color: 'blue',
            statistic_key: 'orderNoComplete'
        },
        {
            value: 1,
            label: 'Hoàn thành',
            tag_color: 'green',
            statistic_key: 'complete'
        },
        {
            value: 2,
            label: 'Quá hạn',
            tag_color: 'red',
            statistic_key: 'overDue'
        }
    ],

//    FORCE_SETTLE_STATUS: [
//        {name: 'approve_pending', value: 2},
//        {name: 'approve', value: 0},
//        {name: 'reject', value: 3},
//        {name: 'complete', value: 200},
////    { name: 'review', value: 2 },
//        // {name: 'undue', value: 5},
//    ],


    FORCE_SETTLE_STATUS: {
        PENDING: {name: 'approve_pending', value: 2, color: '#f1c40f'},
        APPROVE: {name: 'approve', value: 0, color: '#3498db'},
        REJECT: {name: 'reject', value: 3, color: '#e74c3c'},
        COMPLETE: {name: 'complete', value: 200, color: '#27ae60'},
    },

    CUSTOMER_RANK: [
        {
            value: 0,
            color: 'blue',
            label: 'Standard',
        },
        {
            value: 1,
            color: 'green',
            label: 'Premium',
        },
    ],
    PERMISSION_METHODS: [
        {
            label: 'Xem',
            value: 'GET'
        },
        {
            label: 'Thêm mới',
            value: 'POST'
        },
        {
            label: 'Cập nhật',
            value: 'PUT'
        },
        {
            label: 'Chỉnh sửa',
            value: 'PATCH'
        },
        {
            label: 'Xóa',
            value: 'DELETE'
        }
    ],
    APPOTA_STATUS: [
        {
            value: null,
            label: 'Tổng số API',
            tag_color: 'blue',
            statistic_key: 'totalApi'
        },
        {
            value: 0,
            label: 'Thành công',
            tag_color: 'green',
            statistic_key: 'success'
        },
        {
            value: 1,
            label: 'Lỗi',
            tag_color: 'red',
            statistic_key: 'error'
        },
        {
            value: null,
            label: 'Tổng số tiền giao dịch',
            tag_color: 'blue',
            type: 'money',
            statistic_key: 'amountOrder'
        },
    ],
    ASSESSOR_CONTRACT_STATUS: [
        {
            value: 0,
            tag_color: "blue",
            label: "Chờ phê duyệt",
            statistic_key: "unApproved",
        },
        {
            value: 1,
            tag_color: "green",
            label: "Đã phê duyệt",
            statistic_key: "approved",
        },
    ],
    ASSESSOR_ORDER_STATUS: [
        {
            value: 0,
            tag_color: "blue",
            label: "Chờ phê duyệt",
            statistic_key: "unApproved",
        },
        {
            value: 1,
            tag_color: "green",
            label: "Đã phê duyệt",
            statistic_key: "approved",
        },
    ],
};
