import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Tabs, Spin} from "antd";
import CustomerFmkAccountTab from './customer_fmarket_account';
import CustomerFmkTransactionsTab from './customer_fmk_transactions';

const CustomerFmarketTab = (props) => {
    const {customer} = props;

    return (
        <Tabs
            defaultActiveKey={"CUSTOMER-INFO"}
            className="tab-info"
            items={[
                {
                    label: 'Thông tin đăng ký tài khoản',
                    key: 'CUSTOMER-FMK-INFO',
                    children: <CustomerFmkAccountTab customer={customer}/>,
                },
                {
                    label: 'Lịch sử giao dịch',
                    key: 'CUSTOMER-FMK-TRANSACTIONS',
                    children: <CustomerFmkTransactionsTab customer={customer}/>
                },
            ]}
        />
    );
};

export default CustomerFmarketTab;
