import React from "react";
import "../css/components/Filter.scss";
import {Row, Col, Input, Select} from "antd";

import {SearchOutlined} from "@ant-design/icons";
import CONSTANTS from "../../../../config/constants_invest";
import {useTranslation} from "react-i18next";

const Filter = (props) => {
    const {
        params = {},
        setParams = () => {}
    } = props;

    const { t } = useTranslation();
    const texts = t;

    const selectChange = (value) => {
        setParams({...params, customer_investment_type: value});
    };

    return (
        <Row className="filter" gutter={[8, 8]}>
            <Col span={3} lg={6} sm={12} xs={24}>
                <Input
                    prefix={<SearchOutlined/>}
                    value={params.keyword}
                    placeholder={texts('search')}
                    onChange={(e) => setParams({...params, keyword: e.target.value})}
                />
            </Col>

            <Col span={3} lg={6} sm={12} xs={24}>
                <Select
                    placeholder={"Select status"}
                    value={params?.customer_investment_type}
                    onChange={(value) => selectChange(value)}
                    showSearch={true}
                    optionFilterProp="children"
                    className="w-full"
                    filterOption={(input, option) =>
                        `${option.children}`.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {CONSTANTS.CUSTOMER_INVESTMENT_TYPE.map((option) => (
                        <Select.Option value={option?.value} key={option?.value}>
                            {texts(option?.label)}
                        </Select.Option>
                    ))}
                </Select>
            </Col>
        </Row>
    );
};

export default Filter;
