import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Spin} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminHeader} from "../../../components";
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import Filter from "./components/Filter";
import TableData from "./components/TableData";
import userServices from "../../../services/admin/user";
import customerServices from "../../../services/invest/customer";
import CONSTANTS from "../../../config/constants_invest";

const CustomerList = (props) => {
    const {source = '', salesAccountId = 0, onSuccess = () => {}} = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    const [searchParams] = useSearchParams();
    const type = searchParams.get('type');

    const [params, setParams] = useState({
        page: 1,
        size: 10,
        customer_investment_type: parseInt(type) || CONSTANTS.CUSTOMER_INVESTMENT_TYPE[0].value,
    });

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = () => {
        setLoading(true);
        console.log('params', params);
        switch (params?.customer_investment_type){
            case CONSTANTS.CUSTOMER_INVESTMENT_TYPE?.[0]?.value:
                getListCustomer();
                break;
            case CONSTANTS.CUSTOMER_INVESTMENT_TYPE?.[1]?.value:
                getListCustomerSavings();
                break;
            case CONSTANTS.CUSTOMER_INVESTMENT_TYPE?.[2]?.value:
                getListCustomerFmarket();
                break;
        }
    };

    const getListCustomer = async() => {
        let requestParams = {
            ...params,
            page: params.page - 1,
            pageSize: params.size,
        };
        delete requestParams?.customer_investment_type;
        delete requestParams?.fromDate;
        delete requestParams?.toDate;

        await customerServices
            .customer_search(requestParams)
            .then((response) => {
                setData(response?.data || []);
                setTotal(response?.page?.total_elements);
                setLoading(false);
            })
            .catch(() => {
                setData([]);
                setLoading(false);
            });
    };

    const getListCustomerSavings = async() => {
        let fromDate = params?.fromDate || null;
        let toDate = params?.toDate || null;
        await customerServices
            .report_saving_transactions_customer(fromDate, toDate)
            .then((response) => {
                setData(response?.data || []);
                setTotal(response?.page?.total_elements);
                setLoading(false);
            })
            .catch(() => {
                setData([]);
                setLoading(false);
            });
    };

    const getListCustomerFmarket = async() => {
        let fromDate = params?.fromDate || null;
        let toDate = params?.toDate || null;
        await customerServices
            .report_fmarket_transactions_customer(fromDate, toDate)
            .then((response) => {
                setData(response?.data || []);
                setTotal(response?.page?.total_elements);
                setLoading(false);
            })
            .catch(() => {
                setData([]);
                setLoading(false);
            });
    };

    return (
        <Spin spinning={loading}>
            <Filter params={params} setParams={setParams}/>
            <TableData
                // loading={loading}
                data={data}
                params={params}
                source={source}
                salesAccountId={salesAccountId}
                onSuccess={onSuccess}
                onRefresh={fetchData}
            />

            <Pagination
                className="pagination"
                current={params.page}
                pageSize={params.size}
                total={total}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `Tổng số ${total}`}
                onChange={handleChangePage}
            />
        </Spin>
    );
};

export default CustomerList;
