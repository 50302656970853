import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Row, Col, Tag, Space} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import savingsService from "../../../services/invest/savings";
import ModalSavingsTransactionDetail from "./components/modals/ModalSavingsTransactionDetail";
import dashboardServices from "../../../services/invest/dashboard";
import {convertStringToQuery, formatMoneyCurrency, getFmkTransactionTypeColorByStatus} from "../../../utils/common";
import moment from 'moment';
import dayjs from 'dayjs';
import IMG from "../../../assets/images/invest/image";
import authServices from "../../../services/auth";
import {useTranslation} from "react-i18next";

const query = convertStringToQuery(window.location.search);

const SavingsTransactions = (props) => {
    const {router} = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [formVisible, setFormVisible] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [sumAmountSavings, setSumAmountSavings] = useState(0);
    const [params, setParams] = useState({
        page: 1,
        size: 10,
        sort: 'createdDate,DESC',
        dates: [
            dayjs().startOf('day'),
            dayjs().endOf('day'),
        ],
        processing_status: 'PENDING',
    });

    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = () => {
        let new_params = {
            ...params,
            page: params.page - 1,
            from_date: dayjs(params?.dates?.[0]).unix()*1000,
            to_date: dayjs(params?.dates?.[1]).unix()*1000,
        };
        delete new_params.dates;
        getSavingsTransactions(new_params);
        // getSavingsAmount(new_params);
    };

    const getSavingsTransactions = async (new_params) => {
        setLoading(true);
        await savingsService.savings_transactions(new_params)
            .then((response) => {
                setData(response?.data || []);
                setTotal(response?.page?.total_elements);
                setSumAmountSavings(response?.totalAmount || 0);
            })
            .catch(() => {
                setData([]);
            });
        setLoading(false);
    };

    // const getSavingsAmount = async (new_params) => {
    //     await dashboardServices.dash_sum_amount_savings(new_params).then((response) => {
    //         setSumAmountSavings(response?.data?.total_amount || 0)
    //     }).catch(() => {
    //         setSumAmountSavings(0)
    //     });
    // };

    const handleShowDetail = (transaction = null) => {
        setSelectedTransaction(transaction);
        setFormVisible(true)
    };

    const onConfirmTransferred = async(transaction_ids) => {
        console.log('transactionIds', transaction_ids);
        let loginInfo = authServices?.login_info();
        console.log('loginInfo', loginInfo);
        console.log('params', params);
        transaction_ids = transaction_ids.join(',');
        let processing_status = params?.processing_status === 'TRANSFERRED' ? 'PENDING' : 'TRANSFERRED';
        let admin_approve_id = loginInfo?.customerId || 0;
        let admin_approve_name = loginInfo?.phone || '';
        let _params = {
            transaction_ids, processing_status, admin_approve_id, admin_approve_name
        };
        try{
            let res = await savingsService.update_transaction_processing_status({}, _params);
            fetchData();
        }
        catch(err){
            console.log('err', err);
        }
    };

    return (
        <div className="savings-transactions">
            <AdminHeader
                title={router.label}
                // total={total}
                // actions={[
                //     {
                //         key: "add",
                //         label: "Create",
                //         type: "primary",
                //         icon: <PlusOutlined/>,
                //         click: () => {
                //         },
                //     },
                // ]}
            />

            <Row gutter={[12, 0]}>
                <Col span={6} lg={6} sm={12} xs={24}>
                    <div className="dashboard-card dashboard-card-savings-transactions-1">
                        <div className="dashboard-card-content">
                            <div className="dashboard-card-name">{texts('transaction')}</div>
                            <div className="dashboard-card-value">{total}</div>
                        </div>
                    </div>
                </Col>
                <Col span={6} lg={6} sm={12} xs={24}>
                    <div className="dashboard-card dashboard-card-savings-transactions-2">
                        <div className="dashboard-card-content">
                            <div className="dashboard-card-name">{texts('amount')}</div>
                            <div className="dashboard-card-value">{formatMoneyCurrency(sumAmountSavings)}</div>
                        </div>
                    </div>
                </Col>
            </Row>

            <div style={{height: 10}}/>

            <Filter params={params} setParams={setParams}/>

            <TableData
                loading={loading}
                data={data}
                params={params}
                showDetail={(item) => handleShowDetail(item)}
                onConfirmTransferred={onConfirmTransferred}
            />

            <Pagination
                className="pagination"
                current={params.page}
                pageSize={params.size}
                total={total}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `${texts('total')} ${total}`}
                onChange={handleChangePage}
            />

            {
                formVisible && <ModalSavingsTransactionDetail
                    visible={formVisible}
                    transaction={selectedTransaction}
                    onClose={() => setFormVisible(false)}
                    reload={() => fetchData()}
                />
            }
        </div>
    );
};

export default SavingsTransactions;
