import React from "react";
import { Row, Col, Input, Select } from "antd";

import { SearchOutlined } from "@ant-design/icons";

const Filter = (props) => {
  const { params = {}, setParams = () => { } } = props;
  return (
    <Row className="filter" gutter={[8, 8]}>
      <Col>
        <Input
          className="filter-filed"
          prefix={<SearchOutlined />}
          value={params.keyword}
          placeholder="Nhập thông tin tìm kiếm..."
          onChange={(e) => setParams({ ...params, keyword: e.target.value })}
        />
      </Col>
      <Col>
      </Col>
      <Col>
      </Col>
    </Row>
  );
};

export default Filter;
