import React, { useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  Card,
  Image
} from 'antd';

import { } from '../../../../../utils/common'

const Infomation = (props) => {
  const {
    loading = false,
    marketInfo = {}
  } = props

  const marketData = useMemo(() => {
    return [
      {
        name: 'Mã sàn',
        value: `STMĐT_${marketInfo.id}`,
        lg: 12
      },
      {
        name: 'Tên sàn TMĐT',
        value: marketInfo?.name,
        lg: 12
      },
      {
        name: 'Phân loại',
        value: marketInfo?.typeName,
        lg: 12
      },
      {
        name: 'Email',
        value: marketInfo?.email,
        lg: 12
      },
      {
        name: 'Phí thanh toán',
        value: `${marketInfo?.surcharge || 0} % / Tổng hoá đơn`,
        lg: 12
      },
      {
        name: 'Link truy cập',
        value: <a
          href={marketInfo.link}
          target={'_blank'}
        >{marketInfo.link}</a>,
        lg: 24
      },
      {
        name: 'Link Order',
        value: <a
          href={marketInfo.linkOrder}
          target={'_blank'}
        >{marketInfo.linkOrder}</a>,
        lg: 24
      },
    ]
  }, [marketInfo])

  return (
    <Card
      size="small"
      className="w-100 my-m"
      loading={loading}
      title={'Thông tin sàn TMĐT'}
    >
      <Row gutter={[16, 8]}>
        <Col lg={8}>
          <Image
            className={'w-100'}
            style={{
              maxHeight: 200
            }}
            src={marketInfo.logo}
          />
        </Col>
        <Col lg={16}>
          <Row gutter={[16, 8]}>
            {
              marketData.map((info, index) => <Col
                key={index}
                lg={info.lg || 12}
                sm={24}
                xs={24}
              >
                <div className="flex align-items-center">
                  <p className="text-gray mr-xs">{info.name}:</p>
                  <p>{info.value || 'Không có dữ liệu'}</p>
                  <span>{info.note}</span>
                </div>
              </Col>)
            }
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

export default Infomation;