import React, {useEffect, useState} from "react";
import {
    Row,
    Col,
    Input,
    Modal,
    Form,
    Select,
    message,
    Button,
    Popconfirm
} from 'antd';

import {} from "@ant-design/icons";

import CONSTANTS from "../../../../../config/constants_invest";
import {PROJECT_RULES} from "../../../../../config/rules";
import itemServices from '../../../../../services/invest/savings'
import contentServices from '../../../../../services/invest/content'
import {useTranslation} from "react-i18next";
import {UploadImageInvest} from "../../../../../components";


const ProductFormModal = (props) => {
    const [form] = Form.useForm();

    const {
        item = null,
        parent = null,
        visible = false,
        onClose = () => {},
        onSave = () => {},
        // onDelete = () => {},
        reload = () => {},
    } = props;

    const [currentParent, setCurrentParent] = useState();
    const [imgUrl, setImgUrl] = useState(item?.image || '');

    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {
        console.log('item', item);
        if (item) {
            form.setFieldsValue({
                question: item.question,
                answer: item.answer,
                parent: findParent(item)?.id,
                status: item?.status
            });
            // setCurrentParent(findParent(item));
            // console.log('currentParent', currentParent);
            // console.log('data', data);
        }
        else{
            form.setFieldsValue({
                status: 1
            });
        }
    }, [item]);

    const findParent = (it) => {
        return parent.find(e => e.id === it.parentId || e.id === it.parent_id);
    };

    const [callingAPI, setCallingAPI] = useState(false);

    const handleSubmit = () => {
        form.validateFields().then(async (valid) => {
            if (valid) {
                setCallingAPI(true);
                const values = form.getFieldsValue();
                console.log('values', values);
                if (item) {
                    await handleUpdate({
                        ...item,
                        ...values
                    })
                }
                // else {
                //     await handleCreate(values)
                // }
                setCallingAPI(false);
            }
        })
    };

    const handleCreate = async (data) => {
        await itemServices.create(data).then(() => {
            message.success('Thêm gói sản phẩm thành công')
            reload();
            onClose();
        }).catch((error) => {
            message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
    };

    const handleUpdate = async (data) => {
        await itemServices.savings_item_update(data).then(() => {
            message.success('Cập nhật gói sản phẩm thành công')
            reload();
            onClose();
        }).catch((error) => {
            message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
    };

    const saveQuestion = () => {
        form.validateFields().then(async (valid) => {
            if (valid) {
                setCallingAPI(true);
                const values = form.getFieldsValue();
                console.log('values', values);
                if(item){
                    contentServices.update_question({
                        ...item,
                        ...values,
                        image: imgUrl
                    }).then(() => {
                        onSave(() => {
                            message.success('Update successful!');
                            setCallingAPI(false);
                            onClose();
                        });
                    });
                }
                else{
                    let {question, answer, parent} = values;
                    contentServices.create_question({
                        question,
                        answer,
                        user_created: 0,
                        slug: '',
                        parent_id: parent,
                        image: imgUrl
                    }).then(() => {
                        onSave(() => {
                            message.success('Create successful!');
                            setCallingAPI(false);
                            onClose();
                        });
                    });
                }
            }
        })
    };

    const deleteQuestion = () => {
        setCallingAPI(true);
        contentServices.delete_question({}, {id: item?.id}).then(()=>{
            onSave(() => {
                message.success('Deleted!');
                setCallingAPI(false);
                onClose();
            });
        });
    };

    const del_text = 'Delete the Question';
    const del_description = 'Are you sure to delete this Question?';
    const confirmDel = () => {

    };

    const onUploadSuccess = (imgUrl) => {
        setImgUrl(imgUrl);
    };

    return (
        <Modal
            title={item ? "Edit question & answer" : "Create new question & answer"}
            width={600}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => onClose()}
            okButtonProps={{
                loading: callingAPI
            }}
            cancelButtonProps={{
                disabled: callingAPI
            }}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    {texts('cancel')}
                </Button>,
                item && <Popconfirm
                    placement="bottom"
                    title={del_text}
                    description={del_description}
                    onConfirm={deleteQuestion}
                    okText="Yes"
                    cancelText="No">
                    <Button key="delete" type="primary" danger loading={callingAPI}>
                        {texts('delete')}
                    </Button>
                </Popconfirm>,
                <Button
                    key="submit"
                    type="primary"
                    loading={callingAPI}
                    onClick={saveQuestion}>
                    {texts('save')}
                </Button>,
            ]}
        >
            <Form form={form} layout='vertical' style={{marginTop: 20}}>
                <Row justify={'left'} gutter={[24, 0]}>
                    <Col span={18} lg={18} sm={18} xs={18}>
                        <Form.Item
                            name={'parent'}
                            label={texts('parent')}
                            rules={[]}
                        >
                            <Select
                                className="w-100"
                                disabled={callingAPI}
                                // value={currentParent?.question}
                            >
                                {parent.map((p) => (
                                    <Select.Option
                                        value={p.id}
                                        key={p.id}
                                    >
                                        {p.question}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={'status'}
                            label={texts('status')}
                            rules={[]}
                        >
                            <Select
                                className="w-100"
                                disabled={callingAPI}
                            >
                                {CONSTANTS.QUESTION_ANSWER_STATUS.map((status) => (
                                    <Select.Option
                                        value={status.value}
                                        key={status.value}
                                    >
                                        {texts(status.label)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                    </Col>
                    {/*<Col span={6} lg={6} sm={6} xs={12}>*/}
                        {/**/}
                    {/*</Col>*/}
                    <Col span={6} lg={6} sm={6} xs={6}>
                        <Form.Item
                            name={'image'}
                            label={texts('image')}
                            rules={[]}
                        >
                            <UploadImageInvest
                                file_type={CONSTANTS.FILE_TYPE.OTHER_IMG}
                                onUploadSuccess={onUploadSuccess}
                                defaultImg={item?.image}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={24} sm={24} xs={24}>
                        <Form.Item
                            label={texts('question')}
                            name={'question'}
                            rules={PROJECT_RULES}>
                            <Input
                                placeholder="Enter question"
                                disabled={callingAPI}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={24} sm={24} xs={24}>
                        <Form.Item
                            label={texts('answer')}
                            name={'answer'}
                            rules={PROJECT_RULES}>
                            <Input.TextArea
                                rows={4}
                                placeholder="Enter answer"
                                disabled={callingAPI}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>

    );
}

export default ProductFormModal;