import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Tabs, Col, Row} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminDetailHeader} from "../../../components";

import Filter from "../fmarket-transactions/components/Filter";
import TableData from "../fmarket-transactions/components/TableData";
import userServices from "../../../services/admin/user";
import customerServices from "../../../services/invest/customer";
import { useParams } from 'react-router-dom';
import AvatarDefault from './../../../assets/images/Avatar-default.png';
import moment from 'moment';
import {removeParamsEmpty} from "../../../utils/common";
import fmarketServices from "../../../services/invest/fmarket";
import ModalFmarketTransactionDetail from '../fmarket-transactions/components/modals/ModalFmarketTransactionDetail';
import {useTranslation} from "react-i18next";

const FaTransactionTab = (props) => {
    const {fmarketAccount} = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [formVisible, setFormVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [params, setParams] = useState({
        page: 1,
        size: 10,
    });

    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);
        let fmarket_id = fmarketAccount.fmarketId;
        let send_params = removeParamsEmpty(params);
        await fmarketServices.fmarket_transactions({
            ...send_params,
            fmarket_id,
            page: params.page - 1,
            pageSize: params.size,
        })
            .then((response) => {
                setData(response?.data || []);
                setTotal(response?.page?.total_elements);
            })
            .catch(() => {
                setData([]);
            });
        setLoading(false);
    };

    const handleShowDetail = (item = null) => {
        setSelectedItem(item);
        setFormVisible(true)
    };

    return (
        <div className="">
            <Filter params={params} setParams={setParams}/>
            <TableData
                loading={loading}
                data={data}
                params={params}
                detail={selectedItem}
                showDetail={(item) => handleShowDetail(item)}
            />

            <Pagination
                className="pagination"
                current={params.page}
                pageSize={params.size}
                total={total}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `${texts('total')} ${total}`}
                onChange={handleChangePage}
            />

            {
                formVisible && <ModalFmarketTransactionDetail
                    visible={formVisible}
                    transaction={selectedItem}
                    onClose={() => setFormVisible(false)}
                    reload={() => fetchData()}
                />
            }
        </div>
    );
};

export default FaTransactionTab;
