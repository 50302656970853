import React from "react";
import { Row, Table, Button, Modal, Tag, Col, Space, Image } from "antd";

import {
    EditOutlined,
    PhoneOutlined,
    MailOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import {
    convertDateTime, formatMoneyCurrency, getFmkTransactionTypeColorByStatus,
    getSavingsProductStatus
} from "../../../../utils/common";
import {useTranslation} from "react-i18next";

const TableData = (props) => {
    const {
        data,
        loading,
        params,
        edit = () => { }
    } = props;

    const { t } = useTranslation();
    const texts = t;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: texts('no.'),
            dataIndex: "stt",
            key: "stt",
            align: "center",
            width: '46px',
        },
        {
            title: texts('help_center_name'),
            dataIndex: "name",
            key: "name",
            render: (_, record) => (
                <Space>
                    <Image src={record?.logoImage} width={36}/>
                    <div>{record?.name}</div>
                </Space>
            ),
        },
        {
            title: texts('information'),
            dataIndex: "phone",
            key: "phone",
            render: (_, record) => (
                <div style={{display: 'grid'}}>
                    <Space>
                        <PhoneOutlined style={{color: '#aaa'}}/>
                        <div>{record?.phone}</div>
                    </Space>
                    <Space>
                        <MailOutlined style={{color: '#aaa'}}/>
                        <div>{record?.email}</div>
                    </Space>
                </div>
            ),
        },

        {
            title: texts('actions'),
            dataIndex: "actions",
            key: "actions",
            align: "center",
            render: (_, record) => (
                <Row gutter={[8, 8]} justify={'center'}>
                    <Col>
                        <Button
                            onClick={() => edit(record)}
                            ghost
                            type='primary'
                            size='small'>
                            {texts('edit')}
                        </Button>
                    </Col>
                </Row>
            ),
        },
    ];

    const expandable = {
        expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.fundFullName}</p>,
        rowExpandable: (record) => record.fundFullName !== 'Not Expandable',
    };

    return (
        <Table
            bordered
            className="table-data"
            columns={columns}
            dataSource={data.map((d, index) => ({
                ...d,
                stt: index + 1 + (params.page - 1) * params.size,
            }))}
            // expandable={expandable}
            loading={loading}
            pagination={false}
            rowKey={(record) => record?.id}
            size="small"
        />
    );
};

export default TableData;
