import React, { useEffect, useState, useMemo } from "react";
import {
  List,
  Button,
  Badge,
  Tooltip,
  Checkbox,
  Space,
  Modal,
  message
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  DownOutlined,
  UpOutlined
} from "@ant-design/icons";

import { activeStatus } from "../../../../utils/common";
import categoryServices from "../../../../services/admin/category";

import { PERMISSIONS } from "../../../../config/permissions";
import { isPermission } from "../../../../utils/common";

const CardData = (props) => {
  const {
    type = 'form',
    category,
    disabled = false,
    loading = false,
    rolePermissions = [],
    onCreate = () => { },
    onUpdate = () => { },
    onDelete = () => { },
    onUpdateCategory = () => { },
    onSelect = () => { },
    onReload = () => { },
  } = props;

  const [showAll, setShowAll] = useState(false);
  const [data, setData] = useState([]);

  const showData = useMemo(() => {
    if (showAll) {
      return data
    }
    return data.slice(0, 5)
  }, [showAll, data])

  useEffect(() => {
    setData(category.permissions);
  }, [category.permissions]);

  const handleDelete = () => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xoá danh mục?',
      onOk() {
        categoryServices.remove(category.categoryId).then(() => {
          message.success('Xoá danh mục thành công')
          onReload();
        }).catch((error) => {
          message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
      },
      onCancel() { },
    });
  }

  return (
    <List
      size="small"
      header={<div className="flex align-items-center justify-content-between">
        <p className="text-bold">
          {category.name}
          <span className="ml-2xs">({category.permissions.length})</span>
        </p>
        <Space size={[4, 4]}>
          {
            showAll && category.permissions.length > 5 && <Button
              size="small"
              icon={<UpOutlined />}
              type="link"
              onClick={() => setShowAll(false)}
            ></Button>
          }
          {
            !showAll && category.permissions.length > 5 && <Button
              size="small"
              icon={<DownOutlined />}
              type="link"
              onClick={() => setShowAll(true)}
            ></Button>
          }
          {
            type === 'form' && isPermission([PERMISSIONS.CREATE_PERMISSION]) && <Button
              size="small"
              icon={<PlusOutlined />}
              type="link"
              onClick={() => onCreate()}
            ></Button>
          }
          {
            type === 'form' && isPermission([PERMISSIONS.CREATE_PERMISSION]) && <Button
              size="small"
              icon={<EditOutlined />}
              type="link"
              onClick={() => onUpdateCategory()}
            ></Button>
          }
          {
            type === 'form' && isPermission([PERMISSIONS.DELETE_ROLE_CATEGORY]) && <Button
              size="small"
              icon={<DeleteOutlined />}
              type="link"
              danger
              onClick={() => handleDelete()}
            ></Button>
          }
        </Space>
      </div>}
      loading={loading}
      bordered
      style={{
        height: '100%'
      }}
      dataSource={showData}
      renderItem={(item) => (
        <List.Item>
          <div className="flex align-items-center justify-content-between w-100">
            <p>
              {
                type === 'form' && <Tooltip title={activeStatus(item.publicField).label}>
                  <Badge
                    status={activeStatus(item.publicField).tag_color}
                    className="mr-xs"
                  />
                </Tooltip>
              }
              {item.name}
            </p>
            {
              type === 'form' && <Space className="flex" size={[4, 4]}>
                {
                  isPermission([PERMISSIONS.UPDATE_PERMISSION]) && <Button
                    size="small"
                    icon={<EditOutlined />}
                    type="link"
                    onClick={() => onUpdate(item)}
                  ></Button>
                }
                {
                  isPermission([PERMISSIONS.DELETE_PERMISSION]) && <Button
                    size="small"
                    icon={<DeleteOutlined />}
                    type="link"
                    danger
                    onClick={() => onDelete(item)}
                  ></Button>
                }
              </Space>
            }
            {
              type === 'select' && <div className="flex">
                <Checkbox
                  checked={item.isChoose || rolePermissions.includes(item.name)}
                  disabled={rolePermissions.includes(item.name) || disabled}
                  onChange={(e) => onSelect({ ...item, isChoose: e.target.checked })}
                ></Checkbox>
              </div>
            }
          </div>
        </List.Item>
      )}
    />
  );
};

export default CardData;
