const BACKEND_URL = process.env.REACT_APP_FINBLUE_API_URL + '/cms';

export default {
  CONTRACT: `${BACKEND_URL}/contract`,
  CONTRACT_DETAIL: `${BACKEND_URL}/contract/detail`,
  CONTRACT_FILTER: `${BACKEND_URL}/contract/filter`,
  CONTRACT_SEARCH: `${BACKEND_URL}/contract/filter/search`,
  CONTRACT_VERIFY: `${BACKEND_URL}/contract/verify`,
  CONTRACT_VERIFY_FILED: `${BACKEND_URL}/contract/verify-filed`,
  CONTRACT_STATISTIC: `${BACKEND_URL}/contract/statistic`,
  CONTRACT_CUSTOMER_STATISTIC: `${BACKEND_URL}/contract/statistic/customerId`,
  CONTRACT_PRODUCTS_APPROVED: `${BACKEND_URL}/contract/products-approved`,
  CONTRACT_PRODUCTS_APPROVED_BY_ID: `${BACKEND_URL}/contract/products-approved/:id`,

  LOAN: `${BACKEND_URL}/loan`,
  LOAN_DETAIL: `${BACKEND_URL}/loan/detail/:id`,
  LOAN_FILTER: `${BACKEND_URL}/loan/filter`,
  LOAN_INFO_ORDER: `${BACKEND_URL}/loan/info-order/:id`,
  LOAN_SEARCH: `${BACKEND_URL}/loan/filter/search`,
  LOAN_LENDER_SEARCH: `${BACKEND_URL}/loan/filter/search/lender`,
  LOAN_MARKET_SEARCH: `${BACKEND_URL}/loan/filter/search/ecommerce`,
  LOAN_STATISTIC: `${BACKEND_URL}/loan/statistic`,
  LOAN_LENDER_STATISTIC: `${BACKEND_URL}/loan/statistic/lender`,
  LOAN_MARKET_STATISTIC: `${BACKEND_URL}/loan/statistic/ecommerce`,
  LOAN_CUSTOMER_STATISTIC: `${BACKEND_URL}/loan/statistic/customer`,
  LOAN_APPROVED: `${BACKEND_URL}/loan/approved`,
  LOAN_LENDER_APPROVED: `${BACKEND_URL}/loan/lender/approved`,
  LOAN_VERIFY: `${BACKEND_URL}/loan/verify`,
  LOAN_LENDER_VERIFY: `${BACKEND_URL}/loan/verify/lender`,
  LOAN_ECOMMERCE_VERIFY: `${BACKEND_URL}/loan/verify/ecommerce`,
  LOAN_INFO: `${BACKEND_URL}/loan/info-loan/:id`,
  LOAN_REVIEW_HISTORY: `${BACKEND_URL}/loan/review-history/:id`,

  PRODUCTS: `${BACKEND_URL}/products`,
  PRODUCTS_DETAIL: `${BACKEND_URL}/products/get-all-detail`,
  PRODUCT_ADD: `${BACKEND_URL}/products/add`,
  PRODUCT_UPDATE: `${BACKEND_URL}/products/update`,
  PRODUCT_TRANSACTIONS: `${BACKEND_URL}/products/product-transaction/get-all`,
  PRODUCT_TRANSACTIONS_ADD: `${BACKEND_URL}/products/product-transaction/add`,
  PRODUCT_TRANSACTIONS_UPDATE: `${BACKEND_URL}/products/product-transaction/update`,
  PRODUCT_TRANSACTIONS_DELETE: `${BACKEND_URL}/products/product-transaction/delete/:id`,
  PRODUCTS_TRANSACTIONS: `${BACKEND_URL}/products/transaction/get-all`,
  PRODUCTS_TRANSACTION_ADD: `${BACKEND_URL}/products/transaction/add`,
  PRODUCTS_TRANSACTION_UPDATE: `${BACKEND_URL}/products/transaction/update`,

  PROFILES_CUSTOMER_EKYC_SEARCH: `${BACKEND_URL}/profiles/custmer/ekyc/search`,
  PROFILES_CUSTOMER: `${BACKEND_URL}/profiles/customer`,
//  PROFILES_CUSTOMER_EKYC_SEARCH: `${BACKEND_URL}/profiles/customer/ekyc/search`,
  PROFILES_CUSTOMER_EKYC_STATISTIC: `${BACKEND_URL}/profiles/customer/ekyc/statistic`,
  PROFILES_CUSTOMER_EKYC_APPROVED: `${BACKEND_URL}/profiles/customer/ekyc/:id/approved`,
  PROFILES_CUSTOMER_MANAGER: `${BACKEND_URL}/profiles/customer/manage`,
  PROFILES_CUSTOMER_STATISTIC: `${BACKEND_URL}/profiles/customer/statistic`,

  PAYMENT_LOAN_HISTORY_SCHEDULE: `${BACKEND_URL}/payment/history-schedule/:loan_id`,
  PAYMENT_LOAN_DEBT_BRICK: `${BACKEND_URL}/payment/debt-brick`,
  PAYMENT_CUSTOMER: `${BACKEND_URL}/payment/customer`,

  MENU_DATA_TYPE: `${BACKEND_URL}/menu/data/data-type/get-all`,

  MARKETS: `${BACKEND_URL}/menu/data/markets`,
  MARKET_DETAIL: `${BACKEND_URL}/menu/data/markets/:id`,
  MARKET_ADD: `${BACKEND_URL}/menu/data/markets/add`,
  MARKET_UPDATE: `${BACKEND_URL}/menu/data/markets/update`,
  MARKET_DELETE: `${BACKEND_URL}/menu/data/markets/delete`,
  MARKET_ORDER: `${BACKEND_URL}/market/order`,
  MARKET_ORDER_STATISTIC: `${BACKEND_URL}/market/order/statistic`,

  LENDERS: `${BACKEND_URL}/lender/get-all`,
  LENDER_DETAIL: `${BACKEND_URL}/lender/:id`,
  LENDER_ADD: `${BACKEND_URL}/lender/add`,
  LENDER_UPDATE: `${BACKEND_URL}/lender/update`,
  LENDER_DELETE: `${BACKEND_URL}/lender/delete`,
  LENDER_ORDER: `${BACKEND_URL}/lender/:id/order`,
  LENDER_ORDER_STATISTIC: `${BACKEND_URL}/lender/:id/order/statistic`,
  LENDER_ORDER_PAYMENT_SCHEDULE: `${BACKEND_URL}/payment/schedule/order`,

  DICT_ITEMS: `${BACKEND_URL}/dict-item/get-all`,
  DICT_ITEM_BRANCHS: `${BACKEND_URL}/dict-item/:id/get-all`,

  FINANCES: `${BACKEND_URL}/finance/get-all`,
  FINANCES_ADD: `${BACKEND_URL}/finance/add`,
  FINANCES_UPDATE: `${BACKEND_URL}/finance/update`,
  FINANCES_DELETE: `${BACKEND_URL}/finance/delete`,
  FINANCES_FINBLUE: `${BACKEND_URL}/finance/finblue`,
  FINANCES_FINBLUE_ADD: `${BACKEND_URL}/finance/finblue-add`,
  FINANCES_CUSTOMER: `${BACKEND_URL}/finance/customer`,

  BUSINESS: `${BACKEND_URL}/business/get-list-business`,
  BUSINESS_CHILD: `${BACKEND_URL}/business/get-list-business-child`,

  REPORT_MARKET: `${BACKEND_URL}/report/market`,
  REPORT_LENDER: `${BACKEND_URL}/report/lender`,
  REPORT_CUSTOMER: `${BACKEND_URL}/report/customer`,
  REPORT_ORDER: `${BACKEND_URL}/report/order`,
  REPORT_ORDER_MONEY: `${BACKEND_URL}/report/order-money`,
  REPORT_SYSTEM: `${BACKEND_URL}/report/system`,

  REPORT_DEBTS: `${BACKEND_URL}/report-debt/get-all`,
  REPORT_DEBTS_CUSTOMER_ORDERS: `${BACKEND_URL}/report-debt/customer/get-orders`,

  RECEIPTS: `${BACKEND_URL}/receipt/get-all`,
  RECEIPT_BY_SCHEDULE: `${BACKEND_URL}/receipt/get-by-schedule`,

  LOG_APPOTA: `${BACKEND_URL}/log/appota`,
  LOG_APPOTA_DETAIL: `${BACKEND_URL}/log/appota/detail`,

  ASSIGN_ASSESSOR: `${BACKEND_URL}/assign/assessor`,
  ASSIGN_ASSESSOR_DETAIL: `${BACKEND_URL}/assign/assessor/get-employee`,
  ASSIGN_ASSESSOR_ORDERS: `${BACKEND_URL}/assign/assessor/get-orders`,
  ASSIGN_ASSESSOR_CONTRACTS: `${BACKEND_URL}/assign/assessor/get-contracts`,
  ASSIGN_ASSESSOR_WORKS: `${BACKEND_URL}/assign/assessor/get-works`,
  ASSIGN_ASSESSOR_DIVIDE_EQUALLY: `${BACKEND_URL}/assign/assessor/divide-equally`
};
