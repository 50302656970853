import React from "react";
import {
  Row,
  Table,
  Button,
  Tag
} from 'antd';

import {
  EditOutlined
} from "@ant-design/icons";

import {
  formatNumber,
  assessorContractStatus
} from '../../../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    rowSelection = null
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 150,
      render: (_, record) => (
        <Tag color={assessorContractStatus(record.status)?.tag_color}>
          {assessorContractStatus(record.status)?.label}
        </Tag>
      ),
    },
    {
      title: 'Mã hợp đồng',
      dataIndex: 'contractId',
      key: 'contractId',
      align: 'center',
      width: 150,
      render: (_, record) => (
        `HĐ_${record.contractId}`
      ),
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 150,
    },
    {
      title: 'Điểm tín dụng',
      dataIndex: 'score',
      key: 'score',
      align: 'center',
      width: 150,
      render: (_, record) => (
        formatNumber(record.score)
      ),
    },
    {
      title: 'Chấm bởi',
      dataIndex: 'scoreBy',
      key: 'scoreBy',
      align: 'center',
      width: 120,
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <Row gutter={[0, 8]} justify='center'>
          <Button
            icon={<EditOutlined />}
            type='primary'
            size="small"
            ghost
            onClick={() => window.navigatePage('LIMIT_APPROVAL_DETAIL', { id: record.contractId })}
          >Chi tiết</Button>
        </Row>
      ),
    },
  ].filter((c) => !rowSelection || c.key !== 'actions')

  return (
    <Table
      bordered
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 }))}
      loading={loading}
      pagination={false}
      rowKey={(record) => record?.contractId}
      scroll={{ x: 800 }}
      size="small"
      rowSelection={rowSelection ? {
        type: 'checkbox',
        ...rowSelection,
      } : null}
    />
  );
}

export default TableData;