import React, { useEffect, useState } from "react";
import {
  Table,
} from 'antd';

import {
  formatCurrency,
} from '../../utils/common'

const Products = (props) => {
  const {
    data = [],
    bodered = true
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 100
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'productName',
      key: 'productName',
      align: 'left',
      width: 200
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      width: 150,
    },
    {
      title: 'Đơn giá',
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      width: 200,
      render: (_, record) => (
        formatCurrency(record.price)
      ),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'money',
      key: 'money',
      align: 'right',
      width: 200,
      render: (_, record) => (
        formatCurrency(record.price * record.quantity)
      ),
    },
  ]

  return (
    <Table
      bodered={bodered}
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 }))}
      pagination={false}
      rowKey={(record) => record?.productName}
      scroll={{ x: 1024 }}
      size="small"
    />
  );
}

export default Products;