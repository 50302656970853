import React, { useMemo, useState, useEffect } from "react";
import {
} from 'antd';

import { OrderInformation } from "../../../../../components";
import loanServices from '../../../../../services/finblue/loan';

const Order = (props) => {
  const [getting, setGetting] = useState(false);
  const [orderInfo, setOrderInfo] = useState({})

  const {
    loading = false,
    params = {}
  } = props


  useEffect(() => {
    getOrderInfo()
  }, [])

  const getOrderInfo = async () => {
    setGetting(true);
    await loanServices.get_info_order(params.id).then((response) => {
      setOrderInfo(response?.data || {})
    }).catch(() => {
      setOrderInfo({})
    })
    setGetting(false);
  }

  return (
    <OrderInformation
      loading={loading || getting}
      orderInfo={orderInfo}
    />
  );
}

export default Order;