import React from "react";
import {
  Row,
  Col,
  Input,
  Select,
  DatePicker
} from 'antd';

import {
  SearchOutlined
} from "@ant-design/icons";

import CONSTANTS from '../../../../../../config/constants'

const Filter = (props) => {
  const {
    params = {},
    setParams = () => { }
  } = props
  return (
    <Row
      className="filter"
      justify={'space-between'}
      gutter={[0, 8]}
    >
      <Col>
        <Row
          justify={'left'}
          gutter={[8, 8]}
        >
          <Col>
            <Input
              className="filter-filed"
              prefix={<SearchOutlined />}
              value={params.keyword}
              placeholder="Nhập thông tin tìm kiếm..."
              onChange={(e) => setParams({ ...params, keyword: e.target.value })}
            />
          </Col>
          <Col>
            <Select
              placeholder={'Trạng thái'}
              className="filter-filed"
              value={params.status}
              onChange={(v) => setParams({ ...params, status: v })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                `${option.children}`
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              <Select.Option
                value={''}
                key={'all'}
              >
                Tất cả
              </Select.Option>
              {CONSTANTS.MARKET_ORDER_STATUS.map((status) => (
                <Select.Option
                  value={status.value}
                  key={status.value}
                >
                  {status.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Col>
      <Col align="right">
        <Row
          justify={'end'}
          gutter={[8, 8]}
        >
          <Col span={24} lg={24} sm={24} xs={24}>
            <DatePicker.RangePicker
              className="filter-filed"
              format={CONSTANTS.DATE_FORMAT}
              value={params?.dates || []}
              onChange={(v) => setParams({ ...params, dates: v })}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Filter;