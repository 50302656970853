import React, { useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card
} from 'antd';

import contractServices from "../../../../../services/finblue/contract";
import { TableContent } from "../../../../../components";
import Transactions from "../../../limit-approval/components/detail/Transactions";

const Customer = (props) => {
  const [getting, setGetting] = useState(false)
  const {
    loading = false,
    loanInfo = {},
    contractInfo = {},
    setContractInfo = () => { }
  } = props

  useEffect(() => {
    if (loanInfo?.loanCode) {
      getContractInfo();
    }
  }, [loanInfo])

  const getContractInfo = () => {
    setGetting(true)
    contractServices.get_detail({ loanCode: loanInfo?.loanCode }).then((response) => {
      setContractInfo(response.data)
      setGetting(false)
    }).catch(() => {
      setContractInfo({})
      setGetting(false)
    })
  }

  const customerInfo = useMemo(() => {
    return [
      ...(contractInfo?.contractDatas || []).filter((d) => d.unit !== 'image'),
      {
        id: 'contract',
        name: 'Hợp đồng',
        content: `HĐ_${contractInfo?.contractId}`,
        unit: 'link',
        click: () => window.navigatePage('LIMIT_APPROVAL_DETAIL', { id: contractInfo?.contractId }, { customerId: loanInfo?.customerId })
      }
    ]
  }, [contractInfo, loanInfo])

  const images = useMemo(() => {
    return (contractInfo?.contractDatas || []).filter((d) => d.unit === 'image')
  }, [contractInfo])

  return (
    <Card
      size="small"
      className="w-100 mb-m"
      loading={loading || getting}
      title={'Thông tin khách hàng'}
    >
      <Row gutter={[16, 8]}>
        {
          customerInfo.map((info) => <Col
            key={info.id}
            lg={12}
            sm={24}
            xs={24}
          >
            <div className="flex align-items-center">
              <p className="text-gray mr-xs">{info.name}:</p>
              <TableContent record={info} />
            </div>
          </Col>)
        }
        {
          images.map((image) => <Col
            className="mt-m"
            key={image.id}
            lg={8}
            sm={8}
            xs={24}
          >
            <div className="text-center">
              <TableContent record={image} fullWidth={true} />
              <p className="text-gray mt-m">{image.name}</p>
            </div>
          </Col>)
        }
        <Col lg={24}>
          <p className="text-bold mt-m">
            Gói hạn mức được cấp
          </p>
          <Transactions
            bordered={false}
            contractInfo={contractInfo}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default Customer;