import React, {useEffect, useState} from "react";
import {
    Row,
    Col,
    Input,
    Modal,
    Form,
    Select,
    message,
    Button
} from 'antd';

import {} from "@ant-design/icons";

import CONSTANTS from "../../../../../config/constants";
import {PROJECT_RULES} from "../../../../../config/rules";
import fmarketServices from '../../../../../services/invest/fmarket'

const ProductFormModal = (props) => {
    const [form] = Form.useForm();
    const [listAssetAllocation, setListAssetAllocation] = useState([]);
    const [totalAllocation, setTotalAllocation] = useState(0);
    const {
        product = null,
        visible = false,
        onClose = () => {
        },
        reload = () => {
        }
    } = props

    useEffect(() => {
        if (product) {
            genArrayAssetAllocation(product?.assetAllocation);
            form.setFieldsValue({
                fundFullName: product.fundFullName,
                fundManager: product.fundManager,
                code: product.code,
                fundType: product.fundType,
                assetAllocation: product.assetAllocation,
                overview: product.overview,
                investmentStrategy: product.investmentStrategy,
                managementDescription: product.managementDescription,
            })
        } else {
            form.setFieldsValue({
                name: null,
                active: true
            })
        }
    }, [product]);

    const [callingAPI, setCallingAPI] = useState(false);

    const handleSubmit = () => {
        form.validateFields().then(async (valid) => {
            if (valid) {
                setCallingAPI(true);
                let assetAllocation = genStringAssetAllocation(listAssetAllocation);
                const values = form.getFieldsValue();
                if (product) {
                    await handleUpdate({
                        ...product,
                        ...values,
                        assetAllocation
                    })
                }
                // else {
                //     await handleCreate(values)
                // }
                setCallingAPI(false);
            }
        })
    }

    const handleCreate = async (data) => {
        await fmarketServices.create(data).then(() => {
            message.success('Create successful!');
            reload();
            onClose();
        }).catch((error) => {
            message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
    }

    const handleUpdate = async (data) => {
        await fmarketServices.funds_product_update(data).then(() => {
            message.success('Update successful!');
            reload();
            onClose();
        }).catch((error) => {
            message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
    }

    const genArrayAssetAllocation = (assetAllocationString = '') => {
        if(assetAllocationString){
            let assetAllocations = assetAllocationString?.split('*');
            let listAssetAllocation = [];
            assetAllocations.forEach((e) => {
                let assetAllocation = e.split(':');
                listAssetAllocation.push({
                    asset: assetAllocation?.[0] || '',
                    allocation: assetAllocation?.[1] || '',
                });
            });
            setListAssetAllocation([...listAssetAllocation]);
        }
    };

    const handleAssetAllocation = (event, index, key) => {
        if(key === 'asset'){
            listAssetAllocation[index][key] = event.target.value.replace(':', '');
        }
        else if(key === 'allocation'){
            listAssetAllocation[index][key] = event.target.value.replace(/[^0-9.]/g, '');
        }
        setListAssetAllocation([...listAssetAllocation]);
        calcTotalAllocation(listAssetAllocation);
    };

    const calcTotalAllocation = (listAssetAllocation) => {
        let totalAllocation = 0;
        listAssetAllocation.forEach((e) => {
            console.log(parseFloat(e.allocation || 0));
            totalAllocation += parseFloat(e.allocation || 0);
        });
        setTotalAllocation(totalAllocation);
        // console.log('genStringAssetAllocation', genStringAssetAllocation(listAssetAllocation));
    };

    const genStringAssetAllocation = (listAssetAllocation) => {
        let arrAssetAllocation = [];
        listAssetAllocation.forEach((e) => {
            if(e.asset && e.allocation){
                arrAssetAllocation.push(e.asset + ':' + e.allocation);
            }
        });
        return arrAssetAllocation.join('*');
    };

    const addNewAssetAllocation = () => {
        listAssetAllocation.push({'asset': '', 'allocation': ''});
        setListAssetAllocation([...listAssetAllocation]);
    };

    const delAssetAllocation = (index) => {
        listAssetAllocation.splice(index, 1);
        setListAssetAllocation([...listAssetAllocation]);
    };

    return (
        <Modal
            title={product ? "Funds infomation" : "Create new funds"}
            width={700}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => onClose()}
            okButtonProps={{
                loading: callingAPI
            }}
            cancelButtonProps={{
                disabled: callingAPI
            }}
            okText='Lưu'
            cancelText='Hủy'
        >
            <Form form={form} layout='vertical' style={{marginTop: 20}}>
                <Row justify={'left'} gutter={[24, 0]}>
                    <Col span={12} lg={12} sm={12} xs={24}>
                        <Form.Item
                            label={'Funds name'}
                            name={'fundFullName'}
                            rules={PROJECT_RULES}>
                            <Input
                                placeholder="Nhập tên quỹ"
                                disabled={callingAPI}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} lg={12} sm={12} xs={24}>
                        <Form.Item
                            label={'Funds manager'}
                            name={'fundManager'}
                            rules={PROJECT_RULES}>
                            <Input
                                placeholder="Nhập tên công ty quản lý"
                                disabled={callingAPI}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} lg={12} sm={12} xs={24}>
                        <Form.Item
                            label={'Funds code'}
                            name={'code'}
                            rules={PROJECT_RULES}>
                            <Input
                                placeholder="Enter funds code"
                                disabled={callingAPI}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} lg={12} sm={12} xs={24}>
                        <Form.Item
                            label={'Funds type'}
                            name={'fundType'}
                            rules={PROJECT_RULES}>
                            <Input
                                placeholder="Enter funds type"
                                disabled={callingAPI}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={24} sm={24} xs={24} style={{marginBottom: 20}}>
                        {/*<Form.Item*/}
                            {/*label={'Asset allocation'}*/}
                            {/*name={'assetAllocation'}*/}
                            {/*rules={PROJECT_RULES}>*/}
                            {/*<Input*/}
                                {/*placeholder="Nhập loại quỹ"*/}
                                {/*disabled={callingAPI}*/}
                            {/*/>*/}
                        {/*</Form.Item>*/}
                        <Row style={{marginBottom: 6}}>Asset allocation</Row>
                        {listAssetAllocation?.length ?
                            <Row gutter={[24, 0]}>
                                <Col span={12} lg={12} sm={12} xs={24}>
                                    Asset
                                </Col>
                                <Col span={12} lg={12} sm={12} xs={24}>
                                    Allocation ({totalAllocation})
                                </Col>
                            </Row>
                            :
                            <Row style={{opacity: 0.5}}>
                                No data
                            </Row>
                        }

                        {listAssetAllocation.map((assetAllocation, index) => {
                            return(
                                <Row gutter={[24, 0]} style={{marginTop: 10}} key={index} align='middle'>
                                    <Col span={12} lg={12} sm={12} xs={12}>
                                        <Input
                                            placeholder="Enter asset name"
                                            onChange={(e) => handleAssetAllocation(e, index, 'asset')}
                                            value={assetAllocation.asset}
                                            disabled={callingAPI}
                                        />
                                    </Col>
                                    <Col span={8} lg={8} sm={8} xs={8}>
                                        <Input
                                            placeholder="Enter allocation"
                                            onChange={(e) => handleAssetAllocation(e, index, 'allocation')}
                                            value={assetAllocation.allocation}
                                            disabled={callingAPI}
                                        />
                                    </Col>
                                    <Col span={4} lg={4} sm={4} xs={4}>
                                        <Button
                                            onClick={() => delAssetAllocation(index)}
                                            danger
                                            type='primary'
                                            size='small'>
                                            Delete
                                        </Button>
                                    </Col>
                                </Row>
                            )
                        })}
                        <Button
                            onClick={() => addNewAssetAllocation()}
                            style={{marginTop: 10}}
                            ghost
                            type='primary'>
                            Add new
                        </Button>
                    </Col>
                    <Col span={12} lg={12} sm={12} xs={24}>
                        <Form.Item
                            label={'Overview'}
                            name={'overview'}
                            rules={PROJECT_RULES}>
                            <Input.TextArea
                                rows={4}
                                disabled={callingAPI}
                                placeholder="Enter overview"/>
                        </Form.Item>
                    </Col>
                    <Col span={12} lg={12} sm={12} xs={24}>
                        <Form.Item
                            label={'Investment Strategy'}
                            name={'investmentStrategy'}
                            rules={PROJECT_RULES}>
                            <Input.TextArea
                                rows={4}
                                disabled={callingAPI}
                                placeholder="Enter investment strategy"/>
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Management description'}
                            name={'managementDescription'}
                            rules={PROJECT_RULES}>
                            <Input.TextArea
                                rows={4}
                                disabled={callingAPI}
                                placeholder="Enter management description"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>

    );
}

export default ProductFormModal;