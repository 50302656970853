import React, { useState, useEffect } from "react";
import { Pagination, Modal, message } from 'antd';
import { PlusOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { AdminHeader } from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import FormModal from "./components/modals/Form"
import positionServices from '../../../services/admin/position';

import { convertStringToQuery } from '../../../utils/common';

import { PERMISSIONS } from "../../../config/permissions";
import { isPermission } from "../../../utils/common";

const Positions = (props) => {
  const { router } = props
  const query = convertStringToQuery(window.location.search);
  const [loading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState({
    page: Number(query?.page || 1),
    pageSize: 10,
    keyword: query?.keyword || null,
  });

  useEffect(() => {
    fetchData();
    window.navigatePage('POSITIONS', {}, {
      page: params.page,
      keyword: params.keyword,
    })
  }, [params])

  const handleChangePage = (page, pageSize) => {
    setParams({
      ...params,
      page,
      pageSize
    })
  };

  const fetchData = async () => {
    setLoading(true);
    await positionServices.list({
      pageSize: params.pageSize,
      page: params.page - 1,
      keyword: params.keyword,
    }).then((response) => {
      setData(response?.data || []);
      setTotal(response?.page?.total_elements)
    }).catch(() => {
      setData([])
      setTotal(0)
    })
    setLoading(false);
  }

  const handleOpenFormModal = (position = null) => {
    setSelectedPosition(position);
    setFormVisible(true)
  };

  const handleDelete = (position) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xoá chức vụ?',
      onOk() {
        positionServices.remove(position.id).then(() => {
          message.success('Xoá tài khoản thành công')
          fetchData();
        }).catch((error) => {
          message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
      },
      onCancel() { },
    });
  }

  return (
    <div className="position-packages">
      <AdminHeader
        title={router.label}
        total={total}
        actions={[
          {
            key: 'add',
            label: 'Thêm mới',
            type: 'primary',
            icon: <PlusOutlined />,
            hide: !isPermission([
              PERMISSIONS.CREATE_POSITION
            ]),
            click: () => handleOpenFormModal()
          }
        ]}
      />
      <Filter
        params={params}
        setParams={(v) => setParams({
          ...v,
          page: 1,
        })}
      />
      <TableData
        loading={loading}
        data={data}
        params={params}
        edit={(v) => handleOpenFormModal(v)}
        onDelete={handleDelete}
      />
      <Pagination
        className="pagination"
        current={params.page}
        pageSize={params.pageSize}
        total={total}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Tổng số ${total}`}
        onChange={handleChangePage}
      />
      {
        formVisible && <FormModal
          visible={formVisible}
          position={selectedPosition}
          onClose={() => setFormVisible(false)}
          reload={() => fetchData()}
        />
      }
    </div>
  );
}

export default Positions;