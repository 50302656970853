import React from "react";
import "../css/components/Filter.scss";
import {Row, Col, Input, Select, DatePicker} from "antd";

import {SearchOutlined} from "@ant-design/icons";
import CONSTANTS from "../../../../config/constants";
import {QuickSelectDate, InputSearch} from "../../../../components";
import SelectProduct from "./SelectProduct";
import SelectProcessingStatus from "./SelectProcessingStatus";
import {useTranslation} from "react-i18next";

const Filter = (props) => {
    const {
        params = {},
        setParams = () => {},
        hideSearch = false
    } = props;

    const { t } = useTranslation();
    const texts = t;

    const onDateChange = (dates) => {
        console.log('onDateChange', dates);
        setParams({...params, dates});
    };

    const onProductChange = (product) => {
        // setParams({...params, dates});
    };

    const onStatusChange = (processing_status) => {
        setParams({...params, processing_status});
    };

    return (
        <Row className="filter" gutter={[8, 8]}>
            {hideSearch ? null :
                <Col span={6} lg={6} sm={12} xs={24}>
                    <InputSearch params={params} setParams={setParams}/>
                </Col>
            }

            <Col span={6} lg={6} sm={12} xs={24}>
                <SelectProcessingStatus params={params} onChange={onStatusChange}/>
            </Col>

            <QuickSelectDate params={params} onChange={onDateChange}/>

            <Col span={6} lg={6} sm={12} xs={24}>
                <SelectProduct params={params} onChange={onProductChange}/>
            </Col>

        </Row>
    );
};

export default Filter;
