import request from "../../utils/request";
import request_invest from "../../utils/request_invest";
import ENDPOINT from "../../config/endpoints/invest";

function salesman_list(params) {
    return request({
        url: ENDPOINT.GET_LIST_BUSINESS_STAFF,
        method: "get",
        params,
    });
}

function salesman_info(params) {
    return request({
        url: ENDPOINT.GET_INFO_BUSINESS_STAFF,
        method: "get",
        params,
    });
}

function salesman_customer_list(params) {
    return request({
        url: ENDPOINT.GET_LIST_CUSTOMER_BY_STAFF,
        method: "get",
        params,
    });
}

function salesman_update(data, params) {
    return request({
        url: ENDPOINT.SALESMAN_UPDATE,
        method: "put",
        params,
        data
    });
}


function salesman_approve(data) {
    return request({
        url: ENDPOINT.GENERATE_SALE_ID,
        method: "post",
        data
    });
}

function assign_customer_to_salse(account_id, account_reference_id) {
    let params = {account_id, account_reference_id};
    return request({
        url: ENDPOINT.ASSIGN_CUSTOMER_TO_SALES,
        method: "put",
        params
    });
}

export default {
    salesman_list,
    salesman_info,
    salesman_customer_list,
    salesman_update,
    salesman_approve,
    assign_customer_to_salse
};
