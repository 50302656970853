import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Input,
    Modal,
    Form,
    Select,
    message,
    Card
} from 'antd';

import {
} from "@ant-design/icons";
import moment from 'moment';
import CONSTANTS from "../../../../../config/constants";
import { PROJECT_RULES } from "../../../../../config/rules";
// import productServices from '../../../../../services/finblue/product';
import customerService from "../../../../../services/invest/customer";
import {displayInvestProfitCapitalDeal, formatMoneyCurrency} from "../../../../../utils/common";
import {getAccountHref} from "../../../../../utils/common_invest";
import {useTranslation} from "react-i18next";

const ModalSavingsTransactionDetail = (props) => {
    const [customer, setCustomer] = useState({});
    const [form] = Form.useForm();
    const {
        transaction = null,
        visible = false,
        onClose = () => {},
        reload = () => {}
    } = props;

    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {
        fetchData();
        // if (product) {
        //     form.setFieldsValue({
        //         name: product.name,
        //         active: product.active
        //     })
        // } else {
        //     form.setFieldsValue({
        //         name: null,
        //         active: true
        //     })
        // }
    }, [transaction])

    const [callingAPI, setCallingAPI] = useState(false);

    const fetchData = async () => {
        await customerService.customer_detail({
                account_id: transaction?.sysUserId
            })
            .then((response) => {
                setCustomer(response?.data || {});
            })
            .catch(() => {
                setCustomer({});
            });
    };

    const handleSubmit = () => {
        form.validateFields().then(async (valid) => {
            if (valid) {
                setCallingAPI(true);
                const values = form.getFieldsValue();
                if (transaction) {
                    await handleUpdate({
                        id: transaction.id,
                        ...values,
                    })
                } else {
                    await handleCreate(values)
                }
                setCallingAPI(false);
            }
        })
    }

    const handleCreate = async (data) => {
        await productServices.create(data).then(() => {
            message.success('Thêm gói sản phẩm thành công')
            reload();
            onClose();
        }).catch((error) => {
            message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
    }

    const handleUpdate = async (data) => {
        await productServices.update(data).then(() => {
            message.success('Cập nhật gói sản phẩm thành công')
            reload();
            onClose();
        }).catch((error) => {
            message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
    }

    return (
        <Modal
            title={texts("order_detail")}
            width={720}
            open={visible}
            footer={null}
            // onOk={() => handleSubmit()}
            onCancel={() => onClose()}
            // okButtonProps={{
            //     loading: callingAPI
            // }}
            // cancelButtonProps={{
            //     disabled: callingAPI
            // }}
            // okText='Lưu'
            // okButtonProps={{ disabled: true, className: "modal-footer-hiden-button" }}
            // cancelText='Đóng'
        >
            <table className="table-transaction-detail">
                <tr>
                    <td className="transaction-detail-col-1">{texts("customer")}</td>
                    <td className="transaction-detail-col-2">
                        <a href={getAccountHref(transaction?.sysUserId)}>{customer?.accFullname || transaction?.accFullname}</a>
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts("phone_number")}</td>
                    <td className="transaction-detail-col-2">{customer?.accPhone}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts("product_name")}</td>
                    <td className="transaction-detail-col-2">{transaction?.productName}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts("order_code")}</td>
                    <td className="transaction-detail-col-2">{transaction?.orderCode}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts("amount")}</td>
                    <td className="transaction-detail-col-2">{formatMoneyCurrency(transaction?.amount)}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts("transaction_date")}</td>
                    <td className="transaction-detail-col-2">{moment(transaction?.createdAt).format('DD/MM/YYYY')}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts("bank_name")}</td>
                    <td className="transaction-detail-col-2">
                        {transaction?.bankCode}
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts("bank_account")}</td>
                    <td className="transaction-detail-col-2">
                        {transaction?.bankAccountName}
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts("bank_no")}</td>
                    <td className="transaction-detail-col-2">
                        {transaction?.bankAccountNumber}
                    </td>
                </tr>


            </table>


        </Modal>

    );
}

export default ModalSavingsTransactionDetail;