import request from "../utils/request";
import Cookies from "js-cookie";
import ENDPOINT from "../config/endpoints";

function login(data) {
  return request({
    url: ENDPOINT.LOGIN,
    method: "post",
    data,
  });
}

function update_login_info(data) {
  Cookies.set("login_info", JSON.stringify(data));
}

function login_info() {
  try {
    return JSON.parse(Cookies.get("login_info"));
  } catch (_) {
    return {};
  }
}

function logout() {
  Cookies.remove("login_info");
  window.location.reload();
}

export default {
  login,
  update_login_info,
  login_info,
  logout,
};
