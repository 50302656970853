import React, { } from "react";
import {
} from 'antd';

import {
} from "@ant-design/icons";

import { } from 'react-redux';
import { Link } from "react-router-dom";

import {
  convertQueryToString,
  getRouterByName,
  getRouterParams,
} from '../../utils/common'


const RouterLink = (props) => {
  const {
    className = '',
    routerName = '',
    routerParams = {},
    routerQuery = {},
    label = '',
    blank = false,
    isPermission = true
  } = props;

  const currentRouter = getRouterByName(routerName)
  const newPath = convertQueryToString(getRouterParams(currentRouter.path, routerParams), routerQuery)
  return (
    <>
      {
        isPermission ? <Link
          className={`router-link ${className}`}
          target={blank ? '_blank' : ''}
          to={newPath}
          title={label}
        >
          {label}
        </Link> : label
      }
    </>
  );
}

export default RouterLink;