import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Form,
  Select,
  message,
} from 'antd';

import {
} from "@ant-design/icons";

import {
  DEPARTEMNT_RULES,
  PHONE_RULES,
  POSITION_RULES,
  ROLE_RULES
} from "../../../../../config/rules";

import staffServices from '../../../../../services/admin/staff';

const StaffFormModal = (props) => {
  const [form] = Form.useForm();
  const [callingAPI, setCallingAPI] = useState(false);

  const {
    departments = [],
    staff = null,
    department = null,
    visible = false,
    roles = [],
    positions = [],
    onClose = () => { },
    onReload = () => { },
  } = props

  useEffect(() => {
    if (staff) {
      form.setFieldsValue({
        departmentId: department?.departmentId || null,
        positionId: staff.positionId,
        phone: staff.phone,
        roleId: staff.roleId
      })
    } else {
      form.setFieldsValue({
        departmentId: department?.departmentId || null,
        positionId: null,
        phone: null,
        roleId: null
      })
    }
  }, [staff, department])

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      setCallingAPI(true);
      if (staff) {
        await handleUpdate(values)
      } else {
        await handleCreate(values)
      }
      setCallingAPI(false);
    })
  }

  const handleCreate = async (data) => {
    await staffServices.create(data).then(() => {
      message.success('Thêm nhân viên mới thành công')
      onReload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const handleUpdate = async (data) => {
    await staffServices.update(staff.id, data).then(() => {
      message.success('Cập nhật nhân viên thành công')
      onReload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  return (
    <Modal
      title={staff ? "Chỉnh sửa nhân viên" : "Thêm mới nhân viên"}
      width={400}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => onClose()}
      okButtonProps={{
        loading: callingAPI
      }}
      cancelButtonProps={{
        disabled: callingAPI
      }}
      okText='Lưu'
      cancelText='Hủy'
    >
      <Form
        form={form}
        layout='vertical'
      >
        <Row
          justify={'left'}
        >
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Phòng ban'}
              name={'departmentId'}
              rules={DEPARTEMNT_RULES}
            >
              <Select
                placeholder={"Vui lòng chọn phòng ban"}
                disabled={callingAPI}
                showSearch={true}
                optionFilterProp="children"
                className="w-full"
                filterOption={(input, option) =>
                  `${option.children}`
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {departments.map((it) => (
                  <Select.Option
                    value={it.departmentId}
                    key={it.departmentId}
                  >
                    {it.departmentName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Số điện thoại'}
              name={'phone'}
              rules={PHONE_RULES}
            >
              <Input
                placeholder={"Vui lòng nhập số điện thoại"}
                disabled={callingAPI}
              ></Input>
            </Form.Item>
          </Col>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Chức vụ'}
              name={'positionId'}
              rules={POSITION_RULES}
            >
              <Select
                placeholder={"Vui lòng chọn chức vụ"}
                disabled={callingAPI}
                showSearch={true}
                optionFilterProp="children"
                className="w-full"
                filterOption={(input, option) =>
                  `${option.children}`
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {positions.map((it) => (
                  <Select.Option
                    value={it.id}
                    key={it.id}
                  >
                    {it.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Vị trí'}
              name={'roleId'}
              rules={ROLE_RULES}
            >
              <Select
                placeholder={"Vui lòng chọn quyền hạn"}
                disabled={callingAPI}
                showSearch={true}
                optionFilterProp="children"
                className="w-full"
                filterOption={(input, option) =>
                  `${option.children}`
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {roles.map((it) => (
                  <Select.Option
                    value={it.roleId}
                    key={it.roleId}
                  >
                    {it.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>

  );
}

export default StaffFormModal;