import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Input,
    Modal,
    Form,
    Select,
    message,
    Card,
    Button,
    Popconfirm,
    Tag
} from 'antd';

import {
} from "@ant-design/icons";
import moment from 'moment';
import {displayInvestProfitCapitalDeal, formatMoneyCurrency} from "../../../../../../utils/common";
import {getAccountHref} from "../../../../../../utils/common_invest";
import {useTranslation} from "react-i18next";
import savingsService from "../../../../../../services/invest/savings";
import '../../index.scss';
import CONSTANTS from "../../../../../../config/constants_invest";

const ModalSettleInfo = (props) => {
    const [customer, setCustomer] = useState({});
    const [form] = Form.useForm();
    const {
        item = null,
        visible = false,
        onClose = () => {},
        reload = () => {}
    } = props;

    const { t } = useTranslation();
    const texts = t;


    const [callingAPI, setCallingAPI] = useState(false);


    const confirmPayment = async() => {
        setCallingAPI(true);
        let res = await savingsService.payment_settle_firm_banking({
            itemId: '',
            paymentForceSettleRequestIds: ''
        });
        console.log('confirmPayment res', res);
    };


    const genTagStatus = (item) => {
        let requestStatus = item?.balanceChangeRequests?.[0]?.requestStatus;
        let status = CONSTANTS.BALANCE_CHANGE_REQUEST_STATUS.find(e => e?.value === requestStatus);
        if(status){
            return <Tag color={status?.color}>{status?.name}</Tag>
        }
        return <Tag color={'red'}>Thất bại</Tag>
    };


    return (
        <Modal
            title={'Thông tin tất toán'}
            width={500}
            open={visible}
//            footer={null}
            // onOk={() => handleSubmit()}

//            footer={[
//                <Button key="cancel" onClick={onClose}>
//                    {texts('close')}
//                </Button>,
//                <Popconfirm
//                    placement="bottom"
//                    title={'Xác nhận chuyển tiền'}
//                    description={''}
//                    onConfirm={confirmPayment}
//                    okText="Đồng ý"
//                    cancelText="Huỷ">
//                    <Button
//                        key="submit"
//                        type="primary"
//                        loading={callingAPI}>
//                        Chuyển tiền
//                    </Button>
//                </Popconfirm>
//            ]}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Đóng
                </Button>,
            ]}

            onCancel={() => onClose()}
            style={{top: 20}}
            // okButtonProps={{
            //     loading: callingAPI
            // }}
            // cancelButtonProps={{
            //     disabled: callingAPI
            // }}
            // okText='Lưu'
            // okButtonProps={{ disabled: true, className: "modal-footer-hiden-button" }}
            // cancelText='Đóng'
        >
            <table className="table-settle-detail">
                <tr>
                    <td className="item-detail-col-1">{texts("customer")}</td>
                    <td className="item-detail-col-2">
                        <a href={getAccountHref(item?.accountInfo?.rowId)}>{item?.accountInfo?.accFullname}</a>
                    </td>
                </tr>
                <tr>
                    <td className="item-detail-col-1">{texts("phone_number")}</td>
                    <td className="item-detail-col-2">{item?.accountInfo?.accPhone}</td>
                </tr>
                <tr>
                    <td className="item-detail-col-1">{texts("contract_code")}</td>
                    <td className="item-detail-col-2">{item?.paymentTransaction?.contractCode}</td>
                </tr>
                <tr>
                    <td className="item-detail-col-1">{texts("product_name")}</td>
                    <td className="item-detail-col-2">{item?.productDetails?.itemName}</td>
                </tr>

                <tr>
                    <td className="item-detail-col-1">{'Tiền gốc'}</td>
                    <td className="item-detail-col-2">{formatMoneyCurrency(item?.paymentForceSettleRequests?.[1]?.forceSettleAmount)}</td>
                </tr>
                <tr>
                    <td className="item-detail-col-1">{'Tiền lãi'}</td>
                    <td className="item-detail-col-2">{formatMoneyCurrency(item?.paymentForceSettleRequests?.[0]?.forceSettleAmount)}</td>
                </tr>
                <tr>
                    <td className="item-detail-col-1">{'Tiền thuế, phí'}</td>
                    <td className="item-detail-col-2">{formatMoneyCurrency(item?.paymentForceSettleRequests?.[0]?.transactionFee + item?.paymentForceSettleRequests?.[1]?.transactionFee)}</td>
                </tr>
                <tr>
                    <td className="item-detail-col-1">{'Tiền tất toán'}</td>
                    <td className="item-detail-col-2">{formatMoneyCurrency(item?.paymentForceSettleRequests?.[0]?.realReceiveAmount + item?.paymentForceSettleRequests?.[1]?.realReceiveAmount)}</td>
                </tr>

                <tr>
                    <td className="item-detail-col-1">{'Ngày tất toán'}</td>
                    <td className="item-detail-col-2">{moment(item?.createdAt).format('DD/MM/YYYY')}</td>
                </tr>
                <tr>
                    <td className="item-detail-col-1">{texts("bank_name")}</td>
                    <td className="item-detail-col-2">
                        {item?.bankCode}
                    </td>
                </tr>
                <tr>
                    <td className="item-detail-col-1">{texts("bank_account")}</td>
                    <td className="item-detail-col-2">
                        {item?.bankAccountName}
                    </td>
                </tr>
                <tr>
                    <td className="item-detail-col-1">{texts("bank_no")}</td>
                    <td className="item-detail-col-2">
                        {item?.bankAccountNumber}
                    </td>
                </tr>
                <tr>
                    <td className="item-detail-col-1">{texts("status")}</td>
                    <td className="item-detail-col-2">
                        {genTagStatus(item)}
                    </td>
                </tr>


            </table>


        </Modal>

    );
}

export default ModalSettleInfo;