import React, {useState, useEffect} from "react";
import {Row, Col, Input, Select, DatePicker} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import savingsService from "../../../../services/invest/savings";
import CONSTANTS from "../../../../config/constants_invest";
import {useTranslation} from "react-i18next";

const SelectProcessingStatus = (props) => {
    const {
        params = {},
        onChange = () => {}
    } = props;

    const { t } = useTranslation();
    const texts = t;

    const [selectValue, setSelectValue] = useState(params?.processing_status);
    const [options, setOptions] = useState([]);

    // useEffect(() => {
    //     getListProduct();
    // }, []);

    const selectChange = (value) => {
        setSelectValue(value);
        onChange(value);
    };

    // const getListProduct = async () => {
    //     await savingsService.savings_products({
    //         page: 0,
    //         pageSize: 10,
    //     })
    //         .then((response) => {
    //             let filterResponse = response?.data || [];
    //             let filterData = ['1T', '3T', '6T', '9T', '12T', '1200T'];
    //             filterResponse = filterResponse.filter(e => filterData.indexOf(e?.itemCode) > -1);
    //             console.log('filterResponse', filterResponse);
    //             setOptions(filterResponse);
    //         })
    //         .catch(() => {
    //             setOptions([]);
    //         });
    // };

    return (
        <Select
            placeholder={texts('select_status')}
            value={selectValue}
            onChange={(value) => selectChange(value)}
            showSearch={true}
            optionFilterProp="children"
            className="w-full"
            filterOption={(input, option) =>
                `${option.children}`.toLowerCase().includes(input.toLowerCase())
            }
        >
            {CONSTANTS.SAVINGS_TRANSACTION_PROCESSING_STATUS.map((option) => (
                <Select.Option value={option?.value} key={option?.value}>
                    {texts(option?.label)}
                </Select.Option>
            ))}
        </Select>
    );
};

export default SelectProcessingStatus;