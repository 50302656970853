import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Input,
    Modal,
    Form,
    Select,
    message,
    Card
} from 'antd';

import {
} from "@ant-design/icons";
import moment from 'moment';
import CONSTANTS from "../../../../../config/constants";
import { PROJECT_RULES } from "../../../../../config/rules";
// import productServices from '../../../../../services/finblue/product';
import customerService from "../../../../../services/invest/customer";
import {displayInvestProfitCapitalDeal, formatMoneyCurrency} from "../../../../../utils/common";
import {useTranslation} from "react-i18next";

const ModalFmarketDetail = (props) => {
    const [form] = Form.useForm();
    const {
        detail = null,
        visible = false,
        onClose = () => {},
        reload = () => {}
    } = props;

    const { t } = useTranslation();
    const texts = t;

    // useEffect(() => {
    //
    // }, [detail])

    return (
        <Modal
            title={texts('account_info')}
            width={600}
            open={visible}
            footer={null}
            // onOk={() => handleSubmit()}
            onCancel={() => onClose()}
            // okButtonProps={{
            //     loading: callingAPI
            // }}
            // cancelButtonProps={{
            //     disabled: callingAPI
            // }}
            // okText='Lưu'
            // okButtonProps={{ disabled: true, className: "modal-footer-hiden-button" }}
            // cancelText='Đóng'
        >
            <table className="table-transaction-detail">
                <tr>
                    <td className="transaction-detail-col-1">{texts('fmarket_id')}</td>
                    <td className="transaction-detail-col-2">{detail?.fmarketId}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('customer_name')}</td>
                    <td className="transaction-detail-col-2">{detail?.faFullName}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('phone_number')}</td>
                    <td className="transaction-detail-col-2">{detail?.faCellPhone}</td>
                </tr>
            </table>
        </Modal>

    );
}

export default ModalFmarketDetail;