import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Form,
  message
} from 'antd';

import {
} from "@ant-design/icons";

import { POSITION_NAME_RULES } from "../../../../../config/rules";
import positionServices from '../../../../../services/admin/position'

const FormModal = (props) => {
  const [form] = Form.useForm();
  const {
    position = null,
    visible = false,
    onClose = () => { },
    reload = () => { }
  } = props

  useEffect(() => {
    if (position) {
      form.setFieldsValue({
        name: position.name,
        description: position.description
      })
    } else {
      form.setFieldsValue({
        name: null,
        description: ''
      })
    }
  }, [position])

  const [callingAPI, setCallingAPI] = useState(false);

  const handleSubmit = () => {
    form.validateFields().then(async (valid) => {
      if (valid) {
        setCallingAPI(true);
        const values = form.getFieldsValue();
        if (position) {
          await handleUpdate({
            id: position.id,
            ...values,
          })
        } else {
          await handleCreate(values)
        }
        setCallingAPI(false);
      }
    })
  }

  const handleCreate = async (data) => {
    await positionServices.create(data).then(() => {
      message.success('Thêm chức vụ thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const handleUpdate = async (data) => {
    await positionServices.update(position.id, data).then(() => {
      message.success('Cập nhật chức vụ thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  return (
    <Modal
      title={position ? "Chỉnh sửa chức vụ" : "Thêm mới chức vụ"}
      width={500}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => onClose()}
      okButtonProps={{
        loading: callingAPI
      }}
      cancelButtonProps={{
        disabled: callingAPI
      }}
      okText='Lưu'
      cancelText='Hủy'
    >
      <Form
        form={form}
        layout='vertical'
      >
        <Row
          justify={'left'}
          gutter={[8, 8]}
        >
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Tên chức vụ'}
              name={'name'}
              rules={POSITION_NAME_RULES}
            >
              <Input
                placeholder="Vui lòng nhập tên chức vụ"
                disabled={callingAPI}
              />
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Mô tả'}
              name={'description'}
              rules={[]}
            >
              <Input.TextArea
                placeholder={"Vui lòng nhập mô tả"}
                disabled={callingAPI}
              ></Input.TextArea>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>

  );
}

export default FormModal;