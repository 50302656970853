import React from "react";
import {
  Row,
  Table,
  Col,
  Button,
  Tag
} from 'antd';

import {
  EditOutlined 
} from "@ant-design/icons";

import {
  convertDateTime,
  activeStatus,
} from '../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    params,
    edit = () => {}
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Gói sản phẩm',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
      render: (_, record) => <a>
        {record.name}
      </a>
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'createdTime',
      key: 'createdTime',
      align: 'center',
      width: 200,
      render: (_, record) => convertDateTime(record.createdTime)
    },
    {
      title: 'Thời gian cập nhật',
      dataIndex: 'updateTime',
      key: 'updateTime',
      align: 'center',
      width: 200,
      render: (_, record) => convertDateTime(record.updateTime)
    },
    {
      title: 'Trạng thái hiển thị',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Tag color={activeStatus(record.active)?.tag_color}>
          {activeStatus(record.active)?.label}
        </Tag>
      ),
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: 250,
      render: (_, record) => (
        <Row gutter={[8, 8]} justify={'center'}>
          <Col>
            <Button
              icon={<EditOutlined />}
              type='primary'
              size="small"
              ghost
              onClick={() => edit(record)}
            >Sửa</Button>
          </Col>
          <Col>
            <Button
              ghost
              type='primary'
              size="small"
              onClick={() => window.navigatePage('PRODUCT_PACKAGE_DETAIL', { id: record.id })}
            >Hạn mức</Button>
          </Col>
        </Row>
      ),
    },
  ]

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 + (params.page - 1) * params.pageSize }))}
      loading={loading}
      pagination={false}
      scroll={{ x: 1024 }}
      rowKey={(record) => record?.id}
      size="small"
    />
  );
}

export default TableData;