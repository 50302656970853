import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function report_market(params = {}) {
  return request({
    url: ENDPOINT.REPORT_MARKET,
    method: "get",
    params,
  });
}

function report_lender(params = {}) {
  return request({
    url: ENDPOINT.REPORT_LENDER,
    method: "get",
    params,
  });
}

function report_customer(params = {}) {
  return request({
    url: ENDPOINT.REPORT_CUSTOMER,
    method: "get",
    params,
  });
}

function report_order(params = {}) {
  return request({
    url: ENDPOINT.REPORT_ORDER,
    method: "get",
    params,
  });
}

function report_order_money(params = {}) {
  return request({
    url: ENDPOINT.REPORT_ORDER_MONEY,
    method: "get",
    params,
  });
}

function report_system(params = {}) {
  return request({
    url: ENDPOINT.REPORT_SYSTEM,
    method: "get",
    params,
  });
}

export default {
  report_market,
  report_lender,
  report_customer,
  report_order,
  report_order_money,
  report_system
};
