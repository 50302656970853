import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
} from 'antd';

import {
  CheckCircleOutlined
} from "@ant-design/icons";


const ApprovalSuccessModal = (props) => {
  const {
    visible = false,
    onNext = () => {},
  } = props;

  return (
    <Modal
      width={500}
      open={visible}
      footer={false}
      closable={false}
    >
      
      <div className="text-center py-xl">
        <CheckCircleOutlined
          style={{
            color: 'green',
            fontSize: 63
          }}
        />
        <p className="text-center mt-m">
          Phê duyệt thành công
        </p>
      </div>
      <div className="flex justify-content-center">
        <Button
          type="primary"
          onClick={() => onNext()}
        >
          Tiếp tục
        </Button>
      </div>
    </Modal>
  );
}

export default ApprovalSuccessModal;