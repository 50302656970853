import {
  DashboardOutlined,
  SolutionOutlined,
  EuroCircleOutlined,
  ShoppingCartOutlined,
  ExceptionOutlined
} from "@ant-design/icons";

export default {
  ROUTERS: [
    {
      name: "DASHBOARD",
      path: "/",
      element: "Dashboard",
      icon: <DashboardOutlined />,
      label: "Dashboard",
    },
    {
      name: "DEBT_DETAIL",
      path: "/debts/:customer_id/detail",
      element: "DebtDetail",
      label: "Danh sách đơn nợ",
      parent: "DASHBOARD",
    },
    {
      name: 'LIMIT_APPROVAL',
      path: '/limit-approval',
      element: 'LimitApproval',
      label: 'Hợp đồng',
      menu: 'MAJOR',
    },
    {
      name: "LIMIT_APPROVAL_DETAIL",
      path: "/limit-approval/:id",
      element: "LimitApprovalDetail",
      parent: "LIMIT_APPROVAL",
      label: "Chi tiết hợp đồng hạn mức",
    },
    {
      name: 'LOAN_APPROVAL',
      path: '/loan-approval',
      element: 'LoanApproval',
      label: 'Đơn vay',
      menu: 'MAJOR',
    },
    {
      name: 'LOAN_APPROVAL_DETAIL',
      path: '/loan-approval/:id',
      element: 'LoanApprovalDetail',
      label: 'Chi tiết đơn vay',
      parent: 'LOAN_APPROVAL'
    },
    {
      name: 'EKYC_APPROVAL',
      path: '/ekyc-approval',
      element: 'EKYCApproval',
      label: 'EKYC',
      menu: 'MAJOR',
    },
    {
      name: 'EKYC_APPROVAL_DETAIL',
      path: '/ekyc-approval/:id',
      element: 'EKYCApprovalDetail',
      parent: 'EKYC_APPROVAL',
      label: 'Chi tiết EKYC',
    },
    {
      name: 'ASSESSORS',
      path: '/assessors',
      element: 'Assessors',
      label: 'Chia đơn/Hồ sơ',
      menu: 'MAJOR'
    },
    {
      name: 'LENDER_LOAN_APPROVAL',
      path: '/lender-loan-approval',
      element: 'LenderLoanApproval',
      label: 'Giải ngân',
      menu: 'MAJOR'
    },
    {
      name: 'LENDER_LOAN_APPROVAL_DETAIL',
      path: '/lender-loan-approval/:id',
      element: 'LenderLoanApprovalDetail',
      label: 'Chi tiết đơn vay',
      parent: 'LENDER_LOAN_APPROVAL'
    },
    {
      name: 'MARKET_LOAN_APPROVAL',
      path: '/market-loan-approval',
      element: 'MarketLoanApproval',
      label: 'Sàn thẩm định',
      menu: 'MAJOR'
    },
    {
      name: 'MARKET_LOAN_APPROVAL_DETAIL',
      path: '/market-loan-approval/:id',
      element: 'MarketLoanApprovalDetail',
      label: 'Chi tiết đơn vay',
      parent: 'MARKET_LOAN_APPROVAL'
    },
    {
      name: 'CONFIRM_PAYMENT_EXCHANGE',
      path: '/confirm-payment-exchange',
      element: 'ConfirmPaymentExchange',
      label: 'Xác nhận thanh toán với sàn',
    },
    {
      name: 'DISBURSEMENT_REQUEST',
      path: '/disbursement-request',
      element: 'DisbursementRequest',
      label: 'Yêu cầu giải ngân',
    },
    {
      name: 'CUSTOMER_PAYMENT',
      path: '/customer-payment',
      element: 'CustomerPayment',
      label: 'Gạch nợ',
    },
    {
      name: 'PRODUCT_PACKAGES',
      path: '/product-packages',
      element: 'ProductPackages',
      label: 'Gói sản phẩm',
      menu: 'PRODUCT',
    },
    {
      name: 'PRODUCT_PACKAGE_DETAIL',
      path: '/product-packages/:id',
      element: 'ProductPackageDetail',
      label: 'Cấu hình hạn mức',
      parent: 'PRODUCT_PACKAGES',
    },
    {
      name: 'PRODUCT_TRANSACTIONS',
      path: '/product-transactions',
      element: 'ProductTransactions',
      label: 'Hạn mức',
      menu: 'PRODUCT',
    },
    {
      name: 'CUSTOMERS',
      path: '/customers',
      element: 'Customers',
      icon: <SolutionOutlined />,
      label: 'Khách hàng',
    },
    {
      name: 'CUSTOMER_DETAIL',
      path: '/customers/:id',
      element: 'CustomerDetail',
      label: 'Chi tiết khách hàng',
      parent: 'CUSTOMERS',
    },
    {
      name: 'CUSTOMER_LOAN_DETAIL',
      path: '/customers/:id/loan/:order_id',
      element: 'CustomerLoanDetail',
      label: 'Lịch thanh toán',
      parent: 'CUSTOMER_DETAIL',
    },
    {
      name: 'MARKETS',
      path: '/markets',
      element: 'Markets',
      label: 'Sàn TMĐT',
      icon: <ShoppingCartOutlined />,
    },
    {
      name: 'MARKET_DETAIL',
      path: '/markets/:id/detail',
      element: 'MarketDetail',
      label: 'Chi tiết Sàn TMĐT',
      parent: 'MARKETS',
    },
    {
      name: 'MARKET_ORDER_DETAIL',
      path: '/markets/:id/order/:order_id',
      element: 'MarketOrderDetail',
      label: 'Chi tiết đơn vay',
      parent: 'MARKET_DETAIL',
    },
    {
      name: 'LENDERS',
      path: '/lenders',
      element: 'Lenders',
      label: 'Công ty tài chính',
      icon: <EuroCircleOutlined />,
    },
    {
      name: 'LENDER_DETAIL',
      path: '/lenders/:id/detail',
      element: 'LenderDetail',
      label: 'Chi tiết công ty tài chính',
      parent: 'LENDERS',
    },
    {
      name: 'LENDER_ORDER_DETAIL',
      path: '/lenders/:id/order/:order_id',
      element: 'LenderOrderDetail',
      label: 'Chi tiết đơn vay',
      parent: 'LENDER_DETAIL',
    },
    {
      name: 'APPOTA',
      path: '/appota',
      element: 'Appota',
      label: 'Appota',
      icon: <ExceptionOutlined />,
    },
    {
      name: 'APPOTA_DETAIL',
      path: '/appota/:id/detail',
      element: 'AppotaDetail',
      label: 'Chi tiết hoá đơn',
      parent: 'APPOTA',
    },
    {
      name: 'BANKS',
      path: '/banks',
      element: 'Banks',
      label: 'Danh sách ngân hàng',
      menu: 'BANK',
    },
    {
      name: 'SYSTEM_BANK_ACCOUNTS',
      path: '/system-bank-accounts',
      element: 'SystemBankAccounts',
      label: 'Tài khoản hệ thống',
      menu: 'BANK',
    },
    {
      name: 'BANK_ACCOUNT_RECEIPTS',
      path: '/system-bank-accounts/:id/bank-account-receipts',
      element: 'BankAccountReceipts',
      label: 'Danh sách phiếu thu',
      parent: 'SYSTEM_BANK_ACCOUNTS',
    },
    {
      name: 'ASSESSOR_DETAIL',
      path: '/assessors/:id/detail',
      element: 'AssessorDetail',
      label: 'Chi tiết thẩm định viên',
      parent: 'ASSESSORS',
    }
  ],
};
