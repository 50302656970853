import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Row, Col, Button, Spin, Tag} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminDetailHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import savingsService from "../../../services/invest/savings";
import ModalSavingsTransactionDetail from "./components/modals/ModalSavingsTransactionDetail";
import {displayInvestProfitCapitalDeal, formatMoneyCurrency} from "../../../utils/common";
import moment from "moment";
import {useParams} from "react-router-dom/index";
// import profileServices from "../../../services/invest/savings";
import {message} from "antd/es/index";
import {useTranslation} from "react-i18next";
import CONSTANTS from "../../../config/constants";

const SavingsForceSettleDetail = (props) => {
    const {router} = props;
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [formVisible, setFormVisible] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [callingAPI, setCallingAPI] = useState(false)
    const [actionKey, setActionKey] = useState('')


    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        setLoading(true);
        let force_settle_id = id;
        await savingsService
            .get_force_settle_detail({
                force_settle_id,
                username:'admin',
                admin_user: 'admin'
            })
            .then((response) => {
                console.log('response?.list_payment_force_settle', response);
                setData(response);
                // setTotal(response?.page?.total_elements);
            })
            .catch((err) => {
                console.log('error', err);
                setData([]);
            });
        setLoading(false);
    };

    const handleShowDetail = (transaction = null) => {
        setSelectedTransaction(transaction);
        setFormVisible(true)
    };

    const handleApproval = (status) => {
        setActionKey(status);
        setCallingAPI(true);
        let status_id = 0;
        let success = 'Approval successful';
        if (status === 'reject') {
            status_id = 3;
            success = 'Rejected';
        }
        savingsService.approve_reject_force_settle({
            force_settle_id: parseInt(id),
            force_settle_id_submit_status: status_id,
            admin_user: 'admin'
        }).then(() => {
            message.success(success);
            setCallingAPI(false);
            fetchData();
        }).catch((error) => {
            setCallingAPI(false);
            message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
    };


    const renderStatus = (status) => {
        switch (status){
            case CONSTANTS.FORCE_SETTLE_STATUS.PENDING.value:
                return <Tag color={CONSTANTS.FORCE_SETTLE_STATUS.PENDING.color}>{texts(CONSTANTS.FORCE_SETTLE_STATUS.PENDING.name)}</Tag>;
            case CONSTANTS.FORCE_SETTLE_STATUS.APPROVE.value:
                return <Tag color={CONSTANTS.FORCE_SETTLE_STATUS.APPROVE.color}>{texts(CONSTANTS.FORCE_SETTLE_STATUS.APPROVE.name)}</Tag>;
            case CONSTANTS.FORCE_SETTLE_STATUS.REJECT.value:
                return <Tag color={CONSTANTS.FORCE_SETTLE_STATUS.REJECT.color}>{texts(CONSTANTS.FORCE_SETTLE_STATUS.REJECT.name)}</Tag>;
            case CONSTANTS.FORCE_SETTLE_STATUS.COMPLETE.value:
                return <Tag color={CONSTANTS.FORCE_SETTLE_STATUS.COMPLETE.color}>{texts(CONSTANTS.FORCE_SETTLE_STATUS.COMPLETE.name)}</Tag>;
            default: return null;
        }
    };


    return (
        <div className="cms-users">
            <AdminDetailHeader
                title={'Chi tiết tất toán trước hạn'}
            />

            <Spin spinning={loading}>

                <div style={{marginTop: 8}}>
                    {renderStatus(data?.force_settle_request_status)}
                </div>

                <Row>
                    <Col span={12} lg={12} sm={24} xs={24}>
                        <table className="table-transaction-detail">
                            <tr>
                                <td className="force-settle-detail-col-1">{texts('fullname')}</td>
                                <td className="force-settle-detail-col-2">{data?.accFullname}</td>
                            </tr>
                            <tr>
                                <td className="force-settle-detail-col-1">{texts('product_name')}</td>
                                <td className="force-settle-detail-col-2">{data?.force_settle_pay_profit_cycle}</td>
                            </tr>
                            <tr>
                                <td className="force-settle-detail-col-1">{texts('amount')}</td>
                                <td className="force-settle-detail-col-2">{formatMoneyCurrency(data?.force_settle_amount)}</td>
                            </tr>
                            <tr>
                                <td className="force-settle-detail-col-1">{texts('flexible_interest')}</td>
                                <td className="force-settle-detail-col-2">{data?.force_settle_flexible_interest}%</td>
                            </tr>
                            {/*<tr>*/}
                                {/*<td className="force-settle-detail-col-1">{texts('original_after_settle')}</td>*/}
                                {/*<td className="force-settle-detail-col-2">{data?.force_settle_original_after_settle}</td>*/}
                            {/*</tr>*/}
                            <tr>
                                <td className="force-settle-detail-col-1">{texts('original_cycle')}</td>
                                <td className="force-settle-detail-col-2">{data?.force_settle_original_cycle}</td>
                            </tr>
                            <tr>
                                <td className="force-settle-detail-col-1">{texts('original_interest')}</td>
                                <td className="force-settle-detail-col-2">{data?.force_settle_original_interest}%</td>
                            </tr>
                            {/*<tr>*/}
                                {/*<td className="force-settle-detail-col-1">{texts('original_sale')}</td>*/}
                                {/*<td className="force-settle-detail-col-2">{data?.force_settle_original_sale}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                                {/*<td className="force-settle-detail-col-1">{texts('payment_type')}</td>*/}
                                {/*<td className="force-settle-detail-col-2">{data?.force_settle_payment_type}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                                {/*<td className="force-settle-detail-col-1">{texts('request_status')}</td>*/}
                                {/*<td className="force-settle-detail-col-2">{data?.force_settle_request_status}</td>*/}
                            {/*</tr>*/}
                            <tr>
                                <td className="force-settle-detail-col-1">{texts('created_date')}</td>
                                <td className="force-settle-detail-col-2">{moment(data?.list_payment_force_settle?.[0]?.created_date).format('DD/MM/YYYY')}</td>
                            </tr>
                        </table>
                    </Col>
                    <Col span={12} lg={12} sm={24} xs={24}>
                        <table className="table-transaction-detail">
                            <tr>
                                <td className="force-settle-detail-col-1">{texts('force_settle_fee')} </td>
                                <td className="force-settle-detail-col-2">{formatMoneyCurrency(data?.force_settle_fee)}</td>
                            </tr>
                            <tr>
                                <td className="force-settle-detail-col-1">{texts('payment_amount_start')}</td>
                                <td className="force-settle-detail-col-2">{formatMoneyCurrency(data?.force_settle_payment_amount_start)}</td>
                            </tr>
                            <tr>
                                <td className="force-settle-detail-col-1">{texts('payment_ran_day')}</td>
                                <td className="force-settle-detail-col-2">{data?.force_settle_payment_ran_day}</td>
                            </tr>
                            <tr>
                                <td className="force-settle-detail-col-1">{texts('profit_calculate')}</td>
                                <td className="force-settle-detail-col-2">{formatMoneyCurrency(data?.force_settle_profit_calculate)}</td>
                            </tr>
                            {/*<tr>*/}
                                {/*<td className="force-settle-detail-col-1">Profit on randay</td>*/}
                                {/*<td className="force-settle-detail-col-2">{formatMoneyCurrency(data?.force_settle_profit_on_randay)}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                                {/*<td className="force-settle-detail-col-1">Profit remain of origin</td>*/}
                                {/*<td className="force-settle-detail-col-2">{formatMoneyCurrency(data?.force_settle_profit_remain_of_origin)}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                                {/*<td className="force-settle-detail-col-1">Remain amount</td>*/}
                                {/*<td className="force-settle-detail-col-2">{formatMoneyCurrency(data?.force_settle_remain_amount)}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                                {/*<td className="force-settle-detail-col-1">Remain profit</td>*/}
                                {/*<td className="force-settle-detail-col-2">{formatMoneyCurrency(data?.force_settle_remain_profit)}</td>*/}
                            {/*</tr>*/}
                            <tr>
                                <td className="force-settle-detail-col-1">{texts('real_receive')}</td>
                                <td className="force-settle-detail-col-2">{formatMoneyCurrency(data?.force_settle_real_receive)}</td>
                            </tr>
                        </table>
                    </Col>
                </Row>

                {data?.force_settle_request_status === CONSTANTS.FORCE_SETTLE_STATUS.PENDING.value &&
                    <Row gutter={[16, 0]} className="detail-footer">
                        <Col>
                            <Button
                                danger
                                type="primary"
                                disabled={loading || (callingAPI && actionKey !== 'reject')}
                                loading={callingAPI && actionKey === 'reject'}
                                onClick={() => handleApproval('reject')}
                            >
                                {texts('reject')}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                disabled={loading || (callingAPI && actionKey !== 'approve')}
                                loading={callingAPI && actionKey === 'approve'}
                                onClick={() => handleApproval('approve')}
                            >
                                {texts('approve')}
                            </Button>
                        </Col>
                    </Row>
                }
            </Spin>
        </div>
    );
};

export default SavingsForceSettleDetail;
