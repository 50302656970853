import React from "react";
import {
  Row,
  Table,
  Button,
  Tag
} from 'antd';

import {
  EditOutlined
} from "@ant-design/icons";

import {
  formatCurrency,
  convertStringToQuery,
  appotaStatus
} from '../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    params
  } = props

  const query = convertStringToQuery(window.location.search);

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Tag color={appotaStatus(record.status)?.tag_color}>
          {appotaStatus(record.status)?.label}
        </Tag>
      ),
    },
    {
      title: 'Mã giao dịch',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 150,
    },
    {
      title: 'Mã khách hàng',
      dataIndex: 'customerId',
      key: 'customerId',
      align: 'left',
      width: 150,
      render: (_, record) => `KH_${record.customerId}`
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'customerName',
      key: 'customerName',
      align: 'left',
      width: 200,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'customerPhone',
      key: 'customerPhone',
      align: 'center',
      width: 120,
    },
    {
      title: 'Giá trị hoá đơn',
      dataIndex: 'amountOrder',
      key: 'amountOrder',
      align: 'right',
      width: 120,
      render: (_, record) => formatCurrency(record.amountOrder)
    },
    {
      title: 'Số tiền vay',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      width: 120,
      render: (_, record) => formatCurrency(record.amount)
    },
    {
      title: 'Loại hoá đơn',
      dataIndex: 'business',
      key: 'business',
      align: 'left',
      width: 200,
    },
    {
      title: 'Tổng số API',
      dataIndex: 'totalApi',
      key: 'totalApi',
      align: 'right',
      width: 100,
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (_, record) => (
        <Row gutter={[0, 8]}>
          <Button
            icon={<EditOutlined />}
            type='primary'
            size="small"
            ghost
            onClick={() => window.navigatePage(
              'APPOTA_DETAIL',
              { id: record.id },
            )}
          >Chi tiết</Button>
        </Row>
      ),
    },
  ]

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 + (params.page - 1) * params.pageSize }))}
      loading={loading}
      pagination={false}
      scroll={{ x: 1024 }}
      rowKey={(record) => record?.id}
      size="small"
    />
  );
}

export default TableData;