import React, { useState, useEffect, useMemo } from "react";
import './css/detail.scss';

import { PlusOutlined } from "@ant-design/icons";
import { useParams } from 'react-router-dom';

import { AdminDetailHeader, ApprovalSuccessModal } from "../../../components";
import TableDataDetail from "./components/detail/TableData";
import FooterDetail from "./components/detail/Footer";
import Transactions from "./components/detail/Transactions";
import ApprovalModal from "./components/modals/Approval";
import AddInfomationModal from "./components/modals/AddInfomation";

import contractServices from '../../../services/finblue/contract';
import menuServices from '../../../services/finblue/menu';

import { convertStringToQuery, contractStatus } from '../../../utils/common'


const LimitApprovalDetail = (props) => {
  const { router } = props
  const { id } = useParams();
  const query = convertStringToQuery(window.location.search);
  const [approvalVisible, setApprovalVisible] = useState(false);
  const [approvalSuccessVisible, setApprovalSuccessVisible] = useState(false)
  const [addInfomationVisible, setAddInfomationVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [contractInfo, setContractInfo] = useState({})
  const [contractDetails, setContractDetails] = useState([])
  const [dataTypes, setDataTypes] = useState([])

  const params = useMemo(() => {
    return {
      contractId: Number(id),
      customerId: Number(query.customerId)
    }
  }, [id, query]);

  const isApproval = useMemo(() => {
    return contractDetails.length > 0
      && !contractDetails.find((d) => [0, 2, 3].includes(d.verified))
      && ![1, 3].includes(contractInfo.status)
  }, [contractDetails, contractInfo]);

  const isResubmmit = useMemo(() => {
    return contractDetails.length > 0
      && !contractDetails.find((d) => [0, 3].includes(d.verified))
      && !!contractDetails.find((d) => [2].includes(d.verified))
      && ![1, 3].includes(contractInfo.status)
  }, [contractDetails, contractInfo]);

  const isShowFooter = useMemo(() => {
    return ![1, 3, null, undefined].includes(contractInfo.status)
  }, [contractInfo]);

  useEffect(() => {
    fetchData()
    fetchDataTypes()
  }, [id])

  const bonusContractDetails = useMemo(() => {
    return contractDetails.filter((d) => d.type === 1)
  }, [contractDetails]);

  const fetchData = async () => {
    setLoading(true);
    await contractServices.get(id).then((response) => {
      setContractDetails(response?.data?.contractDatas || []);
      setContractInfo(response?.data || {})
    }).catch(() => {
      setContractDetails([])
    })
    setLoading(false);
  }

  const fetchDataTypes = async () => {
    await menuServices.list_data_types({
      type: 1,
      active: true,
      page: 0,
      pageSize: 9999999
    }).then((response) => {
      setDataTypes(response?.data || []);
    }).catch(() => {
      setDataTypes([])
    })
  }

  const changeContractDetails = (newData) => {
    const newContractDetails = contractDetails.map((d) => d.id === newData.id ? newData : d)
    setContractDetails(newContractDetails)
  }

  const addContractDetail = (newData) => {
    const newContractDetails = contractDetails
    newContractDetails.push({
      ...newData,
      id: null,
      content: null,
      dataType: newData.id,
      verified: 0,
    })
    setContractDetails(newContractDetails)
  }

  return (
    <div className="limit-approval-detail">
      {
        contractInfo.status === 1 && <div className="transactions mb-m">
          <AdminDetailHeader
            title={'Danh sách hạn mức được duyệt'}
            status={contractStatus(contractInfo?.status)}
          />
          <Transactions
            contractInfo={contractInfo}
          />
        </div>
      }
      <div>
        <AdminDetailHeader
          title={router.label}
          status={contractInfo.status !== 1 ? contractStatus(contractInfo?.status) : ''}
        />
        <TableDataDetail
          type={0}
          loading={loading}
          isShowFooter={isShowFooter}
          data={contractDetails.filter((d) => !d.type)}
          change={changeContractDetails}
        />
      </div>
      <div className="bonus-information mt-m">
        <AdminDetailHeader
          title={'Yêu cầu bổ sung'}
          actions={[
            {
              key: 'add',
              label: 'Thêm mới',
              type: 'text',
              hide: dataTypes.length <= bonusContractDetails.length || !isShowFooter,
              icon: <PlusOutlined />,
              click: () => setAddInfomationVisible(true)
            }
          ]}
        />
        <TableDataDetail
          loading={loading}
          data={contractDetails.filter((d) => d.type)}
          isShowFooter={isShowFooter}
          change={changeContractDetails}
        />
      </div>
      {
        isShowFooter && <FooterDetail
          loading={loading}
          data={contractDetails}
          params={params}
          isApproval={isApproval}
          isResubmmit={isResubmmit}
          openApprovalModal={() => setApprovalVisible(true)}
        />
      }
      {
        approvalVisible && <ApprovalModal
          contractInfo={contractInfo}
          visible={approvalVisible}
          params={params}
          data={contractDetails}
          setApprovalSuccessVisible={setApprovalSuccessVisible}
          onClose={() => setApprovalVisible(false)}
        />
      }
      {
        addInfomationVisible && <AddInfomationModal
          data={contractDetails}
          visible={addInfomationVisible}
          dataTypes={dataTypes}
          add={addContractDetail}
          onClose={() => setAddInfomationVisible(false)}
        />
      }
      {
        approvalSuccessVisible && <ApprovalSuccessModal
          visible={approvalSuccessVisible}
          onNext={() => {
            fetchData();
            setApprovalSuccessVisible(false)
          }}
        />
      }
    </div>
  );
}

export default LimitApprovalDetail;