import React from "react";
import "../css/components/TableData.scss";
import { Row, Table, Button, Modal } from "antd";

import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import { formatMoneyCurrency } from "../../../../utils/common";
import moment from 'moment';
import {useTranslation} from "react-i18next";

const TableData = (props) => {
    const {
        data,
        loading,
        params,
        showDetail = () => { }
    } = props;

    const { t } = useTranslation();
    const texts = t;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: texts('no.'),
            dataIndex: "stt",
            key: "stt",
            align: "center",
        },
        {
            title: texts('customer'),
            dataIndex: "acc_username",
            key: "acc_username",
//            align: "center",
            render: (_, record) => { return record.acc_fullname || record?.acc_username }
        },

        {
            title: texts('product'),
            dataIndex: "product_name",
            key: "product_name",
//            align: "center",
        },
        {
            title: <div style={{textAlign: 'center', fontWeight: 600}}>{texts('amount')}</div>,
            dataIndex: "force_settle_amount",
            key: "force_settle_amount",
            align: "right",
            render: (_, record) => formatMoneyCurrency(record?.force_settle_amount)
        },
        {
            title: texts('request_date'),
            dataIndex: "created_date",
            key: "created_date",
//            align: "center",
            render: (_, record) => moment(record?.created_date).format('HH:mm  DD/MM/YYYY')
        },
        {
            title: texts('actions'),
            dataIndex: "actions",
            key: "actions",
            align: "center",
            render: (_, record) => (
                <Row gutter={[8, 8]} justify={'center'}>
                    <Button
                        ghost
                        type='primary'
                        size='small'
                        onClick={() => showDetail(record)}>
                        {texts('detail')}
                    </Button>
                </Row>
            ),
        },
    ];

    return (
        <Table
            bordered
            className="table-data"
            columns={columns}
            dataSource={data.map((d, index) => ({
                ...d,
                stt: index + 1 + (params.page - 1) * params.size,
            }))}
            loading={loading}
            pagination={false}
            rowKey={(record) => record?.id}
            size="small"
        />
    );
};

export default TableData;
