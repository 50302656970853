import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Input,
    Modal,
    Form,
    Select,
    message,
    Card,
    Tag
} from 'antd';

import {
} from "@ant-design/icons";
import moment from 'moment';
import CONSTANTS from "../../../../../config/constants";
import { PROJECT_RULES } from "../../../../../config/rules";
// import productServices from '../../../../../services/finblue/product';
import customerService from "../../../../../services/invest/customer";
import {displayInvestProfitCapitalDeal, formatMoneyCurrency, getObjectStatus} from "../../../../../utils/common";
import CONSTANTS_INVEST from "../../../../../config/constants_invest";
import {getAccountHref} from "../../../../../utils/common_invest";
import {useTranslation} from "react-i18next";

const ModalSavingsTransactionDetail = (props) => {
    const [customer, setCustomer] = useState({});
    const [form] = Form.useForm();
    const {
        transaction = null,
        visible = false,
        onClose = () => {},
        reload = () => {}
    } = props;

    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {
        fetchData();
        // if (product) {
        //     form.setFieldsValue({
        //         name: product.name,
        //         active: product.active
        //     })
        // } else {
        //     form.setFieldsValue({
        //         name: null,
        //         active: true
        //     })
        // }
    }, [transaction])

    const [callingAPI, setCallingAPI] = useState(false);

    const fetchData = async () => {
        await customerService.customer_detail({
                account_id: transaction?.accId
            })
            .then((response) => {
                setCustomer(response?.data || {});
            })
            .catch(() => {
                setCustomer({});
            });
    };

    const handleSubmit = () => {
        form.validateFields().then(async (valid) => {
            if (valid) {
                setCallingAPI(true);
                const values = form.getFieldsValue();
                if (transaction) {
                    await handleUpdate({
                        id: transaction.id,
                        ...values,
                    })
                } else {
                    await handleCreate(values)
                }
                setCallingAPI(false);
            }
        })
    }

    const handleCreate = async (data) => {
        await productServices.create(data).then(() => {
            message.success('Thêm gói sản phẩm thành công')
            reload();
            onClose();
        }).catch((error) => {
            message.error(error?.status?.message);
        })
    }

    const handleUpdate = async (data) => {
        await productServices.update(data).then(() => {
            message.success('Cập nhật gói sản phẩm thành công')
            reload();
            onClose();
        }).catch((error) => {
            message.error(error?.status?.message);
        })
    }

    const accountHref = `${window.location.origin}/customers/${customer?.rowId}`;

    return (
        <Modal
            title={"Chi tiết giao dịch"}
            width={700}
            open={visible}
            footer={null}
            // onOk={() => handleSubmit()}
            onCancel={() => onClose()}
            // okButtonProps={{
            //     loading: callingAPI
            // }}
            // cancelButtonProps={{
            //     disabled: callingAPI
            // }}
            // okText='Lưu'
            // okButtonProps={{ disabled: true, className: "modal-footer-hiden-button" }}
            // cancelText='Đóng'
        >
            <table className="table-transaction-detail">
                <tr>
                    <td className="transaction-detail-col-1">{texts('customer')}</td>
                    <td className="transaction-detail-col-2"><a href={getAccountHref(customer?.rowId)}>{customer?.accFullname}</a></td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('phone_number')}</td>
                    <td className="transaction-detail-col-2"><a href={accountHref}>{customer?.accPhone}</a></td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('request_type')}</td>
                    <td className="transaction-detail-col-2">
                        <Tag
                            size={'small'}
                            color={getObjectStatus(CONSTANTS_INVEST.BALANCE_CHANGE_REQUEST_TYPE, transaction.balanceChangeType)?.color}>
                            {texts(getObjectStatus(CONSTANTS_INVEST.BALANCE_CHANGE_REQUEST_TYPE, transaction?.balanceChangeType)?.label)}
                        </Tag>
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('request_detail')}</td>
                    <td className="transaction-detail-col-2">
                        <Tag
                            size={'small'}
                            color={getObjectStatus(CONSTANTS_INVEST.BALANCE_CHANGE_REQUEST_SUB_TYPE, transaction.balanceChangeSubType)?.color}>
                            {texts(getObjectStatus(CONSTANTS_INVEST.BALANCE_CHANGE_REQUEST_SUB_TYPE, transaction?.balanceChangeSubType)?.label)}
                        </Tag>
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('amount')}</td>
                    <td className="transaction-detail-col-2">{formatMoneyCurrency(transaction?.changeAmount)}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('request_date')}</td>
                    <td className="transaction-detail-col-2">{moment(transaction?.createdDate).format('DD/MM/YYYY')}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('approve_by')}</td>
                    <td className="transaction-detail-col-2">{transaction?.approveRejectBy}</td>
                </tr>

                <tr>
                    <td className="transaction-detail-col-1">{texts('bank_account')}</td>
                    <td className="transaction-detail-col-2">{transaction?.chargeToBankAccName || texts('no_data')}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('bank_name')}</td>
                    <td className="transaction-detail-col-2">{transaction?.chargeToBankCode || texts('no_data')}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('bank_no')}</td>
                    <td className="transaction-detail-col-2">{transaction?.chargeToBankNo || texts('no_data')}</td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('request_id')}</td>
                    <td className="transaction-detail-col-2">
                        <a>{transaction?.requestId}</a>
                    </td>
                </tr>
                <tr>
                    <td className="transaction-detail-col-1">{texts('request_status')}</td>
                    <td className="transaction-detail-col-2">
                        <Tag
                            size={'small'}
                            color={getObjectStatus(CONSTANTS_INVEST.BALANCE_CHANGE_REQUEST_STATUS, transaction.requestStatus)?.color}>
                            {texts(getObjectStatus(CONSTANTS_INVEST.BALANCE_CHANGE_REQUEST_STATUS, transaction?.requestStatus)?.label)}
                        </Tag>
                    </td>
                </tr>
            </table>
        </Modal>
    );
}

export default ModalSavingsTransactionDetail;