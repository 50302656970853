import React from "react";
import "../css/components/Filter.scss";
import {Row, Col, Input, Select, DatePicker} from "antd";
import CONSTANTS from "../../../../config/constants_invest";

import {SearchOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const Filter = (props) => {
    const {
        params = {}, setParams = () => {
        }
    } = props;

    const { t } = useTranslation();
    const texts = t;

    return (
        <Row className="filter" gutter={[8, 8]}>
            <Col span={6} lg={6} sm={12} xs={24}>
                <Input
                    prefix={<SearchOutlined/>}
                    value={params.keyword}
                    placeholder={texts('search')}
                    onChange={(e) => setParams({...params, keyword: e.target.value})}
                />
            </Col>

            <Col span={6} lg={6} sm={12} xs={24}>
                <Select
                    placeholder={texts("status")}
                    value={params.role_status}
                    onChange={(v) => setParams({...params, role_status: v})}
                    showSearch={true}
                    optionFilterProp="children"
                    className="w-full"
                    filterOption={(input, option) =>
                        `${option.children}`.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {CONSTANTS.SALES_STATUS.map((type) => (
                        <Select.Option value={type.value} key={type.name}>
                            {texts(type.label)}
                        </Select.Option>
                    ))}
                </Select>
            </Col>
        </Row>
    );
};

export default Filter;
