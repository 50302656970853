import React from "react";
import {
  Table,
} from 'antd';

import {
} from "@ant-design/icons";

import {
  formatCurrency,
  formatNumber
} from '../../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
      fixed: 'left'
    },
    {
      title: 'Tên sàn',
      dataIndex: 'marketName',
      key: 'marketName',
      align: 'left',
      width: 200,
      render: (_, record) => <a>
        {record.marketName}
      </a>,
      fixed: 'left'
    },
    {
      title: 'Số đơn',
      dataIndex: 'totalOrder',
      key: 'totalOrder',
      align: 'right',
      width: 100,
      render: (_, record) => formatNumber(record.totalOrder)
    },
    {
      title: 'Tiền phải trả',
      dataIndex: 'totalMoneyOrder',
      key: 'totalMoneyOrder',
      align: 'right',
      width: 200,
      render: (_, record) => formatCurrency(record.totalMoneyOrder)
    },
    {
      title: 'Tiền đã trả',
      dataIndex: 'totalMoneyPaid',
      key: 'totalMoneyPaid',
      align: 'right',
      width: 200,
      render: (_, record) => formatCurrency(record.totalMoneyPaid)
    },
    {
      title: 'Phí thanh toán',
      dataIndex: 'totalSurcharge',
      key: 'totalSurcharge',
      align: 'right',
      width: 200,
      render: (_, record) => formatCurrency(record.totalSurcharge)
    },
    {
      title: 'Phí nền tảng đã thu',
      dataIndex: 'totalSurchargePaid',
      key: 'totalSurchargePaid',
      align: 'right',
      width: 200,
      render: (_, record) => formatCurrency(record.totalSurchargePaid)
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 }))}
      loading={loading}
      pagination={false}
      rowKey={(record) => record?.stt}
      scroll={{ x: 1024 }}
      size="small"
    />
  );
}

export default TableData;