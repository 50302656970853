import React, { useState, useEffect, useMemo } from "react";
import {
  Row,
  Col,
  Select,
  Modal,
  Form,
  InputNumber,
  Spin,
  message,
  Input,
  Tabs,
  Table
} from 'antd';

import productServices from '../../../../../services/finblue/product';
import contractServices from '../../../../../services/finblue/contract';
import businessServices from '../../../../../services/finblue/business';


import {
  formatNumber
} from '../../../../../utils/common'

const ApprovalModal = (props) => {
  const {
    contractInfo = {},
    visible = false,
    data = [],
    params = {},
    onClose = () => { },
    setApprovalSuccessVisible = () => { }
  } = props
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [callingAPI, setCallingAPI] = useState(false);
  const [allBusiness, setAllBusiness] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    fetchBusiness();
    fetchProducts();
  }, [])

  const fetchBusiness = async () => {
    setLoading(true);
    await businessServices.list({}).then((response) => {
      setAllBusiness((response?.data || []).map((p) => ({
        ...p,
        selectedTransactions: []
      })));
    }).catch(() => {
      setAllBusiness([])
    })
    setLoading(false);
  }

  const fetchProducts = async () => {
    setLoading(true);
    await productServices.list({
      page: 0,
      pageSize: 9999999,
      customerId: params.customerId
    }).then((response) => {
      setAllProducts(response?.data || []);
    }).catch(() => {
      setAllProducts([])
    })
    setLoading(false);
  }

  const selectedTransactions = useMemo(() => {
    return allBusiness.map((p) => p.selectedTransactions).flat();
  }, [allBusiness])

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      setCallingAPI(true);
      const transactions = allBusiness.map((b) => (values[`${b.id}`] || []).map((tr) => ({ ...tr, businessId: b.id }))).flat();
      const payload = {
        comment: "",
        contractId: params.contractId,
        customerId: params.customerId,
        requestComment: data.map((d) => ({
          comment: d.comment || '',
          contractDataId: d.id,
          dataType: d.dataType,
          verified: d.verified
        })),
        requestList: transactions.filter((tr) => tr.selected).map((tr) => ({
          interest: tr.interestRate,
          moneyApproved: tr.amount,
          numMonth: tr.numMonth,
          productId: tr.productId,
          businessId: tr.businessId,
          appSurcharge: tr.appSurcharge
        }))
      }
      contractServices.verify(payload).then(() => {
        setCallingAPI(false);
        setApprovalSuccessVisible(true);
        onClose();
      }).catch((error) => {
        setCallingAPI(false);
        message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
      })
    })

  };

  return (
    <Modal
      title={"Phê duyệt hạn mức"}
      width={1000}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => onClose()}
      okText='Duyệt'
      cancelText='Hủy'
      cancelButtonProps={{
        disabled: callingAPI,
      }}
      okButtonProps={{
        disabled: selectedTransactions.length === 0 || !!allBusiness.find((p) => p.selectedTransactions.length > 3),
        loading: callingAPI
      }}
    >
      <Spin spinning={false}>
        <Form
          form={form}
          name="basic"
          layout='vertical'
          initialValues={{
            score: contractInfo.score,
            productId: null,
          }}
        >
          <p>Lịch sử chấm điểm: <b className="text-bold">{contractInfo.score || 0}</b> bởi <b className="text-bold">{contractInfo.scoreBy || 'Đơn vị'}</b></p>
          <Tabs
            items={allBusiness.map((b) => ({
              key: b.id,
              label: `${b.businessType} (${b.selectedTransactions.length})`,
              children: <BusinessData
                form={form}
                formName={`${b.id}`}
                allProducts={allProducts}
                allBusiness={allBusiness}
                setAllBusiness={setAllBusiness}
              />
            }))}
            onChange={() => { }}
          />
        </Form>
      </Spin>
    </Modal>
  );
}

const BusinessData = (props) => {
  const {
    form = Form.useForm()[0],
    formName = '',
    allProducts = [],
    allBusiness = [],
    setAllBusiness = () => { }
  } = props

  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [productId, setProductId] = useState('');

  const transactions = Form.useWatch(formName, form) || [];

  useEffect(() => {
    setProductId(allProducts[0]?.id)
  }, [allProducts])

  useEffect(() => {
    if (productId) {
      fetchAllTransactions()
    }
  }, [productId, keyword])

  const fetchAllTransactions = async () => {
    setLoading(true);
    await productServices.list_transactions({
      page: 0,
      pageSize: 5,
      active: true,
      productId: productId,
      keyword: keyword
    }).then((response) => {
      const transactions = (response?.data || []).sort((a, b) => a.name - b.name).map((d) => ({ ...d, selected: false, productId: productId }))
      form.setFieldValue(formName, transactions)
      setAllBusiness(allBusiness.map((b) => ({
        ...b,
        selectedTransactions: b.id == formName ? [] : b.selectedTransactions
      })))
    }).catch(() => {
      form.setFieldValue(formName, [])
    })
    setLoading(false);
  }

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      const transactionsData = transactions.map((tr, index) => ({ ...tr, selected: selectedRowKeys.includes(index) }))
      form.setFieldValue(formName, transactionsData)
      setAllBusiness(allBusiness.map((b) => ({
        ...b,
        selectedTransactions:
          b.id == formName ?
            transactionsData.filter((tr) => tr.selected).map((tr) => ({ ...tr, businessId: b.id })) :
            b.selectedTransactions
      })))
    }
  };

  return <Row
    justify={'left'}
    gutter={[8, 8]}
  >
    <Col span={12} lg={12} sm={24} xs={24}>
      <Form.Item
        label={'Tìm kiếm tên gói hạn mức'}
        className="mb-0"
      >
        <Input
          placeholder="Nhập tên gói hạn mức để tìm kiếm"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        >
        </Input>
      </Form.Item>
    </Col>
    <Col span={12} lg={12} sm={24} xs={24}>
      <Form.Item
        label={'Gói sản phẩm'}
        className="mb-0"
      >
        <Select
          className="w-full"
          optionFilterProp="children"
          value={productId}
          onChange={(v) => setProductId(v)}
          placeholder={'Vui lòng chọn gói sản phẩm'}
          filterOption={(input, option) =>
            `${option.children}`
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {allProducts.map((product) => (
            <Select.Option
              value={product.id}
              key={product.id}
            >
              {product.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
    <Col lg={24} sm={24} xs={24}>
      <p className="text-right">Chú ý: Không chọn vượt quá 3 hạn mức với mỗi loại</p>
      <Form.List name={formName}>
        {(fields, { }, { errors }) => (
          <Table
            loading={loading}
            bodered={false}
            size="small"
            className="table-data"
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: transactions.map((tr, index) => tr.selected ? index : null).filter((index) => index !== null),
              ...rowSelection,
            }}
            columns={[
              {
                title: 'Hạn mức',
                dataIndex: 'name',
                key: 'name',
                align: 'left',
                width: 150,
                render: (_, record, index) => transactions[index].name
              },
              {
                title: 'Phí nền tảng (%/năm)',
                dataIndex: 'appSurcharge',
                key: 'appSurcharge',
                align: 'left',
                width: 100,
                render: (_, record, index) => formatNumber(transactions[index].appSurcharge)
              },
              {
                title: 'Số tiền (VNĐ)',
                dataIndex: 'amount',
                key: 'amount',
                align: 'left',
                width: 120,
                render: (_, record, index) => <Form.Item
                  {...record}
                  name={[record.name, 'amount']}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: 'Số tiền không được để trống',
                    },
                  ]}
                >
                  <InputNumber
                    className="w-full"
                    disabled={!transactions[index].selected}
                    formatter={v => `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={v => v.replace(/\$\s?|(,*)/g, '')}
                    min={1}
                    addonAfter={'VNĐ'}
                  />
                </Form.Item>
              },
              {
                title: 'Lãi suất (%/năm)',
                dataIndex: 'interestRate',
                key: 'interestRate',
                align: 'left',
                width: 100,
                render: (_, record, index) => <Form.Item
                  {...record}
                  name={[record.name, 'interestRate']}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: 'Lãi suất không được để trống',
                    },
                  ]}
                >
                  <InputNumber
                    className="w-full"
                    disabled={!transactions[index].selected}
                    formatter={v => `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={v => v.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    max={100}
                    addonAfter={'%'}
                  />
                </Form.Item>
              },
              {
                title: 'Thời hạn',
                dataIndex: 'numMonth',
                key: 'numMonth',
                align: 'left',
                width: 100,
                render: (_, record, index) => <Form.Item
                  {...record}
                  name={[record.name, 'numMonth']}
                  noStyle
                  rules={[]}
                >
                  <Select
                    className="w-full"
                    disabled={!transactions[index].selected}
                    onChange={() => { }}
                    options={[3, 6, 12, 24, 36].map((o) => ({
                      value: o,
                      label: `${o} Tháng`
                    }))}
                  />
                </Form.Item>
              },
            ]}
            dataSource={fields.map((d, index) => ({ ...d, stt: index + 1 }))}
            pagination={false}
            rowKey={(record) => record?.key}
            scroll={{ x: 1024 }}
          />
        )}
      </Form.List>
    </Col>
  </Row>
}

export default ApprovalModal;