import React, {useEffect, useState} from "react";
import {
    Row,
    Col,
    Input,
    Modal,
    Form,
    Select,
    message
} from 'antd';

import {} from "@ant-design/icons";

import CONSTANTS from "../../../../../config/constants";
import {PROJECT_RULES} from "../../../../../config/rules";
import productServices from '../../../../../services/invest/savings'
import {useTranslation} from "react-i18next";

const ProductFormModal = (props) => {
    const [form] = Form.useForm();
    const {
        product = null,
        visible = false,
        onClose = () => {
        },
        reload = () => {
        }
    } = props;

    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {
        if (product) {
            form.setFieldsValue({
                itemName: product.itemName,
                activeProduct: product.activeProduct,
                mininumRequireAmount: product.mininumRequireAmount,
                subCycleInterest: product.subCycleInterest,
            })
        } else {
            form.setFieldsValue({
                name: null,
                active: true
            })
        }
    }, [product])

    const [callingAPI, setCallingAPI] = useState(false);

    const handleSubmit = () => {
        form.validateFields().then(async (valid) => {
            if (valid) {
                setCallingAPI(true);
                const values = form.getFieldsValue();
                if (product) {
                    await handleUpdate({
                        ...product,
                        ...values
                    })
                }
                // else {
                //     await handleCreate(values)
                // }
                setCallingAPI(false);
            }
        })
    }

    const handleCreate = async (data) => {
        await productServices.create(data).then(() => {
            message.success('Thêm gói sản phẩm thành công')
            reload();
            onClose();
        }).catch((error) => {
            message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
    }

    const handleUpdate = async (data) => {
        await productServices.savings_product_update(data).then(() => {
            message.success('Cập nhật gói sản phẩm thành công')
            reload();
            onClose();
        }).catch((error) => {
            message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
    }

    return (
        <Modal
            title={product ? texts('edit') : texts('create')}
            width={600}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => onClose()}
            okButtonProps={{
                loading: callingAPI
            }}
            cancelButtonProps={{
                disabled: callingAPI
            }}
            okText={texts('save')}
            cancelText={texts('cancel')}
        >
            <Form form={form} layout='vertical' style={{marginTop: 20}}>
                <Row justify={'left'} gutter={[24, 0]}>
                    <Col span={12} lg={12} sm={12} xs={12}>
                        <Form.Item
                            label={texts('product_name')}
                            name={'itemName'}
                            rules={PROJECT_RULES}>
                            <Input
                                placeholder={texts('enter_product_name')}
                                disabled={callingAPI}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} lg={12} sm={12} xs={12}>
                        <Form.Item
                            label={`${texts('interest')} (%)`}
                            name={'subCycleInterest'}
                            rules={PROJECT_RULES}>
                            <Input
                                placeholder={texts('enter_interest')}
                                disabled={callingAPI}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} lg={12} sm={12} xs={12}>
                        <Form.Item
                            label={texts('minimum_investment_amount')}
                            name={'mininumRequireAmount'}
                            rules={PROJECT_RULES}>
                            <Input
                                // placeholder="Nhập lãi suất"
                                disabled={callingAPI}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} lg={12} sm={12} xs={12}>
                        <Form.Item
                            name={'activeProduct'}
                            label={texts('status')}
                            rules={[]}
                        >
                            <Select
                                className="w-100"
                                disabled={callingAPI}
                            >
                                {CONSTANTS.SAVINGS_PRODUCT_ACTIVE_STATUS.map((status) => (
                                    <Select.Option
                                        value={status.value}
                                        key={status.value}
                                    >
                                        {texts(status.label)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>

    );
}

export default ProductFormModal;