import React, { useMemo, useState } from "react";
import {
  Row,
  Col,
  Button,
  message
} from 'antd';

import profileServices from '../../../../../services/finblue/profile'

const Footer = (props) => {
  const {
    params = {},
    loading = false,
  } = props

  const [callingAPI, setCallingAPI] = useState(false)
  const [actionKey, setActionKey] = useState('')

  const handleApproval = (key) => {
    setActionKey(key)
    setCallingAPI(true);
    const p = { status: 1 };
    let success = 'Phê duyệt thành công'
    if (key === 'unapproval') {
      p.status = 2
      success = 'Từ chối duyệt thành công';
    }
    profileServices.ekyc_approved(params.id, p).then(() => {
      message.success(success)
      setCallingAPI(false);
      window.navigatePage('BACK')
    }).catch((error) => {
      setCallingAPI(false);
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  return (
    <Row gutter={[8, 8]} className="detail-footer">
      <Col>
        <Button
          size="large"
          danger 
          type="primary"
          disabled={loading || (callingAPI && actionKey !== 'unapproval')}
          loading={callingAPI && actionKey === 'unapproval'}
          onClick={() => handleApproval('unapproval')}
        >
          Từ chối duyệt
        </Button>
      </Col>
      <Col>
        <Button
          size="large"
          type="primary"
          disabled={loading || (callingAPI && actionKey !== 'approval')}
          loading={callingAPI && actionKey === 'approval'}
          onClick={() => handleApproval('approval')}
        >
          Duyệt
        </Button>
      </Col>
    </Row>
  );
}

export default Footer;