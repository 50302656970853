import React, { useState, useEffect } from "react";
import { Pagination } from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import { AdminHeader } from "../../../components";

import Statistics from "./components/Statistics";
import Filter from "./components/Filter";
import TableData from "./components/TableData";
import profileServices from '../../../services/finblue/profile';

import { convertStringToQuery } from '../../../utils/common'
import dayjs from 'dayjs';

const EKYCApproval = (props) => {
  const { router } = props
  const query = convertStringToQuery(window.location.search);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState({
    page: Number(query?.page || 1),
    pageSize: 10,
    keyword: query?.keyword || null,
    status: query?.status ? Number(query?.status) : '',
    dates: [
      query?.begin ? dayjs(Number(query?.begin) * 1000) : dayjs().add(-7, 'days'),
      query?.end ? dayjs(Number(query?.end) * 1000) : dayjs()
    ]
  });

  useEffect(() => {
    fetchData();
    window.navigatePage('EKYC_APPROVAL', {}, {
      page: params.page,
      keyword: params.keyword,
      status: params.status,
      begin: dayjs(params.dates[0]).unix(),
      end: dayjs(params.dates[1]).unix(),
    })
  }, [params])

  const handleChangePage = (page, pageSize) => {
    setParams({
      ...params,
      page,
      pageSize
    })
  };

  const fetchData = async () => {
    setLoading(true);
    await profileServices.search_ekyc({
      begin: dayjs(dayjs(params.dates[0]).format('YYYY-MM-DD 00:00:00')).unix() * 1000,
      end: dayjs(dayjs(params.dates[1]).format('YYYY-MM-DD 23:59:59')).unix() * 1000,
      pageSize: params.pageSize,
      page: params.page - 1,
      keyword: params.keyword,
      status: params.status
    }).then((response) => {
      setData(response?.data || []);
      setTotal(response?.page?.total_elements)
    }).catch(() => {
      setData([])
      setTotal(0)
    })
    setLoading(false);
  }

  return (
    <div className="limit-approval">
      <AdminHeader
        title={router.label}
        total={total}
        actions={[
          {
            key: 'add',
            label: 'Thêm mới',
            type: 'primary',
            icon: <PlusOutlined />,
            click: () => {}
          }
        ]}
      />
      <Statistics
        params={params}
        setParams={(v) => setParams({
          ...v,
          page: 1,
        })}
      />
      <Filter
        params={params}
        setParams={(v) => setParams({
          ...v,
          page: 1,
        })}
      />
      <TableData
        loading={loading}
        data={data}
        params={params}
      />
      <Pagination
        className="pagination"
        current={params.page}
        pageSize={params.pageSize}
        total={total}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Tổng số ${total}`}
        onChange={handleChangePage}
      />
    </div>
  );
}

export default EKYCApproval;