import React, { useState, useEffect, useMemo } from "react";

import {
  PlusOutlined
} from "@ant-design/icons";
import { useParams } from 'react-router-dom';

import { AdminDetailHeader } from "../../../components";
import ProductTransactions from "../product-transactions";
import TransactionFormModal from "./components/modals/TransactionForm";

import productServices from '../../../services/finblue/product';

import { activeStatus } from '../../../utils/common';
import dayjs from 'dayjs';

const ProductDetail = (props) => {
  const { router } = props
  const { id } = useParams();
  const [isReload, setIsReload] = useState(null)
  const [detailTotal, setDetailTotal] = useState(0)
  const [productInfo, setProductInfo] = useState({})
  const [selectedTransaction, setSelectedTransaction] = useState({})
  const [transactionFormVisible, setTransactionFormVisible] = useState(false)

  useEffect(() => {
    fetchData()
  }, [id])


  const fetchData = async () => {
    await productServices.get(id).then((response) => {
      setProductInfo(response?.data || {})
    }).catch(() => {
      setProductInfo({})
    })
  }

  const openTransactionFormMoldal = (transaction) => {
    setSelectedTransaction(transaction);
    setTransactionFormVisible(true)
  }

  return (
    <div className="product-detail">
      <AdminDetailHeader
        title={`${router.label}: ${productInfo.name || ''}`}
        status={activeStatus(productInfo?.active)}
        showTotal={true}
        total={detailTotal}
        actions={[
          {
            key: 'add',
            label: 'Cầu hình',
            type: 'primary',
            icon: <PlusOutlined />,
            click: () => openTransactionFormMoldal(null)
          }
        ]}
      />
      <ProductTransactions
        showHeader={false}
        isReload={isReload}
        productId={id}
        setDetailTotal={setDetailTotal}
        detailEdit={openTransactionFormMoldal}
      />
      {
        transactionFormVisible && <TransactionFormModal
          productId={id}
          transaction={selectedTransaction}
          visible={transactionFormVisible}
          reload={() => setIsReload(dayjs().unix())}
          onClose={() => setTransactionFormVisible(false)}
        />
      }
    </div>
  );
}

export default ProductDetail;