import React, {useEffect, useState} from "react";
import {
    Row,
    Col,
    Input,
    Modal,
    Form,
    Select,
    message,
    Button,
    Popconfirm
} from 'antd';

import {} from "@ant-design/icons";

import CONSTANTS from "../../../../../config/constants_invest";
import {PROJECT_RULES, EMAIL_RULES} from "../../../../../config/rules";
import fmarketServices from '../../../../../services/invest/fmarket'
import {useTranslation} from "react-i18next";
import contentServices from "../../../../../services/invest/content";
import {UploadImageInvest} from "../../../../../components";

const ProductFormModal = (props) => {
    const [form] = Form.useForm();

    const {
        product = null,
        visible = false,
        onClose = () => {},
        reload = () => {}
    } = props;

    const [listAssetAllocation, setListAssetAllocation] = useState([]);
    const [logoImage, setLogoImage] = useState(product?.logoImage || '');


    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {
        form.setFieldsValue({
            name: product?.name || '',
            email: product?.email || '',
            phone: product?.phone || '',
        })
    }, [product]);

    const [callingAPI, setCallingAPI] = useState(false);


    const onSave = () => {
        form.validateFields().then(async (valid) => {
            if (valid) {
                setCallingAPI(true);
                const values = form.getFieldsValue();
                console.log('values', values);
                if(product){
                    contentServices.help_center_update({
                        ...values,
                        logoImage,
                        id: product?.id
                    }).then(() => {
                        reload();
                        message.success(texts('update_successful'));
                        setCallingAPI(false);
                        onClose();
                    });
                }
                else{
                    contentServices.help_center_create({
                        ...values,
                        logoImage,
                    }).then(() => {
                        reload();
                        message.success(texts('create_successful'));
                        setCallingAPI(false);
                        onClose();
                    });
                }
            }
        })
    };


//    const handleCreate = async (data) => {
//        await fmarketServices.create(data).then(() => {
//            message.success('Create successful!');
//            reload();
//            onClose();
//        }).catch((error) => {
//            message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
//        })
//    };
//
//    const handleUpdate = async (data) => {
//        await fmarketServices.funds_product_update(data).then(() => {
//            message.success('Update successful!');
//            reload();
//            onClose();
//        }).catch((error) => {
//            message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
//        })
//    };


    const onDelete = async() => {
        contentServices.help_center_delete(product?.id).then(() => {
            reload();
            message.success(texts('delete_successful'));
            setCallingAPI(false);
            onClose();
        });
    };

    const onUploadSuccess = (imgUrl) => {
        setLogoImage(imgUrl);
    };


    return (
        <Modal
            title={texts('help_center')}
            width={400}
            open={visible}
            onOk={() => {}}
            onCancel={() => onClose()}
            okButtonProps={{
                loading: callingAPI
            }}
            cancelButtonProps={{
                disabled: callingAPI
            }}
            okText={texts('save')}
            cancelText={texts('cancel')}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    {texts('cancel')}
                </Button>,
                product &&
                    <Popconfirm
                        placement="bottom"
                        title={'Xoá trung tâm hỗ trợ'}
                        description={`Xác nhận xoá thông tin trung tâm hỗ trợ "${product?.name}"`}
                        onConfirm={onDelete}
                        okText="Yes"
                        cancelText="No">
                        <Button key="delete" type="primary" danger loading={callingAPI}>
                            {texts('delete')}
                        </Button>
                    </Popconfirm>,
                <Button
                    key="submit"
                    type="primary"
                    loading={callingAPI}
                    onClick={onSave}>
                    {texts('save')}
                </Button>,
            ]}
            style={{top: 20}}
        >
            <Form form={form} layout='vertical' style={{marginTop: 20}}>
                <Row justify={'left'} gutter={[24, 0]}>
                    <Col span={24} lg={24} sm={24} xs={24}>
                        <Form.Item
                            label={texts('name')}
                            name={'name'}
                            rules={[{required: true, message: 'Vui nhập Tên'}]}
                        >
                            <Input
                                placeholder="Nhập tên"
                                disabled={callingAPI}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={24} sm={24} xs={24}>
                        <Form.Item
                            label={texts('phone')}
                            name={'phone'}
                            rules={[{required: true, message: 'Vui nhập Số điện thoại'}]}
                        >
                            <Input
                                placeholder="Nhập số điện thoại"
                                disabled={callingAPI}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={24} sm={24} xs={24}>
                        <Form.Item
                            label={texts('email')}
                            name={'email'}
                            rules={EMAIL_RULES}>
                            <Input
                                placeholder="Nhập Email"
                                disabled={callingAPI}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={24} sm={24} xs={24}>
                        <Form.Item
                            name={'logoImage'}
                            label={texts('image')}
                            rules={[]}
                        >
                            <UploadImageInvest
                                file_type={CONSTANTS.FILE_TYPE.OTHER_IMG}
                                onUploadSuccess={onUploadSuccess}
                                defaultImg={product?.logoImage}
                            />
                        </Form.Item>
                    </Col>


                </Row>
            </Form>
        </Modal>

    );
}

export default ProductFormModal;