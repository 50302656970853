import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/invest";

function fmarket_accounts(params) {
    return request({
        url: ENDPOINT.FMARKET_ACCOUNTS,
        method: "get",
        params,
    });
}

function fmarket_orders(params) {
    return request({
        url: ENDPOINT.FMARKET_ORDERS,
        method: "get",
        params,
    });
}

function fmarket_transactions(params) {
    return request({
        url: ENDPOINT.FMARKET_TRANSACTIONS,
        method: "get",
        params,
    });
}

function fmarket_funds(params) {
    return request({
        url: ENDPOINT.MUTUAL_FUNDS_DATA,
        method: "get",
        params,
    });
}


function funds_product_update(data, params = {}) {
    return request({
        url: ENDPOINT.UPDATE_MUTUAL_FUNDS,
        method: "put",
        params,
        data
    });
}


function fmarket_trading_contract(accountId) {
    return request({
        url: ENDPOINT.FMARKET_TRADING_CONTRACT,
        method: "get",
        params: {accountId},
    });
}


function fmarket_trading_contract_sign(accountId){
    return request({
        url: ENDPOINT.FMARKET_TRADING_CONTRACT_SIGN,
        method: "get",
        params: {accountId},
    });
}

export default {
    fmarket_accounts,
    fmarket_orders,
    fmarket_transactions,
    fmarket_funds,
    funds_product_update,
    fmarket_trading_contract,
    fmarket_trading_contract_sign
};
