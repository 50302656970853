import React from 'react';
import { Routes, Route } from 'react-router-dom';
import pages from '../pages';

const PageContent = (props) => {
  return (
    <Routes>
      {
        props.routers.map((r) => {
          const Element = pages[r.element] || (() => <></>);
          return (<Route path={r.path} key={r.name} element={<Element router={r} />} />)
        })
      }
    </Routes>
  );
};

export default PageContent;
