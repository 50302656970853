import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Tabs, Col, Row, Image, Badge, Button, Space, Spin} from "antd";
import {PlusOutlined, EditOutlined} from "@ant-design/icons";
import {AdminDetailHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import userServices from "../../../services/admin/user";
import customerServices from "../../../services/invest/customer";
import { useParams } from 'react-router-dom';
import AvatarDefault from './../../../assets/images/Avatar-default.png';
import moment from 'moment';
import CONSTANTS from '../../../config/constants_invest'
import {getObjectStatus} from "../../../utils/common_invest";
import {openNewPageByRoute} from "../../../utils/common";
import ModalAddSalesmanToCustomer from './ModalAddSalesmanToCustomer';
import salesService from "../../../services/invest/sales";
// import customerServices from "../../../services/invest/customer";
import { useTranslation } from 'react-i18next';

const CustomerInfoTab = (props) => {
    const {customer, salesman = null} = props;
    const [salesStatus, setSalesStatus] = useState(getObjectStatus(CONSTANTS.SALES_STATUS, salesman?.roleStatus));
    const [savingContract, setSavingContract] = useState();
    const [customerSalesman, setCustomerSalesman] = useState();
    const [showModalSalesman, setShowModalSalesman] = useState(false);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();
    const texts = t;

    // console.log('salesStatus', salesStatus);
    // console.log('customer', customer);

    useEffect(() => {
        setSalesStatus(getObjectStatus(CONSTANTS.SALES_STATUS, salesman?.roleStatus));
    }, [salesman?.accountId]);

    useEffect(() => {
        getCustomerSavingContract();
        getCustomerSalesman();
    }, [customer?.rowId]);


    const getCustomerSavingContract = async() => {
        if(customer?.rowId){
            await customerServices.get_saving_contract(customer?.rowId).then((response) => {
                setSavingContract(response.data);
            }).catch(() => {

            });
        }
    };

    const getCustomerSalesman = async(account_id) => {
        // accountReferenceId
        if(!account_id){
            account_id = customer?.accountReferenceId;
        }
        console.log('getCustomerSalesman account_id', account_id);
        if(account_id){
            await customerServices.customer_detail({account_id})
                .then((response) => {
                    setCustomerSalesman(response?.data || {});
                    // setTotal(response?.page?.total_elements);
                })
                .catch(() => {
                    // setData([]);
                });
        }

    };

    const fetchData = async () => {
        // setLoading(true);
        // let account_id = id;
        // await customerServices.customer_detail({account_id})
        //     .then((response) => {
        //         setCustomer(response?.data || {});
        //         // setTotal(response?.page?.total_elements);
        //     })
        //     .catch(() => {
        //         // setData([]);
        //     });
        // setLoading(false);
    };

    const renderMaritalStatus = (marital_status) => {
        return texts(CONSTANTS.MARITAL_STATUS.find(e => e.value === marital_status)?.name || 'not_update');
    };

    const navToCustomerDetail = (_customer) => {
        // let link = openNewPageByRoute('CUSTOMER_DETAIL', { id: _customer.rowId }, {});
        // console.log('link', link);
        // window.navigatePage('CUSTOMER_DETAIL', { id: _customer.rowId }, {})
        openNewPageByRoute('SALESMAN_DETAIL', { id: _customer.rowId }, {});
    };

    const onSelectSalesman = async(_salesman) => {
        console.log('_salesman', _salesman);
        console.log('customer', customer);

        await salesService.assign_customer_to_salse(customer?.rowId, _salesman?.accountId)
            .then((response) => {
                console.log('response', response);
                getCustomerSalesman(_salesman?.accountId);
            })
            .catch((err) => {
                console.log('error', err);

            });

        setShowModalSalesman(false);
    };

    return (
        <Spin spinning={loading}>
            <Row gutter={[30, 30]}>
                <Col align-content='center'>
                    {salesStatus?.label ?
                        <Badge.Ribbon text={salesStatus?.label} color={salesStatus?.color}>
                            <img className='profile-avatar' src={AvatarDefault}/>
                        </Badge.Ribbon>
                        :
                        <img className='profile-avatar' src={AvatarDefault}/>
                    }
                </Col>

                <Col>
                    <div className='profile-name'>
                        {customer?.accFullname}
                    </div>
                    <table>
                        <tbody>
                            {/*{salesman ?*/}
                                {/*<tr>*/}
                                    {/*<td className='content-label'>{texts('code')}:</td>*/}
                                    {/*<td className='content-value'>{salesman?.rowId}</td>*/}
                                {/*</tr>*/}
                                {/*: null}*/}
                            <tr>
                                <td className='content-label'>{texts('customer_id')}:</td>
                                <td className='content-value'>{customer?.rowId}</td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('phone_number')}:</td>
                                <td className='content-value'>{customer?.accPhone}</td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('address')}</td>
                                <td className='content-value'>{customer?.accAddress || texts('not_update')}</td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('email')}:</td>
                                <td className='content-value'>{customer?.accEmail || texts('not_update')}</td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('active_phone_status')}:</td>
                                <td className='content-value'>{customer?.accActivePhone ? texts('verified') : texts('not_verified')}</td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('marital_status')}:</td>
                                <td className='content-value'>{renderMaritalStatus(customer?.maritalStatus)}</td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('nationality')}:</td>
                                <td className='content-value'>{customer?.nationalityId}</td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('register_date')}:</td>
                                <td className='content-value'>{moment(customer?.createdDate).format('DD/MM/YYYY')}</td>
                            </tr>
                            <tr>
                                <td className='content-label'>{texts('last_update')}:</td>
                                <td className='content-value'>{moment(customer?.updateAt).format('DD/MM/YYYY')}</td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('id_front_photo')}:</td>
                                <td className='content-value'>
                                    {customer?.imageFrontCardId ?
                                        <Image
                                            width={300}
                                            // height={200}
                                            src={customer?.imageFrontCardId}
                                        />
                                        :
                                        texts('not_update')
                                    }

                                </td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('id_back_photo')}:</td>
                                <td className='content-value'>
                                    {customer?.imageBackCardId ?
                                        <Image
                                            width={300}
                                            // height={200}
                                            src={customer?.imageBackCardId}
                                        />
                                        :
                                        texts('not_update')
                                    }
                                </td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('selfie_photo')}:</td>
                                <td className='content-value'>
                                    {customer?.imageSelfie ?
                                        <Image
                                            width={300}
                                            // height={200}
                                            src={customer?.imageSelfie}
                                        />
                                        :
                                        texts('not_update')
                                    }
                                </td>
                            </tr>

                            <tr>
                                <td className='content-label'>{texts('saving_contract')}:</td>
                                <td className='content-value'>
                                    {savingContract ?
                                        <a href={savingContract?.contractUrl}>{texts('view_contract')}</a>
                                        :
                                        texts('no_contract')
                                    }
                                </td>
                            </tr>
                            {savingContract ?
                                <tr>
                                    <td className='content-label'>{texts('signature')}:</td>
                                    <td className='content-value'>
                                        {savingContract?.signBase64 ?
                                            <Image
                                                width={120}
                                                // height={200}
                                                src={savingContract?.signBase64}
                                            />
                                            :
                                            texts('no_contract')
                                        }
                                    </td>
                                </tr>
                                : null}

                            {/*<tr>*/}
                                {/*<td className='content-label'>{texts('salesman')}:</td>*/}
                                {/*<td className='content-value'>*/}
                                    {/*{customerSalesman ?*/}
                                        {/*<Space>*/}
                                            {/*<a onClick={() => navToCustomerDetail(customerSalesman)}>{customerSalesman?.accFullname}</a>*/}
                                            {/*<Button*/}
                                                {/*icon={<EditOutlined />}*/}
                                                {/*type="primary"*/}
                                                {/*size="small"*/}
                                                {/*ghost*/}
                                                {/*onClick={() => setShowModalSalesman(true)}*/}
                                            {/*>*/}
                                                {/*{texts('change_salesman')}*/}
                                            {/*</Button>*/}
                                        {/*</Space>*/}
                                        {/*:*/}
                                        {/*<Button*/}
                                            {/*icon={<PlusOutlined />}*/}
                                            {/*type="primary"*/}
                                            {/*size="small"*/}
                                            {/*ghost*/}
                                            {/*onClick={() => setShowModalSalesman(true)}*/}
                                        {/*>*/}
                                            {/*{texts('add_salesman')}*/}
                                        {/*</Button>*/}
                                    {/*}*/}
                                {/*</td>*/}
                            {/*</tr>*/}

                        </tbody>
                    </table>
                </Col>
            </Row>

            <ModalAddSalesmanToCustomer
                onSelect={onSelectSalesman}
                onClose={() => setShowModalSalesman(false)}
                visible={showModalSalesman}
            />
        </Spin>
    );
};

export default CustomerInfoTab;
