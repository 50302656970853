import React, { useEffect, useState, useMemo } from "react";
import {
  Modal,
  message,
  Checkbox
} from 'antd';

import {
} from "@ant-design/icons";

import {
} from "../../../../../config/rules";

import assessorServices from '../../../../../services/finblue/assessor';

const allOptions = [
  {
    label: 'Hợp đồng hạn mức',
    value: 1,
  },
  {
    label: 'Đơn vay',
    value: 2,
  }
]

const DivideEquallyModal = (props) => {
  const [callingAPI, setCallingAPI] = useState(false);
  const [options, setOptions] = useState(allOptions.map((o) => o.value));

  const {
    visible = false,
    onClose = () => { },
    reload = () => { }
  } = props

  const handleConfirm = async () => {
    setCallingAPI(true)
    await assessorServices.divide_equally({}, {
      type: allOptions.length === options.length ? null : options[0]
    }).then(() => {
      message.success('Giao việc nhanh thành công')
      reload();
      onClose();
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
    setCallingAPI(false)
  }

  return (
    <Modal
      title={'Giao việc nhanh'}
      width={400}
      open={visible}
      onOk={() => handleConfirm()}
      onCancel={() => onClose()}
      okButtonProps={{
        disabled: options.length === 0,
        loading: callingAPI
      }}
      cancelButtonProps={{
        disabled: callingAPI
      }}
      okText='Xác nhận'
      cancelText='Hủy'
    >
      <p className="text-success mb-m">
        Chia đều đơn vay, hợp đồng hạn mức cần phê duyệt cho thẩm định viên
      </p>
      <Checkbox.Group
        options={allOptions}
        value={options}
        onChange={(v) => setOptions(v)}
      />
    </Modal>

  );
}

export default DivideEquallyModal;