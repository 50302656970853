import React from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Form,
  Select
} from 'antd';

import {
} from "@ant-design/icons";

const AddInfomation = (props) => {
  const [form] = Form.useForm();
  const {
    visible = false,
    dataTypes = [],
    onClose = () => {},
    add = () => {}
  } = props

  const handleSubmit = () => {
    form.validateFields().then((valid) => {
      if (valid) {
        const values = form.getFieldsValue();
        const dataType = dataTypes.find((t) => t.id === values.dataType);
        add({
          ...dataType,
          comment: values.comment
        });
        onClose();
      }
    })
  }
  return (
    <Modal
      title="Thêm thông tin hợp đồng hạn mức"
      width={500}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => onClose()}
      okText='Xác nhận'
      cancelText='Hủy'
    >
      <Form
        form={form}
        name="basic"
        layout='vertical'
      >
        <Row
          justify={'left'}
          gutter={[8, 8]}
        >
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              name={'dataType'}
              label={'Trường thông tin'}
              rules={[
                {
                  required: true,
                  message: 'Trường thông tin không được để trống!'
                }
              ]}
            >
              <Select
                placeholder={'Vui lòng chọn trường thông tin'}
                className="w-100"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  `${option.children}`
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {dataTypes.map((dataType) => (
                  <Select.Option
                    value={dataType.id}
                    key={dataType.id}
                  >
                    {dataType.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24} xs={24}>
            <Form.Item
              label={'Bình luận'}
              name={'comment'}
            >
              <Input.TextArea
                placeholder="Vui lòng nhập bình luận"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
    
  );
}

export default AddInfomation;