import React from "react";
import './css/index.scss';
import { Button, Tag } from 'antd';
import { red } from '@ant-design/colors';

const AdminDetailHeader = (props) => {
  const {
    title = '',
    total = 0,
    actions = [],
    showTotal = false,
    showScore = false,
    score = 0,
    scoreBy = '',
    status = null,
    className,
    subtitle = null
  } = props
  return (
    <div className={`admin-header ${className}`}>
      <div className="admin-header__left">
        <h1 className="admin-header__left--title">{title}</h1>
        {
          subtitle && <p>{subtitle}</p>
        }
        {
          showTotal && <p className="admin-header__left--subtitle">
            Tổng số: {total}
          </p>
        }
      </div>
      <div className="admin-header__right flex align-items-center">
        {
          showScore && <p>Lịch sử chấm điểm: <span className="text-bold" style={{
            color: red.primary
          }}>{score}</span> ({scoreBy})</p>
        }
        {
          status && <Tag className="ml-m mr-0" color={status?.tag_color}>
            {status?.label}
          </Tag>
        }
        {
          actions.filter((a) => !a.hide).filter((a) => !a.hide).map((a) =>
            <Button
              className="ml-xs"
              key={a.key}
              type={a.type}
              loading={a.loading}
              disabled={a.disabled}
              danger={a.danger}
              onClick={() => a.click()}
              icon={a.icon}
            >
              {a.label}
            </Button>
          )}
      </div>
    </div>
  );
};

export default AdminDetailHeader;
