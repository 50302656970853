import React, {useState, useEffect, useMemo} from "react";
import './css/index.scss';
import {Row, Col, Table, Button, Modal, Tag, DatePicker, Spin, Space} from "antd";
import IMG from './../../../assets/images/invest/image';
import CONSTANTS from "../../../config/constants";
import INVEST_CONSTANTS from "../../../config/constants_invest";
import {convertStringToQuery, formatMoneyCurrency, isPermission} from "../../../utils/common";
import dayjs from 'dayjs';
import {Bar} from "react-chartjs-2";
import {Chart as ChartJS, registerables} from "chart.js";
import dashboardServices from "../../../services/invest/dashboard";
import { useTranslation } from 'react-i18next';

import { PERMISSIONS } from "../../../config/permissions";
import customerServices from "../../../services/invest/customer";

ChartJS.register(...registerables);
// ChartJS.defaults.datasets.bar.barThickness = 16;
ChartJS.defaults.datasets.bar.maxBarThickness = 20;

const query = convertStringToQuery(window.location.search);

const CHART_SHOW_TYPE = {
    CUSTOMER: {value: 0, title: 'customer', textChart: 'quantity'},
    SAVINGS: {value: 1, title: 'saving_transactions', textChart: 'amount'},
    CUSTOMER_FUNDS: {value: 2, title: 'funds_customer', textChart: 'quantity'},
    FUNDS: {value: 3, title: 'funds_transactions', textChart: 'amount'},
};

const optionsChartDefault = {
    plugins: {
        legend: {
            position: 'bottom',
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            title: {
                display: false,
                text: 'Date'
            }
        },
        y: {
            beginAtZero: true,
            min: 0,
            // max: 300,

            grid: {
                display: true
            },
            title: {
                display: false,
                text: 'customer'
            }
        }
    },
    // maintainAspectRatio: false
};



const FundTransactionChart = () => {
    const [optionsChart, setOptionsChart] = useState(optionsChartDefault);
    const [cutOffTransactionFunds, setCutOffTransactionFunds] = useState([]);
    const [loading, setLoading] = useState(false);

    const [dataChart, setDataChart] = useState({labels: [], datasets: []});

    const [params, setParams] = useState({
        dates: [
            query?.from_date ? dayjs(Number(query?.begin) * 1000) : dayjs().startOf('month'),
            query?.to_date ? dayjs(Number(query?.end) * 1000) : dayjs().endOf('month'),
        ]
    });

    const { t } = useTranslation();
    const texts = t;

    const permission = useMemo(() => {
        return {
            sumCustomer: isPermission([PERMISSIONS.SUM_CUSTOMER]),
            sumCustomerFunds: isPermission([PERMISSIONS.SUM_CUSTOMER_FUNDS]),
            sumAmountSavings: isPermission([PERMISSIONS.TOTAL_AMOUNT_SAVINGS]),
            sumAmountFunds: isPermission([PERMISSIONS.GET_SUM_AMOUNT_FUND]),
            cutOffCustomer: isPermission([PERMISSIONS.GET_CUT_OFF_CUSTOMER])
        }
    }, []);

    useEffect(() => {
        getChartTransactionFunds();
    }, [params, permission]);


    const genDataChart = (dataSources, labelFiled = 'date', dataField = 'quantity') => {
        let labels = [];
        let data = [];
        dataSources.forEach((e) => {
            labels.push(dayjs(e?.[labelFiled]).format('DD-MM'));
            data.push(e?.[dataField]);
        });
        return {labels, data};
    };


    const getCutOffTransactionFunds = async (_params) => {
        const params_send = {
            // date: _params?.date,
            ..._params,
            type_date: INVEST_CONSTANTS.DATE_TYPE.BY_DATE,
            type: INVEST_CONSTANTS.FUNDS_TYPE.TRANSACTION.value
        };
        await dashboardServices.dash_cut_off_transaction_funds(params_send).then((response) => {
            let cutOff = response?.data || [];
            setCutOffTransactionFunds(cutOff);
            // let {labels, data} = genDataChart(cutOff, 'date', 'quantityBuy');
            let dataChartBuy = genDataChart(cutOff, 'date', 'amountBuy');
            let dataChartSell = genDataChart(cutOff, 'date', 'amountSell');
            let dataChartTransfer = genDataChart(cutOff, 'date', 'amountTransfer');
            let dataChart = {
                labels: dataChartBuy.labels,
                datasets: [
                    {
                        label: "Mua",
                        data: dataChartBuy.data,
                        backgroundColor: '#2ecc71',
                    },
                    {
                        label: "Bán",
                        data: dataChartSell.data,
                        backgroundColor: '#e74c3c',
                    },
                    {
                        label: "Chuyển đổi",
                        data: dataChartTransfer.data,
                        backgroundColor: '#3498db',
                    },
                ]
            };
            setDataChart(dataChart);
            setLoading(false);
        }).catch(() => {
            setCutOffTransactionFunds([])
        });
    };


    const getChartTransactionFunds = () => {
        let new_params = {
            from_date: dayjs(dayjs(params.dates[0])).unix()*1000,
            to_date: dayjs(dayjs(params.dates[1])).unix()*1000,
        };
        // let date = genDate(new_params);
        // new_params = {...new_params, date};
        getCutOffTransactionFunds(new_params);
    };


    const navToFundTransaction = () => {
        window.navigatePage('FMARKET_TRANSACTIONS', {}, {});
    }

    return (
        permission.cutOffCustomer &&
        <Spin spinning={loading}>
            <Row gutter={[16, 16]} className="dashboard-chart-section">
                <Col span={24} lg={24} sm={24} xs={24}>
                    <div className="dashboard-chart-wrap">
                        <div className="dashboard-chart-header">
                            <Space style={{flex: 1}} size={24}>
                                <div className="dashboard-chart-title">Số tiền giao dịch Chứng chỉ quỹ</div>
                                <a onClick={navToFundTransaction}>Xem chi tiết</a>
                            </Space>

                            <DatePicker.RangePicker
                                className="filter-date-picker"
                                format={CONSTANTS.DATE_FORMAT}
                                value={params?.dates || []}
                                onChange={(v) => setParams({...params, dates: v})}
                            />
                        </div>
                        <div className='bar-chart'>
                            <Bar
                                options={optionsChart}
                                data={dataChart}
                                height={120}
                                // width={800}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Spin>
    );
}

export default FundTransactionChart;