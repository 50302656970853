import React, { } from "react";
import {
  Table,
  Tag
} from 'antd';

import {
  formatCurrency,
  convertDateTime,
} from '../../utils/common'

const ReceiptTable = (props) => {
  const {
    loading = false,
    bordered = false,
    data = [],
    className
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 100
    },
    {
      title: 'Mã giao dịch chuyển tiền',
      dataIndex: 'expensesCode',
      key: 'expensesCode',
      align: 'center',
      width: 200
    },
    {
      title: 'Tiền thanh toán',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      width: 200,
      render: (_, record) => (
        formatCurrency(record.amount)
      ),
    },
    {
      title: 'Tiền gốc',
      dataIndex: 'money',
      key: 'money',
      align: 'right',
      width: 200,
      render: (_, record) => (
        formatCurrency(record.money)
      ),
    },
    {
      title: 'Tiền lãi',
      dataIndex: 'interest',
      key: 'interest',
      align: 'right',
      width: 200,
      render: (_, record) => (
        formatCurrency(record.interest)
      ),
    },
    {
      title: 'Thời gian tất toán',
      dataIndex: 'day',
      key: 'day',
      align: 'right',
      width: 200,
      render: (_, record) => (
        convertDateTime(record.day)
      ),
    },
    {
      title: 'Nội dung',
      dataIndex: 'reason',
      key: 'reason',
      align: 'left',
      width: 200,
    }
  ]

  return (
    <Table
      loading={loading}
      bordered={bordered}
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 }))}
      pagination={false}
      rowKey={(record) => record?.id}
      size="small"
      scroll={{ x: 800 }}
      className={className}
    />
  );
}

export default ReceiptTable;