import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import savingsService from "../../../services/invest/savings";
import {convertStringToQuery} from "../../../utils/common";
import ModalSettleInfo from "./css/components/modals/ModalSettleInfo";
const query = convertStringToQuery(window.location.search);

const SavingsSettleNormal = (props) => {
    const {router} = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [formVisible, setFormVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [params, setParams] = useState({
        page: 1,
        size: 20,
//        isRequested: 1,
//        isAutoFirmBanking: 0,
//        accountId: 1000008,
//        itemId: 87
    });

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);
//        console.log('params', params);
//        console.log('dates[0]', params?.dates?.[0]);
        await savingsService
            .settle_firm_banking_status({
                ...params,
                page: params.page - 1
            })
            .then((response) => {
//                console.log('response?.data', response?.data);
                setData(response?.data || []);
            })
            .catch((err) => {
                console.log('error', err);
                setData([]);
            });
        setLoading(false);
    };

    const handleShowDetail = (item = null) => {
        console.log('item', item);
//        window.navigatePage(
//            'SAVINGS_FORCE_SETTLE_DETAIL',
//            {id: item.row_id},
//            {...query}
//        )
         setSelectedItem(item);
         setFormVisible(true)
    };


    return (
        <div className="cms-users">
            {/*<AdminHeader*/}
            {/*title={router.label}*/}
            {/*total={total}*/}
            {/*/>*/}


            <Filter params={params} setParams={setParams}/>

            <TableData
                loading={loading}
                data={data}
                className="table-data"
                params={params}
                showDetail={(item) => handleShowDetail(item)}
            />

            <Pagination
                className="pagination"
                current={params?.page || 1}
                pageSize={params?.size || 10}
                total={total}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `Tổng số ${total}`}
                onChange={handleChangePage}
            />

            {
                formVisible &&
                <ModalSettleInfo
                    visible={formVisible}
                    item={selectedItem}
                    onClose={() => setFormVisible(false)}
                    reload={() => fetchData()}
                />
            }
        </div>
    );
};

export default SavingsSettleNormal;
