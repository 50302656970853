import React from "react";
import {
  Row,
  Col,
  Select,
  DatePicker
} from 'antd';

import {
} from "@ant-design/icons";

import CONSTANTS from '../../../../../../config/constants'

const Filter = (props) => {
  const {
    params = {},
    setParams = () => { }
  } = props
  return (
    <Row
      className="filter"
      justify={'space-between'}
      gutter={[0, 8]}
    >
      <Col>
        <Row
          justify={'left'}
          gutter={[8, 8]}
        >
          <Col>
            <Select
              placeholder={'Sản TMĐT'}
              className="filter-filed"
              value={params.marketId}
              onChange={(v) => setParams({
                ...params,
              })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                `${option.children}`
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              <Select.Option
                value={''}
                key={'all'}
              >
                Tất cả
              </Select.Option>
              {[].map((status, index) => (
                <Select.Option
                  value={`${status.value} ${status.day_num || 0}`}
                  key={index}
                >
                  {status.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              placeholder={'Công ty tài chính'}
              className="filter-filed"
              value={params.lenderId}
              onChange={(v) => setParams({
                ...params
              })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                `${option.children}`
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              <Select.Option
                value={''}
                key={'all'}
              >
                Tất cả
              </Select.Option>
              {[].map((status, index) => (
                <Select.Option
                  value={`${status.value} ${status.day_num || 0}`}
                  key={index}
                >
                  {status.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Col>
      <Col align="right">
        <Row
          justify={'end'}
          gutter={[8, 8]}
        >
          <Col span={24} lg={24} sm={24} xs={24}>
            <DatePicker.RangePicker
              className="filter-filed"
              format={CONSTANTS.DATE_FORMAT}
              value={params?.dates || []}
              onChange={(v) => setParams({ ...params, dates: v })}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Filter;