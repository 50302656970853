import React, { useState, useEffect } from "react";
import { Pagination, Modal, message } from "antd";
import { PlusOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { AdminHeader } from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import userServices from "../../../services/admin/user";

import { PERMISSIONS } from "../../../config/permissions";
import { isPermission } from "../../../utils/common";

const CmsUsers = (props) => {
  const { router } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    size: 10,
  });

  useEffect(() => {
    fetchData();
  }, [params]);

  const handleChangePage = (page, size) => {
    setParams({
      ...params,
      page,
      size,
    });
  };

  const fetchData = async () => {
    setLoading(true);
    await userServices
      .search_users({
        ...params,
        page: params.page - 1,
        pageSize: params.size,
      })
      .then((response) => {
        setData(response?.data || []);
        setTotal(response?.page?.total_elements);
      })
      .catch(() => {
        setData([]);
      });
    setLoading(false);
  };

  const handleDelete = (user) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xoá tài khoản?',
      onOk() {
        userServices.remove_user(user.customerId).then(() => {
          message.success('Xoá tài khoản thành công')
          fetchData();
        }).catch((error) => {
          message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
      },
      onCancel() { },
    });
  }

  return (
    <div className="cms-users">
      <AdminHeader
        title={router.label}
        total={total}
        actions={[
          {
            key: "add",
            label: "Thêm mới",
            type: "primary",
            icon: <PlusOutlined />,
            hide: !isPermission([
              PERMISSIONS.CREATE_USER_ROLE,
              PERMISSIONS.VIEW_LIST_ROLE
            ]),
            click: () => window.navigatePage('CMS_USER_CREATE'),
          },
        ]}
      />
      <Filter
        params={params}
        setParams={(v) => setParams({
          ...v,
          page: 1,
        })}
      />
      <TableData
        loading={loading}
        data={data}
        params={params}
        onDelete={handleDelete}
      />
      <Pagination
        className="pagination"
        current={params.page}
        pageSize={params.size}
        total={total}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Tổng số ${total}`}
        onChange={handleChangePage}
      />
    </div>
  );
};

export default CmsUsers;
