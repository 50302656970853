import React, { useState, useEffect } from "react";
import { Modal, Card, message } from 'antd';
import { ExclamationCircleFilled } from "@ant-design/icons";

import TableData from "./bank-accounts/TableData";
import BankAccountFormModal from "../../../banks/components/modals/BankAccountForm"
import financeServices from '../../../../../services/finblue/finance';

const BankAccounts = (props) => {
  const { params } = props
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);

  useEffect(() => {
    fetchData();
  }, [params])

  const fetchData = async () => {
    setLoading(true);
    await financeServices.search_customer_finances({
      customerId: params.id,
    }).then((response) => {
      setData(response?.data || []);
    }).catch(() => {
      setData([])
    })
    setLoading(false);
  }

  const handleOpenFormModal = (market = null) => {
    setSelectedBankAccount(market);
    setFormVisible(true)
  };

  const handleDelete = async (account) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xoá không?',
      onOk() {
        financeServices.remove({
          id: account.id
        }).then(() => {
          message.success('Xoá tài khoản ngân hàng thành công')
          fetchData();
        }).catch((error) => {
          message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        })
      },
      onCancel() { },
    });
  }

  return (
    <Card
      size="small"
      className="w-100"
      loading={loading}
      title={'Tài khoản ngân hàng'}
    >
      <TableData
        loading={loading}
        data={data}
        params={params}
        remove={(v) => handleDelete(v)}
        edit={(v) => handleOpenFormModal(v)}
      />
      {
        formVisible && <BankAccountFormModal
          visible={formVisible}
          bankAccount={selectedBankAccount}
          onClose={() => setFormVisible(false)}
          reload={() => fetchData()}
        />
      }
    </Card>
  );
}

export default BankAccounts;