import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import savingsService from "../../../services/invest/savings";
import ModalSavingsTransactionDetail from "./components/modals/ModalSavingsTransactionDetail";
import {convertStringToQuery} from "../../../utils/common";
const query = convertStringToQuery(window.location.search);
import dayjs from 'dayjs';
import moment from 'moment';

const SavingsForceSettle = (props) => {
    const {router} = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [formVisible, setFormVisible] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [params, setParams] = useState({
        final_status: 2,
        page: 1,
        size: 50,
        dates: [
            query?.begin ? dayjs(Number(query?.begin) * 1000) : dayjs().add(-7, 'days'),
            query?.end ? dayjs(Number(query?.end) * 1000) : dayjs(),
            // '01-01-2023',
            // '30-12-2024'
        ]
    });

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);
        console.log('params', params);
        console.log('dates[0]', params?.dates?.[0]);
        await savingsService
            .list_intermediate_settled({
                paging: {
                    // ...params,
                    from_date: dayjs(dayjs(params.dates[0])).format('YYYYMMDD') || '20230101',
                    to_date: dayjs(dayjs(params.dates[1])).format('YYYYMMDD') || '20233012',
                    page_index: params.page - 1,
                    page_size: params.size,
                    final_status: params.final_status
                }
            })
            .then((response) => {
                console.log('response?.list_payment_force_settle', response?.list_payment_force_settle);
                setData(response?.list_payment_force_settle || []);
                setTotal(response?.list_total_record || 0);
            })
            .catch((err) => {
                console.log('error', err);
                setData([]);
            });
        setLoading(false);
    };

    const handleShowDetail = (item = null) => {
        console.log('item', item);
        window.navigatePage(
            'SAVINGS_FORCE_SETTLE_DETAIL',
            {id: item.row_id},
            {...query}
        )
        // setSelectedTransaction(transaction);
        // setFormVisible(true)
    };

    return (
        <div className="cms-users">
            {/*<AdminHeader*/}
                {/*title={router.label}*/}
                {/*total={total}*/}
            {/*/>*/}
            <Filter params={params} setParams={setParams}/>
            <TableData
                loading={loading}
                data={data}
                params={params}
                showDetail={(item) => handleShowDetail(item)}
            />

            <Pagination
                className="pagination"
                current={params.page}
                pageSize={params.size}
                total={total}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `Tổng số ${total}`}
                onChange={handleChangePage}
            />

            {
                formVisible && <ModalSavingsTransactionDetail
                    visible={formVisible}
                    transaction={selectedTransaction}
                    onClose={() => setFormVisible(false)}
                    reload={() => fetchData()}
                />
            }
        </div>
    );
};

export default SavingsForceSettle;
