import React from "react";
import "../css/components/TableData.scss";
import { Row, Table, Button, Modal, Tag } from "antd";

import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import {
    convertDateTime, formatMoneyCurrency, getFmkTransactionTypeColorByStatus,
    getSavingsProductStatus
} from "../../../../utils/common";

const TableData = (props) => {
    const {
        data,
        loading,
        params,
        edit = () => { }
    } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: "STT",
            dataIndex: "stt",
            key: "stt",
            align: "center",
        },
        {
            title: "Tên quỹ",
            dataIndex: "itemName",
            key: "itemName",
            align: "center",
        },

        {
            title: "Lãi suất (%/năm)",
            dataIndex: "subCycleInterest",
            key: "subCycleInterest",
            align: "center",
            render: (_, record) => record?.subCycleInterest + '%'
        },
        {
            title: "Đầu tư tối thiểu",
            dataIndex: "mininumRequireAmount",
            key: "mininumRequireAmount",
            align: "center",
            render: (_, record) => formatMoneyCurrency(record?.mininumRequireAmount)
        },
        {
            title: "Trạng thái",
            dataIndex: "activeProduct",
            key: "activeProduct",
            align: "center",
            render: (_, record) =>
                <Tag
                    size={'default'}
                    color={getSavingsProductStatus(record.activeProduct)?.color}>
                    {getSavingsProductStatus(record?.activeProduct)?.label}
                </Tag>
        },
        {
            title: "Hành động",
            dataIndex: "actions",
            key: "actions",
            align: "center",
            render: (_, record) => (
                <Row gutter={[8, 8]} justify={'center'}>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => edit(record)}
                        ghost
                        type='primary'
                        size='small'>
                        Sửa
                    </Button>
                    <Modal
                        title="Basic Modal"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <p>Some contents...</p>
                    </Modal>
                </Row>
            ),
        },
    ];

    return (
        <Table
            bordered
            className="table-data"
            columns={columns}
            dataSource={data.map((d, index) => ({
                ...d,
                stt: index + 1 + (params.page - 1) * params.size,
            }))}
            loading={loading}
            pagination={false}
            rowKey={(record) => record?.id}
            size="small"
        />
    );
};

export default TableData;
