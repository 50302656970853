import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import fmarketServices from "../../../services/invest/fmarket";
import {removeParamsEmpty} from "../../../utils/common";
import ModalFmarketOrderDetail from './components/modals/ModalFmarketOrderDetail';

const FmarketOrders = (props) => {
    const {router} = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [formVisible, setFormVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [params, setParams] = useState({
        page: 1,
        size: 10,
        sort: 'createDate,DESC'
    });

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);
        let send_params = removeParamsEmpty(params);
        await fmarketServices.fmarket_orders({
                ...send_params,
                page: params.page - 1,
                pageSize: params.size,
            })
            .then((response) => {
                setData(response?.data || []);
                setTotal(response?.page?.total_elements);
            })
            .catch(() => {
                setData([]);
            });
        setLoading(false);
    };

    const handleShowDetail = (item = null) => {
        console.log('item', item);
        setSelectedItem(item);
        setFormVisible(true)
    };

    return (
        <div className="cms-users">
            <AdminHeader
                title={router.label}
                total={total}
                actions={[
                    {
                        key: "add",
                        label: "Thêm mới",
                        type: "primary",
                        icon: <PlusOutlined/>,
                        click: () => {
                        },
                    },
                ]}
            />
            <Filter params={params} setParams={setParams}/>
            <TableData
                loading={loading}
                data={data}
                params={params}
                detail={selectedItem}
                showDetail={(item) => handleShowDetail(item)}
            />

            <Pagination
                className="pagination"
                current={params.page}
                pageSize={params.size}
                total={total}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `Tổng số ${total}`}
                onChange={handleChangePage}
            />

            {
                formVisible && <ModalFmarketOrderDetail
                    visible={formVisible}
                    transaction={selectedItem}
                    onClose={() => setFormVisible(false)}
                    reload={() => fetchData()}
                />
            }
        </div>
    );
};

export default FmarketOrders;
