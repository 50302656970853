import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function list_log_appota(params) {
  return request({
    url: ENDPOINT.LOG_APPOTA,
    method: "get",
    params,
  });
}

function log_appota_detail(params) {
  return request({
    url: ENDPOINT.LOG_APPOTA_DETAIL,
    method: "get",
    params,
  });
}

export default {
  list_log_appota,
  log_appota_detail
};
