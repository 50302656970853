import React, { useMemo, useState } from "react";
import {
  Row,
  Col,
  Button,
} from 'antd';

const Top = (props) => {
  const {
    loanInfo = {},
  } = props
  return (
    <Row
      gutter={[8, 8]}
      className="detail-top mt-s mb-m"
      align={'middle'}
      justify={'space-between'}
    >
      <Col>
        <p>Mã đơn: <span className="text-bold">{loanInfo?.loanCode || 'HD-12455765'}</span></p>
      </Col>
    </Row>
  );
}

export default Top;