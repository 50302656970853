import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function get_receipt_by_schedule(params = {}) {
  return request({
    url: ENDPOINT.RECEIPT_BY_SCHEDULE,
    method: "get",
    params,
  });
}

function get_receipts(params = {}) {
  return request({
    url: ENDPOINT.RECEIPTS,
    method: "get",
    params,
  });
}

export default {
  get_receipt_by_schedule,
  get_receipts
};
