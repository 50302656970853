
import CONSTANTS from "../config/constants";
import moment from 'moment';

export const getFmkTransactionTypeColorByStatus = (status) => {
    return CONSTANTS.FMARKET_TRANSACTION_STATUS.find(e => e.value === status)?.color || 'green';
};

export const getSavingsProductStatus = (status) => {
    return CONSTANTS.SAVINGS_PRODUCT_ACTIVE_STATUS.find(e => e.value === status) || {};
};

export const getObjectStatus = (array, status) => {
    return array?.find(e => e.value === status) || {};
};

export const displayInvestProfitCapitalDeal = (profit_capital_deal) => {
    console.log('profit_capital_deal', profit_capital_deal);
    return CONSTANTS.INVEST_PROFIT_CAPITAL_DEAL.find((e) => e.value == profit_capital_deal)?.name;
};

export const getAccountHref = (customer_id) => {
    return `${window.location.origin}/customers/${customer_id}`;
};

export const genRequestId = () => {
    return moment().format('YYYYMMDDHHmmss') + (Math.floor(Math.random()*89999 ) + 10000).toString();
}