import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function list(params = {}) {
  return request({
    url: ENDPOINT.MARKETS,
    method: "get",
    params,
  });
}

function get(id, params = {}) {
  return request({
    url: ENDPOINT.MARKET_DETAIL.replace(':id', id),
    method: "get",
    params,
  });
}

function list_order(params = {}) {
  return request({
    url: ENDPOINT.MARKET_ORDER,
    method: "get",
    params,
  });
}

function get_order_statistic(params = {}) {
  return request({
    url: ENDPOINT.MARKET_ORDER_STATISTIC,
    method: "get",
    params,
  });
}

function create(data, params = {}) {
  return request({
    url: ENDPOINT.MARKET_ADD,
    method: "post",
    data,
    params,
  });
}

function update(data, params = {}) {
  return request({
    url: ENDPOINT.MARKET_UPDATE,
    method: "put",
    data,
    params,
  });
}

function remove(params = {}) {
  return request({
    url: ENDPOINT.MARKET_DELETE,
    method: "delete",
    params,
  });
}

export default {
  list,
  get,
  list_order,
  get_order_statistic,
  create,
  update,
  remove
}
