import moment from "moment";
import ROUTERS from "../config/routers";
import CONSTANTS from "../config/constants";
import store from "../store";
const countryCodes = require('country-codes-list')

const has = Object.prototype.hasOwnProperty;
const allRouters = [
  ...ROUTERS.AUTH_ROUTERS,
  ...ROUTERS.ERROR_ROUTERS,
  ...ROUTERS.ADMIN_ROUTERS,
].map((r) => ({
  ...r,
  keys: r.path.split('/'),
}));

export const isDiff = (A, B) => JSON.stringify(A) !== JSON.stringify(B);

export const isEmpty = (prop) => {
  return (
    prop === null ||
    prop === undefined ||
    (has.call(prop, "length") && prop.length === 0) ||
    (prop.constructor === Object && Object.keys(prop).length === 0)
  );
};

export const formatNumber = (value = 0, fixed = 0) => {
  let newValue = value || 0;
  if (fixed && `${value}`.split(".")[1]?.length > fixed) {
    newValue = Number(value).toFixed(fixed);
  }
  return `${newValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatCurrency = (value = 0, fixed = 0) => {
  let newValue = value || 0;
  if (fixed && `${value}`.split(".")[1]?.length > fixed) {
    newValue = Number(value).toFixed(fixed);
  }
  return `${newValue} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatMoneyCurrency = (value = 0, fixed = 0, currency = 'VND') => {
  return Intl.NumberFormat(undefined, { style: 'currency', currency, maximumFractionDigits: fixed }).format(value).replace(/^(\D+)/, '$1 ').replace(/\s+/, ' ');
};

export const separatorNumber = (value = 0) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export const convertQueryToString = (routerPath, query) => {
  if (typeof query === "object" && !isEmpty(query)) {
    const querys = [];
    Object.keys(query).forEach(key => {
      querys.push(`${key}=${![null, undefined].includes(query[key]) ? query[key] : ''}`)
    });
    return `${routerPath}?${querys.join("&")}`;
  }
  if (typeof query === "string") {
    return `${routerPath}${query}`;
  }
  return routerPath;
};

export const convertStringToQuery = (queryString) => {
  const querys = queryString.slice(1, queryString.length)?.split("&");
  const query = {};
  querys.forEach((q) => {
    const key = q?.split("=")[0];
    const value = q?.split("=")[1];
    query[key] = value;
  });
  return query;
};

export const convertGender = (gender) => {
  return CONSTANTS.GENDERS.find((g) => g.value === gender || g.value === 2)
};


export const convertTime = (time) => {
  const hours = Math.floor(Number(time) / 60);
  const minutes = Number(time) % 60;
  return `${hours}h${minutes}m`;
};

export const convertDateTime = (date, format = CONSTANTS.DATE_TIME_FORMAT) => {
  if (date) {
    return moment(date).format(format);
  }
  return "";
};

export const disabledDate = (current) => {
  return (
    current &&
    moment(current).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
  );
};

export const disabledTime = (now, condition = true, type = "hour") => {
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  if (condition) {
    return {
      disabledHours: () =>
        type === "hour"
          ? range(0, 23).filter((h) => h <= moment(now).hour())
          : [],
      disabledMinutes: () =>
        type === "minute"
          ? range(0, 59).filter((h) => h <= moment(now).minute)
          : [],
      disabledSeconds: () =>
        type === "second"
          ? range(0, 59).filter((h) => h <= moment(now).second)
          : [],
    };
  }
  return {
    disabledHours: () => [],
  };
};

export const getCurrentWeekday = (date) => {
  return moment(date).weekday() + 2 > 7 ? 1 : moment(date).weekday() + 2;
};

export const randomColor = () => {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
};

export const convertDateNow = () => {
  const now = moment();
  const mOptions = [0, 15, 30, 45];
  const h = now.hours();
  const m = now.minutes();
  const lastM = mOptions.find((o) => o > m);
  if (lastM) {
    return moment(moment().format(`YYYY/MM/DD ${h}:${lastM}:ss`));
  } else {
    return moment(moment().add(1, "hours").format(`YYYY/MM/DD HH:00:ss`));
  }
};

export const getRouterParams = (routerPath, params) => {
  let path = routerPath;
  const keys = routerPath
    .split("/:")
    .map((p) => p.split("/")[0])
    .filter(Boolean);
  keys.forEach((key) => {
    path = path.replace(`:${key}`, params[key]);
  });
  return path;
};

export const getRouterByName = (name) => {
  const route = allRouters.find((r) => r.name === name);
  return route
    ? {
      ...route,
      key: route.name,
    }
    : {};
};

export const getMenuByName = (name) => {
  const { MENUS } = require("../config/menus");
  return MENUS.find((m) => m.key === name);
};

export const compareLocation = (routerKeys, location) => {
  const locationKeys = location.pathname?.split('/');
  const result = {
    params: {},
    valid: true
  }
  if (locationKeys.length !== routerKeys.length) {
    result.valid = false;
  } else {
    routerKeys.forEach((k, index) => {
      if (k.includes(':')) {
        result.params = {
          ...result.params,
          [k.replace(':', '')]: locationKeys[index]
        }
      } else if (locationKeys[index] !== k) {
        result.valid = false
      }
    })
  }
  return result
}

export const getRouterByLocation = (location) => {
  const route = allRouters.find((r) => compareLocation(r.keys, location).valid);
  return route ? {
    ...route,
    key: route.name,
    params: compareLocation(route.keys, location).params,
    query: convertStringToQuery(location.search)
  } : null
}

export const getMenuByLocation = (location) => {
  const route = getRouterByLocation(location);
  if (route && route.menu) {
    return getMenuByName(route.menu);
  }
  const parent = allRouters.find((r) => r.name === route?.parent);
  return getMenuByName(parent?.menu);
};

export const getRoutersByMenu = (menu) => {
  return ROUTERS.ADMIN_ROUTERS.filter((r) => r.menu === menu && !r.parent).map((m) => ({
    ...m,
    key: m.name,
  }));
};

export const getRoutersByLocation = (location) => {
  const { MENUS } = require("../config/menus");
  const route = getRouterByLocation(location);
  const parents = [];
  let parent = allRouters.find((r) => r.name === route?.parent);
  while (parent) {
    parents.unshift(parent);
    parent = allRouters.find((r) => r.name === parent?.parent);
  }
  let menu = MENUS.find((m) => m.key === (parents[0]?.menu || route?.menu));
  let results = menu ? [menu] : []
  if (parents.length > 0) {
    return [...results, ...parents, route];
  }
  if (route) {
    return [...results, route];
  }
  return [getRouterByName("DASHBOARD")];
};

export const contractStatus = (status) => {
  return CONSTANTS.CONTRACT_STATUS.find((s) => s.value == status) || {};
};

export const ekycStatus = (status) => {
  return CONSTANTS.EKYC_STATUS.find((s) => s.value == status) || {}
}

export const fieldAction = (action) => {
  return CONSTANTS.FIELD_ACTIONS.find((f) => f.value === action)
}

export const matchValueStatus = (matchValue) => {
  return CONSTANTS.MATCH_VALUES.find((f) => f.value === matchValue)
}

export const reviewedStatus = (status) => {
  return CONSTANTS.REVIEWED_STATUS.find((f) => f.value === status)
}

export const loanStatus = (status) => {
  return CONSTANTS.LOAN_STATUS.find((s) => s.value == status) || {}
}

export const loanMarketStatus = (status) => {
  return CONSTANTS.LOAN_MARKET_STATUS.find((s) => s.value == status) || {}
}

export const marketStatus = (status) => {
  return CONSTANTS.MARKET_STATUS.find((s) => s.value == status) || {}
}

export const lenderStatus = (status) => {
  return CONSTANTS.LENDER_STATUS.find((s) => s.value == status) || {}
}

export const activeStatus = (status = false) => {
  return CONSTANTS.ACTIVE_STATUS.find((s) => s.value == status) || {}
}

export const numMonths = () => {
  const values = []
  for (let index = 1; index <= 36; index++) {
    values.push({
      label: `${index} Tháng`,
      value: index
    })
  }
  return values
}

export const displayInvestProfitCapitalDeal = (profit_capital_deal) => {
  return CONSTANTS.INVEST_PROFIT_CAPITAL_DEAL.find((e) => e.value == profit_capital_deal)?.name;
}

export const removeParamsEmpty = (params) => {
  let asArray = Object.entries(params);
  let filtered = asArray.filter(([key, value]) => value != '' && value !== null && value != undefined);
  return Object.fromEntries(filtered);
};

export const getFmkTransactionTypeColorByStatus = (status) => {
  return CONSTANTS.FMARKET_TRANSACTION_STATUS.find(e => e.value === status)?.color || 'green';
};

export const getSavingsProductStatus = (status) => {
  return CONSTANTS.SAVINGS_PRODUCT_ACTIVE_STATUS.find(e => e.value === status) || {};
};

export const getObjectStatus = (array, status) => {
  return array?.find(e => e.value === status) || {};
};

export const blackListStatus = (status) => {
  return CONSTANTS.BLACK_LIST_STATUS.find(e => e.value === status) || {};
};

export const customerStatus = (status) => {
  return CONSTANTS.CUSTOMER_STATUS.find(e => e.value === status) || {};
};

export const loanCustomerStatus = (status) => {
  return CONSTANTS.LOAN_CUSTOMER_STATUS.find(e => e.value === status) || {};
};

export const paymentStatus = (status) => {
  return CONSTANTS.PAYMENT_STATUS.find(e => e.value === status) || {};
};

export const marketOrderStatus = (status) => {
  return CONSTANTS.MARKET_ORDER_STATUS.find(e => e.value === status) || {};
};

export const lenderOrderStatus = (status) => {
  return CONSTANTS.LENDER_ORDER_STATUS.find(e => e.value === status) || {};
};

export const countryInfo = (code) => {
  return countryCodes.all().find((c) => c.countryCallingCode == code)
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });
}

export const customerPaymentStatus = (status) => {
  return CONSTANTS.CUSTOMER_PAYMENT_STATUS.find((s) => s.value === status)
}

export const appotaStatus = (status) => {
  return CONSTANTS.APPOTA_STATUS.find((s) => s.value === status)
}

export const assessorContractStatus = (status) => {
  return CONSTANTS.ASSESSOR_CONTRACT_STATUS.find((s) => s.value === status)
}

export const assessorOrderStatus = (status) => {
  return CONSTANTS.ASSESSOR_ORDER_STATUS.find((s) => s.value === status)
}

export const customerRank = (rank) => {
  return CONSTANTS.CUSTOMER_RANK.find((s) => s.value === rank)
}

export const isPermission = (keys) => {
  const cmsInfo = store.getState().system.cmsInfo
  if (!keys || cmsInfo.key === 'CMS_FINBLUE') {
    return true
  }
  const roleCategories = store.getState().userInfo.loginInfo?.user?.roleCategories || []
  const allPermissions = roleCategories.map((c) => c.permissions).flat()
  const choosePermissions = allPermissions.filter((p) => !!p.isChoose)
  const permissionCodes = choosePermissions.map((p) => p.code)
  let result = false
  keys.forEach((key) => {
    if (!permissionCodes.includes(key)) {
      result = false
      return
    }
    result = true
  })
  return result
}

export const getSideBarMenus = () => {
  const { MENUS } = require("../config/menus");
  const loginInfo = store.getState().userInfo.loginInfo
  
  let menus = []

  if (loginInfo && loginInfo.customerId) {
    menus = MENUS.map((m) => {
      if (m.children) {
        return {
          ...m,
//          children: m.children.filter((c) => isPermission(c.permission_keys))
        }
      }
      return {
        ...m,
//        hide: !isPermission(m.permission_keys)
        hide: false
      }
    }).filter((m) => {
      if (m.children) {
        return m.children.length > 0
      }
      return !m.hide
    })
  }
  return menus.map((m) => {
    delete m.hide
    return m
  })
};

export const getFirstMenu = () => {
  const sideBarMenus = getSideBarMenus()
  return sideBarMenus[0] ? (sideBarMenus[0].children ? sideBarMenus[0].children[0] : sideBarMenus[0]) : null
};

export const openNewPageByRoute = (routeName, params, query) => {
  let currentRouter = getRouterByName(routeName);
  let url = convertQueryToString(getRouterParams(currentRouter.path, params), query);
  window.open(`${window.location.origin}${url}`)
};

export const formatFileName = (name) => {
    let fileExt = name.split('.')?.[1] || 'pdf';
    name = name.split('.')?.[0];

    // Chuyển hết sang chữ thường
    name = name.toLowerCase();

    // xóa dấu
    name = name.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, "a");
    name = name.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, "e");
    name = name.replace(/(ì|í|ị|ỉ|ĩ)/g, "i");
    name = name.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, "o");
    name = name.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, "u");
    name = name.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, "y");
    name = name.replace(/(đ)/g, "d");

    // Xóa ký tự đặc biệt
    name = name.replace(/([^0-9a-z-\s])/g, "");

    // Xóa khoảng trắng thay bằng ký tự -
    name = name.replace(/(\s+)/g, "-");

    // xóa phần dự - ở đầu
    name = name.replace(/^-+/g, "");

    // xóa phần dư - ở cuối
    name = name.replace(/-+$/g, "");

    return name + '.' + fileExt;
};