import * as actionTypes from "../actionTypes";
import CONSTANTS from "../../config/constants";

const initialState = {
  locale: "vi",
  isLoading: true,
  cmsInfo: CONSTANTS.CMS_APPS[0]
};

const system = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_LANGUAGE:
      return { ...state, locale: action.payload };
    case actionTypes.TOGGLE_LOADING:
      return { ...state, isLoading: action.payload };
    case actionTypes.UPDATE_CMS_INFO:
      return { ...state, cmsInfo: action.payload };
    default:
      return state;
  }
};

export default system;
