import React, { useState, useEffect } from "react";
import { AdminDetailHeader } from "../../../components";
import DashboardFilter from './components/Filter';
import DashboardSystem from './components/System';
import DashboardMarket from './components/Market';
import DashboardLender from './components/Lender';
import DashboardLoan from './components/Loan';
import DashboardDebt from './components/Debt';

import dayjs from 'dayjs';
import { convertStringToQuery } from '../../../utils/common'

const Dasboard = (props) => {
  const { } = props
  const query = convertStringToQuery(window.location.search);
  const [params, setParams] = useState({
    dates: [
      query?.begin ? dayjs(Number(query?.begin) * 1000) : dayjs().add(-7, 'days'),
      query?.end ? dayjs(Number(query?.end) * 1000) : dayjs()
    ]
  });
  useEffect(() => {
    window.navigatePage('DASHBOARD', {}, {
      begin: dayjs(params.dates[0]).unix(),
      end: dayjs(params.dates[1]).unix(),
    })
  }, [params])
  return (
    <div className="dasboard">
      <AdminDetailHeader
        title={'Báo cáo tổng quát'}
        actions={[]}
      />
      <DashboardFilter
        params={params}
        setParams={(v) => setParams({
          ...v
        })}
      />
      <DashboardSystem
        params={params}
      />
      <DashboardMarket
        params={params}
      />
      <DashboardLender
        params={params}
      />
      <DashboardLoan
        params={params}
      />
      <DashboardDebt
        params={params}
      />
    </div>
  );
}

export default Dasboard;