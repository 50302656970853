import React, { useState, useEffect } from "react";
import { Pagination } from 'antd';
import { ArrowDownOutlined } from "@ant-design/icons";
import { AdminHeader } from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import DivideEquallyModal from "./components/modals/DivideEqually";
import DivideModal from "./components/modals/Divide";

import assessorServices from '../../../services/finblue/assessor';

import { convertStringToQuery } from '../../../utils/common';

const Assessors = (props) => {
  const { router } = props
  const query = convertStringToQuery(window.location.search);
  const [loading, setLoading] = useState(false);
  const [selectedAssessor, setSelectedAssessor] = useState({});
  const [divideVisible, setDivideVisible] = useState(false);
  const [divideEquallyVisible, setDivideEquallyVisible] = useState(false);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState({
    page: Number(query?.page || 1),
    pageSize: 10,
    keyword: query?.keyword || null,
    active: query?.active ? (query?.active == 'true' ? true : false) : '',
  });

  useEffect(() => {
    fetchData();
    window.navigatePage('ASSESSORS', {}, {
      page: params.page,
      keyword: params.keyword,
      active: params.active,
    })
  }, [params])

  const handleChangePage = (page, pageSize) => {
    setParams({
      ...params,
      page,
      pageSize
    })
  };

  const fetchData = async () => {
    setLoading(true);
    await assessorServices.get_assessors({
      pageSize: params.pageSize,
      page: params.page - 1,
      keyword: params.keyword,
      active: params.active
    }).then((response) => {
      setData(response?.data || []);
      setTotal(response?.page?.total_elements)
    }).catch(() => {
      setData([])
      setTotal(0)
    })
    setLoading(false);
  }

  const handleDivideModal = (assessor) => {
    setSelectedAssessor(assessor)
    setDivideVisible(true)
  };

  return (
    <div className="lenders">
      <AdminHeader
        title={router.label}
        total={total}
        actions={[
          {
            key: 'divide-equally',
            label: 'Giao việc nhanh',
            type: 'primary',
            icon: <ArrowDownOutlined />,
            click: () => setDivideEquallyVisible(true)
          }
        ]}
      />
      <Filter
        params={params}
        setParams={(v) => setParams({
          ...v,
          page: 1,
        })}
      />
      <TableData
        loading={loading}
        data={data}
        params={params}
        onDivide={handleDivideModal}
      />
      <Pagination
        className="pagination"
        current={params.page}
        pageSize={params.pageSize}
        total={total}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Tổng số ${total}`}
        onChange={handleChangePage}
      />
      {
        divideVisible && <DivideModal
          visible={divideVisible}
          assessor={selectedAssessor}
          onClose={() => setDivideVisible(false)}
          reload={() => fetchData()}
        />
      }
      {
        divideEquallyVisible && <DivideEquallyModal
          visible={divideEquallyVisible}
          onClose={() => setDivideEquallyVisible(false)}
          reload={() => fetchData()}
        />
      }
    </div>
  );
}

export default Assessors;