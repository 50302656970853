import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function get_assessors(params = {}) {
  return request({
    url: ENDPOINT.ASSIGN_ASSESSOR,
    method: "get",
    params,
  });
}

function get_assessor(params = {}) {
  return request({
    url: ENDPOINT.ASSIGN_ASSESSOR_DETAIL,
    method: "get",
    params,
  });
}

function get_assessor_orders(params = {}) {
  return request({
    url: ENDPOINT.ASSIGN_ASSESSOR_ORDERS,
    method: "get",
    params,
  });
}

function get_assessor_contracts(params = {}) {
  return request({
    url: ENDPOINT.ASSIGN_ASSESSOR_CONTRACTS,
    method: "get",
    params,
  });
}

function get_assessor_works(params = {}) {
  return request({
    url: ENDPOINT.ASSIGN_ASSESSOR_WORKS,
    method: "get",
    params,
  });
}

function divide(data = {}) {
  return request({
    url: ENDPOINT.ASSIGN_ASSESSOR,
    method: "post",
    data,
  });
}

function divide_equally(data = {}, params = {}) {
  return request({
    url: ENDPOINT.ASSIGN_ASSESSOR_DIVIDE_EQUALLY,
    method: "post",
    data,
    params
  });
}

export default {
  get_assessors,
  get_assessor,
  get_assessor_orders,
  get_assessor_contracts,
  get_assessor_works,
  divide,
  divide_equally
};
