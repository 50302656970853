import React, { useEffect, useState } from "react";
import {
  Table,
} from 'antd';

import {
  formatCurrency,
  convertDateTime
} from '../../utils/common'

const PaymentSchedules = (props) => {
  const {
    loading = false,
    data = [],
    bodered = true
  } = props

  const columns = [
    {
      title: 'Kỳ hạn',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 100
    },
    {
      title: 'Ngày đóng tiền',
      dataIndex: 'expectedDate',
      key: 'expectedDate',
      align: 'center',
      width: 200,
      render: (_, record) => (
        convertDateTime(record.expectedDate, 'DD-MM-YYYY')
      ),
    },
    {
      title: 'Số tiền gốc',
      dataIndex: 'money',
      key: 'money',
      align: 'right',
      width: 200,
      render: (_, record) => (
        formatCurrency(record.money)
      ),
    },
    {
      title: 'Số tiền lãi',
      dataIndex: 'interest',
      key: 'interest',
      align: 'right',
      width: 200,
      render: (_, record) => (
        formatCurrency(record.interest)
      ),
    },
    {
      title: 'Tổng',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      width: 200,
      render: (_, record) => (
        formatCurrency(record.amount)
      ),
    },
  ]

  return (
    <Table
      loading={loading}
      bodered={bodered}
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 }))}
      pagination={false}
      rowKey={(record) => record?.stt}
      scroll={{ x: 1024 }}
      size="small"
    />
  );
}

export default PaymentSchedules;