import React, { useState, useEffect, useMemo } from "react";

import {
  EditOutlined,
  CloseOutlined,
  ExclamationCircleFilled
} from "@ant-design/icons";
import { useParams } from 'react-router-dom';

import { Modal, message } from 'antd'

import { AdminDetailHeader } from "../../../components";
import Information from "./components/detail/Information";
import Orders from "./components/detail/Orders";

import LenderFormModal from "./components/modals/LenderForm"

import lenderServices from '../../../services/finblue/lender';

import { } from '../../../utils/common';

const LenderDetail = (props) => {
  const { router } = props
  const { id } = useParams();
  const [loading, setLoading] = useState({})
  const [lenderInfo, setLenderInfo] = useState({})
  const [formVisible, setFormVisible] = useState(false);
  const [removing, setRemoving] = useState(false);

  useEffect(() => {
    fetchData()
  }, [id])


  const fetchData = async () => {
    setLoading(true)
    await lenderServices.get(id).then((response) => {
      setLenderInfo(response?.data || {})
    }).catch(() => {
      setLenderInfo({})
    })
    setLoading(false)
  }

  const handleRemoveLender = () => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xoá ?',
      okButtonProps: {
        loading: removing,
      },
      cancelButtonProps: {
        disabled: removing
      },
      onOk() {
        setRemoving(true);
        lenderServices.remove({ id }).then(() => {
          message.success('Xoá thành công')
          setRemoving(false);
          window.navigatePage('LENDERS');
        }).catch((error) => {
          message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
          setRemoving(false);
        });
      },
      onCancel() { },
    });
  }

  return (
    <div className="lender-detail">
      <AdminDetailHeader
        title={`${router.label}: ${lenderInfo.name || ''}`}
        showTotal={false}
        actions={[
          {
            key: 'edit',
            label: 'Chỉnh sửa',
            type: 'primary',
            icon: <EditOutlined />,
            disabled: loading || removing,
            click: () => setFormVisible(true)
          },
          {
            key: 'delete',
            label: 'Xoá',
            type: 'primary',
            danger: true,
            icon: <CloseOutlined />,
            disabled: loading,
            loading: removing,
            click: () => handleRemoveLender()
          }
        ]}
      />
      <Information
        loading={loading}
        lenderInfo={lenderInfo}
      />
      <Orders
        loading={loading}
        lenderInfo={lenderInfo}
        lenderId={id}
      />
      {
        formVisible && <LenderFormModal
          visible={formVisible}
          lender={lenderInfo}
          id={id}
          onClose={() => setFormVisible(false)}
          reload={() => fetchData()}
        />
      }
    </div>
  );
}

export default LenderDetail;