import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {PlusOutlined} from "@ant-design/icons";
import {AdminDetailHeader, CKEditorInvest} from "../../../components";
import {Pagination, Row, Col, Button} from "antd";
import Filter from "./components/Filter";
import TableData from "./components/TableData";
import ProductFormModal from "./components/modals/ProductForm";
import contentService from "../../../services/invest/content";
import {convertStringToQuery} from "../../../utils/common";
import {message} from "antd/es/index";
// import CKEditorInvest from "../../../components/CKEditorInvest";


const Policy = (props) => {
    const {router} = props;
    const [loading, setLoading] = useState(false);
    // const [formVisible, setFormVisible] = useState(false);
    // const [selectedProduct, setSelectedProduct] = useState(null);
    // const [content, setContent] = useState("");
    const [data, setData] = useState();
    // const [total, setTotal] = useState(10);
    const [callingAPI, setCallingAPI] = useState(false)
    const [actionKey, setActionKey] = useState('')
    const [params, setParams] = useState({
        page: 1,
        size: 20,
    });

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);
        await contentService.get_policy({})
            .then((response) => {
                console.log('response?.data?.shortDesc', response?.data?.[0]?.shortDesc);
                setData(response?.data?.[0]?.shortDesc || '');
            })
            .catch(() => {
                setData('');
            });
        setLoading(false);
    };

    const onChangeEditor = (value) => {
        setData(value || " ");
    };

    const update = () => {
        console.log('data', data);
        // setActionKey('updating');
        // setCallingAPI(true);
        // contentService.update_policy(data).then(() => {
        //     message.success(success);
        //     setCallingAPI(false);
        // }).catch((error) => {
        //     setCallingAPI(false);
        //     message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        // });
    };

    return (
        <div className="content-policy">
            <AdminDetailHeader
                title={router.label}
            />

            {data && <CKEditorInvest initValue={data} onChange={onChangeEditor} />}


            <Row gutter={[24, 0]} className="detail-footer">
                
                <Col>
                    <Button
                        type="primary"
                        disabled={loading || (callingAPI && actionKey !== 'updating')}
                        loading={callingAPI && actionKey === 'updating'}
                        onClick={() => update()}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default Policy;
