import React, { useMemo, useState } from "react";
import {
  Row,
  Col,
  Button,
  message
} from 'antd';

import loanServices from '../../../../../services/finblue/loan';
import { UnapprovalModal, ApprovalModal } from "../../../../../components";


const Footer = (props) => {
  const {
    data = {},
    params = {},
    loading = false,
  } = props

  const [callingAPI, setCallingAPI] = useState(false)
  const [unapprovalVisible, setUnapprovalVisible] = useState(false)
  const [approvalVisible, setApprovalVisible] = useState(false)

  const loanUnapproval = async (data) => {
    setCallingAPI(true);
    await loanServices.ecommerce_verify({
      ...data,
      status: 2,
      orderId: params.id,
    }, { orderId: params.id }).then(() => {
      message.success('Từ chối duyệt thành công')
      setCallingAPI(false);
      window.navigatePage('BACK')
      setUnapprovalVisible(false);
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const loanApproval = (data) => {
    setCallingAPI(true);
    loanServices.ecommerce_verify({
      ...data,
      status: 1,
      orderId: params.id,
    }).then(() => {
      message.success('Phê duyệt duyệt thành công')
      setCallingAPI(false);
      window.navigatePage('BACK')
      setApprovalVisible(false);
    }).catch((error) => {
      message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
    })
  }

  const showApproval = useMemo(() => {
    return data.marketStatus == 0
  }, [data])

  return (
    <Row gutter={[8, 8]} className="detail-footer">
      {
        showApproval && <Col lg={12}>
          <Button
            size="large"
            danger
            type="primary"
            className="w-100"
            disabled={loading || callingAPI}
            loading={callingAPI}
            onClick={() => setUnapprovalVisible(true)}
          >
            Từ chối
          </Button>
        </Col>
      }
      {
        showApproval && <Col lg={12}>
          <Button
            size="large"
            type="primary"
            className="w-100"
            disabled={loading || callingAPI}
            loading={callingAPI}
            onClick={() => {
              setApprovalVisible(true);
            }}
          >
            Phê duyệt
          </Button>
        </Col>
      }
      {
        unapprovalVisible && <UnapprovalModal
          visible={unapprovalVisible}
          callingAPI={callingAPI}
          onClose={() => setUnapprovalVisible(false)}
          submit={(value) => loanUnapproval(value)}
        />
      }
      {
        approvalVisible && <ApprovalModal
          visible={approvalVisible}
          callingAPI={callingAPI}
          onClose={() => setApprovalVisible(false)}
          submit={(value) => loanApproval(value)}
        />
      }
    </Row>
  );
}

export default Footer;