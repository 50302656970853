import React from "react";
import "../css/components/Filter.scss";
import { Row, Col, Input, Select } from "antd";

import { SearchOutlined } from "@ant-design/icons";

const Filter = (props) => {
  const { params = {}, setParams = () => {} } = props;
  return (
    <Row className="filter" gutter={[8, 8]}>
      <Col span={3} lg={6} sm={12} xs={24}>
        <Input
          prefix={<SearchOutlined />}
          value={params.keyword}
          placeholder="Nhập thông tin tìm kiếm..."
          onChange={(e) => setParams({ ...params, keyword: e.target.value })}
        />
      </Col>

      <Col span={3} lg={6} sm={12} xs={24}>
        <Select
          placeholder={"Quyền hạn"}
          value={params.status}
          onChange={(v) => setParams({ ...params, status: v })}
          showSearch={true}
          optionFilterProp="children"
          className="w-full"
          filterOption={(input, option) =>
            `${option.children}`.toLowerCase().includes(input.toLowerCase())
          }
        >
          {[].map((it) => (
            <Select.Option value={it.shopId || "-1"} key={it.shopId || "-1"}>
              {it.shopName || it.shopId || "-1"}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col span={3} lg={6} sm={12} xs={24}>
        <Select
          placeholder={"Quyền truy cập đặc biệt"}
          className="w-full"
          onChange={(v) => v}
          optionFilterProp="children"
          filterOption={(input, option) =>
            `${option.children}`.toLowerCase().includes(input.toLowerCase())
          }
        >
          {[].map((status) => (
            <Select.Option value={status.status} key={status.id}>
              {status.label}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

export default Filter;
