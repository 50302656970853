import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Tabs, Spin} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminDetailHeader} from "../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import userServices from "../../../services/admin/user";
import customerServices from "../../../services/invest/customer";
import { useParams } from 'react-router-dom';
import CustomerInfoTab from './customer_info_tab';
import CustomerTransactionTab from './customer_transaction_tab';
import CustomerFmkAccountTab from './customer_fmarket_account';
import CustomerFmkTransactionsTab from './customer_fmk_transactions';
import CustomerFmarketTab from "./customer_fmarket_tab";
import CustomerAmberTab from "./customer_amber_tab";
import CustomerGoldTab from "./customer_gold_tab";

const CustomerDetail = (props) => {
    const {router} = props;
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState({});
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [params, setParams] = useState({
        page: 1,
        size: 10,
    });

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);
        let account_id = id;
        await customerServices.customer_detail({account_id})
            .then((response) => {
                setCustomer(response?.data || {});
                setLoading(false);
                // setTotal(response?.page?.total_elements);
            })
            .catch(() => {
                setLoading(false);
                // setData([]);
            });
        setLoading(false);
    };

    return (
        <div className="cms-users">
            <AdminDetailHeader
                title={customer?.accFullname}
                // total={total}
                // actions={[
                //     {
                //         key: "add",
                //         label: "Thêm mới",
                //         type: "primary",
                //         icon: <PlusOutlined/>,
                //         click: () => {},
                //     },
                // ]}
            />

            <div className="content-wrapper">
                <div className="page-content">

                    <div className="main-content">
                        <div className='extra-custome-info'>
                            <Spin spinning={loading}>
                                <Tabs
                                    defaultActiveKey={"CUSTOMER-INFO"}
                                    className="tab-info"
                                    items={[
                                        {
                                            label: 'Thông tin BlueInvest',
                                            key: 'CUSTOMER-INFO',
                                            children: <CustomerInfoTab customer={customer}/>,
                                        },
                                        {
                                            label: 'Fmarket',
                                            key: 'CUSTOMER-FMARKET',
                                            children: <CustomerFmarketTab customer={customer}/>,
                                        },
                                        {
                                            label: 'Amber',
                                            key: 'CUSTOMER-ORDER',
                                            children: <CustomerAmberTab customer={customer}/>
                                        },
                                        {
                                            label: 'Vàng',
                                            key: 'CUSTOMER-GOLD',
                                            children: <CustomerGoldTab customer={customer}/>
                                        },
                                        {
                                            label: 'Bất động sản',
                                            key: 'CUSTOMER-REAL-ESTATE',
                                            children: <CustomerGoldTab customer={customer}/>
                                        },
//                                        {
//                                            label: 'Thông tin tài khoản Fmarket',
//                                            key: 'CUSTOMER-FMK-INFO',
//                                            children: <CustomerFmkAccountTab customer={customer}/>,
//                                        },
//                                        {
//                                            label: 'Lịch sử GD Fmarket',
//                                            key: 'CUSTOMER-FMK-TRANSACTIONS',
//                                            children: <CustomerFmkTransactionsTab customer={customer}/>
//                                        },
                                    ]}
                                />
                            </Spin>

                        </div>
                    </div>
                </div>
            </div>

            {/*<Filter params={params} setParams={setParams}/>*/}
            {/*<TableData loading={loading} data={data} params={params}/>*/}

            {/*<Pagination*/}
                {/*className="pagination"*/}
                {/*current={params.page}*/}
                {/*pageSize={params.size}*/}
                {/*total={total}*/}
                {/*showSizeChanger*/}
                {/*showQuickJumper*/}
                {/*showTotal={(total) => `Tổng số ${total}`}*/}
                {/*onChange={handleChangePage}*/}
            {/*/>*/}
        </div>
    );
};

export default CustomerDetail;
