import React, {useState, useEffect, useMemo} from "react";
import './css/index.scss';
import {Row, Col, Table, Button, Modal, Tag, DatePicker, Spin, Space} from "antd";
import IMG from './../../../assets/images/invest/image';
import CONSTANTS from "../../../config/constants";
import INVEST_CONSTANTS from "../../../config/constants_invest";
import {convertStringToQuery, formatMoneyCurrency, isPermission} from "../../../utils/common";
import dayjs from 'dayjs';
import {Bar} from "react-chartjs-2";
import {Chart as ChartJS, registerables} from "chart.js";
import dashboardServices from "../../../services/invest/dashboard";
import { useTranslation } from 'react-i18next';

import { PERMISSIONS } from "../../../config/permissions";
import customerServices from "../../../services/invest/customer";
import SavingCustomerChart from "./SavingCustomerChart";
import SavingTransactionChart from "./SavingTransactionChart";
import FundCustomerChart from "./FundCustomerChart";
import FundTransactionChart from "./FundTransactionChart";

ChartJS.register(...registerables);
// ChartJS.defaults.datasets.bar.barThickness = 16;
ChartJS.defaults.datasets.bar.maxBarThickness = 20;

const query = convertStringToQuery(window.location.search);

const CHART_SHOW_TYPE = {
    CUSTOMER: {value: 0, title: 'customer', textChart: 'quantity'},
    SAVINGS: {value: 1, title: 'saving_transactions', textChart: 'amount'},
    CUSTOMER_FUNDS: {value: 2, title: 'funds_customer', textChart: 'quantity'},
    FUNDS: {value: 3, title: 'funds_transactions', textChart: 'amount'},
};

const optionsChartDefault = {
    plugins: {
        legend: {
            position: 'bottom',
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            title: {
                display: true,
                text: 'Date'
            }
        },
        y: {
            beginAtZero: true,
            min: 0,
            // max: 300,

            grid: {
                display: true
            },
            title: {
                display: true,
                text: 'customer'
            }
        }
    },
    // maintainAspectRatio: false
};



const Dashboard = () => {
    const [chartShowType, setChartShowType] = useState(CHART_SHOW_TYPE.CUSTOMER);
    const [optionsChart, setOptionsChart] = useState(optionsChartDefault);
    const [sumCustomer, setSumCustomer] = useState(0);
    const [sumCustomerBuySaving, setSumCustomerBuySaving] = useState(0);
    const [sumCustomerBuyFunds, setSumCustomerBuyFunds] = useState(0);
    const [cutOffCustomer, setCutOffCustomer] = useState([]);
    const [cutOffSavings, setCutOffSavings] = useState([]);
    const [cutOffCustomerFunds, setCutOffCustomerFunds] = useState([]);
    const [cutOffTransactionFunds, setCutOffTransactionFunds] = useState([]);
    const [sumCustomerFunds, setSumCustomerFunds] = useState(0);
    const [sumAmountFunds, setSumAmountFunds] = useState(0);
    const [sumAmountSavings, setSumAmountSavings] = useState(0);
    const [loading, setLoading] = useState(false);

    const [dataChart, setDataChart] = useState({labels: [], datasets: []});

    const [params, setParams] = useState({
        dates: [
            query?.from_date ? dayjs(Number(query?.begin) * 1000) : dayjs().startOf('month'),
            query?.to_date ? dayjs(Number(query?.end) * 1000) : dayjs().endOf('month'),
        ]
    });

    const { t } = useTranslation();
    const texts = t;

    const permission = useMemo(() => {
      return {
        sumCustomer: isPermission([PERMISSIONS.SUM_CUSTOMER]),
        sumCustomerFunds: isPermission([PERMISSIONS.SUM_CUSTOMER_FUNDS]),
        sumAmountSavings: isPermission([PERMISSIONS.TOTAL_AMOUNT_SAVINGS]),
        sumAmountFunds: isPermission([PERMISSIONS.GET_SUM_AMOUNT_FUND]),
        cutOffCustomer: isPermission([PERMISSIONS.GET_CUT_OFF_CUSTOMER])
      }
    }, [])

    useEffect(() => {
      if (permission.sumCustomer) {
        getSumCustomer();
      }
      if (permission.sumCustomerFunds) {
        getSumCustomerFunds();
      }
      if (permission.sumAmountSavings) {
        getSumAmountSavings();
      }
      if (permission.sumAmountFunds) {
        getSumAmountFunds();
      }
      // tạm bỏ lấy data chart
//      if (permission.cutOffCustomer) {
//        getData(chartShowType);
//      }
      getTotalCustomerSavings();
      getTotalCustomerFmarket();
    }, [params, permission]);

    const genDate = (dates) => {
        let to_date = dayjs(dates.to_date);
        let currentDate = dayjs(dates.from_date);
        let dateArray = [];
        while (currentDate < to_date){
            dateArray.push(dayjs(currentDate).unix()*1000);
            currentDate = dayjs(currentDate).add(1, 'days');
        }
        return dateArray.join(',');
    };

    const getSumCustomer = async (_params) => {
        let new_params = {...params, ..._params};
        delete new_params.dates;
        await dashboardServices.dash_sum_customer(new_params).then((response) => {
            setSumCustomer(response?.data?.quantity || 0)
        }).catch(() => {
            setSumCustomer(0)
        });
    };

    const getSumCustomerFunds = async (_params) => {
        let new_params = {...params, ..._params};
        delete new_params.dates;
        await dashboardServices.dash_sum_customer_funds(new_params).then((response) => {
            setSumCustomerFunds(response?.data?.quantity || 0)
        }).catch(() => {
            setSumCustomerFunds(0)
        });
    };

    const genDataChart = (dataSources, labelFiled = 'date', dataField = 'quantity') => {
        let labels = [];
        let data = [];
        dataSources.forEach((e) => {
            labels.push(dayjs(e?.[labelFiled]).format('DD-MM'));
            data.push(e?.[dataField]);
        });
        return {labels, data};
    };

    const getCutOffCustomer = async (_params) => {
        const params_send = {
            // date: _params?.date,
            ..._params,
            type_date: INVEST_CONSTANTS.DATE_TYPE.BY_DATE,
            type_customer: INVEST_CONSTANTS.CUSTOMER_TYPE[0].value
        };
        await dashboardServices.dash_cut_off_customer(params_send).then((response) => {
            let cutOffCustomer = response?.data || [];
            setCutOffCustomer(cutOffCustomer);
            let {labels, data} = genDataChart(cutOffCustomer);
            let dataChart = {
                labels,
                datasets: [{
                    label: "Customer register",
                    data,
                    backgroundColor: '#3498db',
                }]
            };
            setDataChart(dataChart);
            setLoading(false);
        }).catch(() => {
            setCutOffCustomer([])
        });
    };

    const getCutOffCustomerFunds = async (_params) => {
        const params_send = {
            // date: _params?.date,
            ..._params,
            type_date: INVEST_CONSTANTS.DATE_TYPE.BY_DATE,
            type: INVEST_CONSTANTS.FUNDS_TYPE.CUSTOMER.value
        };
        await dashboardServices.dash_cut_off_transaction_funds(params_send).then((response) => {
            let cutOff = response?.data || [];
            setCutOffCustomerFunds(cutOff);
            let {labels, data} = genDataChart(cutOff);
            let dataChart = {
                labels,
                datasets: [{
                    label: "Customer register",
                    data,
                    backgroundColor: '#3498db',
                }]
            };
            setDataChart(dataChart);
            setLoading(false);
        }).catch(() => {
            setCutOffCustomerFunds([])
        });
    };

    const getSumAmountFunds = async (_params) => {
        let new_params = {...params, ..._params};
        delete new_params.dates;
        await dashboardServices.dash_sum_amount_funds(new_params).then((response) => {
            setSumAmountFunds(response?.data?.total_amount_buy || 0)
        }).catch(() => {
            setSumAmountFunds(0)
        });
    };

    const getSumAmountSavings = async (_params) => {
        let new_params = {...params, ..._params};
        delete new_params.dates;
        await dashboardServices.dash_sum_amount_savings(new_params).then((response) => {
            setSumAmountSavings(response?.data?.total_amount || 0)
        }).catch(() => {
            setSumAmountSavings(0)
        });
    };

    const getCutOffSavingOrder = async (_params) => {
        const params_send = {
            // date: _params?.date,
            // from_date: _params?.from_date,
            // to_date: _params?.to_date,
            ..._params,
            type_date: INVEST_CONSTANTS.DATE_TYPE.BY_DATE
        };
        await dashboardServices.dash_cut_off_saving_order(params_send).then((response) => {
            let cutOff = response?.data || [];
            setCutOffSavings(cutOff);
            let {labels, data} = genDataChart(cutOff, 'date', 'total_amount');
            let dataChart = {
                labels,
                datasets: [{
                    label: "Transactions",
                    data,
                    backgroundColor: '#3498db',
                }]
            };
            setDataChart(dataChart);
            setLoading(false);
        }).catch(() => {
            setCutOffSavings([])
        });
    };

    const getCutOffTransactionFunds = async (_params) => {
        const params_send = {
            // date: _params?.date,
            ..._params,
            type_date: INVEST_CONSTANTS.DATE_TYPE.BY_DATE,
            type: INVEST_CONSTANTS.FUNDS_TYPE.TRANSACTION.value
        };
        await dashboardServices.dash_cut_off_transaction_funds(params_send).then((response) => {
            let cutOff = response?.data || [];
            setCutOffTransactionFunds(cutOff);
            // let {labels, data} = genDataChart(cutOff, 'date', 'quantityBuy');
            let dataChartBuy = genDataChart(cutOff, 'date', 'quantityBuy');
            let dataChartSell = genDataChart(cutOff, 'date', 'quantitySell');
            let dataChartTransfer = genDataChart(cutOff, 'date', 'quantityTransfer');
            let dataChart = {
                labels: dataChartBuy.labels,
                datasets: [
                    {
                        label: "Buy amount",
                        data: dataChartBuy.data,
                        backgroundColor: '#2ecc71',
                    },
                    {
                        label: "Sell amount",
                        data: dataChartSell.data,
                        backgroundColor: '#e74c3c',
                    },
                    {
                        label: "Transfer amount",
                        data: dataChartTransfer.data,
                        backgroundColor: '#3498db',
                    },
                ]
            };
            setDataChart(dataChart);
            setLoading(false);
        }).catch(() => {
            setCutOffTransactionFunds([])
        });
    };

    const getChartCustomer = () => {
        let new_params = {
            from_date: dayjs(dayjs(params.dates[0])).unix()*1000,
            to_date: dayjs(dayjs(params.dates[1])).unix()*1000,
        };
        // let date = genDate(new_params);
        // new_params = {...new_params, date};
        getCutOffCustomer(new_params);
    };

    const getChartSavings = () => {
        let new_params = {
            from_date: dayjs(dayjs(params.dates[0])).unix()*1000,
            to_date: dayjs(dayjs(params.dates[1])).unix()*1000,
        };
        // let date = genDate(new_params);
        // new_params = {...new_params, date};
        getCutOffSavingOrder(new_params);
    };

    const getChartCustomerFunds = () => {
        let new_params = {
            from_date: dayjs(dayjs(params.dates[0])).unix()*1000,
            to_date: dayjs(dayjs(params.dates[1])).unix()*1000,
        };
        // let date = genDate(new_params);
        // new_params = {...new_params, date};
        getCutOffCustomerFunds(new_params);
    };

    const getChartTransactionFunds = () => {
        let new_params = {
            from_date: dayjs(dayjs(params.dates[0])).unix()*1000,
            to_date: dayjs(dayjs(params.dates[1])).unix()*1000,
        };
        // let date = genDate(new_params);
        // new_params = {...new_params, date};
        getCutOffTransactionFunds(new_params);
    };

    const getOptionsChart = (chartShowType) => {
        let optionsChart = {...optionsChartDefault};
        optionsChart.scales.y.title.text = chartShowType.textChart;
        optionsChart.scales.y.title.title = chartShowType.title;
        return optionsChart;
    };

    const getData = (chartShowType) => {
        setLoading(true);
        setOptionsChart(getOptionsChart(chartShowType));
        setChartShowType(chartShowType);
        switch (chartShowType.value){
            case CHART_SHOW_TYPE.CUSTOMER.value:
                getChartCustomer();
                break;
            case CHART_SHOW_TYPE.SAVINGS.value:
                getChartSavings();
                break;
            case CHART_SHOW_TYPE.CUSTOMER_FUNDS.value:
                getChartCustomerFunds();
                break;
            case CHART_SHOW_TYPE.FUNDS.value:
                getChartTransactionFunds();
                break;
            default:
                break;
        }
    };


    const getTotalCustomerSavings = async() => {
        await customerServices
            .report_saving_transactions_customer()
            .then((response) => {
                // setData(response?.data || []);
                // setTotal(response?.page?.total_elements);
                setSumCustomerBuySaving(response?.page?.total_elements || 0)
                // setLoading(false);
            })
            .catch(() => {
                // setSumCustomerBuySaving(0)
                // setLoading(false);
            });
    };

    const getTotalCustomerFmarket = async() => {
        // let fromDate = params?.fromDate || null;
        // let toDate = params?.toDate || null;
        await customerServices
            .report_fmarket_transactions_customer()
            .then((response) => {
                // setData(response?.data || []);
                // setTotal(response?.page?.total_elements);
                // setLoading(false);
                setSumCustomerBuyFunds(response?.page?.total_elements || 0)
            })
            .catch(() => {
                // setData([]);
                // setLoading(false);
            });
    };

    // const optionsChart = {
    //     plugins: {
    //         legend: {
    //             position: 'bottom',
    //         }
    //     },
    //     scales: {
    //         x: {
    //             grid: {
    //                 display: false
    //             },
    //             title: {
    //                 display: true,
    //                 text: 'Ngày'
    //             }
    //         },
    //         y: {
    //             beginAtZero: true,
    //             min: 0,
    //             // max: 300,
    //
    //             grid: {
    //                 display: true
    //             },
    //             title: {
    //                 display: true,
    //                 text: 'Khách hàng'
    //             }
    //         }
    //     },
    //     // maintainAspectRatio: false
    // }
    //
    // const data = {
    //     labels: [
    //         "23/03", "24/03", "25/03", "26/03", '27/03', "28/03", "29/03",
    //         // "23/03", "24/03", "25/03", "26/03", '27/03', "28/03", "29/03",
    //         // "23/03", "24/03", "25/03", "26/03", '27/03', "28/03", "29/03",
    //         // "23/03", "24/03", "25/03", "26/03", '27/03', "28/03", "29/03",
    //     ],
    //     datasets: [
    //         {
    //             label: "Khách hàng đăng ký mới",
    //             data: [
    //                 100, 120, 130, 140, 150, 160, 270,
    //                 100, 120, 130, 140, 150, 160, 320,
    //                 100, 120, 130, 140, 150, 160, 270,
    //                 100, 120, 130, 140, 150, 160, 270,
    //                 // 100, 120, 130, 140, 150, 160, 270,
    //             ],
    //             backgroundColor: '#3498db',
    //         },
    //         {
    //             label: "Khách hàng online",
    //             data: [
    //                 50, 60, 70, 80, 90, 100, 110,
    //                 50, 60, 70, 80, 90, 100, 110,
    //                 100, 120, 130, 140, 150, 160, 270,
    //                 100, 120, 130, 140, 150, 160, 270,
    //                 // 100, 120, 130, 140, 150, 160, 270,
    //             ],
    //             backgroundColor: '#e74c3c',
    //         },
    //     ]
    // }

    const navToCustomer = (type) => {
        window.navigatePage('CUSTOMERS', {}, { type });
    }

    return (
        <div className="dashboard">

            <Row gutter={[16, 16]}>
                <Col span={8} lg={8} sm={12} xs={24}>
                    <div className="dashboard-card dashboard-card-saving-customer" onClick={() => navToCustomer(1)}>
                        <Space className="dashboard-card-title dashboard-card-title-saving" gap={0}>
                            <img src={IMG.DASH_SAVINGS}/>
                            <div>{`${texts('saving')}`}</div>
                        </Space>
                        <Space style={{padding: '12px 0'}} size={16}>
                            {permission.sumCustomer ?
                                <div className="dashboard-card-content">
                                    <div className="dashboard-card-name text-capitalize-first">Số người</div>
                                    <div className="dashboard-card-value">{sumCustomerBuySaving}</div>
                                </div>
                                : null}
                            {permission.sumAmountSavings ?
                                <div className="dashboard-card-content">
                                    <div className="dashboard-card-name text-capitalize-first">Số tiền</div>
                                    <div className="dashboard-card-value">{formatMoneyCurrency(sumAmountSavings)}</div>
                                </div>
                                : null}
                        </Space>
                    </div>
                </Col>
                <Col span={8} lg={8} sm={12} xs={24}>
                    <div className="dashboard-card dashboard-card-saving-customer" onClick={() => navToCustomer(2)}>
                        <Space className="dashboard-card-title dashboard-card-title-fund" gap={0}>
                            <img src={IMG.DASH_FUNDS}/>
                            <div>{`${texts('funds')}`}</div>
                        </Space>
                        <Space style={{padding: '12px 0'}} size={16}>
                            {permission.sumCustomerFunds ?
                                <div className="dashboard-card-content">
                                    <div className="dashboard-card-name text-capitalize-first">Số người</div>
                                    <div className="dashboard-card-value">{sumCustomerBuyFunds}</div>
                                </div>
                                : null}
                            {permission.sumAmountFunds ?
                                <div className="dashboard-card-content">
                                    <div className="dashboard-card-name text-capitalize-first">Số tiền</div>
                                    <div className="dashboard-card-value">{formatMoneyCurrency(sumAmountFunds)}</div>
                                </div>
                                : null}
                        </Space>
                    </div>
                </Col>
                <Col span={8} lg={8} sm={12} xs={24}>
                    <div className="dashboard-card dashboard-card-saving-customer">
                        <Space className="dashboard-card-title dashboard-card-title-real-estate" gap={0}>
                            <img src={IMG.DASH_REAL_ESTATE}/>
                            <div>Vàng và Bất động sản</div>
                        </Space>
                        <Space style={{padding: '12px 0'}} size={16}>
                            <div className="dashboard-card-content">
                                <div className="dashboard-card-name text-capitalize-first">Số người</div>
                                <div className="dashboard-card-value">0</div>
                            </div>
                            <div className="dashboard-card-content">
                                <div className="dashboard-card-name text-capitalize-first">Số tiền</div>
                                <div className="dashboard-card-value">{formatMoneyCurrency(0)}</div>
                            </div>
                        </Space>
                    </div>
                </Col>
            </Row>

            <SavingCustomerChart />

            <SavingTransactionChart />

            <FundCustomerChart />

            <FundTransactionChart />

        </div>
    );
}

export default Dashboard;