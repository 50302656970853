import React, {useState, useEffect} from "react";
import {PlusOutlined} from "@ant-design/icons";
import {AdminHeader} from "../../../components";


import fmkService from "../../../services/invest/fmarket";
import {useTranslation} from "react-i18next";

const PartnerAmber = (props) => {
    const {router} = props;
    const [loading, setLoading] = useState(false);


    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {

    }, []);



    return (
        <div className="cms-users">

        </div>
    );
};

export default PartnerAmber;
