import React from "react";
import {
  Row,
  Table,
  Col,
  Button,
  Tag
} from 'antd';

import {
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import {
  convertDateTime,
  activeStatus,
  formatCurrency,
  formatNumber
} from '../../../../utils/common'

const TableData = (props) => {
  const {
    data,
    loading,
    params,
    productId,
    edit = () => { },
    detailEdit = () => { },
    remove = () => { }
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 80,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Tag color={activeStatus(record.active)?.tag_color}>
          {activeStatus(record.active)?.label}
        </Tag>
      ),
    },
    {
      title: 'Tên hạn mức',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
      render: (_, record) => <a>
        {record.name || record.limitName}
      </a>
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      width: 200,
      render: (_, record) => formatCurrency(record.amount)
    },
    {
      title: 'Phí nền tảng (%/năm)',
      dataIndex: 'appSurcharge',
      key: 'appSurcharge',
      align: 'right',
      width: 200,
      render: (_, record) => formatNumber(record.appSurcharge)
    },
    {
      title: 'Kỳ hạn (Tháng)',
      dataIndex: 'numMonth',
      key: 'numMonth',
      align: 'center',
      width: 150,
    },
    {
      title: 'Lãi suất (%/năm)',
      dataIndex: 'interestRate',
      key: 'interestRate',
      align: 'center',
      width: 120,
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'createdTime',
      key: 'createdTime',
      align: 'center',
      width: 200,
      render: (_, record) => convertDateTime(record.createdTime)
    },
    {
      title: 'Thời gian cập nhật',
      dataIndex: 'updateTime',
      key: 'updateTime',
      align: 'center',
      width: 200,
      render: (_, record) => convertDateTime(record.updateTime)
    },
    {
      title: 'Hành động',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: productId ? 250 : 150,
      render: (_, record) => (
        <Row gutter={[8, 8]} justify={'center'}>
          <Col>
            {
              !productId && <Button
                icon={<EditOutlined />}
                type='primary'
                size="small"
                ghost
                onClick={() => edit(record)}
              >Sửa</Button>
            }
            {
              productId && <Button
                icon={<EditOutlined />}
                type='primary'
                size="small"
                ghost
                onClick={() => detailEdit(record)}
              >Sửa</Button>
            }
          </Col>
          {
            productId && <Col>
              <Button
                icon={<DeleteOutlined />}
                danger
                type='primary'
                size="small"
                ghost
                onClick={() => remove(record)}
              >Xoá</Button>
            </Col>
          }
        </Row>
      ),
    },
  ]

  return (
    <Table
      bordered
      className="table-data"
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 + (params.page - 1) * params.pageSize }))}
      loading={loading}
      pagination={false}
      scroll={{ x: 1024 }}
      rowKey={(record) => record?.id}
      size="small"
    />
  );
}

export default TableData;