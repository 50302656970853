import React, { useEffect, useState } from "react";
import {
  Table,
} from 'antd';

import {
  convertDateTime
} from '../../utils/common'

const PaymentSchedules = (props) => {
  const {
    data = [],
    bodered = true
  } = props

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 100
    },
    {
      title: 'Thời gian',
      dataIndex: 'first',
      key: 'first',
      align: 'center',
      width: 200,
      render: (_, record) => (
        convertDateTime(record.first)
      ),
    },
    {
      title: 'Nội dung',
      dataIndex: 'second',
      key: 'second',
      align: 'left',
    },
  ]

  return (
    <Table
      bodered={bodered}
      columns={columns}
      dataSource={data.map((d, index) => ({ ...d, stt: index + 1 }))}
      pagination={false}
      rowKey={(record) => record?.stt}
      scroll={{ x: 1024 }}
      size="small"
    />
  );
}

export default PaymentSchedules;