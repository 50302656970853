import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function list(params = {}) {
  return request({
    url: ENDPOINT.LENDERS,
    method: "get",
    params,
  });
}

function get(id, params = {}) {
  return request({
    url: ENDPOINT.LENDER_DETAIL.replace(':id', id),
    method: "get",
    params,
  });
}

function list_order(id, params = {}) {
  return request({
    url: ENDPOINT.LENDER_ORDER.replace(':id', id),
    method: "get",
    params,
  });
}

function get_order_statistic(id, params = {}) {
  return request({
    url: ENDPOINT.LENDER_ORDER_STATISTIC.replace(':id', id),
    method: "get",
    params,
  });
}

function get_order_payment_schedule(params = {}) {
  return request({
    url: ENDPOINT.LENDER_ORDER_PAYMENT_SCHEDULE,
    method: "get",
    params,
  });
}

function create(data, params = {}) {
  return request({
    url: ENDPOINT.LENDER_ADD,
    method: "post",
    data,
    params,
  });
}

function update(data, params = {}) {
  return request({
    url: ENDPOINT.LENDER_UPDATE,
    method: "put",
    data,
    params,
  });
}

function remove(params = {}) {
  return request({
    url: ENDPOINT.LENDER_DELETE,
    method: "delete",
    params,
  });
}

export default {
  list,
  get,
  list_order,
  get_order_statistic,
  get_order_payment_schedule,
  create,
  update,
  remove
}
