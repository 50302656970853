import { EMAIL_PATTERN, PHONE_PATTERN, LINK_PATTERN, BANK_ACCOUNT_PATTERN } from './patterns'
export const USERNAME_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập tên đăng nhập.'
  },
//  {
//    pattern: PHONE_PATTERN,
//    message: 'Tên đăng nhập không hợp lệ',
//  },
]

export const PHONE_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập số điện thoại.'
  },
  {
    pattern: PHONE_PATTERN,
    message: 'Số điện thoại không hợp lệ',
  },
]

export const EMAIL_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập email.'
  },
  {
    pattern: EMAIL_PATTERN,
    message: 'Email không hợp lệ',
  },
]

export const PASSWORD_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập mật khẩu.'
  }
]

export const LINK_RULES = [
  {
    pattern: LINK_PATTERN,
    message: 'Link không hợp lệ',
  },
]

export const PROJECT_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập tên gói sản phẩm.'
  }
]

export const MARKET_NAME_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập tên sàn.'
  }
]

export const TRANSACTION_NAME_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập tên hạn mức.'
  }
]

export const TRANSACTION_AMOUNT_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập số tiền cho hạn mức.'
  }
]

export const TRANSACTION_INTERES_RATE_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập lãi suất cho hạn mức.'
  }
]

export const TRANSACTION_SELECT_RULES = [
  {
    required: true,
    message: 'Vui lòng chọn hạn mức.'
  }
]

export const LENDER_NAME_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập tên công ty tài chính.'
  }
]

export const BUSINESS_RULES = [
  {
    required: true,
    message: 'Vui lòng chọn loại sản phẩm.'
  }
]

export const PACKAGES_RULES = [
  {
    required: true,
    message: 'Vui lòng chọn gói sản phẩm.'
  }
]

export const BANK_RULES = [
  {
    required: true,
    message: 'Vui lòng chọn ngân hàng.'
  }
]

export const BANK_BRANCH_RULES = [
  {
    required: true,
    message: 'Vui lòng chọn chi nhánh ngân hàng.'
  }
]

export const BANK_ACCOUNT_NAME_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập tên tài khoản.'
  }
]

export const BANK_ACCOUNT_NUMBER_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập số tài khoản.'
  },
  {
    pattern: BANK_ACCOUNT_PATTERN,
    message: 'Số tài khoản không hợp lệ',
  },
]

export const BANK_ACCOUNT_RULES = [
  {
    required: true,
    message: 'Vui lòng chọn tài khoản.'
  }
]

export const CUSTOMER_RULES = [
  {
    required: true,
    message: 'Vui lòng chọn khách hàng.'
  }
]

export const RECEIPT_DAY_RULES = [
  {
    required: true,
    message: 'Vui lòng chọn thời gian.'
  }
]

export const CATEGORY_RULES = [
  {
    required: true,
    message: 'Vui lòng chọn danh mục.'
  }
]

export const PERMISSION_NAME_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập tên quyền truy cập.'
  }
]

export const CATEGORY_NAME_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập tên danh mục.'
  }
]

export const METHOD_RULES = [
  {
    required: true,
    message: 'Vui lòng chọn phương thức.'
  }
]

export const PATTERN_PATH_RULES = [
  {
    required: true,
    message: 'Vui nhập path.'
  }
]

export const ROLE_NAME_RULES = [
  {
    required: true,
    message: 'Vui nhập tên quyền hạn.'
  }
]

export const DEPARTEMNT_NAME_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập tên phòng ban.'
  }
]

export const POSITION_NAME_RULES = [
  {
    required: true,
    message: 'Vui lòng nhập tên chức vụ.'
  }
]

export const DEPARTEMNT_RULES = [
  {
    required: true,
    message: 'Vui lòng chọn phòng ban.'
  }
]

export const POSITION_RULES = [
  {
    required: true,
    message: 'Vui lòng chọn chức vụ.'
  }
]

export const ROLE_RULES = [
  {
    required: true,
    message: 'Vui lòng chọn quyền hạn.'
  }
]