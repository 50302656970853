import axios from "axios";
import qs from 'qs';
import { handleResponseErrorMessage } from "./response";
import authServices from "../services/auth";
// create an axios instance
const service = axios.create({
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 60000, // request timeout
});

// request interceptor
service.interceptors.request.use(
    (config) => {
        const accessToken = authServices.login_info() ? authServices.login_info().token : "";
        // config.headers["Content-Type"] = "application/json";
        config.headers["Access-Control-Allow-Origin"] = "*";
        config.headers["Authorization"] = `${accessToken}`;
        config.paramsSerializer = params => qs.stringify(params, { encode: false });
        console.log('config', config);
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    (response) => {
        const res = response.data;
        // if (res.status.code == 'SSHOP-405') {
        //     authServices.logout();
        // }
        return res;
    },
    (error) => {
        return Promise.reject(handleResponseErrorMessage(error));
    }
);

export default service;
