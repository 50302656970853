import React, {useEffect, useState} from 'react';

import {Breadcrumb} from 'antd';
import {useLocation} from 'react-router-dom';

import {getRoutersByLocation, getRouterByLocation} from "../../utils/common";

import './css/Breadcrumb.scss';

function LayoutBreadcrumb() {
    const location = useLocation();
    const currentPage = getRouterByLocation(location);
    const [routers, setRouters] = useState([])
    useEffect(() => {
        if (location) {
            const routers = getRoutersByLocation(location);
//            console.log('routers', routers);
            setRouters(routers);
        }
    }, [location])
    return (
        routers?.length > 1 &&
        <Breadcrumb className='mb-m'>
            {
                routers.map((r, index) => (
                    <Breadcrumb.Item key={index}>
                        {
                            index === routers.length - 1 && <span>
                {r.label}
              </span>
                        }
                        {
                            index !== routers.length - 1 && <a
                                onClick={() => window.navigatePage(r.name, currentPage.params)}
                            >{r.label}</a>
                        }
                    </Breadcrumb.Item>
                ))
            }
        </Breadcrumb>
    )
}

export default LayoutBreadcrumb;