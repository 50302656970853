import React, { useEffect, useState } from "react";
import {
} from 'antd';

import { TransactionTable } from '../../../../../components'
import contractServices from '../../../../../services/finblue/contract';


const Transaction = (props) => {
  const {
    contractInfo = {},
    bordered = true
  } = props

  const [transactions, setTransactions] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (contractInfo?.contractId) {
      fetchTransactions();
    }
  }, [contractInfo])

  const fetchTransactions = async () => {
    setLoading(true);
    await contractServices.get_products_approved_by_id(contractInfo.contractId).then((response) => {
      setTransactions(response?.data || []);
    }).catch(() => {
      setTransactions([])
    })
    setLoading(false);
  }

  return (
    <TransactionTable
      loading={loading}
      bordered={bordered}
      data={transactions}
      className="table-data"
    />
  );
}

export default Transaction;