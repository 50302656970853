import React, { useState, useEffect, useMemo } from "react";
import { useParams } from 'react-router-dom';

import { AdminDetailHeader } from "../../../components";
import DetailOrder from "./components/detail/Order";
import Histories from "./components/detail/Histories";
import logServices from '../../../services/finblue/log';

import { appotaStatus } from '../../../utils/common'


const AppotaDetail = (props) => {
  const { router } = props
  const { id } = useParams();
  const [loading, setLoading] = useState(false)
  const [logAppotaInfo, setLogAppotaInfo] = useState({})

  const params = useMemo(() => {
    return {
      id: Number(id),
    }
  }, [id]);

  useEffect(() => {
    getLogAppotaInfo()
  }, [])

  const getLogAppotaInfo = (loading = true) => {
    setLoading(loading)
    logServices.log_appota_detail({
      orderId: params?.id
    }).then((response) => {
      setLogAppotaInfo(response.data)
      setLoading(false)
    }).catch(() => {
      setLogAppotaInfo({})
      setLoading(false)
    })
  }

  return (
    <div className="limit-approval-detail">
      <AdminDetailHeader
        title={router.label}
        status={appotaStatus(logAppotaInfo.status)}
      />
      <div className="mt-s mb-m">
        <DetailOrder
          loading={loading}
          params={params}
        />
      </div>
      <Histories
        loading={loading}
        data={logAppotaInfo.logs}
        params={params}
      />
    </div>
  );
}

export default AppotaDetail;