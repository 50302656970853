import React, { useState, useEffect } from "react";
import {
  Row,
} from 'antd';
import { Statistic } from '../../../../components'
import { formatCurrency, formatNumber } from '../../../../utils/common'

import CONSTANTS from '../../../../config/constants'

const Statistics = (props) => {
  const {
    extraData,
  } = props

  const [statistics, setStatistics] = useState([]);

  useEffect(() => {
    if (extraData) {
      fetchData();
    }
  }, [extraData])

  const fetchData = async () => {
    setStatistics(CONSTANTS.APPOTA_STATUS.map((s) => ({
      ...s,
      total: s.type == 'money' ? formatCurrency(extraData[s.statistic_key] || 0) : formatNumber(extraData[s.statistic_key] || 0)
    })));
  }

  return (
    <Row
      className="statistics"
      justify={'left'}
    >
      <Statistic
        statistics={statistics}
        setParams={() => { }}
      />
    </Row>
  );
}

export default Statistics;