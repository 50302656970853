import React, { useMemo } from "react";
import {
  Row,
  Col,
  Card,
  Alert
} from 'antd';

import { blue } from '@ant-design/colors';
import ProductTable from "../product-table";
import { formatCurrency, convertDateTime } from "../../utils/common";

const OrderInformation = (props) => {
  const {
    loading = false,
    orderInfo = {}
  } = props

  const orderName = useMemo(() => {
    let name = ''
    switch (orderInfo.businessId) {
      case 1:
      case 2:
        name = `${orderInfo?.business} > ${orderInfo?.marketName}`
        break;
      case 3:
        name = `${orderInfo?.business} > ${orderInfo?.reason}`
        break;
      case 4:
        name = `${orderInfo?.business} > ${orderInfo?.businessParent}`
        break;
      default:
        break;
    }
    return name;
  }, [orderInfo])

  const orderInfoData = useMemo(() => {
    let data = []
    switch (orderInfo.businessId) {
      case 1:
      case 2:
        data = [
          {
            name: 'Thời gian tạo',
            value: convertDateTime(orderInfo.time)
          },
          {
            name: 'Giá trị đơn hàng',
            value: formatCurrency(orderInfo.moneyOrder)
          },
          {
            name: 'Gian hàng',
            value: orderInfo?.marketName
          },
        ]
        break;
      case 3:
        data = [
          {
            name: 'Thời gian tạo',
            value: convertDateTime(orderInfo.time)
          },
          {
            name: 'Mã đơn hàng',
            value: orderInfo.loanCode
          },
          {
            name: 'Tiền dịch vụ',
            value: formatCurrency(orderInfo?.moneyReceive)
          },
          {
            name: 'Phí nền tảng (%/năm)',
            value: formatCurrency(orderInfo?.appSurcharge)
          },
          {
            name: 'Giá trị đơn hàng',
            value: formatCurrency(orderInfo.moneyOrder)
          },
          {
            name: 'Ngân hàng',
            value: orderInfo?.bankName
          },
          {
            name: 'Tài khoản ngân hàng',
            value: orderInfo?.bankAccount
          },
          {
            name: 'Số Tài khoản',
            value: orderInfo?.bankNumber
          }
        ]
        break;
      case 4:
        data = [
          {
            name: 'Dịch vụ',
            value: orderInfo?.billService,
          },
          {
            name: 'Mã hoá đơn',
            value: orderInfo.billCode || orderInfo.loanCode
          },
          {
            name: 'Thời gian tạo',
            value: convertDateTime(orderInfo.time)
          },
          {
            name: 'Giá trị hoá đơn',
            value: formatCurrency(orderInfo.moneyOrder)
          },
          {
            name: 'Số điện thoại',
            value: orderInfo.phone
          },
          {
            name: 'Nhà mạng',
            value: orderInfo.telco
          },
          {
            name: 'Loại dịch vụ mạng',
            value: orderInfo.telcoService == 'prepaid' ? 'Trả trước' : 'Trả sau'
          },
          {
            name: 'Thông báo',
            value: orderInfo?.appotaMessage ? <Alert
              className="w-100"
              message={orderInfo?.appotaMessage}
              type="info"
              closable={false}
            /> : '',
            lg: 24
          }
        ].filter((d) => !!d.value)
        break;
      default:
        break;
    }
    return data
  }, [orderInfo])

  return (
    <Card
      className="w-100 mb-m"
      size="small"
      loading={loading}
      title={<p>
        Thông tin đơn hàng <span className="ml-l text-bold" style={{
          color: blue.primary
        }}>{orderName}</span>
      </p>}
    >
      <Row gutter={[16, 8]}>
        {
          orderInfoData.map((info) => <Col
            key={info.name}
            lg={info.lg || 12}
            sm={24}
            xs={24}
          >
            <div className="flex align-items-center">
              <div className="text-gray mr-xs">{info.name}:</div>
              <div>{info.value || 'Không có dữ liệu'}</div>
            </div>
          </Col>)
        }
        {
          [1, 2].includes(orderInfo.businessId) && <Col lg={24}>
            <p className="text-bold mt-m">
              Danh sách sản phẩm
            </p>
            <ProductTable
              bodered={false}
              data={orderInfo?.products}
            />
          </Col>
        }
      </Row>
    </Card>
  );
}

export default OrderInformation;