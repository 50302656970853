import React, { useState, useEffect, useMemo } from "react";

import {
} from "@ant-design/icons";
import { useParams } from 'react-router-dom';

import { } from 'antd'

import { AdminDetailHeader } from "../../../components";

import Infomation from "./components/Infomation";
import Orders from "./components/detail/Orders";
import Contracts from "./components/detail/Contracts";

import assessorServices from '../../../services/finblue/assessor';

import { } from '../../../utils/common';

const AssessorDetail = (props) => {
  const { router } = props
  const { id } = useParams();
  const [loading, setLoading] = useState({})
  const [assessorInfo, setAssessorInfo] = useState({})

  useEffect(() => {
    fetchData()
  }, [id])


  const fetchData = async () => {
    setLoading(true)
    await assessorServices.get_assessor({ customerId: id }).then((response) => {
      setAssessorInfo(response?.data || {})
    }).catch(() => {
      setAssessorInfo({})
    })
    setLoading(false)
  }

  return (
    <div className="lender-detail">
      <AdminDetailHeader
        title={`${router.label}: ${assessorInfo.name || ''}`}
        showTotal={false}
        actions={[]}
      />
      <Infomation
        assessor={assessorInfo}
      />
      <Contracts
        loading={loading}
        customerId={id}
      />
      <Orders
        loading={loading}
        customerId={id}
      />
    </div>
  );
}

export default AssessorDetail;