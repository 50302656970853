import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/finblue";

function list(params = {}) {
  return request({
    url: ENDPOINT.PRODUCTS,
    method: "get",
    params,
  });
}

function list_detail(params = {}) {
  return request({
    url: ENDPOINT.PRODUCTS_DETAIL,
    method: "get",
    params,
  });
}


function list_product_transactions(params = {}) {
  return request({
    url: ENDPOINT.PRODUCT_TRANSACTIONS,
    method: "get",
    params,
  });
}

function list_transactions(params = {}) {
  return request({
    url: ENDPOINT.PRODUCTS_TRANSACTIONS,
    method: "get",
    params,
  });
}

function create(data, params = {}) {
  return request({
    url: ENDPOINT.PRODUCT_ADD,
    method: "post",
    data,
    params,
  });
}

function update(data, params = {}) {
  return request({
    url: ENDPOINT.PRODUCT_UPDATE,
    method: "put",
    data,
    params,
  });
}

function get(id, params = {}) {
  return request({
    url: `${ENDPOINT.PRODUCTS}/${id}`,
    method: "get",
    params,
  });
}

function create_transaction(data) {
  return request({
    url: ENDPOINT.PRODUCTS_TRANSACTION_ADD,
    method: "post",
    data,
  });
}

function update_transaction(data) {
  return request({
    url: ENDPOINT.PRODUCTS_TRANSACTION_UPDATE,
    method: "put",
    data,
  });
}

function add_product_transaction(data) {
  return request({
    url: ENDPOINT.PRODUCT_TRANSACTIONS_ADD,
    method: "post",
    data,
  });
}

function update_product_transaction(data) {
  return request({
    url: ENDPOINT.PRODUCT_TRANSACTIONS_UPDATE,
    method: "post",
    data,
  });
}

function remove_product_transaction(id) {
  return request({
    url: ENDPOINT.PRODUCT_TRANSACTIONS_DELETE.replace(':id', id),
    method: "delete",
  });
}

export default {
  list,
  list_product_transactions,
  list_detail,
  list_transactions,
  create,
  update,
  get,
  create_transaction,
  update_transaction,
  add_product_transaction,
  update_product_transaction,
  remove_product_transaction
}
